/**
 * Copyright Christian Kubitza
 * christian@ck3d.de
 * 2015-2018
 */

var LigaMap = (function(self) {
	"use strict";

	self.label = self.label || {};

	var statRendered, statDisplayed;
	var labelCanvas, labelCanvasContext, labelCanvasScale, labelCanvasScaledWidth, labelCanvasScaledHeight, labelCanvasResolution,
		labelCanvasScaledWidthWithMargin, labelCanvasScaledHeightWithMargin, labelCanvasAspectWithMargin;
	var labelLoadedIconImages = {};

	var labelQueue, visibleLabels;
	var labelScreenUsage;
	var currentLabelScreenUsageCheckCellSize = [1.0, 1.0];
	var getLabelAtPositionData = null;
	var	renderCenterX, renderCenterY, renderZoom;
	var labelCamera, labelScene;


	var getTextCanvas = function(canvasScale, text, color, fontSetting, hasBorder, hasOutline) {
//		console.log("getTextCanvas()", canvasScale, text, color, fontSetting, hasBorder, hasOutline);
		var canvas	= document.createElement( 'canvas' );
		if (text && text.length > 0) {
			var context	= canvas.getContext( '2d' );
			context.scale(canvasScale, canvasScale);
			var fontSize = 17;//parseFloat(fontSetting);	// worst case value
			var textheight = hasBorder? (fontSize*1.5) : (fontSize*1.1);
			context.font = fontSetting;
			var measureText = (context.measureText(text)).width;	// zumindest Breite messen
			var canvasWidth = measureText + (hasBorder? 1*fontSize : 0.1*fontSize);
			var canvasHeight = textheight;
		canvas.width	= canvasWidth*canvasScale;
		canvas.height	= canvasHeight*canvasScale;
			
			context	= canvas.getContext( '2d' );
			context.scale(canvasScale, canvasScale);
			context.font = fontSetting;
			context.textAlign = "center";
			
			// Debug:
//			context.fillStyle = 'rgba(0,128,255,0.25)';
//			context.fillRect(0, 0, canvas.width/canvasScale, canvas.height/canvasScale);

		var textx = canvasWidth/2;
		var texty = canvasHeight/2 + 5;	// +5 passt bei User-Labels
			if (hasOutline) {
			context.strokeStyle = color;
				context.lineWidth = 3;
			context.lineJoin = "miter";
			context.miterLimit = 3;
			context.strokeText(text, textx, texty);
		}
		context.fillStyle = 'white';
		context.fillText(text, textx, texty);
			if (hasBorder) {
			context.beginPath();
				context.strokeStyle = 'white';
			context.lineWidth = 1;
			context.rect(1, 1, canvasWidth-2, canvasHeight-2);
			context.stroke();
		}
		}

		return canvas;
	};

	var getIconCanvas = function(canvasScale, iconUrl) {
		// TODO: Bei Bildern die Scale auf max 1.0 begrenzen?
//		console.log("getIconCanvas()", canvasScale, iconUrl);
		var canvas	= document.createElement('canvas');

		var draw = function(img) {
			var canvasWidth = img.naturalWidth;
			var canvasHeight = img.naturalHeight;
			canvas.width	= canvasWidth*canvasScale;
			canvas.height	= canvasHeight*canvasScale;
			var context	= canvas.getContext( '2d' );
			context.scale(canvasScale, canvasScale);

			// Debug:
//			context.fillStyle = 'rgba(128,0,255,0.25)';
//			context.fillRect(0, 0, canvas.width/canvasScale, canvas.height/canvasScale);

			context.drawImage(img, 0, 0, canvasWidth, canvasHeight);
		};
		
		if (labelLoadedIconImages[iconUrl]) {
			draw(labelLoadedIconImages[iconUrl]);
		} else {
			LigaMap.console.warn("icon not loaded: ", iconUrl);
			}
		
		return canvas;
	};
		
	var getLabelCanvasHash = function(label) {
		var s = label.text;
		if (label.type) {
			s += "_"+label.type+"_"+label.useSmallIcon+"_"+label.iconOnly+"_"+label.isSelected;
		}
		return s;
	};


	var renderLabel = function(config, label, alpha, checkLabelClickPosition) {
		if (config.labelRendering2D && !labelCanvasContext) return;
		if (config.labelRendering3D && !labelScene) return;
//		var alpha = label.alpha*alpha;
		var labelComposition = (label.type && config.labelCompositions[label.type])? config.labelCompositions[label.type] : [{}];
		var canvasExists = (label.userData.canvases && (label.userData.canvases.length === labelComposition.length));
		var canvasValid = true;
		var canvasCanBeRendered = true;
		var canvasHash = getLabelCanvasHash(label);
		if (canvasExists && canvasHash !== label.userData['hash']) {
			canvasValid = false;
//			LigaMap.console.log('label invalid, changed: ', label.userData['hash'], '->', canvasHash, label);
		}
		var compositionStateKeys = ["default"];	// siehe config.labelCompositions[][]
		if (label.isSelected)	compositionStateKeys.push("selected");
		if (label.useSmallIcon)	compositionStateKeys.push("small");
		if (label.iconOnly)		compositionStateKeys.push("iconOnly");
		if (label.useSmallIcon && label.iconOnly)		compositionStateKeys.push("smallIconOnly");
		
		var getCompositionValue = function(composition, property, defaultValue) {
			var value = defaultValue;
			if (composition[property] !== undefined) {
				if (typeof composition[property] === 'object') {	// is key-value
					var key;
					for (var i in compositionStateKeys) {
						key = compositionStateKeys[i];
						if (composition[property][key] !== undefined) {	// return selected
							value = composition[property][key];
						}
					}
				} else {	// is raw value
					value = composition[property];
				}
			}
			return value;
	};

		var getCurrentIcon = function(compositionIndex) {
			var iconName = getCompositionValue(labelComposition[compositionIndex], 'icon', undefined);
			if (iconName === undefined) {
				return false;	// no icon
			}
			if (!config.labelIcons[iconName]) {
				LigaMap.console.warn("icon not found in config", iconName, compositionIndex, label);
				return false;
			}
			return config.labelIcons[iconName];
		};

		var checkAllCompositionIconsLoaded = function() {
			var icon;
			var allIconsLoaded = true;
			for (var i=0; i<labelComposition.length; i++) {
				icon = getCurrentIcon(i);
				if (icon !== false) {
					if (labelLoadedIconImages[icon.url] === undefined) {
						//console.log("load icon: ", icon.url);					
						(function(icon) {
							labelLoadedIconImages[icon.url] = null;
							var img = new Image();
							img.onload = function() {
								//console.log("loaded icon: ", icon.url);
								labelLoadedIconImages[icon.url] = this;
							};
							img.onerror = function() {
								//console.log("error loading icon: ", icon.url);
								// delay next load try
								setTimeout(function() {
									labelLoadedIconImages[icon.url] = undefined;
								}, config.layerRetryFailedLoadDelay);
							};
							img.src = icon.url;
						})(icon);
						allIconsLoaded = false;
					} else if (labelLoadedIconImages[icon.url] === null) {
//						console.log("already loading icon and not complete: ", icon.url);
						allIconsLoaded = false;
					}
				}
			}
			return allIconsLoaded;
		};

		var getRenderObject = function(canvas) {
			var texture = new THREE.Texture(canvas);
			texture.generateMipmaps = false;
			texture.needsUpdate	= true;
			texture.premultiplyAlpha = false;
			texture.magFilter = THREE.LinearFilter;
			//texture.minFilter = THREE.LinearMipMapLinearFilter;
			texture.minFilter = THREE.LinearFilter;	// TODO: Vekleinerung insbes. bei Icons sieht nicht gut aus

			var lgeometry = new THREE.PlaneGeometry(canvas.width/labelCanvasScale, canvas.height/labelCanvasScale);
			var lmaterial = new THREE.ShaderMaterial({
				uniforms: {
					texture: { type: "t", value: texture },
					alpha: {type: "f", value: 1.0}
				},
				vertexShader: LigaMap.shader.getLabelShaderVertex(),
				fragmentShader: LigaMap.shader.getLabelShaderFragment(),
				depthWrite: false,
				depthTest: false,
				side: THREE.FrontSide,
				shading: THREE.FlatShading,
				transparent: true
			});

			var lobject = new THREE.Mesh(lgeometry, lmaterial);
			lobject.rotation.x = Math.PI;
			lobject.matrixAutoUpdate = false;
			return lobject;
		};
		
		if (!canvasExists || !canvasValid) {
			if (!checkAllCompositionIconsLoaded()) {
				canvasCanBeRendered = false;
		}
		}
		
		if ((!canvasExists || !canvasValid) && canvasCanBeRendered && (statRendered < config.performance.maxCreateLabelsPerFrame)) {	// Max. X Labels pro Frame neu generieren
			label.userData['hash'] = canvasHash;
			if (!canvasExists) {
				label.userData.canvases = [];
				label.userData.canvasObjects = [];
			}
			var icon, isIcon, canvas, coords, content;
			var centerX, centerY, offsetX, offsetY, referenceX, referenceY, scale;
			var renderedAnyCanvas = false;
			for (var i=0; i<labelComposition.length; i++) {
				icon = getCurrentIcon(i);
				isIcon = (icon !== false);
				content = isIcon? icon.url : label.text;
				if (label.userData.canvases[i] && (label.userData.canvases[i].currentCoords.content === content)) {
					// braucht nicht neu gerendert werden
					canvas = label.userData.canvases[i].canvas;
					} else {
					if (isIcon) {
						canvas = getIconCanvas(config.labelResolution, icon.url);
						// TODO: IconCanvas selbst cachen (evtl. abhängig von scale)
					} else {
						canvas = getTextCanvas(config.labelResolution, label.text, label.color, label.fontSetting, label.hasBorder, true);
						}
					renderedAnyCanvas = true;
					}
				centerX = (isIcon && icon.centerX)? (icon.centerX*config.labelResolution) : 0.5*canvas.width;
				centerY = (isIcon && icon.centerY)? (icon.centerY*config.labelResolution) : 0.5*canvas.height;
				offsetX = getCompositionValue(labelComposition[i], 'offsetX', 0.0)*config.labelResolution;
				offsetY = getCompositionValue(labelComposition[i], 'offsetY', 0.0)*config.labelResolution;
				scale = getCompositionValue(labelComposition[i], 'scale', 1.0);
				if (i > 0) {	// previous coords must exist
					referenceX = getCompositionValue(labelComposition[i], 'referenceX', null);
					if (referenceX !== null) {
						offsetX += coords.offsetX + coords.scale*label.userData.canvases[i-1].canvas.width*referenceX - coords.scale*coords.centerX;
					}
					referenceY = getCompositionValue(labelComposition[i], 'referenceY', null);
					if (referenceY !== null) {
						offsetY += coords.offsetY + coords.scale*label.userData.canvases[i-1].canvas.width*referenceY - coords.scale*coords.centerY;
					}
				}
				coords = {
					centerX: centerX,
					centerY: centerY,
					offsetX: offsetX,
					offsetY: offsetY,
					scale: scale,
					alpha: getCompositionValue(labelComposition[i], 'alpha', 1.0),
					content: content,
				};
				if (!label.userData.canvases[i]) {
					label.userData.canvases[i] = {currentCoords:coords};
					}
				label.userData.canvases[i].lastCoords = label.userData.canvases[i].currentCoords;
				label.userData.canvases[i].canvas = canvas;
				label.userData.canvases[i].currentCoords = coords;
				label.userData.canvases[i].isIcon = isIcon;
				if (label.userData.canvases[i].object && label.userData.canvases[i].object.parent) {
					labelScene.remove(label.userData.canvases[i].object);
					//LigaMap.console.log("removed from labelScene (invalid)", label.userData, i);
				}
				if (config.labelRendering3D) {
					label.userData.canvases[i].object = getRenderObject(canvas);
			}
		}
			//LigaMap.console.log("Rendered label composition for ", label.text, ": ", labelComposition);
			label.userData['fadeValue'] = 0.0;	// start state fade anim
			if (renderedAnyCanvas) statRendered++;
			//canvasExists = true;
			canvasValid = true;
		}
		
		var checkLabelClickPositionResult = -1;
		//if (checkLabelClickPosition !== null) console.log("check Label Click-Position", label.text);
		if (canvasExists) {
			checkLabelClickPositionResult = 0;
			alpha = alpha * label.alpha;
			for (var i=0; i<label.userData.canvases.length; i++) {
				if (label.userData.canvases[i].canvas.width > 0 && label.userData['renderCoords'][i] && label.userData['renderCoords'][i].visible) {
					if (config.labelRendering3D) {
						if (!label.userData.canvases[i].object.parent) {
							labelScene.add(label.userData.canvases[i].object);
							//LigaMap.console.log("added to labelScene", label.userData, i);
						}
						label.userData.canvases[i].object.material.uniforms.alpha.value = alpha * label.userData['renderCoords'][i].alpha;
						label.userData.canvases[i].object.position.x = (label.userData['renderCoords'][i].x+0.5*label.userData['renderCoords'][i].width);
						label.userData.canvases[i].object.position.y = (label.userData['renderCoords'][i].y+0.5*label.userData['renderCoords'][i].height);
						if (config.labelRendering2D) label.userData.canvases[i].object.position.y += 10;	// um besser zu vergleichen
						label.userData.canvases[i].object.scale.x = label.userData.canvases[i].object.scale.y = label.userData['renderCoords'][i].scale;
						label.userData.canvases[i].object.updateMatrix();
					}
					if (config.labelRendering2D) {
						labelCanvasContext.globalAlpha = alpha * label.userData['renderCoords'][i].alpha;
						labelCanvasContext.drawImage(label.userData.canvases[i].canvas, label.userData['renderCoords'][i].x, label.userData['renderCoords'][i].y, label.userData['renderCoords'][i].width, label.userData['renderCoords'][i].height);
					}
					if ((checkLabelClickPosition !== null) && (checkLabelClickPositionResult === 0)) {
						if (
							(checkLabelClickPosition.x >= label.userData['renderCoords'][i].x) &&
							(checkLabelClickPosition.x <= (label.userData['renderCoords'][i].x+label.userData['renderCoords'][i].width)) &&
							(checkLabelClickPosition.y >= label.userData['renderCoords'][i].y) &&
							(checkLabelClickPosition.y <= (label.userData['renderCoords'][i].y+label.userData['renderCoords'][i].height))
						) {
							//console.log("HIT canvas #", i);
							checkLabelClickPositionResult = (label.userData.canvases[i].isIcon? 2 : 1);	// provisorischer Click-Index
						}
					}
				}
			}
			// Debug
			//labelCanvasContext.globalAlpha = 1.0;
			//labelCanvasContext.fillStyle = '#ff00ff';
			//labelCanvasContext.fillRect(label.userData['screenPosition'].x-1, label.userData['screenPosition'].y-1, 2, 2);
		}
		
		return checkLabelClickPositionResult;
	}
	
	self.label.addToRenderQueue = function(id, x, y, z, objUserData, fontSetting, alpha, hasBorder, importance, useSmallIcon, iconOnly, clickableData, keepInView, labelLayer, isSelected) {
		var centerDistanceSquared = Math.pow(renderCenterX-x, 2) + Math.pow(renderCenterY-y, 2);
		var distanceRandomized = centerDistanceSquared + 0.5*renderZoom*renderZoom*objUserData.randVal;	// Zufall hinzu, für "unschärfere" Radius-Grenzen
		if (!objUserData.label) {
			objUserData.label = {};
		}
		labelQueue.push({
			id:id,
			labelLayer:labelLayer,
			x:x, y:y, z:z,
			fontSetting:fontSetting,
			alpha:alpha,
			hasBorder:hasBorder,
			importance:importance,
			useSmallIcon:(useSmallIcon==true),
			iconOnly:(iconOnly==true),
			clickableData:(clickableData? clickableData : null),
			centerDistanceSquared:centerDistanceSquared,
			centerDistanceSquaredRandomized:distanceRandomized,
			keepInView:(keepInView==true),
			isSelected:(isSelected==true),
			userData: objUserData.label,
			type: objUserData['iconType'],
			text: objUserData['name'],
			color: objUserData['color']
		});
	};


	self.label.render = function(config, timestamp, camera, toScreenPositionFunction, renderer, setRenderingIsDirtyFunction) {
		var i, j, label, labelid, pos, fadeInAlpha, fadeOutAlpha;
		//var labelNoImportanceDistanceSquared = Math.pow(config.labelNoImportanceDistance, 2);
		var sortedLabelQueue = labelQueue.sort(function(a, b) {
			// Nur wenn nicht sehr nah am Zentrum... (vorerst deaktiviert)
			//if ((a.centerDistanceSquared > labelNoImportanceDistanceSquared) && (b.centerDistanceSquared > labelNoImportanceDistanceSquared)) {
				// Sortierung nach mehr "importance"
				var d = (b.importance - a.importance);
				if (d != 0) {	// wenn ungleich, fertig
					return d;
				}
			//}
			// Sortierung nach weniger Distanz zum Zentrum
			return (a.centerDistanceSquaredRandomized - b.centerDistanceSquaredRandomized);
		});
		
		// Debug
		//var labelCanvasContext = labelCanvas.getContext("2d");
		//labelCanvasContext.beginPath();
		//labelCanvasContext.lineWidth = "1";
		//labelCanvasContext.strokeStyle = "green";
		//labelCanvasContext.rect(config.labelKeptInViewMargin, config.labelKeptInViewMargin, labelCanvasScaledWidthWithMargin, labelCanvasScaledHeightWithMargin);
		//labelCanvasContext.stroke();

		var updateLabelScreenCoords = function(label) {
			var labelVector = new THREE.Vector3(label.x, label.y, label.z);
			var objectScreenPos = toScreenPositionFunction(undefined, camera, labelVector);
			var keptInView = false;
			if (label.keepInView) {
				if ((objectScreenPos.x < config.labelKeptInViewMargin) || (objectScreenPos.x > config.labelKeptInViewMargin+labelCanvasScaledWidthWithMargin) || (objectScreenPos.y < config.labelKeptInViewMargin) || (objectScreenPos.y > config.labelKeptInViewMargin+labelCanvasScaledHeightWithMargin)) {
					keptInView = true;
					labelVector.applyMatrix4(camera.matrixWorldInverse);
					labelVector.x /= labelCanvasAspectWithMargin;
					var l = Math.max(Math.abs(labelVector.x), Math.abs(labelVector.y), 0.0001);
					labelVector.x /= l;
					labelVector.y /= l;
					objectScreenPos.x = config.labelKeptInViewMargin + (labelVector.x+1)*0.5 * labelCanvasScaledWidthWithMargin;
					objectScreenPos.y = config.labelKeptInViewMargin + (1.0 - (labelVector.y+1)*0.5) * labelCanvasScaledHeightWithMargin;
				}
			}
			label.userData['screenPosition'] = {x:objectScreenPos.x, y:objectScreenPos.y};
			var fadeValue = label.userData['keptInViewFadeValue'] || 0.0;
			// TODO: Realzeit-Abhängiger Fade
			if (keptInView) {
				label.userData['keptInViewFadeValue'] = Math.min(1.0, fadeValue+config.labelKeptInViewScaleFadeSpeed);
			} else {
				label.userData['keptInViewFadeValue'] = Math.max(0.0, fadeValue-config.labelKeptInViewScaleFadeSpeed);
			}
			var fadeValueEased = EasingFunctions.easeInOutQuad(fadeValue);
			var scale = 1.0*(1.0-fadeValueEased) + config.labelKeptInViewScale*fadeValueEased;

			var drawWidth, drawHeight, drawPosX, drawPosY;
			// TODO: Realzeit-Abhängiger Fade
			if (label.userData['fadeValue'] === undefined) label.userData['fadeValue'] = 1.0;
			label.userData['fadeValue'] = Math.min(1.0, label.userData['fadeValue']+config.labelKeptInViewScaleFadeSpeed);
			fadeValueEased = EasingFunctions.easeInOutQuad(label.userData['fadeValue']);
			label.userData['renderCoords'] = [];	// TODO: nicht immer neu erstellen (zu canvase packen?)
			if (label.userData.canvases && (label.userData.canvases.length > 0)) {
				var cScale, cAlpha, cOffsetX, cOffsetY, cCenterX, cCenterY, cVisible;
				for (var i=0; i<label.userData.canvases.length; i++) {
					cAlpha = label.userData.canvases[i].currentCoords.alpha*fadeValueEased + label.userData.canvases[i].lastCoords.alpha*(1-fadeValueEased);
					cVisible = (cAlpha > 0.0001);
					if (cVisible) {
						cScale = label.userData.canvases[i].currentCoords.scale*fadeValueEased + label.userData.canvases[i].lastCoords.scale*(1-fadeValueEased);
						cOffsetX = label.userData.canvases[i].currentCoords.offsetX*fadeValueEased + label.userData.canvases[i].lastCoords.offsetX*(1-fadeValueEased);
						cOffsetY = label.userData.canvases[i].currentCoords.offsetY*fadeValueEased + label.userData.canvases[i].lastCoords.offsetY*(1-fadeValueEased);
						cCenterX = label.userData.canvases[i].currentCoords.centerX*fadeValueEased + label.userData.canvases[i].lastCoords.centerX*(1-fadeValueEased);
						cCenterY = label.userData.canvases[i].currentCoords.centerY*fadeValueEased + label.userData.canvases[i].lastCoords.centerY*(1-fadeValueEased);
						drawWidth = cScale * label.userData.canvases[i].canvas.width/labelCanvasResolution;
						drawHeight = cScale * label.userData.canvases[i].canvas.height/labelCanvasResolution;
						drawPosX = objectScreenPos.x + scale * (cOffsetX - cScale*cCenterX)/labelCanvasResolution;
						drawPosY = objectScreenPos.y + scale * (cOffsetY - cScale*cCenterY)/labelCanvasResolution;
						label.userData['renderCoords'].push({x:drawPosX, y:drawPosY, width:(scale*drawWidth), height:(scale*drawHeight), alpha:cAlpha, visible:true, scale:(config.pixelRatio*scale*cScale/labelCanvasResolution)});
					} else {
						label.userData['renderCoords'].push({x:0, y:0, width:0, height:0, alpha:0, visible:false, scale:1});
					}
				}
			} else {	// no canvases -> fallback size (for adding to screen usage)
				label.userData['renderCoords'].push({x:objectScreenPos.x, y:objectScreenPos.y, width:1, height:1, alpha:1.0, visible:true, scale:1});
			}
			label.userData['renderCoordsTimestamp'] = timestamp;
		};
		
		var addToScreenUsage = function(label) {
			var getScreenUsageCellCoords = function(containerX, containerY) {
				var x = Math.floor(labelScreenUsage[0].length * containerX / labelCanvasScaledWidth);
				var y = Math.floor(labelScreenUsage[0][0].length * containerY / labelCanvasScaledHeight);
				return {x:x, y:y};
			};

			var maxValue = config.labelScreenUsageMaxCellValue[label.labelLayer];
			var min, max, cmin, cmax;
			var x, y, isUsed = false;
			var anythingInView = false;
			var screenUsageCells = {};
			for (var i=0; i<label.userData['renderCoords'].length; i++) if (label.userData['renderCoords'][i].visible) {
				min = getScreenUsageCellCoords(label.userData['renderCoords'][i].x, label.userData['renderCoords'][i].y);
				max = getScreenUsageCellCoords(label.userData['renderCoords'][i].x+label.userData['renderCoords'][i].width, label.userData['renderCoords'][i].y+label.userData['renderCoords'][i].height);
				cmin = {x:Math.max(0, min.x), y:Math.max(0, min.y)};
				cmax = {x:Math.min(labelScreenUsage[label.labelLayer].length-1, max.x), y:Math.min(labelScreenUsage[label.labelLayer][0].length-1, max.y)};
				if ((cmin.x > cmax.x) || (cmin.y > cmax.y)) {	// check if out of view
					continue;
				}
				anythingInView = true;
				if (maxValue > 0) {
					// ist der Platz überhaupt frei?
					for (x=cmin.x; x<=cmax.x; x++) {
						for (y=cmin.y; y<=cmax.y; y++) {
							if (labelScreenUsage[label.labelLayer][x][y] >= maxValue) {
								isUsed = true;
								break;
							}
							screenUsageCells[x+'_'+y] = [x, y];
						}
						if (isUsed) break;
					}
				}
				if (isUsed) break;
			}
			if (!anythingInView || isUsed) {
				return false;
			}
			// Platz markieren
			for (var i in screenUsageCells) {
				labelScreenUsage[label.labelLayer][screenUsageCells[i][0]][screenUsageCells[i][1]]++;
			}
			return true;
		};

		// Aktuelle Labels zu Liste der anzuzeigenden Labels hinzufügen bzw. aktualisieren
		j = 0;
		var foundLabelsAtPosition = [];
		//if (getLabelAtPositionData !== null) console.log("need to check labels");
		for (i=0; i<sortedLabelQueue.length; i++) {
			label = sortedLabelQueue[i];
			updateLabelScreenCoords(label);
			if (addToScreenUsage(label)) {
				labelid = label.id;
					if (visibleLabels[labelid] != undefined) {
						visibleLabels[labelid].lastVisible = timestamp;
						visibleLabels[labelid].label = label;
					} else {
						visibleLabels[labelid] = {firstVisible:timestamp, lastVisible:timestamp, label:label, lastAlpha:0.0};
					}
					j++;
					if (j >= config.performance.maxRenderLabelsPerFrame) {
						break;
					}
				}
			}

		// Liste der anzuzeigenden Labels abarbeiten (ein-/ausfaden, nach Fadeout aus Liste entfernen)
		var checkLabelClickPosition, checkLabelClickPositionResult, alpha;
		var renderChanged = false;
		var labelLastAlpha = 0, labelNewAlpha = 0, labelLastPos = {x:0,y:0};
		for (labelid in visibleLabels) {
			label = visibleLabels[labelid].label;
			fadeInAlpha = Math.max(0, Math.min(1, (timestamp - visibleLabels[labelid].firstVisible - config.labelFadeDelay*1000)/(config.labelFadeDuration*1000)));
			fadeOutAlpha = Math.max(0, Math.min(1, 1 - (timestamp - visibleLabels[labelid].lastVisible - 2*config.labelFadeDelay*1000)/(config.labelFadeDuration*1000)));
			alpha = Math.min(fadeInAlpha, fadeOutAlpha);
			if ((fadeOutAlpha > 0) && ((fadeInAlpha > 0) || timestamp == visibleLabels[labelid].lastVisible)) {	// nicht bereits ausgefadet und auch nicht schon wieder verschwunden während vor-fadein-delay
				if (label.userData['renderCoordsTimestamp'] < timestamp) { // für "ausfadende" außerhalb der view noch nicht berechnet
					updateLabelScreenCoords(label);
				}
				labelLastAlpha = visibleLabels[labelid].lastAlpha;
				//labelLastPos = (visibleLabels[labelid].lastPos? visibleLabels[labelid].lastPos : {x:0,y:0});
				checkLabelClickPosition = ((getLabelAtPositionData !== null) && (label.clickableData !== null))? getLabelAtPositionData : null;
				checkLabelClickPositionResult = renderLabel(config, label, alpha, checkLabelClickPosition);
				if (checkLabelClickPositionResult < 0) {
					// konnte nicht gerendert werden (canvas nicht fertig), fadein hinauszögern
					visibleLabels[labelid].firstVisible = timestamp;
				} else if (checkLabelClickPositionResult > 0) {
					// Click-Position hat getroffen
					foundLabelsAtPosition.push({
						data:label.clickableData, clickIndex:checkLabelClickPositionResult
					});
				}
				statDisplayed++;
				if (
					(Math.abs(labelLastAlpha-alpha) > 0.001)/* ||
					(Math.abs(labelLastPos.x-pos.x) > 0.001) ||
					(Math.abs(labelLastPos.y-pos.y) > 0.001)*/
				) {
					renderChanged = true;
				}
				visibleLabels[labelid].lastAlpha = alpha;
				//visibleLabels[labelid].lastPos = pos;
				// LigaMap.console.log(fadeInAlpha, fadeOutAlpha); return;
			} else {
				if (config.labelRendering3D) {
					if (label.userData && label.userData.canvases) {	// clean up labelScene before deletion
						var obj;
						for (var i=0; i<label.userData.canvases.length; i++) {
							obj = label.userData.canvases[i].object;
							if (obj.parent) {
								labelScene.remove(obj);
								//LigaMap.console.log("removed from labelScene", labelScene.children.length);//label.userData, i);
							}
							if (obj.material) {
								if (obj.material.uniforms && obj.material.uniforms.texture) {
									obj.material.uniforms.texture.value.dispose();
								}
								obj.material.dispose();
							}
							if (obj.geometry) {
								obj.geometry.dispose();
							}
							if (label.userData.canvases.canvas) {	// try to let browser faster free memory...
								label.userData.canvases.canvas.width = 0;
								label.userData.canvases.canvas.height = 0;
							}
						}
					}
				}
				delete(visibleLabels[labelid]);
				renderChanged = true;
			}
			label.userData['alpha'] = alpha;	// um Alpha auf Connection-Objekte zu übertragen
		}
		if (getLabelAtPositionData !== null) {
			getLabelAtPositionData.callBack(foundLabelsAtPosition);
			getLabelAtPositionData = null;
		}
		if (config.labelRendering3D) {
			renderer.render(labelScene, labelCamera);
		}
		if (renderChanged && setRenderingIsDirtyFunction) {
			setRenderingIsDirtyFunction("labelRender");
		}

		// Debug ScreenUsage
		if (config.labelScreenUsageDebug >= 0 && labelCanvas) {
			var labelCanvasContext = labelCanvas.getContext("2d");
			labelCanvasContext.globalAlpha = 0.5;
			var cellWidth = labelCanvas.width / (labelCanvasScale*labelScreenUsage[config.labelScreenUsageDebug].length);
			var cellHeight = labelCanvas.height / (labelCanvasScale*labelScreenUsage[config.labelScreenUsageDebug][0].length);
			for (var x=0; x<labelScreenUsage[config.labelScreenUsageDebug].length; x++) {
				for (var y=0; y<labelScreenUsage[config.labelScreenUsageDebug][x].length; y++) if (labelScreenUsage[config.labelScreenUsageDebug][x][y] > 0) {
					var usageNormalized = Math.max(0, Math.min(1, (labelScreenUsage[config.labelScreenUsageDebug][x][y]-1)/4));	// Heat-Coloring 5-steps
					var heatRed = Math.round(255 * Math.min(1.0, usageNormalized*2));
					var heatGreen = Math.round(255 * Math.min(1.0, (1-usageNormalized)*2));
					labelCanvasContext.fillStyle = "#" + ("0"+heatRed.toString(16)).substr(-2) + ("0"+heatGreen.toString(16)).substr(-2) + "00";
					labelCanvasContext.fillRect(x*cellWidth+0.25, y*cellHeight+0.25, cellWidth-0.5, cellHeight-0.5);
				}
			}
		}
	};


	self.label.getLabelAtPositionOnNextRender = function(x, y, callBack) {
		getLabelAtPositionData = {x: x, y: y, callBack: callBack};
	};


	self.label.init = function(config, containerWidth, containerHeight) {
		if (config.labelRendering2D) {
		if (labelCanvas) {
			return labelCanvas;
		}
			LigaMap.console.log("Init labels with 2D-Rendering")
		labelCanvas = document.createElement( 'canvas' );
		}
		if (config.labelRendering3D) {
			if (labelScene) {
				return labelCanvas;	// undefined
			}
			LigaMap.console.log("Init labels with 3D-Rendering");
			labelScene = new THREE.Scene();
			labelCamera = new THREE.OrthographicCamera( -1, 1, -1, 1, -500, 500 );
			labelCamera.matrixAutoUpdate = false;
		}
		self.label.updateSize(config, containerWidth, containerHeight);
		visibleLabels = {};
		return labelCanvas;
	};


	self.label.updateSize = function(config, containerWidth, containerHeight) {
		labelCanvasScale = Math.max(config.pixelRatio, 0.001);
		if (config.labelRendering2D) {
		labelCanvas.width = containerWidth*labelCanvasScale;
		labelCanvas.height = containerHeight*labelCanvasScale;
		labelCanvas.style.width = containerWidth+"px";
		labelCanvas.style.height = containerHeight+"px";
		labelCanvasContext = labelCanvas.getContext('2d');
		labelCanvasContext.scale(labelCanvasScale, labelCanvasScale);
		}
		labelCanvasScaledWidth = containerWidth;
		labelCanvasScaledHeight = containerHeight;
		labelCanvasResolution = config.labelResolution;
		if (config.labelResolutionDPIAgnostic) {
			labelCanvasResolution = config.labelResolution*config.labelResolutionDPIAgnosticReferenceHeight/containerHeight;
		}
		labelCanvasScaledWidthWithMargin = Math.max(1, labelCanvasScaledWidth-2*config.labelKeptInViewMargin);
		labelCanvasScaledHeightWithMargin = Math.max(1, labelCanvasScaledHeight-2*config.labelKeptInViewMargin);
		labelCanvasAspectWithMargin = labelCanvasScaledWidthWithMargin/Math.max(0.1, labelCanvasScaledHeightWithMargin);
		
		if (labelCamera) {
			labelCamera.left = 0;
			labelCamera.right = containerWidth;
			labelCamera.top = 0;
			labelCamera.bottom = containerHeight;
			labelCamera.updateProjectionMatrix();
		}

		// screen usage
		if (config.labelResolutionDPIAgnostic) {
			currentLabelScreenUsageCheckCellSize[1] = Math.ceil(containerHeight / (config.labelResolutionDPIAgnosticReferenceHeight/config.labelScreenUsageCheckCellSize[1]));
			currentLabelScreenUsageCheckCellSize[0] = Math.ceil(config.labelScreenUsageCheckCellSize[0] * currentLabelScreenUsageCheckCellSize[1]/config.labelScreenUsageCheckCellSize[1]);
		} else {
			currentLabelScreenUsageCheckCellSize[0] = config.labelScreenUsageCheckCellSize[0];
			currentLabelScreenUsageCheckCellSize[1] = config.labelScreenUsageCheckCellSize[1];
		}
		var cellCountX = Math.max(1, Math.round(containerWidth / currentLabelScreenUsageCheckCellSize[0]));
		var cellCountY = Math.max(1, Math.round(containerHeight / currentLabelScreenUsageCheckCellSize[1]));
		labelScreenUsage = new Array(config.labelScreenUsageMaxCellValue.length);
		for (var l=0; l<config.labelScreenUsageMaxCellValue.length; l++) {
			labelScreenUsage[l] = new Array(cellCountX);
			for (var i=0; i<labelScreenUsage[l].length; i++) {
				labelScreenUsage[l][i] = new Array(cellCountY);
			}
		}
	};


	self.label.prepareRender = function(centerX, centerY, zoom) {
		renderCenterX = centerX;
		renderCenterY = centerY;
		renderZoom = zoom;
		statRendered = 0;
		statDisplayed = 0;
		if (labelCanvasContext) {
		labelCanvasContext.clearRect(0, 0, labelCanvas.width/labelCanvasScale, labelCanvas.height/labelCanvasScale);
		}
		labelQueue = [];
		// screen usage
		for (var l=0; l<labelScreenUsage.length; l++) {
			for (var i=0; i<labelScreenUsage[l].length; i++) {
				for (var j=0; j<labelScreenUsage[l][i].length; j++) {
					labelScreenUsage[l][i][j] = 0;
			}
		}
		}
	};


	self.label.getStatRendered = function() {
		return statRendered;
	};

	self.label.getStatOverall = function() {
		return labelQueue.length;
	};

	self.label.getStatDisplayed = function() {
		return statDisplayed;
	};

	self.label.getLabelCanvasContext = function() {
		return labelCanvasContext;
	};

	self.label.getLabelCanvasResolution = function() {
		return labelCanvasResolution;
	}


	return self;
}(LigaMap || {}));
