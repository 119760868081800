/**
 * Copyright Christian Kubitza
 * christian@ck3d.de
 * 2015-2019
 */

var LigaMap = (function(self) {
	"use strict";

	self.console = SwitchableConsole("LigaMap", true, false);

	return self;
}(LigaMap || {}));



var LigaMap = (function(self) {
	"use strict";
	
	self.debugDurationQuadtree = 0.0;

	var LigaMapInstance = function($, container, _config, initFinishedCallback) {
		var config = LigaMap.getDefaultConfig();
		var camera, cameraFrustum, renderer, stats, /*selectedObject, */tempCamera, renderCamera;
		var cameraHelper, cameraHelperScene;
		var containerWidth, containerHeight;
		var lastRenderTimestamp = 0.0, lastRenderTimestampDilated = 0.0, currentTimeDilation = 0.0;
		var fpsHistoryFrameCounter = 0;
		var fpsHistory = Array();
		var fpsHistoryIndex = 0;
		var fpsHistoryTimestamp = 0;
		var fpsHistoryAverage = 0.0;
		var fpsHistoryLastRating = '';
		var loadingStatusContainer = null;
		var loadingCount = 0;
		var interactionPlane, interactionCamera, cameraPositionReference, cameraSpeedReference, cameraSpeedReferenceSmoothed, cameraZoomed;
		var lastLayerUpdateSkipped = false;
		var backgroundIsLoading = 0;
		var dblClickZoomType = 0;	// >0, ==0, <0
		var maxSupportedTextureSize = -1;
		var heatmapConfig = [];
		var dirLight, dirLightLo, dirLightShadowMapViewer;
		var spotLightHelper, spotLightShadowHelper;
		var currentSelectedUser, currentSelectedConnection = null, connectionsAreAnimated = false;
		var initFinished = false, initStarted = false;
		var introStarted = false;
		var introFinished = false;
		var updateLayerIntervalID = null, loadLayerBackgroundIntervalID = null;
		var userDetailElement = {lastX: 0, lastY: 0, lastCX: 0, lastCY: 0, forcePositionUpdate: false};
		var debugDurationQuadtreePrintValue = 0;
		var debugDurationQuadtreePrintValueLastTimestamp = 0;
		var isZoomingAlpha = 1.0, isZoomingSourceZoom = 0.0, isZoomingEstimatedTargetZoom = 0.0, isZoomingLastEstimatedTargetZoom = 0.0, wasZooming = false;
		var debug3DHelpers = {};
		var isDirtyTimestamp = true;
		var isDirtyReasons = {count:0};
		

		var self = this;

		var heatmapDebug = location.search.toLowerCase().indexOf('hmdebug')>=0;
		var showShadowMapViewer = location.search.toLowerCase().indexOf('shadowmapviewer')>=0;
		var containedRadiusDebugObject;

		var activeCameraAnimation = null;
		var activeCameraViewOffsetAnimation = null;
		var input;
		//var gemGrShader, krsGrShader, bldGrShader;
		var layers = {};	// ... of Layer()




		// Privileged

		this.isLoadingPossible = function() {
			return (loadingCount < config.maxConcurrentLoadCount);
		};

		this.getCameraPosition = function() {
			return camera.position;
		};

		this.getConfig = function(itemArray) {
			var returnConfig = {};
			if (typeof(itemArray) === typeof(undefined)) {
				returnConfig = config;
			} else {
				for (var c in itemArray) {
					// LigaMap.console.log(itemArray[c]);
					if (config[itemArray[c]] != undefined) {
						returnConfig[itemArray[c]] = config[itemArray[c]];
					}
				}
			}
			return returnConfig;
		};

		this.setConfig = function(newConfig) {
			for (var c in config) {
				if (newConfig[c] != undefined) {
					if (initFinished) {
						// Abort update?
						switch (c) {
							case "generateFloortiles":
							case "generateFloortileBuildings":
								if (!layers['stadium_floor']) {
									LigaMap.console.log("... cannot update setting, please restart map with setting set: ", c, newConfig[c]);
								}
								continue;
								break;
						}
					}
					config[c] = newConfig[c];
					if (initFinished || (c=="userPosition") || ((c=="renderActive") && config[c])) {
						LigaMap.console.log("set config: ", c, newConfig[c]);
						// Live-Update
						switch (c) {
							case "colorHeatmap":
								for (var i in layers['heatmap']['usedMaterials']) {
									var m = layers['heatmap']['usedMaterials'][i];
									if (m.name == 'heatmap') {
										m.uniforms.color.value.set(newConfig[c]);
									}
								}
								break;
							case "fov":
							case "fovPortraitCorrectionFactor":
								self.containerSizeChanged();
								break;
							case "showConnections":
								setSelectedConnection(null);
								connectionsAreAnimated = false;
								for (var ci in config.showConnections) if (config.showConnections[ci].direction > 0) {
									connectionsAreAnimated = true;
									break;
								}
								LigaMap.mesh.buildConnections(config, layers, getLastRenderTimestamp);
								break;
							case "showLeagues":
								LigaMap.mesh.loadLeagues(config, layers, getLastRenderTimestamp, function(data) {
//									LigaMap.console.log("loadLeagues finished", data);
									if (data && (data.length > 0)) {
										// Bounding Box aller Ligen berechnen
										var bbMinX = config.maxPanX, bbMaxX = config.minPanX, bbMinY = config.maxPanY, bbMaxY = config.minPanY;
										var leagueData = {};
										for (var l in data) {
											if (data[l].minX < bbMinX) bbMinX = data[l].minX;
											if (data[l].maxX > bbMaxX) bbMaxX = data[l].maxX;
											if (data[l].minY < bbMinY) bbMinY = data[l].minY;
											if (data[l].maxY > bbMaxY) bbMaxY = data[l].maxY;
											leagueData[data[l].leagueID] = {leagueID:data[l].leagueID, name:data[l].name, level:data[l].level};
										}
										container.trigger("mapLeaguesLoaded", {
											leagues: leagueData,
											boundingBox: {
												min: {x: bbMinX, y: bbMinY},
												max: {x: bbMaxX, y: bbMaxY}
											},
//											leagueData: data
										});
									}
								}, updateLoadingIndicator);
								break;
							case "renderActive":
								afterRenderActiveChange();
								break;
							case "userPosition":
								afterUserPositionChange();
								break;
							case "centerView":
								animateCameraTo({
									x: newConfig[c].center? newConfig[c].center[0] : undefined,
									y: newConfig[c].center? newConfig[c].center[1] : undefined,
									z: newConfig[c].zoom,
									radius: newConfig[c].radius,
									yaw: newConfig[c].yaw,
									tilt: newConfig[c].tilt,
									duration: newConfig[c].duration,
									doShortestYaw: newConfig[c].doShortestYaw,
									useCenterOffset: (newConfig[c].useCenterOffset!==undefined)? (newConfig[c].useCenterOffset==true) : true,
									autoDuration: newConfig[c].autoDuration,
									screenOffsetX: newConfig[c].screenOffsetX,
									screenOffsetY: newConfig[c].screenOffsetY
								});
								break;
							case "labelScreenUsageDebug":
								if (!config["labelRendering2D"]) LigaMap.console.log("... not working when labelRendering2D is false");
								break;
							case "showBoundingBoxes":
							case "generateFloortiles":
							case "generateFloortileBuildings":
								LigaMap.console.log("... affects only future geometry additions");
								break;
							case "leaguesVisible":
							case "usersVisible":
							case "gemVisible":
							case "allLabelsVisible":
							case "fogEnabled":
							case "colorHeatmapUserMin":
							case "colorHeatmapUserMax":
							case "showQuadtree":
							case "showDebugText":
							case "leageLODLimits":
							case "labelScreenUsageMaxCellValue":
							case "introAnimation":
							case "labelIconDetailOffsets":
							case "labelKeptInViewMargin":
								// z.B. in renderLoop() benutzt, keine Aktualisierung nötig, nur default-Warnung verhindern
								break;
							default:
								LigaMap.console.log("... no live-update of changed setting available");
						}
					}
				}
			}
			if (config.showLoadingStatus || config.showDebugText) {
				if (loadingStatusContainer === null) {
					loadingStatusContainer = $('<div class="loading"></div>');
					container.append(loadingStatusContainer);
				}
			} else {
				if (loadingStatusContainer !== null) {
					loadingStatusContainer.remove();
					loadingStatusContainer = null;
				}
			}
			setRenderingIsDirty("setConfig");
		};

		this.toggleLayer = function(which, visible) {
			if (layers[which]) {
				// LigaMap.console.log(layers[which]);
				if (visible != undefined) {
					layers[which].visible = visible? true : false;
				} else {

					layers[which].visible = !layers[which].visible;
				}
				layers[which].autoVisible = false;
				setRenderingIsDirty("toggleLayer");
			} else {
				LigaMap.console.log("layer not defined: "+which);
				return false;
			}
			return true;
		};

		this.getLayerValues = function() {
			var values = {};
			for (var i in layers) {
				values[i] = {};
				for (var j in layers[i]) {
					switch (j) {
						case 'ready':
						case 'minZoom':
						case 'maxZoom':
						// case 'visible':
						case 'neverRender':
						case 'neverUnload':
						case 'alphaAnimationSpeed':
						case 'alphaZoomLink':
						case 'alphaZoomLinkMinFade':
						case 'alphaZoomLinkMaxFade':
						case 'alpha':
							values[i][j] = layers[i][j];
							break;
						case 'quadtree':
							values[i]['isLoadable'] = layers[i][j].isLoadable();
							values[i]['objectCount'] = (layers[i][j].getAllObjects()).length;
							var t = layers[i][j].getLoadedLeavesCount();
							values[i]['loadedLeavesCount'] = t['loadedLeavesCount'];
							values[i]['loadedObjectsCount'] = t['loadedObjectsCount'];
							break;
						case 'scene':
							values[i]['sceneCount'] = (layers[i][j] && layers[i][j].children)? layers[i][j].children.length : 0;
							break;
						default:
					}
				}
			}
			return values;
		};

		this.getLayer = function(which) {
			if (!layers[which]) {
				return;
			}
			return layers[which];
		};

		this.setLayerValues = function(values) {
			for (var i in values) if (layers[i] != undefined) {
				for (var j in values[i]) {
					switch (j) {
						case 'minZoom':
						case 'maxZoom':
						// case 'visible':
						case 'alphaAnimationSpeed':
						case 'alphaZoomLink':
						case 'alphaZoomLinkMinFade':
						case 'alphaZoomLinkMaxFade':
							// LigaMap.console.log("set ", j, " to ", values[i][j]);
							layers[i][j] = values[i][j];
							break;
						default:
							LigaMap.console.log("unknown Setting: ", j);
					}
				}
			} else {
				LigaMap.console.log("unknown Layername: ", i);
			}
			setRenderingIsDirty("setLayerValues");
		};
		
		this.removeObjectsFromLayer = function(layerName, objects, notFromQuadtree) {
			// LigaMap.console.log("LigaMapInstance.removeObjectFromLayer()", layerName, objects.length, notFromQuadtree);
			if (!layers[layerName]) {
				return false;
			}
			if (!notFromQuadtree) {
				// console.time("LigaMapInstance.removeObjectsFromLayer (quadtree) "+layerName);
				layers[layerName].quadtree.removeObjects(objects);
				// console.timeEnd("LigaMapInstance.removeObjectsFromLayer (quadtree) "+layerName);
			}
			// console.time("LigaMapInstance.removeObjectsFromLayer (layers) "+layerName);
			var ret = layers[layerName].removeObjects(objects, true);
			// console.timeEnd("LigaMapInstance.removeObjectsFromLayer (layers) "+layerName);
			return ret;
		};

		this.getLoadedUsers = function() {
			var allUserObjects = layers['user-lb'].quadtree.getAllObjects();
			if (!allUserObjects) {
				return [];
			}
			var allUsers = new Array(allUserObjects.length);
			for (var i=0; i<allUserObjects.length; i++) {
				var o = allUserObjects[i];
				allUsers[i] = {
					id: o.userData.id,
					x: o.position.x,
					y: o.position.y,
					name: o.userData.name,
					color: o.userData.origColor
				};
			}
			return allUsers;
		};

		/**
		 * Updates position of selected user (and enables visibility)
		 * 
		 * @param {number} x 
		 * @param {number} y 
		 * @param {number} [z]
		 * @returns {boolean} - false if there is currently no selected user
		 */
		this.updateSelectedUserPosition = function(x, y, z) {
			if (currentSelectedUser != undefined) {
				currentSelectedUser.position.x = x;
				currentSelectedUser.position.y = y;
				currentSelectedUser.position.z = (z != undefined)? z : 0;
				currentSelectedUser.visible = true;
				userDetailElement.forcePositionUpdate = true;
				setRenderingIsDirty("updateSelectedUserPosition");
				return true;
			}
			return false;
		};
		
		this.invalidateUserData = function() {
			if (!initFinished) {
				return;
			}
			LigaMap.console.log("invalidateUserData()");
			var userLayerNames = ['user', 'userpos', 'league', 'heatmap', 'stadium_dots', 'stadium_floor', 'stadium_shadow', 'stadium_lo', 'stadium', 'user-lb'];
			for (var n in userLayerNames) if (layers[userLayerNames[n]]) {
				layers[userLayerNames[n]].clear();
			}
			LigaMap.mesh.loadedStadiumDots = {};
			loadHeatmap();
			self.unselectConnection();
			if (config.showLeagues && Array.isArray(config.showLeagues) && (config.showLeagues.length > 0)) {
				LigaMap.mesh.loadLeagues(config, layers, getLastRenderTimestamp, function() {}, updateLoadingIndicator);
			}
		};

		this.set3DHelper = function(id, x, y, z, scale) {
			if (!id) return;
			if (!cameraHelperScene) return;
			if (!debug3DHelpers[id]) {
				var di = 0;
				for (var i=0;i<id.length;i++) {
					di += id.charCodeAt(i);
				}
				var object = new THREE.Mesh( new THREE.CircleGeometry(1, 4), new THREE.MeshBasicMaterial( {name:'3DHelper_'+id, color: LigaMap.debugColors[di%LigaMap.debugColors.length], wireframe: true } ) );
				debug3DHelpers[id] = object;
				cameraHelperScene.add(object);
			}
			if (x !== undefined) debug3DHelpers[id].position.x = x;
			if (y !== undefined) debug3DHelpers[id].position.y = y;
			if (z !== undefined) debug3DHelpers[id].position.z = z;
			if (scale !== undefined) {
				debug3DHelpers[id].scale.x = debug3DHelpers[id].scale.y = debug3DHelpers[id].scale.z = scale;
			}
		};

		this.remove3DHelper = function(id) {
			if (debug3DHelpers[id]) {
				cameraHelperScene.remove(debug3DHelpers[id]);
				delete debug3DHelpers[id];
			}
		};
		
		this.forceUserColor = function(r, g, b, a) {
			if (layers['stadium'] && layers['stadium'].scene) {
				layers['stadium'].scene.traverse(function(element) {
					if (element.geometry && element.geometry.attributes && element.geometry.attributes.userColors) {
						var attribute = element.geometry.getAttribute('userColors');
						for (var i=0; i<attribute.count; i++) {
							attribute.setXYZW(i, r, g, b, a);
						}
						attribute.needsUpdate = true;
					}
				});
			}
			if (layers['stadium_lo'] && layers['stadium_lo'].scene) {
				layers['stadium_lo'].scene.traverse(function(element) {
					if (element.geometry && element.geometry.attributes && element.geometry.attributes.userColors) {
						var attribute = element.geometry.getAttribute('userColors');
						for (var i=0; i<attribute.count; i++) {
							attribute.setXYZW(i, r, g, b, a);
						}
						attribute.needsUpdate = true;
					}
				});
			}
		};

		// private functions

		var getLastRenderTimestamp = function(dilated) {
			if (dilated===true)	return lastRenderTimestampDilated;
			return lastRenderTimestamp;
		};
		
		var setRenderingIsDirty = function(reason) {
			if (!reason) reason = 'unknown';
			if (!isDirtyReasons[reason]) isDirtyReasons[reason] = 0;
			isDirtyReasons[reason]++;
			isDirtyReasons.count++;
			isDirtyTimestamp = lastRenderTimestamp;
		};

		var init = function(ignoreConfigLateInit) {
			if (initStarted) {
				return;
			}

			// override if not possible
			if (!window.Worker && config['useWebworker']) {
				_config['useWebworker'] = false;
				LigaMap.console.warn("no webworker support by browser");
			}
			// get config options
			if (_config != undefined) {
				self.setConfig(_config);
				_config = undefined;
			}

			if (config.lateInit && !ignoreConfigLateInit) {
				return;
			}
			initStarted = true;

			LigaMap.mesh.init(config, function() { return layers;}, getLastRenderTimestamp);

			setupWebGL();

			$(function() {
				input = LigaMap.input.setupControls(
					container,
					config,
					setDoubleClickZoomOutMovement,
					getInteractionPlanePosition,
					selectAtScreenPosition,
					setSaveCameraPosition,
					function() {
						cameraSpeedReference.x = cameraSpeedReferenceSmoothed.x;
						cameraSpeedReference.y = cameraSpeedReferenceSmoothed.y;
						cameraSpeedReference.z = cameraSpeedReferenceSmoothed.z;
					},
					setZoomCamera,
					function() {
						return cameraSpeedReference;
					},
					setSelectedUser,
					setRenderingIsDirty,
					setSelectedConnection,
					self.set3DHelper,
					self.remove3DHelper
				);
				input.setCameraPosition(camera.position);
				input.setInputEnabled(introFinished);
				setSmoothMotion(config.smoothMotion);
				setRenderingIsDirty("documentReady");
				container.trigger("mapInitFinished");
			});

			initFinished = true;
			afterRenderActiveChange();
			
			if (typeof initFinishedCallback === "function") {
				initFinishedCallback();
			}
		};
		
		this.containerSizeChanged = function() {
			if (!initFinished) return;
				containerWidth = container.innerWidth();
				containerHeight = Math.max(1, container.innerHeight());
				camera.aspect = containerWidth / containerHeight;
			camera.fov = config.fov*LigaMap.getCameraFOVCorrection(camera.aspect, config.fovPortraitCorrectionFactor);
			renderCamera.fov = camera.fov;
			tempCamera.fov = camera.fov;
				camera.updateProjectionMatrix();
				renderCamera.aspect = camera.aspect;
				renderCamera.updateProjectionMatrix();
				tempCamera.aspect = camera.aspect;
				tempCamera.updateProjectionMatrix();
				renderer.setSize(containerWidth, containerHeight);
				LigaMap.label.updateSize(config, containerWidth, containerHeight);
				if (config.useRealtimeShadow && showShadowMapViewer) {
					dirLightShadowMapViewer.updateForWindowResize();
				}
		};
		
		this.getInput = function() {
			return input;
		};

		var setSelectedUser = function(position, userData) {
			// always unselect connection
			setSelectedConnection(null);
			setRenderingIsDirty("setSelectedUser");
			var wasValid = (currentSelectedUser != undefined);
			currentSelectedUser = undefined;
			var positionNotInView = false;
			if (userData == undefined) {
				currentSelectedUser = undefined;
			} else {
				currentSelectedUser = new THREE.Object3D();
				if ((position === undefined) && (userData.origPosition !== undefined)) {
					position = userData.origPosition;
				}
				if (position !== undefined) {
					currentSelectedUser.position.x = position.x;
					currentSelectedUser.position.y = position.y;
					currentSelectedUser.position.z = position.z;
					userDetailElement.forcePositionUpdate = true;
					if (cameraFrustum && !cameraFrustum.containsPoint(position)) {
						positionNotInView = true;
					}
				}
				currentSelectedUser.visible = (position !== undefined);
				currentSelectedUser.userData = userData;
			}
			// LigaMap.console.log(currentSelectedUser);
			if (currentSelectedUser != undefined) {
				config['userPosition'] = {x:currentSelectedUser.position.x, y:currentSelectedUser.position.y, id:currentSelectedUser.userData.id, name:currentSelectedUser.userData.name};
				container.trigger("mapUserSelected", [currentSelectedUser.userData, currentSelectedUser.visible, positionNotInView? position : null]);
				return true;
			} else {
				config['userPosition'] = null;
				if (wasValid) {
					container.trigger("mapUserUnselected");
				}
				return false;
			}
		};

		this.unselectConnection = function() {
			setSelectedConnection(null);
		};
		
		var setSelectedConnection = function(connection) {
			if (currentSelectedConnection === connection) {
				connection = null;
			}
			if (currentSelectedConnection !== connection) {
				currentSelectedConnection = connection;
				var centerView = null;
				var inView = false;
				if (
					currentSelectedConnection !== null &&
					currentSelectedConnection.userData &&
					currentSelectedConnection.userData['labelObject'] &&
					currentSelectedConnection.userData['labelObject'].userData &&
					currentSelectedConnection.userData['labelObject'].userData['origPosition']
				) {
					centerView = currentSelectedConnection.userData['labelObject'].userData['origPosition'];
					inView = (cameraFrustum && cameraFrustum.containsPoint(centerView));
				}
				container.trigger("mapConnectionSelected", [connection? connection.userData : null, centerView, inView]);
				userDetailElement.forcePositionUpdate = true;
			}
		};
		
		var selectAtScreenPosition = function(screenX, screenY) {
			// Connection angeklickt?
			if (camera.position.z < layers['connections'].maxZoom) {
				var projectedPositionForConnection = getInteractionPlanePosition(screenX, screenY, false, config.connectionHeight);
				var closestConnections = layers['connections'].quadtree.getObjectsInRange(projectedPositionForConnection, config.selectUserToleranceRadius, false, 'connectionSegment');
				var closestConnection = null;
				var closestConnectionDistanceSquared = 0.0;
				var maxSelectToleranceSquared = Math.pow(config.selectConnectionToleranceFactor * getContainedRadius(camera.position.z, true), 2);
				for (var i in closestConnections) {
					var o = closestConnections[i];
					var closestPoint = o.userData.connectionSegment.closestPointToPoint(projectedPositionForConnection, true);
					var distanceSquared = closestPoint.distanceToSquared(projectedPositionForConnection);
					if (distanceSquared < maxSelectToleranceSquared) {
						if ((closestConnection===null) || (distanceSquared < closestConnectionDistanceSquared)) {
							closestConnection = o;
							closestConnectionDistanceSquared = distanceSquared;
						}
					}
				}
				if (closestConnection !== null) {
					if (currentSelectedConnection === closestConnection) {	// click on selected -> unselect
						closestConnection = null;
					}
					setSelectedConnection(closestConnection);
					return true;
				} else {				
					setSelectedConnection(closestConnection);
				}
			} else {
				LigaMap.console.log("too far away to select connection");
			}
			// User angeklickt?
			if (camera.position.z < config.selectUserMaxZoom) {
				var projectedPosition = getInteractionPlanePosition(screenX, screenY, false);
				if (projectedPosition) {
					return selectUserAtWorldPosition(projectedPosition);
				}
			} else {
				LigaMap.console.log("too far away to select user");
			}
			return false;
		};

		var selectUserAtWorldPosition = function(projectedPosition) {
			// LigaMap.console.log("selectUserAtWorldPosition()", projectedPosition);
			// var position = projectedPosition;
			var closestUser = layers['user-lb'].quadtree.getObjectsInRange(projectedPosition, config.selectUserToleranceRadius, true);
			if (closestUser.length > 0) {
				return setSelectedUser(closestUser[0].position, closestUser[0].userData);
			} else {
				return false;
			}
		};
		
		var restartFPSHistory = function() {
			fpsHistory = Array();
			fpsHistoryTimestamp = Date.now();
			fpsHistoryFrameCounter = 0;
			fpsHistoryLastRating = '';
		};

		var afterRenderActiveChange = function() {
			if (config.renderActive) {
				setRenderingIsDirty("afterRenderActiveChange");
				init(true);
				if (loadLayerBackgroundIntervalID != null) {
					window.clearInterval(loadLayerBackgroundIntervalID);
				}
				loadLayerBackgroundIntervalID = null;
				if (updateLayerIntervalID == null) {
					restartFPSHistory();
					updateLayerIntervalID = window.setInterval(updateLayerVisiblity, config.layerUpdateInterval);
					// updateLayerVisiblity();	// unnütz, da frustum noch nicht bereit (TODO: warum?)
					renderLoop();
				}

			} else {
				if (updateLayerIntervalID != null) {
					window.clearInterval(updateLayerIntervalID);
				}
				updateLayerIntervalID = null;
				if (loadLayerBackgroundIntervalID == null) {
					loadLayerBackgroundIntervalID = window.setInterval(loadLayersWhenInactive, config.layerBackgroundLoadInterval);
				}
			}
			if (input) {
				input.setInputEnabled(config.renderActive);
			}
		};
		
		var afterUserPositionChange = function() {
			var success = false;
			var c = "userPosition";
			if ((config[c].x != undefined) && (config[c].y != undefined)) {
				if ((config[c].id != undefined) && (config[c].name != undefined)) {
					success = setSelectedUser(new THREE.Vector3(config[c].x, config[c].y, 0), {id:config[c].id, name:config[c].name});
				} else {
					success = selectUserAtWorldPosition(new THREE.Vector3(config[c].x, config[c].y, 0));
				}
			} else if (config[c].id != undefined) {						
				success = setSelectedUser(undefined, {id:config[c].id});	// nur id, Position muss nachträglich noch gesetzt werden (solange nicht sichtbar)
			} else {
				setSelectedUser();	// unselect
			}
			return success;
		};

		var setDoubleClickZoomOutMovement = function(toValue) {
			dblClickZoomType = ((toValue>0)? 1 : ((toValue<0)? -1 : 0));
			if (dblClickZoomType > 0) {	// zoom-in
				setSmoothMotion(6*config.smoothMotion);
			} else {
				setSmoothMotion(config.smoothMotion);
			}
		};


		var getSceneVectorFromScreenPosition = function(screenX, screenY) {
			return new THREE.Vector2((screenX / containerWidth)*2 - 1, - (screenY / containerHeight)*2 + 1);
		};

		var updateInteractionPlaneElevation = function(elevation) {
			var zPosition = elevation? elevation : 0.0;
			if (zPosition != interactionPlane.position.z) {
				interactionPlane.position.z = zPosition;
				interactionPlane.updateMatrix();
				interactionPlane.updateMatrixWorld();
			}
		};

		var getInteractionPlanePosition = function(screenX, screenY, doResetCamera, elevateInteractionPlaneBy, useRenderCamera) {
			if (doResetCamera || !interactionCamera) {
				// LigaMap.console.log("reset interaction-camera");
				interactionCamera = renderCamera.clone();
				// LigaMap.console.log(interactionCamera.position, renderCamera.position, camera.position);
			}
			var pos = getSceneVectorFromScreenPosition(screenX, screenY);
			var raycaster = new THREE.Raycaster();
			raycaster.setFromCamera(pos, useRenderCamera? renderCamera : interactionCamera);
			if (elevateInteractionPlaneBy!==undefined) updateInteractionPlaneElevation(elevateInteractionPlaneBy);
			var intersects = raycaster.intersectObject(interactionPlane, false);
			if ( intersects.length > 0 ) {
				// LigaMap.console.log("getInteractionPlanePosition("+screenX+", "+screenY+"): ", intersects[0].point);
				var intersection = intersects[0].point;
				return intersection;
				//return {x:0,y:0,z:0};
			}
			LigaMap.console.warn("getInteractionPlanePosition("+screenX+", "+screenY+"): ", "no intersection");
			return false;
		};

		var getProjectedPlanePosition = function(camX, camY, elevateInteractionPlaneBy) {
			var pos = new THREE.Vector2(camX, camY);
			var raycaster = new THREE.Raycaster();
			raycaster.setFromCamera(pos, renderCamera, renderCamera.near, renderCamera.far);
			updateInteractionPlaneElevation(elevateInteractionPlaneBy);
			var intersects = raycaster.intersectObject(interactionPlane, false);
			if ( intersects.length > 0 ) {
				var intersection = intersects[0].point;
				return intersection;
			}
			return false;
		};

		var getInteractionPlanePositionFromCoordinates = function(x, y, z, yaw, tilt, screenOffsetX, screenOffsetY, elevateInteractionPlaneBy) {
			var traceCamera = renderCamera.clone();
			traceCamera.rotation.set(0, 0, (yaw!==undefined)? yaw : (input? input.getCameraYawValue() : 0));
			traceCamera.rotateOnAxis(new THREE.Vector3(1, 0, 0), (tilt!==undefined)? tilt : (input? input.getCameraTiltValue() : config.startTilt));
			traceCamera.position.set(x, y, 0);
			traceCamera.translateZ(z);
			traceCamera.updateMatrixWorld();
			traceCamera.near = Math.abs(z)*config.camNearClipFactor;
			traceCamera.far = Math.abs(z)*config.camFarClipFactor;
			traceCamera.updateProjectionMatrix();

			var pos = getSceneVectorFromScreenPosition(containerWidth*0.5+(screenOffsetX? screenOffsetX : 0), containerHeight*0.5+(screenOffsetY? screenOffsetY : 0));
			var raycaster = new THREE.Raycaster();
			raycaster.setFromCamera(pos, traceCamera);
			updateInteractionPlaneElevation(elevateInteractionPlaneBy);
			var intersects = raycaster.intersectObject(interactionPlane, false);
			if ( intersects.length > 0 ) {
				var intersection = intersects[0].point;
				return intersection;
			}
			return false;
		};

		var loadHeatmap = function() {
			if (typeof(layers['heatmap']) === 'undefined') return;
			$.ajax({
				method: "GET",
				url: config.ajaxHeatmapUrl,
				dataType: 'json',
				timeout: 10*1000
			}).done(function( data ) {
				if (data && data.heatmapConfigUrl && data.heatmapTextureUrl) {
					config.heatmapConfigUrl = data.heatmapConfigUrl;
					config.heatmapTextureUrl = data.heatmapTextureUrl;
					LigaMap.console.log("loadHeatmap: URLs updated", data);
				} else {
					LigaMap.console.warn("loadHeatmap: Could not update URLs", data);
				}
			
				var savedSettings = $.ajaxSettings.beforeSend;
				$.ajaxSettings.beforeSend = function() {$.ajaxSettings.beforeSend = savedSettings;};
				$.ajax({
					url: config.heatmapConfigUrl,
					dataType: "json",
					timeout: 10*1000,
					xcsrf: false,
				}).done(function(data) {
					heatmapConfig = data;
					if (!config.heatmapDisableMap) {
						// LigaMap.console.log("loadHeatmap: add map...");
						backgroundIsLoading++;
						var material = new THREE.ShaderMaterial({
							name: 'heatmap',
							uniforms: {
								layerAlpha: {type: "f", value: 1.0},
								fogFactor: {type: "f", value: 1.0},
								resolution: {type: "v2", value: new THREE.Vector2(1.0, 1.0) },
								color: {type: "c", value: new THREE.Color(config.colorHeatmap)},
								layerMaxAlpha: {type: "f", value: 1.0},
								map: {type: "t", value: new THREE.TextureLoader().load(config.heatmapTextureUrl,
									function(texture) {	// onLoad
										LigaMap.console.log("loadHeatmap: map texture loaded", texture);
										texture.magFilter = THREE.NearestFilter;
										texture.minFilter = THREE.NearestFilter;
										texture.needsUpdate = true;
										heatmapObject.visible = true;
										backgroundIsLoading--;
									},
									undefined,	// onProgress
									function(texture) {	// onError
										LigaMap.console.log("loadHeatmap: map texture loading error", texture);
										backgroundIsLoading--;
									}
								)},
								heatmapSteps: {type: "i", value: heatmapConfig['config']['heatmap_steps']},
								heatmapGreyMin: {type: "f", value: heatmapConfig['config']['heatmap_grey_min']/255.0},
								heatmapGreyMax: {type: "f", value: heatmapConfig['config']['heatmap_grey_max']/255.0},
								heatmapThresh: {type: "f", value: heatmapConfig['config']['thresh']},
								heatmapWidth: {type: "f", value: heatmapConfig['config']['heatmap_width']},
								heatmapHeight: {type: "f", value: heatmapConfig['config']['heatmap_height']},
								fadeInStartTime: {type: "f", value: lastRenderTimestamp},
								currentTime: {type: "f", value: lastRenderTimestamp},
								quadtreeFade: {type: "f", value: 1.0}
							},
							vertexShader: LigaMap.shader.getHeatmapShaderVertex(),
							fragmentShader: "#define USE_FADEIN\n"+LigaMap.shader.getHeatmapShaderFragment(config.fogStartFactor, config.minZoom, config.maxZoom, config.objectFadeInDuration),
							depthWrite: false,
							depthTest: false,
							// side: THREE.FrontSide,
							side: THREE.DoubleSide,	// TODO: temporär
							shading: THREE.FlatShading,
							transparent: true
						});
						var heatmapObject = new THREE.Mesh(
							new THREE.PlaneGeometry(1, 1),
							material
						);
						heatmapObject.visible = false;
						heatmapObject.scale.x = 100000;
						heatmapObject.scale.y = -1*100000;
						heatmapObject.position.x = 50000 + 0.5*(heatmapObject.scale.x/heatmapConfig['config']['heatmap_width']);
						heatmapObject.position.y = 50000 + 0.5*(heatmapObject.scale.y/heatmapConfig['config']['heatmap_height']);
						heatmapObject.position.z = 0;
						heatmapObject.matrixAutoUpdate = false;
						heatmapObject.updateMatrix();
						heatmapObject.updateMatrixWorld();
						layers['heatmap'].scene.add(heatmapObject);
						layers['heatmap'].usedMaterials.push(material);
						material.userData = {visible:true};	// nicht im Quadtree, also manuell
					}

					// Dots
					if (!config.heatmapDisableDots) {
						// LigaMap.console.log("loadHeatmap: add dots...");
						var dotObject = LigaMap.mesh.getDotObject(config, getLastRenderTimestamp, heatmapConfig['users'], config.colorHeatmapUserMax, config.heatmapMaxUserDotSize);
						layers['heatmap'].scene.add(dotObject);
						layers['heatmap'].usedMaterials.push(dotObject.material);
						dotObject.material.userData = {visible:true};	// nicht im Quadtree, also manuell
					}
				}).fail(function( jqXHR, textStatus, errorThrown ) {
					LigaMap.console.warn("loadHeatmap: Could not load config (loading error)", textStatus, errorThrown);
				});
			}).fail(function( jqXHR, textStatus, errorThrown ) {
				LigaMap.console.warn("loadHeatmap: Could not update URLs (loading error)", textStatus, errorThrown);
			});
		};

		var setupWebGL = function() {
			containerWidth = container.innerWidth();
			containerHeight = Math.max(1, container.innerHeight());

			// Setup Scenes
			layers = LigaMap.createLayers(self, config.minPanX, config.maxPanX, config.minPanY, config.maxPanY, config.quadTreeDepth, setRenderingIsDirty);

			// Camera
			camera = new THREE.PerspectiveCamera(config.fov, containerWidth / containerHeight, config.minZoom * 0.1, config.maxZoom * 1.1);
			// camera.matrixAutoUpdate = false;
			//camera = new THREE.OrthographicCamera( containerWidth / - 2, containerWidth / 2, containerHeight / 2, containerHeight / - 2, 1, 1000 );
			camera.position.x = config.startX;
			camera.position.y = config.startY;
			camera.position.z = config.startZoom;
			camera.lookAt( new THREE.Vector3(camera.position.x, camera.position.y/* + 200000*0.5*/, 0) );
			renderCamera = camera.clone();
			renderCamera.parent = new THREE.Object3D();	// verhindert ein updateMatrixWorld() bei jedem Rendervorgang -> muss manuell ausgeführt werden
			// camera.updateMatrixWorld();
			cameraFrustum = new THREE.Frustum;

			tempCamera = new THREE.PerspectiveCamera(config.fov, containerWidth / containerHeight, config.minZoom * 0.1, config.maxZoom * 1.1);
			tempCamera.position.x = camera.position.x;
			tempCamera.position.y = camera.position.y;
			tempCamera.position.z = config.maxZoom;
			tempCamera.lookAt( new THREE.Vector3(camera.position.x, camera.position.y, 0) );

			// Debug
			cameraHelper = new THREE.Mesh( new THREE.PlaneGeometry(1, 1), new THREE.MeshBasicMaterial( {name:'cameraHelper', color: 0x888844, wireframe: true } ) );
			cameraHelperScene = new THREE.Scene();
			cameraHelperScene.add(cameraHelper);

			// Renderer
			renderer = new THREE.WebGLRenderer({ alpha: true, antialias: config.antialiasing });
			maxSupportedTextureSize = renderer.context.getParameter(renderer.context.MAX_TEXTURE_SIZE);
			renderer.shadowMap.enabled = true;
			renderer.shadowMap.type = THREE.PCFSoftShadowMap;//THREE.BasicShadowMap;
			renderer.shadowMap.renderReverseSided = false;
			renderer.autoClear = false;
			// renderer.setClearColor( 0xeeeeee);
			//renderer.gammaInput = true;
			//renderer.gammaOutput = true;
			renderer.setPixelRatio(config.pixelRatio);
			renderer.setSize(containerWidth, containerHeight);
			container.append(renderer.domElement);

			// Labels
			container.append(LigaMap.label.init(config, containerWidth, containerHeight));

			// InteractionPlane für Mausinteraktionen
			interactionPlane = new THREE.Mesh(
				new THREE.PlaneBufferGeometry( config.maxZoom*8, config.maxZoom*8, 8, 8 ),
				new THREE.MeshBasicMaterial({ name:'interactionPlane'/*, visible: false*/} )
			);
			// layers['bld'].scene.add( interactionPlane );
			// camera.add( interactionPlane );
			cameraSpeedReference = new THREE.Vector3();
			cameraSpeedReferenceSmoothed = new THREE.Vector3();
			cameraPositionReference = new THREE.Vector3(camera.position.x, camera.position.y, camera.position.z);
			cameraZoomed = false;

			setZoomCamera(camera.position.z);	// Layer Visibility

			// Background-Map
			backgroundIsLoading = 0;
			if (typeof(layers['bg']) !== 'undefined') {
				var material = new THREE.ShaderMaterial({
					name: 'background',
					uniforms: {
						layerAlpha: {type: "f", value: 1.0},
						fogFactor: {type: "f", value: 1.0},
						resolution: {type: "v2", value: new THREE.Vector2(1.0, 1.0) },
						color: {type: "c", value: new THREE.Color(0xeeeeee)},
						layerMaxAlpha: {type: "f", value: 1.0}
					},
					vertexShader: LigaMap.shader.getFillShaderVertex(),
					fragmentShader: LigaMap.shader.getFillShaderFragment(config.fogStartFactor, config.minZoom, config.maxZoom, config.objectFadeInDuration),
					depthWrite: false,
					depthTest: false,
					// side: THREE.FrontSide,
					side: THREE.DoubleSide,	// TODO: temporär
					shading: THREE.FlatShading,
					// transparent: true
				});
				var backdrop = new THREE.Mesh(
					new THREE.PlaneGeometry(300, 300),
					material
				);
				backdrop.scale.x = 99999;
				backdrop.scale.y = 99999;
				backdrop.position.x = 0;
				backdrop.position.y = 0;
				backdrop.position.z = 0;
				backdrop.matrixAutoUpdate = false;
				backdrop.updateMatrix();
				backdrop.updateMatrixWorld();
				layers['bg'].scene.add(backdrop);
				layers['bg'].usedMaterials.push(material);
				material.userData = {visible:true};	// nicht im Quadtree, also manuell

				var backgroundObject = new THREE.Object3D();
				backgroundObject.scale.x = config.backgroundScaleX;
				backgroundObject.scale.y = config.backgroundScaleY;
				backgroundObject.position.x = config.backgroundPositionX;	//camera.position.x - 2500;
				backgroundObject.position.y = config.backgroundPositionY;	//camera.position.y - 7000;
				layers['bg'].scene.add(backgroundObject);
				backgroundObject.matrixAutoUpdate = false;
				backgroundObject.updateMatrix();
				backgroundObject.updateMatrixWorld();
				backgroundIsLoading++;
				material = new THREE.ShaderMaterial({
					name: 'backgroundMap',
					uniforms: {
						layerAlpha: {type: "f", value: 1.0},
						fogFactor: {type: "f", value: 1.0},
						resolution: {type: "v2", value: new THREE.Vector2(1.0, 1.0) },
						color: {type: "c", value: new THREE.Color(0xff0000)},
						layerMaxAlpha: {type: "f", value: 1.0},
						map: {type: "t", value: new THREE.TextureLoader().load(config.assetBasePath+'img/'+config.localePath+((maxSupportedTextureSize>=4096 && !config.lowResTextures)? 'background_4k.jpg' : 'background_2k.jpg'),
							function(texture) {	// onLoad
								// LigaMap.console.log("Texture loaded", texture);
								texture.needsUpdate = true;
								backgroundObjectFull.visible = true;
								backgroundIsLoading--;
								material.uniforms.fadeInStartTime.value = getLastRenderTimestamp() + 3000;	// TODO: Delay wegen Ruckelns wieder rausnehmen
							},
							undefined,	// onProgress
							function(texture) {	// onError
								// LigaMap.console.log("Texture loaded error", texture);
								backgroundIsLoading--;
							}
						)},
						fadeInStartTime: {type: "f", value: 0},
						currentTime: {type: "f", value: 0},
						quadtreeFade: {type: "f", value: 1.0}
					},
					vertexShader: LigaMap.shader.getFillShaderVertex(),
					fragmentShader: "#define USE_FADEIN\n#define USE_MAP\n"+LigaMap.shader.getFillShaderFragment(config.fogStartFactor, config.minZoom, config.maxZoom, config.objectFadeInDuration),
					depthWrite: false,
					depthTest: false,
					// side: THREE.FrontSide,
					side: THREE.DoubleSide,	// TODO: temporär
					shading: THREE.FlatShading,
					transparent: true
				});
				var backgroundObjectFull = new THREE.Mesh(
					new THREE.PlaneGeometry(300, 300),
					material
				);
				backgroundObjectFull.visible = false;
				backgroundObject.add(backgroundObjectFull);
				backgroundObjectFull.matrixAutoUpdate = false;
				backgroundObjectFull.updateMatrix();
				backgroundObjectFull.updateMatrixWorld();
				layers['bg'].usedMaterials.push(material);
				material.userData = {visible:true};	// nicht im Quadtree, also manuell
			}

			loadHeatmap();

			// objects + lights
			dirLight = new THREE.DirectionalLight( 0xffffff, 1.0 );
			dirLight.position.set( -11000, -10000, 8000 );
			if (config.useRealtimeShadow) {
				dirLight.castShadow = true;
				dirLight.shadow.bias = -0.01;
				dirLight.shadow.mapSize.width = config.shadowMapSize;
				dirLight.shadow.mapSize.height = config.shadowMapSize;
				// Werte werden jedes Frame überschrieben:
				dirLight.shadow.camera.near = 10000;
				dirLight.shadow.camera.far = 30000;
				dirLight.shadow.camera.right    =  1000;
				dirLight.shadow.camera.left     = -1000;
				dirLight.shadow.camera.top      =  1000;
				dirLight.shadow.camera.bottom   = -1000;
			}
			dirLightLo = dirLight.clone();
			// dirLightLo.castShadow = false;
			var dirLight2 = new THREE.DirectionalLight( 0xeeeeff, 0.5 );
			dirLight2.position.set( -0.4, 1.1, 0 );
			dirLight2.updateMatrix();
			dirLight2.updateMatrixWorld();
			var dirLight3 = new THREE.DirectionalLight( 0xffeedd, 0.5 );
			dirLight3.position.set( 0.9, -0.5, 0.5 );
			dirLight3.updateMatrix();
			dirLight3.updateMatrixWorld();
			if (typeof(layers['stadium'])!=='undefined') {
				layers['stadium'].scene.add( dirLight );
				// layers['stadium'].scene.add( new THREE.AmbientLight( 0x808080 ) );	// Schatten aufhellen
				layers['stadium'].scene.add( dirLight2 );
				layers['stadium'].scene.add( dirLight3 );
			}
			if (typeof(layers['stadium_lo'])!=='undefined') {
			layers['stadium_lo'].scene.add( dirLightLo );
			layers['stadium_lo'].scene.add( dirLight2.clone() );
			layers['stadium_lo'].scene.add( dirLight3.clone() );
			}
			if (typeof(layers['preload'])!=='undefined') {
			layers['preload'].scene.add(dirLight.clone());
			layers['preload'].scene.add(dirLight2.clone());
			layers['preload'].scene.add(dirLight3.clone());
			}

			if (config.useRealtimeShadow) {
				if (config.showShadowHelper) {
					spotLightShadowHelper = new THREE.CameraHelper( dirLight.shadow.camera );
					spotLightShadowHelper.frustumCulled = false;
					layers['debug'].scene.add( spotLightShadowHelper );
				}
				if (showShadowMapViewer) {
					dirLightShadowMapViewer = new THREE.ShadowMapViewer( dirLight );
					dirLightShadowMapViewer.size.set( 256, 256 );
					dirLightShadowMapViewer.position.set( 10, 10 );
				}
			}

			if ( renderer.extensions.get( 'ANGLE_instanced_arrays' ) === false ) {
				LigaMap.console.warn('Your browser does not support "WebGL instancing"');
			}
			LigaMap.mesh.loadStadiums(config, layers, renderer.getMaxAnisotropy(), checkIntroReady, checkIntroReady);

			// Connections
			LigaMap.mesh.buildConnections(config, layers, getLastRenderTimestamp);

			// Leagues
			if (config.showLeagues && Array.isArray(config.showLeagues) && (config.showLeagues.length > 0)) {
				LigaMap.mesh.loadLeagues(config, layers, getLastRenderTimestamp, function() {}, updateLoadingIndicator);
			}

			// containedRadiusDebugObject = new THREE.Mesh( new THREE.CylinderGeometry(0.5, 1.0, 0.001, 16, 1, true), new THREE.MeshBasicMaterial( {color: 0xffff00, wireframe: true} ) );
			containedRadiusDebugObject = new THREE.Mesh( new THREE.CircleGeometry(1.0, 16), new THREE.MeshBasicMaterial( {color: 0xffff00, wireframe: true} ) );
			containedRadiusDebugObject.position.x = camera.position.x;
			containedRadiusDebugObject.position.y = camera.position.y;
			containedRadiusDebugObject.position.z = 0;
			// containedRadiusDebugObject.rotation.x = 0.5*Math.PI;
			if (layers['debug']) {
				layers['debug'].scene.add(containedRadiusDebugObject);
			}

			// Stats
			if (typeof Stats != "undefined") {
				stats = new Stats();
				//stats.setMode( 1 );
				$(stats.domElement).addClass("stats").appendTo(container);
			}
		}


		var compareObject = function(o_a, o_b, prefix) {
			if (prefix == undefined) {
				prefix = "?";
			}
			if (typeof(o_a) != 'object') {
				LigaMap.console.warn(prefix+":", "o_a is not an object");
				return;
			}
			if (typeof(o_b) != 'object') {
				LigaMap.console.warn(prefix+":", "o_b is not an object");
				return;
			}
			if (o_a == null) {
				LigaMap.console.warn(prefix+":", "o_a is NULL");
				return;
			}
			if (o_b == null) {
				LigaMap.console.warn(prefix+":", "o_b is NULL");
				return;
			}
			// LigaMap.console.log(prefix, o_a, typeof(o_a), o_b, typeof(o_b));
			for (var p in o_a) {
				if (o_a[p] != o_b[p]) {
					if ((typeof(o_a[p]) == "object") && (typeof(o_b[p]) == "object") && (o_a[p] != null) && (o_b[p] != null)) {
						compareObject(o_a[p], o_b[p], prefix+"."+p);
					} else {
						LigaMap.console.warn(prefix+"."+p+":", o_a[p], "!=", o_b[p]);
					}
				}
			}
		};

		var updateLoadingIndicator = function() {
			if (config.showDebugText) {
				return;
			}
			container.toggleClass("ol-map-loading", ((backgroundIsLoading > 0) || (loadingCount > 0) || (LigaMap.mesh.addingCount > 0)));
			if (loadingStatusContainer !== null) {
				loadingStatusContainer.empty();
				if (backgroundIsLoading > 0) {
					loadingStatusContainer.append("<span>Loading background </span>");
				}
				if ((loadingCount > 0) || (LigaMap.mesh.addingCount > 0)) {
					loadingStatusContainer.append("<span>Loading: "+loadingCount+" </span>");
					loadingStatusContainer.append("<span>Adding: "+LigaMap.mesh.addingCount+" </span>");
				}
			}
		};

		this.dataUnloaded = function(layername, leave) {
			if (layername == 'user') {
				// LigaMap.console.log("dataUnloaded()", layername, leave, unloadReason);
				var posString = leave.minX+'_'+leave.maxX+'_'+leave.minY+'_'+leave.maxY;
				if (!LigaMap.mesh.loadedStadiumDots['loaded_'+posString] && LigaMap.mesh.loadedStadiumDots['notLoaded_'+posString]) {
					var dotObject = LigaMap.mesh.loadedStadiumDots['notLoaded_'+posString];
					LigaMap.mesh.loadedStadiumDots['loaded_'+posString] = dotObject;
					LigaMap.mesh.loadedStadiumDots['notLoaded_'+posString] = null;
					layers['stadium_dots'].scene.add(dotObject);
					layers['stadium_dots'].usedMaterials.push(dotObject.material);
					if (!layers['stadium_dots'].quadtree.addObject(dotObject, leave.minX, leave.maxX, leave.minY, leave.maxY)) {
						LigaMap.console.log("stadium_dots nicht im Quadtree:", dotObject, leave.minX, leave.maxX, leave.minY, leave.maxY);
					}
				}
			}
		};

		this.loadData = function(layername, leave) {
			if (backgroundIsLoading > 0) {	// Background-Map still loading...
				return false;
			}
			if (loadingCount >= config.maxConcurrentLoadCount) {	// Maximum count of loaded items reached
				return false;
			}
			if (layername == "userpos") {
				if (typeof(LigaMap.mesh.loadedStadiumDots['loaded_'+leave.minX+'_'+leave.maxX+'_'+leave.minY+'_'+leave.maxY]) != 'undefined') {
					return true;
				}
			}
			loadingCount++;
			updateLoadingIndicator();
			$.ajax({
				method: "GET",
				url: config.ajaxUrl,
				dataType: 'json',
				timeout: 10*1000,
				data: {layer: layername, qtlevel: layers[layername].loadingQuadtreeOffset, minx: leave.minX/config.sceneScale, maxx: leave.maxX/config.sceneScale, miny: leave.minY/config.sceneScale, maxy: leave.maxY/config.sceneScale}
			}).always(function() {
				layers[layername].quadtree.leaveUpdateLoadedTime(leave);
				loadingCount--;
				updateLoadingIndicator();
			}).fail(function() {
				layers[layername].quadtree.leaveSetIsLoaded(leave, false);
				if (layername == "bld") {
					checkIntroReady();
				}
			}).done(function( data ) {
				layers[layername].quadtree.leaveSetIsLoaded(leave, true);
				switch(layername) {
					case "foreign":
						LigaMap.mesh.addData(config, layers, getLastRenderTimestamp, layername, config.colorForeignGr, 1, data, leave.minX, leave.maxX, leave.minY, leave.maxY, function() {
						}, updateLoadingIndicator);
						break;
					case "bld":
						LigaMap.mesh.addData(config, layers, getLastRenderTimestamp, layername, config.colorBldGr, 1, data, leave.minX, leave.maxX, leave.minY, leave.maxY, function() {
						}, updateLoadingIndicator);
						break;
					case "krs":
						LigaMap.mesh.addData(config, layers, getLastRenderTimestamp, layername, config.colorKrsGr, 1, data, leave.minX, leave.maxX, leave.minY, leave.maxY, function() {
						}, updateLoadingIndicator);
						break;
					case "gem":
						LigaMap.mesh.addData(config, layers, getLastRenderTimestamp, layername, config.colorGemGr, 1, data, leave.minX, leave.maxX, leave.minY, leave.maxY, function() {
						}, updateLoadingIndicator);
						break;
					case "userpos":
						if (typeof(LigaMap.mesh.loadedStadiumDots['loaded_'+leave.minX+'_'+leave.maxX+'_'+leave.minY+'_'+leave.maxY]) != 'undefined') {
							return true;
						}
						for (var i=0; i<data['pos'].length; i++) {
							var currentMinX = data['pos'][i][0][0];
							var currentMaxX = data['pos'][i][0][1];
							var currentMinY = data['pos'][i][0][2];
							var currentMaxY = data['pos'][i][0][3];
							var currentData = data['pos'][i][1];
							var dotObject = LigaMap.mesh.getDotObject(config, getLastRenderTimestamp, currentData, config.colorUserPreviewDots, config.userPreviewDotSize);
							LigaMap.mesh.loadedStadiumDots['loaded_'+currentMinX+'_'+currentMaxX+'_'+currentMinY+'_'+currentMaxY] = dotObject;
							LigaMap.mesh.loadedStadiumDots['notLoaded_'+currentMinX+'_'+currentMaxX+'_'+currentMinY+'_'+currentMaxY] = null;
							layers['stadium_dots'].scene.add(dotObject);
							layers['stadium_dots'].usedMaterials.push(dotObject.material);
							if (!layers['stadium_dots'].quadtree.addObject(dotObject, currentMinX, currentMaxX, currentMinY, currentMaxY)) {
								LigaMap.console.log("stadium_dots nicht im Quadtree:", dotObject, currentMinX, currentMaxX, currentMinY, currentMaxY);
							}
						}
						break;
					default:
						LigaMap.mesh.addData(config, layers, getLastRenderTimestamp, layername, 0xff0000, 0.5, data, leave.minX, leave.maxX, leave.minY, leave.maxY, function() {
							// LigaMap.console.log("FERTIG", layername, leave);
						}, updateLoadingIndicator);
				}
				if (layername == "bld") {
					checkIntroReady();
				}
			});
			return true;
		};


		this.playIntro = function() {
			introStarted = true;
			introFinished = false;
			if (input) {
				input.setInputEnabled(false);
			}
			restartFPSHistory();
			container.trigger("mapIntroStarted");
			var introStep = 0;
			var startNextIntroStep = function(success) {
				if (success && config.introAnimation[introStep]) {
					animateCameraTo({	// copy config object (overwrite onFinishedCallback)
						x: config.introAnimation[introStep].x,
						y: config.introAnimation[introStep].y,
						z: config.introAnimation[introStep].z,
						radius: config.introAnimation[introStep].radius,
						yaw: config.introAnimation[introStep].yaw,
						tilt: config.introAnimation[introStep].tilt,
						duration: config.introAnimation[introStep].duration,
						doShortestYaw: config.introAnimation[introStep].doShortestYaw,
						easings: config.introAnimation[introStep].easings,
						onFinishedCallback: startNextIntroStep
					}, true);
					introStep++;
				} else {
					if (input) {
						input.setInputEnabled(config.renderActive);
					}
					introFinished = true;
					container.trigger("mapIntroFinished", success);
				}
			};
			// Einige Layer einblenden, während der ersten Introstufe (oder nach dem Intro)
			var introOverallDuration = 0, i;
			for (i in config.introAnimation) {
				if (config.introAnimation[i].duration) {
					introOverallDuration += config.introAnimation[i].duration;
				}
			}
			var fadeLayer = function(l, afterIntro) {
				if (!l) return;
				l.autoVisible = true;
				l.alphaForcedAnimation.active = true;
				l.alphaForcedAnimation.startValue = 0.0;
				l.alphaForcedAnimation.endValue = 1.0;
				if (afterIntro) {
					l.alphaForcedAnimation.startTime = getLastRenderTimestamp() + introOverallDuration*1000;
					l.alphaForcedAnimation.duration = config.objectFadeInDuration;
				} else {
				l.alphaForcedAnimation.startTime = getLastRenderTimestamp();
				if (config.introAnimation[introStep] && config.introAnimation[introStep].duration) {
					l.alphaForcedAnimation.duration = config.introAnimation[introStep].duration;
				} else {
					l.alphaForcedAnimation.duration = 1.0;
					LigaMap.console.warn("introStep/duration not found", introStep, config.introAnimation[introStep], config.introAnimation);
				}
			}
			};
			for (i in config.introFadeInLayers) {
				fadeLayer(layers[config.introFadeInLayers[i]], false);
			}
			for (i in config.introFinishedFadeInLayers) {
				fadeLayer(layers[config.introFinishedFadeInLayers[i]], true);
			}
			startNextIntroStep(true);
		};
		
		
		var checkIntroReady = function(statusData) {
			if (!introStarted) {
				var bldReady = (typeof(layers['bld'])==='undefined') || layers['bld'].quadtree.isAllLoaded();
				var meshesReady = LigaMap.mesh.allAssetsLoaded;
				container.trigger("mapLoadingProgress", {progress:(0.1*(bldReady? 1 : 0) + 0.9*(meshesReady? 1 : LigaMap.mesh.allAssetsLoadingProgress)), statusData:statusData});
				if (bldReady && meshesReady) {
					// Intro vorbereiten (alles ausblenden, was im Intro eingeblendet werden soll)
					var allLayers = config.introFadeInLayers.concat(config.introFinishedFadeInLayers);
					for (var i in allLayers) {
						var l = layers[allLayers[i]];
						if (l) {
						l.visible = false;
						l.autoVisible = false;
					}
					}
					if (config.introStartDelay > 0) {
						setTimeout(function() {
					container.trigger("mapIntroReady");
						}, config.introStartDelay);
					} else {
						container.trigger("mapIntroReady");
					}
				}
			}
		};


		var setZoomCamera = function(newZoomPos, screenX, screenY, screenFactor) {
			if (cameraZoomed) {	// zoom only once per frame
				setSaveCameraPosition(undefined, undefined, newZoomPos);
				return;
			}
			if (camera.position.z != newZoomPos) {
				var x = undefined, y = undefined;
				if ((screenX != undefined) && (screenY != undefined)) {
					var pos = getInteractionPlanePosition(screenX, screenY, true);
					newZoomPos = Math.max(config.minZoom, Math.min(config.maxZoom, newZoomPos));
					var zoomPercentage = 1.0 - newZoomPos / Math.max(1, camera.position.z);
					if (screenFactor != undefined) {
						zoomPercentage *= screenFactor;
					}
					if (pos) {
						x = camera.position.x*(1-zoomPercentage) + pos.x*zoomPercentage;
						y = camera.position.y*(1-zoomPercentage) + pos.y*zoomPercentage;
					} else {	// out of interaction plane
						x = camera.position.x;
						y = camera.position.y;
					}
				}
				setSaveCameraPosition(x, y, newZoomPos);
				cameraZoomed = true;
				// Reset visibility (TODO: entfernen)
				autoUpdateLayerVisibility();
			}
		};

		var autoUpdateLayerVisibility = function() {
			for (var i in layers) {
				var l = layers[i];
				l.autoVisible = true;
				if (i == 'preload') {
					if (l.visible) LigaMap.console.log("*** render preload once ***");
					l.visible = false;
					l.autoVisible = false;
				} else {
					l.visible = ((camera.position.z >= l.minZoom) && (camera.position.z <= l.maxZoom));
				}
			}
		};


		/** obj(Object3D) und/oder worldOffset(Vector3) angeben **/
		var toScreenPosition = function(obj, camera, worldOffset) {
		    var vector = new THREE.Vector3();

		    var widthHalf = 0.5*containerWidth;
		    var heightHalf = 0.5*containerHeight;

			if (obj != undefined) {
			    obj.updateMatrixWorld();
		        vector = obj.getWorldPosition();
				if (worldOffset != undefined) {
					vector.add(worldOffset);
				}
			} else {
				if (worldOffset != undefined) {
					vector.copy(worldOffset);
				} else {
					LigaMap.console.warn("toScreenPosition() wrong parameters ", obj, camera, worldOffset);
					return;
				}
			}
		    vector.project(camera);

		    vector.x = ( vector.x * widthHalf ) + widthHalf;
		    vector.y = - ( vector.y * heightHalf ) + heightHalf;

		    return {
		        x: vector.x,
		        y: vector.y
		    };
		};

		var updateCameraFrustum = function() {
			renderCamera.updateMatrixWorld();
			renderCamera.matrixWorldInverse.getInverse( renderCamera.matrixWorld );
			cameraFrustum.setFromMatrix( new THREE.Matrix4().multiplyMatrices( renderCamera.projectionMatrix, renderCamera.matrixWorldInverse ) );
		};

		var setSaveCameraPosition = function(x, y, z, useStrictBoundaries) {
			var zoomfactor = (camera.position.z-config.minZoom)/(config.maxZoom-config.minZoom);
			var useCentering = (	// move borders more to center when...
				(input && input.getCameraIsPinned()) ||	// ... manually dragging or
				((dblClickZoomType == 0) && (cameraSpeedReference.z <= 0))	// ... not double-click-zoom and not zooming-out (i.e. drag-after-motion)
			);
			useCentering = false;	// debug
			if (x != undefined) {
				// camera.position.x = x;
				var simpleAdaptiveMinX = config.minPanX;
				var simpleAdaptiveMaxX = config.maxPanX;
				if (useCentering) {
					var centerX = (config.minPanX+config.maxPanX)*0.5;
					simpleAdaptiveMinX = zoomfactor*centerX + (1-zoomfactor)*config.minPanX;
					simpleAdaptiveMaxX = zoomfactor*centerX + (1-zoomfactor)*config.maxPanX;
					// avoid jumps
					if (!useStrictBoundaries) {
						simpleAdaptiveMinX = Math.min(simpleAdaptiveMinX, camera.position.x);
						simpleAdaptiveMaxX = Math.max(simpleAdaptiveMaxX, camera.position.x);
					}
				}
				var newValue = Math.min(simpleAdaptiveMaxX, Math.max(simpleAdaptiveMinX, x));
				if (Math.abs(newValue-camera.position.x) > 0.001) {
					setRenderingIsDirty("cameraPosX");
				}
				camera.position.x = newValue;
			}
			if (y != undefined) {
				// camera.position.y = y;
				var simpleAdaptiveMinY = config.minPanY;
				var simpleAdaptiveMaxY = config.maxPanY;
				if (useCentering) {
					var centerX = (config.minPanY+config.maxPanY)*0.5;
					simpleAdaptiveMinY = zoomfactor*centerX + (1-zoomfactor)*config.minPanY;
					simpleAdaptiveMaxY = zoomfactor*centerX + (1-zoomfactor)*config.maxPanY;
					// avoid jumps
					if (!useStrictBoundaries) {
						simpleAdaptiveMinY = Math.min(simpleAdaptiveMinY, camera.position.y);
						simpleAdaptiveMaxY = Math.max(simpleAdaptiveMaxY, camera.position.y);
					}
				}
				var newValue = Math.min(simpleAdaptiveMaxY, Math.max(simpleAdaptiveMinY, y));
				if (Math.abs(newValue-camera.position.y) > 0.001) {
					setRenderingIsDirty("cameraPosY");
				}
				camera.position.y = newValue;
			}
			if (z != undefined) {
				var newValue = Math.min(config.maxZoom, Math.max(config.minZoom, z));
				if (Math.abs(newValue-camera.position.z) > 0.001) {
					setRenderingIsDirty("cameraPosZ");
				}
				camera.position.z = newValue;
			}
			if (input) {
				input.setCameraPosition(camera.position);
			}
		};

		var updateShadowPosition = function() {
			dirLight.updateMatrix();
			dirLight.updateMatrixWorld();
			dirLightLo.updateMatrix();
			dirLightLo.updateMatrixWorld();

			if (!config.useRealtimeShadow) {
				return;
			}

			var size = 0.5*config.shadowBoxSize*(camera.position.z/config.maxZoom);
			var localCenterVector = new THREE.Vector3( camera.position.x, camera.position.y, 0 );
			
			var matrix = new THREE.Matrix4();
			localCenterVector.applyProjection( matrix.getInverse( dirLight.shadow.camera.matrixWorld ) );
			dirLight.shadow.camera.right	= localCenterVector.x+size;
			dirLight.shadow.camera.left		= localCenterVector.x-size;
			dirLight.shadow.camera.top		= localCenterVector.y+size;
			dirLight.shadow.camera.bottom	= localCenterVector.y-size;
			dirLight.shadow.camera.far		= -localCenterVector.z+size;
			dirLight.shadow.camera.near		= -localCenterVector.z-size;
			dirLight.shadow.camera.updateProjectionMatrix();

			// spotLightHelper.update();
			if (config.showShadowHelper) {
				spotLightShadowHelper.update();
			}
		};

		var getContainedRadius = function(zoomValue, getMax) {	// Radius zurückgeben, der bei zoomValue in Ansicht passt (getMax: horz/vert maximum)
			var v = (zoomValue * Math.tan(0.5*camera.fov * Math.PI/180));
			if (getMax) {
				if (containerWidth > containerHeight) {
					return Math.max(1, containerWidth) * v / Math.max(1, containerHeight);
				} else {
					return v;
				}
			}
			return v;
		};

		var getBestZoomValue = function(radius, yaw, tilt) {	// Zoomposition zurückgeben, sodass Objekt mit "radius" Größe in Ansicht passt
			// TODO: yaw und tilt auch beachten, momentan nur für senkrechte Ansicht (worst case)
			return (radius / Math.tan(0.5*camera.fov * Math.PI/180));
		};

		var stopAnimateCameraTo = function() {	// Callback ausführen, falls vorhanden
			if ((typeof self.activeCameraAnimation === 'object') && (self.activeCameraAnimation !== null)) {
				var callBack = self.activeCameraAnimation.onFinishedCallback;
				var isCompleted = self.activeCameraAnimation.completed;
				self.activeCameraAnimation = null;	// erst nullen, dann callBack aufrufen (falls weiter animiert werden soll)
				if (typeof callBack  === 'function') {
					callBack(isCompleted);
				}
				return true;
			}
			return false;	// keine Animation aktiv
		};

		var animateCameraTo = function(options, isForIntro) {
			if (!isForIntro) {
				if (introStarted && !introFinished) {
					LigaMap.console.log("aborting intro");
					if (input) {
						input.setInputEnabled(config.renderActive);
					}
					introFinished = true;
				}
			}
			var x = options.x;
			var y = options.y;
			var z = options.z;
			var radius = options.radius;
			var yaw = options.yaw;
			var tilt = options.tilt;
			var duration = options.duration;
			var doShortestYaw = (options.doShortestYaw != undefined)? (options.doShortestYaw==true) : true;	// default: true
			var easings = options.easings;
			var onFinishedCallback = options.onFinishedCallback;

			stopAnimateCameraTo();

			var endZoom = z;
			if (radius !== undefined) {
				endZoom = getBestZoomValue(radius, yaw, tilt);
			}
			if ((duration === undefined) || (duration == 0)) {
				renderLoopCameraAnimationSetValues(x, y, endZoom, yaw, tilt);
			} else {
				var startYaw = 0, endYaw = undefined;
				if (yaw !== undefined) {
					var twoPI = 2.0*Math.PI;
					startYaw = input? input.getCameraYawValue() : 0;
					endYaw = yaw;
					if (doShortestYaw) {
						startYaw = startYaw % twoPI;
						if (startYaw < 0) {
							endYaw += twoPI;
						}
						endYaw = endYaw % twoPI;
						if (endYaw < 0) {
							endYaw += twoPI;
						}
						// startYaw, endYaw: [0..2*PI]
						var deltaYaw = endYaw-startYaw;
						if (deltaYaw > Math.PI) {
							endYaw -= twoPI;
						} else if (deltaYaw < -Math.PI) {
							endYaw += 2*Math.PI;
						}
					}
				}
				
				if (options.useCenterOffset || options.autoDuration || options.screenOffsetX || options.screenOffsetY) {
					if (x === undefined) x = camera.position.x;
					if (y === undefined) y = camera.position.y;
					if (endZoom === undefined) endZoom = camera.position.z;
				}

				if (options.screenOffsetX || options.screenOffsetY) {
					// Simulate input panning by screenOffset pixels on final camera
					var pos = getInteractionPlanePositionFromCoordinates(x, y, endZoom, endYaw, tilt, options.screenOffsetX, options.screenOffsetY, false);
					if (pos) {
						// apply reversed delta position
						x = x+(x-pos.x);
						y = y+(y-pos.y);
					}
				}
				
				var centerOffsetZ = undefined;
				var distance = undefined;
				if (options.useCenterOffset || options.autoDuration) {
					distance = Math.sqrt(Math.pow(camera.position.x-x, 2) + Math.pow(camera.position.y-y, 2));
				}
				if (options.useCenterOffset) {
					if ((distance > camera.position.z) && (distance > endZoom)) {
						centerOffsetZ = distance;
					}
				}
				if (options.autoDuration) {
					var zMiddle = (0.5*(endZoom+camera.position.z)) + (centerOffsetZ? 0.5*centerOffsetZ : 0);
					var zDistance = centerOffsetZ? (Math.abs(endZoom-zMiddle) + Math.abs(camera.position.z-zMiddle)) : Math.abs(endZoom-camera.position.z);
					var durationH = config.animationAutodurationFactor * (distance/zMiddle);
					var durationV = config.animationAutodurationFactor * (zDistance/zMiddle);
					LigaMap.console.log("autoduration: ", {duration:duration, durationH:durationH, durationV:durationV});
					duration = Math.max(duration, durationH, durationV);	// nur evtl. länger
				}

				// Prepare easing functions
				var easingFunctions = [];
				var easingFunctionIndex = {};
				var addUniqueAndGetIndex = function(arr, val) {
					for (var i=0; i<arr.length; i++) {
						if (val === arr[i]) {
							return i;
						}
					}
					arr.push(val);
					return (arr.length - 1);
				};
				var addEasing = function(name, defaultEasing) {
					if (easings && (typeof easings[name] === 'string')) {
						easingFunctionIndex[name] = addUniqueAndGetIndex(easingFunctions, easings[name]);
					} else {
						easingFunctionIndex[name] = addUniqueAndGetIndex(easingFunctions, defaultEasing);	// default easing
					}
				};
				addEasing('time', 'olSmoothTime');	// mit "linear" überschreiben, falls nötig
				addEasing('x', 'olSmoothMovement');
				addEasing('y', 'olSmoothMovement');
				addEasing('z', centerOffsetZ? 'olSmoothZoom' : 'olSmoothMovement');
				addEasing('yaw', 'olSmoothMovement');
				addEasing('tilt', 'olSmoothMovement');

				self.activeCameraAnimation = {
					startX: camera.position.x,
					startY: camera.position.y,
					startZ: camera.position.z,
					startYaw: startYaw,
					startTilt: (input? input.getCameraTiltValue() : config.startTilt),
					endX: x,
					endY: y,
					endZ: endZoom,
					endYaw: endYaw,
					endTilt: tilt,
					centerOffsetZ: centerOffsetZ,
					duration: duration,
					startTime: 0,
					easingFunctions: easingFunctions,
					easingFunctionIndex: easingFunctionIndex,
					onFinishedCallback: onFinishedCallback,
					completed: false,
					// Original-Daten für Change-Funktion zwischenspeichern, s.u.
					origEndX: options.x,
					origEndY: options.y,
					origScreenOffsetX: options.screenOffsetX,
					origScreenOffsetY: options.screenOffsetY,
					origCameraAspect: camera.aspect
				};
			}
			autoUpdateLayerVisibility();
			setRenderingIsDirty("animateCameraTo");
		};

		/**
		 * Offsets target point coordinate calculation for animations
		 */
		this.changeAnimationScreenOffset = function(screenOffsetX, screenOffsetY) {
			// LigaMap.console.log("changeAnimationScreenOffset()", screenOffsetX, screenOffsetY, camera? camera.aspect : undefined);
			if (
				self.activeCameraAnimation &&
				(self.activeCameraAnimation.origScreenOffsetX !== undefined) &&
				(self.activeCameraAnimation.origScreenOffsetY !== undefined) &&
				((Math.abs(self.activeCameraAnimation.origScreenOffsetX-screenOffsetX) > 5.0) ||
				(Math.abs(self.activeCameraAnimation.origScreenOffsetY-screenOffsetY) > 5.0) ||
				(Math.abs(self.activeCameraAnimation.origCameraAspect-camera.aspect) > 0.1))
			) {
				var pos = getInteractionPlanePositionFromCoordinates(self.activeCameraAnimation.origEndX, self.activeCameraAnimation.origEndY, self.activeCameraAnimation.endZ, self.activeCameraAnimation.endYaw, self.activeCameraAnimation.endTilt, screenOffsetX, screenOffsetY, false);
				if (!pos) return;
				// apply reversed delta position
				var x = self.activeCameraAnimation.origEndX+(self.activeCameraAnimation.origEndX-pos.x);
				var y = self.activeCameraAnimation.origEndY+(self.activeCameraAnimation.origEndY-pos.y);

				self.activeCameraAnimation.endX = x;
				self.activeCameraAnimation.endY = y;
				self.activeCameraAnimation.origScreenOffsetX = screenOffsetX;
				self.activeCameraAnimation.origScreenOffsetY = screenOffsetY;
				self.activeCameraAnimation.origCameraAspect = camera.aspect;
			}
		};

		/**
		 * Offsets center of camera view
		 */
		this.setCameraViewOffset = function( fullWidth, fullHeight, x, y, width, height ) {
			if (!renderCamera) return false;
			// console.log("setCameraViewOffset()", renderCamera.view);
			self.activeCameraViewOffsetAnimation = {
				currentView: renderCamera.view,
				targetView: {
					fullWidth: fullWidth,
					fullHeight: fullHeight,
					offsetX: x,
					offsetY: y,
					width: width,
					height: height,
				}
			};
			if (!self.activeCameraViewOffsetAnimation.currentView) {	// default view
				self.activeCameraViewOffsetAnimation.currentView = {
					fullWidth: containerWidth,
					fullHeight: containerHeight,
					offsetX: 0,
					offsetY: 0,
					width: containerWidth,
					height: containerHeight,
				};
			}
			setRenderingIsDirty("cameraViewOffsetChanged");
			return true;
		};

		var setSmoothMotion = function(value) {
			if (input) return input.setSmoothMotion(value);
			return false;
		};
		
		var getSmoothMotion = function() {
			return (input? input.getSmoothMotion() : config.smoothMotion);
		}

		var renderLoopCameraAnimationSetValues = function(x, y, z, yaw, tilt) {
			if (input) {
				if (yaw !== undefined) {
					input.setCameraYawValue(yaw);
				}
				if (tilt !== undefined) {
					input.setCameraTiltValue(tilt);
				}
			}
			setSaveCameraPosition(x, y, z, false);
		};

		var renderLoop = function(timestamp) {
			if (config.performance.logSlowFrames) {
				window.performance.clearMarks();
				window.performance.clearMeasures();
				window.performance.mark('prep-start');
			}
			if (!timestamp) {
				timestamp = 0;
				if ((typeof performance == "object") && (typeof performance.now == "function")) {
					timestamp = performance.now();
				}
			}
			var deltaTime = 0;
			if (lastRenderTimestamp) {
				deltaTime = timestamp - lastRenderTimestamp;
			}
			var deltaSeconds = deltaTime / 1000.0;
			var normalDeltaTime = 1000.0/60.0;
			var isDirty = (isDirtyReasons.count > 0);
			lastRenderTimestamp = timestamp;
			var dirtyDelay = (connectionsAreAnimated? config.startTimeDilationWhenNotDirtyAfterWithAnimatedConnections : config.startTimeDilationWhenNotDirtyAfter);
			var timeDilationActive = (config.useTimeDilationWhenInactive? (lastRenderTimestamp > (isDirtyTimestamp + dirtyDelay)) : false);
			var dilationSmoothMotion = ((connectionsAreAnimated && timeDilationActive)? config.smoothMotionTimeDilationSlow : config.smoothMotionTimeDilationFast);
			currentTimeDilation = (currentTimeDilation*dilationSmoothMotion + (timeDilationActive? 0 : 1))/(dilationSmoothMotion+1);
			var renderActive = (!config.stopRenderingWhenTimeFullDilated || isDirty || currentTimeDilation > 0.01);

			if (renderActive) {
				lastRenderTimestampDilated += currentTimeDilation * deltaTime;
				if (isDirty) {
					isDirtyReasons = {count:0};
				}
				if (self.activeCameraViewOffsetAnimation) {	// animate viewOffset
					var cv = self.activeCameraViewOffsetAnimation.currentView;
					var tv = self.activeCameraViewOffsetAnimation.targetView;
					cv.offsetX = (tv.offsetX + cv.offsetX*config.smoothMotionCamSpeedSmoothed)/(config.smoothMotionCamSpeedSmoothed+1);
					cv.offsetY = (tv.offsetY + cv.offsetY*config.smoothMotionCamSpeedSmoothed)/(config.smoothMotionCamSpeedSmoothed+1);
					cv.width = (tv.width + cv.width*config.smoothMotionCamSpeedSmoothed)/(config.smoothMotionCamSpeedSmoothed+1);
					cv.height = (tv.height + cv.height*config.smoothMotionCamSpeedSmoothed)/(config.smoothMotionCamSpeedSmoothed+1);
					cv.fullWidth = (tv.fullWidth + cv.fullWidth*config.smoothMotionCamSpeedSmoothed)/(config.smoothMotionCamSpeedSmoothed+1);
					cv.fullHeight = (tv.fullHeight + cv.fullHeight*config.smoothMotionCamSpeedSmoothed)/(config.smoothMotionCamSpeedSmoothed+1);
					if (
						(Math.abs(cv.offsetX - tv.offsetX) < 0.1) &&
						(Math.abs(cv.offsetY - tv.offsetY) < 0.1) &&
						(Math.abs(cv.width - tv.width) < 0.1) &&
						(Math.abs(cv.height - tv.height) < 0.1) &&
						(Math.abs(cv.fullWidth - tv.fullWidth) < 0.1) &&
						(Math.abs(cv.fullHeight - tv.fullHeight) < 0.1)
					) {
						if (Math.abs(cv.offsetX) < 0.1 && Math.abs(cv.offsetY) < 0.1 && cv.fullWidth === cv.width && cv.fullHeight === cv.height) {
							renderCamera.clearViewOffset();
						}
						self.activeCameraViewOffsetAnimation = null;
					} else {
						renderCamera.setViewOffset(Math.round(cv.fullWidth), Math.round(cv.fullHeight), Math.round(cv.offsetX), Math.round(cv.offsetY), Math.round(cv.width), Math.round(cv.height));
						setRenderingIsDirty("cameraViewOffsetChanged");
					}
				}
				var cameraAnimated = false;
				var isZoomingEstimatedTargetZoomAlreadyUpdated = false;
				if (self.activeCameraAnimation) {
					if (self.activeCameraAnimation.startTime > 0) {
						var d = (timestamp - self.activeCameraAnimation.startTime) / (1000.0*self.activeCameraAnimation.duration);
						var stopAnim = false;
						if (d > 1.0) {	// completed
							d = 1.0;
							stopAnim = true;
						}
						var dEased = [];
						var dEasedTime = d;
						if (self.activeCameraAnimation.easingFunctionIndex.time !== undefined) {
							dEasedTime = EasingFunctions[self.activeCameraAnimation.easingFunctions[self.activeCameraAnimation.easingFunctionIndex.time]](d);
						}
						for (var i=0; i<self.activeCameraAnimation.easingFunctions.length; i++) {
							dEased[i] = EasingFunctions[self.activeCameraAnimation.easingFunctions[i]](dEasedTime);
						}
						var offsetZ = 0.0;
						if (self.activeCameraAnimation.centerOffsetZ !== undefined) {
							var d2 = 2 * (Math.min(dEasedTime, 0.5) - Math.max(0, (dEasedTime-0.5)));	// conversion: 0..0.5..1 -> 0..1..0
							var d2Eased = EasingFunctions.olSmoothZoom(d2);
							offsetZ = d2Eased*self.activeCameraAnimation.centerOffsetZ;
						}
						renderLoopCameraAnimationSetValues(
							(self.activeCameraAnimation.endX !== undefined)?	self.activeCameraAnimation.endX*dEased[self.activeCameraAnimation.easingFunctionIndex.x] + self.activeCameraAnimation.startX*(1-dEased[self.activeCameraAnimation.easingFunctionIndex.x])	: undefined,
							(self.activeCameraAnimation.endY !== undefined)?	self.activeCameraAnimation.endY*dEased[self.activeCameraAnimation.easingFunctionIndex.y] + self.activeCameraAnimation.startY*(1-dEased[self.activeCameraAnimation.easingFunctionIndex.y])	: undefined,
							(self.activeCameraAnimation.endZ !== undefined)?	self.activeCameraAnimation.endZ*dEased[self.activeCameraAnimation.easingFunctionIndex.z] + self.activeCameraAnimation.startZ*(1-dEased[self.activeCameraAnimation.easingFunctionIndex.z]) + offsetZ	: undefined,
							(self.activeCameraAnimation.endYaw !== undefined)?	self.activeCameraAnimation.endYaw*dEased[self.activeCameraAnimation.easingFunctionIndex.yaw] + self.activeCameraAnimation.startYaw*(1-dEased[self.activeCameraAnimation.easingFunctionIndex.yaw])	: undefined,
							(self.activeCameraAnimation.endTilt !== undefined)?	self.activeCameraAnimation.endTilt*dEased[self.activeCameraAnimation.easingFunctionIndex.tilt] + self.activeCameraAnimation.startTilt*(1-dEased[self.activeCameraAnimation.easingFunctionIndex.tilt])	: undefined
						);
						if ((self.activeCameraAnimation.endZ !== undefined) && (Math.abs(self.activeCameraAnimation.endZ-self.activeCameraAnimation.startZ) > 0.0000001)) {
							isZoomingEstimatedTargetZoom = self.activeCameraAnimation.endZ;
							isZoomingEstimatedTargetZoomAlreadyUpdated = true;
						}
						cameraAnimated = true;
						if (stopAnim) {
							self.activeCameraAnimation.completed = true;
							stopAnimateCameraTo();	// handle callback etc.
							// Force zero speed
							cameraPositionReference.x = camera.position.x;
							cameraPositionReference.y = camera.position.y;
							cameraPositionReference.z = camera.position.z;
							if (currentSelectedUser != undefined) {
								userDetailElement.forcePositionUpdate = true;	// bei niedrigen FPS sonst manchmal kein Update
						}
						}
					} else {
						self.activeCameraAnimation.startTime = timestamp;
						setRenderingIsDirty("renderLoopCameraAnim");
					}
				}

				var repeatCount = Math.min(60, Math.max(1, Math.round(deltaTime/normalDeltaTime)));	// TODO: nur mit deltaTime arbeiten?
				if ((input && input.getCameraIsPinned(true)) || cameraZoomed || cameraAnimated) {	// prohibit zero speed
					repeatCount = 1;
				}
				for (var i=0; i<repeatCount; i++) {	// normalise speed with different fps (capped)
					// Camera
					if ((input && input.getCameraIsPinned(true)) || cameraZoomed || cameraAnimated) {
						cameraSpeedReference.x = (camera.position.x - cameraPositionReference.x)*normalDeltaTime;//deltaTime;
						cameraSpeedReference.y = (camera.position.y - cameraPositionReference.y)*normalDeltaTime;//deltaTime;
						cameraSpeedReference.z = (camera.position.z - cameraPositionReference.z)*normalDeltaTime;//deltaTime;
						cameraZoomed = false;
						setRenderingIsDirty("renderLoopCameraSpeed");
					} else {
						var newPosX = camera.position.x + cameraSpeedReference.x/Math.max(1, normalDeltaTime);
						var newPosY = camera.position.y + cameraSpeedReference.y/Math.max(1, normalDeltaTime);
						var newPosZ = camera.position.z + cameraSpeedReference.z/Math.max(1, normalDeltaTime);
						setSaveCameraPosition(newPosX, newPosY, newPosZ);
					}
					cameraPositionReference.x = camera.position.x;
					cameraPositionReference.y = camera.position.y;
					cameraPositionReference.z = camera.position.z;

					cameraSpeedReference.x = (cameraSpeedReference.x*getSmoothMotion())/(getSmoothMotion()+1);
					cameraSpeedReference.y = (cameraSpeedReference.y*getSmoothMotion())/(getSmoothMotion()+1);
					cameraSpeedReference.z = (cameraSpeedReference.z*getSmoothMotion())/(getSmoothMotion()+1);

					cameraSpeedReferenceSmoothed.x = (cameraSpeedReference.x+cameraSpeedReferenceSmoothed.x*config.smoothMotionCamSpeedSmoothed)/(config.smoothMotionCamSpeedSmoothed+1);
					cameraSpeedReferenceSmoothed.y = (cameraSpeedReference.y+cameraSpeedReferenceSmoothed.y*config.smoothMotionCamSpeedSmoothed)/(config.smoothMotionCamSpeedSmoothed+1);
					cameraSpeedReferenceSmoothed.z = (cameraSpeedReference.z+cameraSpeedReferenceSmoothed.z*config.smoothMotionCamSpeedSmoothed)/(config.smoothMotionCamSpeedSmoothed+1);
				}
				renderCamera.rotation.set(0, 0, input? input.getCameraYawValue() : 0);
				renderCamera.rotateOnAxis(new THREE.Vector3(1, 0, 0), input? input.getCameraTiltValue() : config.startTilt);
				renderCamera.position.set(camera.position.x, camera.position.y, 0);
				renderCamera.translateZ(camera.position.z);
				renderCamera.updateMatrixWorld();	// manuell, da es durch vorhandenes .parent nicht automatisch passiert
				renderCamera.near = Math.abs(camera.position.z)*config.camNearClipFactor;
				renderCamera.far = Math.abs(camera.position.z)*config.camFarClipFactor;
				renderCamera.updateProjectionMatrix();

				updateShadowPosition();

				if (!isZoomingEstimatedTargetZoomAlreadyUpdated) {
					isZoomingEstimatedTargetZoom = camera.position.z + (cameraSpeedReference.z/normalDeltaTime)*(getSmoothMotion()+1);
				}

				var zoomValue = camera.position.z;
				var zoomValueNormalizedAbsolute = zoomValue/config.maxZoom;
				var zoomValueNormalized = (zoomValue-config.minZoom)/(config.maxZoom-config.minZoom);
				var zoomValueNormalizedLinear = Math.sqrt(Math.sqrt(zoomValueNormalized));
				if (Math.abs(cameraSpeedReference.z) < 0.1) {
					setDoubleClickZoomOutMovement(0);
				}
				var estimatedTargetZoomChanged = (Math.abs(isZoomingLastEstimatedTargetZoom - isZoomingEstimatedTargetZoom) > (config.isZoomingSpeedToleranceFactor*camera.position.z));
				var isZooming = estimatedTargetZoomChanged || isZoomingEstimatedTargetZoomAlreadyUpdated || (Math.abs(cameraSpeedReference.z) > (config.isZoomingSpeedToleranceFactor*camera.position.z));
				var isZoomingAlphaSpeed = deltaSeconds*config.isZoomingAlphaAnimationSpeed;
				var connectionZoomValue;
				if (isZooming) {
					if (wasZooming) {
						if (estimatedTargetZoomChanged) {
							isZoomingAlpha = 0;
							isZoomingSourceZoom = zoomValue;
						}
					}
					var isZoomingIn = (isZoomingSourceZoom > isZoomingEstimatedTargetZoom);// (cameraSpeedReference.z < 0);
					var zoomingCenterValue = (isZoomingSourceZoom+isZoomingEstimatedTargetZoom)*0.5;
					var isZoomingInSmallerHalf = (zoomValue < zoomingCenterValue);
					var isZoomingInSecondHalf = isZoomingIn? isZoomingInSmallerHalf : !isZoomingInSmallerHalf;
					var zoomProgress = ((isZoomingEstimatedTargetZoom-isZoomingSourceZoom) != 0)? Math.min(1, Math.max(0, (zoomValue-isZoomingSourceZoom)/(isZoomingEstimatedTargetZoom-isZoomingSourceZoom))) : 0.5;
					var isZoomingAlphaCap = 1 - (Math.min(1, zoomProgress*2) - Math.max(0, zoomProgress*2 - 1));
					if (isZoomingInSecondHalf) {
						connectionZoomValue = isZoomingEstimatedTargetZoom/config.maxZoom;
						isZoomingAlpha = Math.max(0, Math.min(1, isZoomingAlpha + isZoomingAlphaSpeed));
					} else {
						connectionZoomValue = isZoomingSourceZoom/config.maxZoom;
						isZoomingAlpha = Math.max(0, Math.min(1, isZoomingAlpha - isZoomingAlphaSpeed));
					}
					isZoomingAlpha = Math.min(isZoomingAlpha, isZoomingAlphaCap);
				} else {
					isZoomingAlpha = 1.0;//Math.max(0, Math.min(1, isZoomingAlpha + isZoomingAlphaSpeed));
					isZoomingSourceZoom = isZoomingEstimatedTargetZoom;
					connectionZoomValue = isZoomingEstimatedTargetZoom/config.maxZoom;
				}
				wasZooming = isZooming;
				isZoomingLastEstimatedTargetZoom = isZoomingEstimatedTargetZoom;

				var containedRadius = getContainedRadius(zoomValue, true);

				var tiltValueNormalized = ((input? input.getCameraTiltValue() : config.startTilt)-config.minTilt)/(config.maxTilt-config.minTilt);
				var tiltValueNormalized2 = config.fogEnabled? tiltValueNormalized*tiltValueNormalized : 0;

				//updateObjects();
				if (stats != undefined) {
					stats.update();
				}

				// Prepare Render
				renderer.clear(true, true, true);
				LigaMap.label.prepareRender(camera.position.x, camera.position.y, camera.position.z);

				// Render Layers
				var updatedMaterialCount = 0;
				var skippedMaterialCount = 0;
				var updatedMaterialsTime = 0.0;
				var currentQuadtreeLevel = getQuadtreeLevel(zoomValueNormalized);
				if (config.performance.logSlowFrames) {
					window.performance.mark('prep-end');
					window.performance.measure('prep', 'prep-start', 'prep-end');
				}
				var renderInfo = {calls:0, faces:0, points:0, vertices:0};
				var connectionsAreLabelsOnly = ((config.showConnections.length > 0) && (config.showConnections[0].labelsonly));
				var currentSelectedConnectionUID = -1;
				if (	currentSelectedConnection &&
						currentSelectedConnection.userData &&
						currentSelectedConnection.userData['selectUID']) {
					currentSelectedConnectionUID = currentSelectedConnection.userData['selectUID'];
				}
				var hideUserLabel = undefined;
				if (
					(currentSelectedConnectionUID < 0) &&
					(currentSelectedUser != undefined) &&
					(currentSelectedUser.visible)
				) {
					hideUserLabel = currentSelectedUser.userData.id;
				}

				for (var ln in layers) {
					if (config.performance.logSlowFrames) {
						window.performance.mark('layer-'+ln+'-start');
					}
					window.updateMatrixCount = 0;	// count and measure in three.js
					window.updateMatrixTime = 0;
					var l = layers[ln];
					var oldVisible = l.visible;
					var oldAlpha = l.alpha;
					l.updateVisibilityValues(zoomValue, timestamp, deltaSeconds);
					if (l.visible && !l.neverRender && !getIsLayerForceDisabled(ln, zoomValueNormalized)) {
						if (oldVisible != l.visible || Math.abs(oldAlpha-l.alpha) > 0.001 || l.clearFadeActive) {
							setRenderingIsDirty("renderLoopLayerVisible");
						}
						var easedAlpha = EasingFunctions.easeInOutQuad(l.alpha);
						if (l.labelFont) {
							// Find Labels in View for Canvas-Rendering
							var labelObjects = l.quadtree.getContainedObjects(containedRadius, cameraFrustum, currentQuadtreeLevel,false);
							if (l.ready) {
								// TODO: labelObjects= hier mit reinziehen?
								var labelOffset = [0.0, 0.0, 0.0];
								var isConnectionLabel = (ln=='cnnctns-lb');
								var isUserLabel = ((ln=='user-lb') || isConnectionLabel);
								var hasIcon = isConnectionLabel;
								var iconOnly = (connectionsAreLabelsOnly && (zoomValue > config.labelIconSmallNoLabelZoom));
								if (isUserLabel) {
									labelOffset[2] = Math.max(config.connectionHeight, 4 + zoomValue*0.015);
								}
								if (hasIcon) {
									if (!connectionsAreLabelsOnly) {
										labelOffset[2] = config.connectionHeight;
									} else {
										// Icon sanft zu Offset schieben:
										var offsetFadeValue = Math.max(0, Math.min(1, (zoomValue-config.labelIconSmallNoLabelOffsetFadeZoomMin)/(config.labelIconSmallNoLabelOffsetFadeZoomMax-config.labelIconSmallNoLabelOffsetFadeZoomMin)));
										labelOffset[2] = /*offsetFadeValue*0.0 +*/ (1.0-offsetFadeValue)*labelOffset[2];
									}
								}
								var labelLayer = (hasIcon && iconOnly)? 1 : 0;
								var isSelected;
								for (var i=0; i<labelObjects.length; i++) {
									var object = labelObjects[i];
									if (isUserLabel && (hideUserLabel !== undefined) && (hideUserLabel == object.userData.id)) {
										continue;	// Label von aktuell markiertem User nicht zeichnen
									}
									var useSmallIcon = connectionsAreLabelsOnly;
									if (!object.userData['name']) {
										continue;	// Labels ohne Text überspringen
									}
									isSelected = false;
									if (isConnectionLabel && currentSelectedConnectionUID == object.userData.id) {
										isSelected = true;
								}
								if (isUserLabel && !object.userData.origPosition) {
									object.userData.origPosition = object.position;
								}
								var clickableData = isUserLabel? object.userData : null;
								LigaMap.label.addToRenderQueue(
									ln+"-"+object.id,
									object.position.x+labelOffset[0], object.position.y+labelOffset[1], object.position.z+labelOffset[2],
									object.userData, l.labelFont, easedAlpha, isUserLabel, isSelected? 9999 : l.labelImportance,
									useSmallIcon, iconOnly||isSelected, clickableData,
									(object.userData['keepInView']===true),
									labelLayer, isSelected);
								}
							}
						} else {
							// Regular render
							if (l.ready) {
								l.quadtree.showContainedObjects(containedRadius, cameraFrustum, currentQuadtreeLevel, true);
								// Update Layer Shaders
								if (config.performance.logSlowFrames) {
									var st = window.performance.now();
								}
								var reuseAlpha = ((ln == 'league') || ((ln == 'connections') && (zoomValue > config.connectionShowAllMaxZoom)));	// LOD-Alpha bzw. Label-Alpha mitbenutzen
								if (ln == 'connections') {
									var obj, objDirection, viewVector, angle, labelAlpha, connectionVisible;
									for (var oi in l.scene.children) {
										obj = l.scene.children[oi];
										connectionVisible = true;
										if (reuseAlpha &&
											obj.userData && obj.userData.labelObject && obj.userData.labelObject.userData && obj.userData.labelObject.userData.label && 
											obj.material && obj.material && obj.material.userData
										) {	// Label-Visibility beachten
											labelAlpha = obj.userData.labelObject.userData.label.alpha;
											obj.material.uniforms.layerAlpha.value = labelAlpha;
											obj.material.userData.visible = (obj.material.userData.visible && (labelAlpha > 0));
											connectionVisible = obj.material.userData.visible;
										}
										if (connectionVisible && obj.userData && obj.userData['rotationAngle'] !== undefined) {
											objDirection = (new THREE.Vector3(1, 0, 0)).applyAxisAngle(new THREE.Vector3(0, 0, 1), obj.userData['rotationAngle'])
											viewVector = new THREE.Vector3(renderCamera.position.x - camera.position.x, renderCamera.position.y - camera.position.y, renderCamera.position.z);
											angle = viewVector.angleTo(objDirection);
											obj.setRotationFromEuler(new THREE.Euler( 0, Math.PI*0.5 - angle, obj.userData['rotationAngle'], 'XZY' ));
											obj.updateMatrix();
											obj.updateMatrixWorld();
										}
									}
								}
								var m;
								for (var mi in l.usedMaterials) {
									m = l.usedMaterials[mi];
									if (m.userData && m.userData.visible) {	// im Quadtree aktualisiert
										if (!reuseAlpha) {
											m.uniforms.layerAlpha.value = easedAlpha;
										} else {
											m.uniforms.layerAlpha.value = Math.min(easedAlpha, m.uniforms.layerAlpha.value);
										}
										m.uniforms.fogFactor.value = tiltValueNormalized2;
										m.uniforms.resolution.value.x = window.innerWidth;
										m.uniforms.resolution.value.y = window.innerHeight;
										if (ln == 'foreign-gr') {
											m.uniforms.offset.value = config.borderFactorForeign * zoomValueNormalized - config.borderMinuendForeign;//6.5 * zoomValueNormalized - 0.7;
										}
										else if (ln == 'bld-gr') {
											m.uniforms.offset.value = config.borderFactorBld * zoomValueNormalized - config.borderMinuendBld;//6.5 * zoomValueNormalized - 0.7;
										}
										else if (ln == 'krs-gr') {
											m.uniforms.offset.value = config.borderFactorKrs * zoomValueNormalized - config.borderMinuendKrs;//5.0 * zoomValueNormalized - 0.8;
										}
										else if (ln == 'gem-gr') {
											m.uniforms.offset.value = config.borderFactorGem * zoomValueNormalized - config.borderMinuendGem;//4.0 * zoomValueNormalized - 0.95;
										}
										else if (ln == 'heatmap') {
											if (m.uniforms.dotScale) {
												m.uniforms.dotScale.value = 0.9*(zoomValue/config.maxZoom) + 0.1;
												var dotZoomValueNormalized = (zoomValue-config.heatmapUserMinZoom)/(config.maxZoom-config.heatmapUserMinZoom);
												var c1r = (config.colorHeatmapUserMin&0xff0000) >> 16;
												var c2r = (config.colorHeatmapUserMax&0xff0000) >> 16;
												var c1g = (config.colorHeatmapUserMin&0x00ff00) >> 8;
												var c2g = (config.colorHeatmapUserMax&0x00ff00) >> 8;
												var c1b = (config.colorHeatmapUserMin&0x0000ff);
												var c2b = (config.colorHeatmapUserMax&0x0000ff);
												var mixedR = Math.round(dotZoomValueNormalized*c2r + (1-dotZoomValueNormalized)*c1r);
												var mixedG = Math.round(dotZoomValueNormalized*c2g + (1-dotZoomValueNormalized)*c1g);
												var mixedB = Math.round(dotZoomValueNormalized*c2b + (1-dotZoomValueNormalized)*c1b);
												var mixedColor = (mixedR<<16) + (mixedG<<8) + mixedB;
												m.uniforms.color.value.set(mixedColor);
											}
										}
										else if (ln == 'stadium_dots') {
											if (m.uniforms.dotScale) {
												m.uniforms.dotScale.value = 0.9*(zoomValue/config.maxZoom) + 0.1;
											}
										}
										else if (ln == 'connections') {
											var isSelected = false;
											if (	currentSelectedConnectionUID &&
													m.userData['selectUID']) {
												if (currentSelectedConnectionUID == m.userData['selectUID']) {
													isSelected = true;
												}
											}
											if (m.uniforms.offset) {
												m.uniforms.mapUStartLine.value = isSelected? 0.5-0.125*0.5 : 0.25-0.125*0.5;	// gepunktete oder durchgezogene Linie?
												m.uniforms.offset.value = config.connectionShaderPushStrength * zoomValueNormalizedAbsolute;
												m.uniforms.uvOffset.value = config.connectionShaderPushStrength * connectionZoomValue;
												m.uniforms.isZoomingAlpha.value = isZoomingAlpha;
											}
											if (m.uniforms.dotScale) {
												m.uniforms.dotScale.value = zoomValue/config.maxZoom;
											}
										}
										else if (ln == 'stadium_floor') {
											m.uniforms.colorFadeStrength.value =  config.generateFloortileBuildings? (layers['stadium']? layers['stadium'].alpha : layers['stadium_lo'].alpha) : 0;
										}
										else if (ln == 'league') {
											// Weicher Übergang wie bei Layer.updateVisibilityValues() (nach Zeit, nicht nach Zoom)
											var minZoom = (m.userData.lod>0)? config.leageLODLimits[m.userData.lod-1] : 0;
											var maxZoom = (m.userData.lod<config.leageLODLimits.length)? config.leageLODLimits[m.userData.lod] : 999999;
											var talpha = m.uniforms.layerAlpha.value;
											var d = deltaSeconds*l.alphaAnimationSpeed;
											if ((zoomValue >= minZoom) && (zoomValue <= maxZoom)) {
												if ((config.showConnections.length > 0) && !connectionsAreLabelsOnly) {	// fade when connections visible
													if (talpha > config.leagueAlphaWithConnections) {
														talpha = talpha - d;
													} else if ((talpha+d) < config.leagueAlphaWithConnections) {
														talpha = talpha + d;
													}
												} else {
													talpha = talpha + d;
												}
											} else {
												talpha = talpha - d;
											}
											var talpha2 = Math.max(0, Math.min(config.leagueAlpha, talpha));
											if (Math.abs(talpha2 - m.uniforms.layerAlpha.value) > 0.01) {
												setRenderingIsDirty("leagueLayerFading");
											}
											m.uniforms.layerAlpha.value = talpha2;
										}
										if (m.uniforms.currentTime != undefined) {
											m.uniforms.currentTime.value = timestamp;
											if (!l.ready) {	// delay fadein until layer ready
												m.uniforms.fadeInStartTime.value = timestamp;
												setRenderingIsDirty("fadeInLayerNotReady");
											}
										}

										if (m.uniforms.currentTimeDilated != undefined) {
											m.uniforms.currentTimeDilated.value = lastRenderTimestampDilated;
										}
										if (m.uniforms.zoomValue != undefined) {
											m.uniforms.zoomValue.value = 1.0-zoomValueNormalizedLinear;
										}
										updatedMaterialCount++;
									} else {
										skippedMaterialCount++;
									}
								}
								if (config.performance.logSlowFrames) {
									updatedMaterialsTime += (window.performance.now()-st);
								}
								// render
								renderer.render(l.scene, config.useDebugCam? tempCamera : renderCamera);
								if (config.showDebugText) {		
									renderInfo.calls += renderer.info.render.calls;
									renderInfo.faces += renderer.info.render.faces;
									renderInfo.points += renderer.info.render.points;
									renderInfo.vertices += renderer.info.render.vertices;
								}
								if (config.useRealtimeShadow && showShadowMapViewer) {
									if (ln == 'stadium') dirLightShadowMapViewer.render(renderer);
								}
								renderer.clearDepth();
							}
						}
					}
					if (window.updateMatrixTime > 0.1) LigaMap.console.log(ln, window.updateMatrixTime.toFixed(1), "ms /", window.updateMatrixCount);
					if (config.performance.logSlowFrames) {
						window.performance.mark('layer-'+ln+'-end');
						window.performance.measure('layer-'+ln, 'layer-'+ln+'-start', 'layer-'+ln+'-end');
					}
				}
				if (config.performance.logSlowFrames) {
					window.performance.mark('labels-start');
				}
				LigaMap.label.render(config, timestamp, config.useDebugCam? tempCamera : renderCamera, toScreenPosition, renderer, setRenderingIsDirty);
				if (config.showDebugText && config.labelRendering3D) {		
					renderInfo.calls += renderer.info.render.calls;
					renderInfo.faces += renderer.info.render.faces;
					renderInfo.points += renderer.info.render.points;
					renderInfo.vertices += renderer.info.render.vertices;
				}
				if (config.performance.logSlowFrames) {
					window.performance.mark('labels-end');
					window.performance.measure('labels', 'labels-start', 'labels-end');
				}

				// User-Selection
				if ((currentSelectedUser != undefined) && (currentSelectedUser.visible)) {
					if (config.performance.logSlowFrames) {
						window.performance.mark('selecteduser-start');
					}
					var pos = toScreenPosition(currentSelectedUser, config.useDebugCam? tempCamera : renderCamera, new THREE.Vector3(0, 0, config.connectionHeight));
					var fixedX = pos.x.toFixed(1);
					var fixedY = pos.y.toFixed(1);
					var cx = userDetailElement.lastCX;
					var cy = userDetailElement.lastCY;
					var ca = 0.0;
					if (currentSelectedConnection) {
						if (currentSelectedConnection.userData && currentSelectedConnection.userData['labelObject']) {
							var labelObject = currentSelectedConnection.userData['labelObject'];
							if (labelObject.userData && labelObject.userData.label && labelObject.userData.label['screenPosition'] && (labelObject.userData.label['renderCoordsTimestamp'] >= timestamp)) {
								pos = labelObject.userData.label['screenPosition'];
							} else {	// Fallback 1
								pos = toScreenPosition(labelObject, config.useDebugCam? tempCamera : renderCamera, new THREE.Vector3(0, 0, config.connectionHeight));
							}
							ca = (labelObject.userData && labelObject.userData.label && labelObject.userData.label['keptInViewFadeValue'])? (1.0-labelObject.userData.label['keptInViewFadeValue']) : 1.0;
						} else {	// Fallback 2
							// Userposition weiterbenutzen
							ca = 1.0;
						}
						if (config.labelIconDetailOffsets[currentSelectedConnection.userData['selectType']]) {
							var cr = LigaMap.label.getLabelCanvasResolution()/config.labelResolution;
							cx = (pos.x + config.labelIconDetailOffsets[currentSelectedConnection.userData['selectType']][0]/Math.max(0.001, cr)).toFixed(1);
							cy = (pos.y + config.labelIconDetailOffsets[currentSelectedConnection.userData['selectType']][1]/Math.max(0.001, cr)).toFixed(1);
						} else {
							cx = pos.x.toFixed(1);
							cy = pos.y.toFixed(1);
						}
					}
					if (userDetailElement.forcePositionUpdate || (userDetailElement.lastX != fixedX) || (userDetailElement.lastY != fixedY) || (userDetailElement.lastCX != cx) || (userDetailElement.lastCY != cy)) {
						userDetailElement.forcePositionUpdate = false;
						userDetailElement.lastX = fixedX;
						userDetailElement.lastY = fixedY;
						userDetailElement.lastCX = cx;
						userDetailElement.lastCY = cy;
						container.trigger('mapUpdateUserDetailsPosition', {x:fixedX, y:fixedY, cx:cx, cy:cy, ca:ca});
					}
					if (config.performance.logSlowFrames) {
						window.performance.mark('selecteduser-end');
						window.performance.measure('selecteduser', 'selecteduser-start', 'selecteduser-end');
					}
				}

				if (config.performance.logSlowFrames) {
					window.performance.mark('post-start');
				}
				if (config.showQuadtree) {
					layers['debug'].quadtree.getContainedObjects(containedRadius, cameraFrustum, currentQuadtreeLevel, true);
					renderer.sortObjects = false;
					renderer.render(layers['debug'].quadtree.debugScene, config.useDebugCam? tempCamera : renderCamera);
					// Update Camera Viewport Visualization Object
					var pos_tl = null, pos_tr = null, pos_bl = null, pos_br = null, pos_c = null;
					var pos_c = getProjectedPlanePosition(0,	0);
					if (pos_c) {
						var pos_tl = getProjectedPlanePosition(-1,	-1);
						var pos_tr = getProjectedPlanePosition(1,	-1);
						var pos_bl = getProjectedPlanePosition(-1,	1);
						var pos_br = getProjectedPlanePosition(1,	1);
						pos_tl = pos_tl? pos_tl : pos_c;
						pos_tr = pos_tr? pos_tr : pos_c;
						pos_bl = pos_bl? pos_bl : pos_c;
						pos_br = pos_br? pos_br : pos_c;
						var gem = cameraHelper.geometry;
						var verts = gem.vertices;
						verts[0].x = pos_tl.x;
						verts[0].y = pos_tl.y;
						verts[1].x = pos_tr.x;
						verts[1].y = pos_tr.y;
						verts[2].x = pos_bl.x;
						verts[2].y = pos_bl.y;
						verts[3].x = pos_br.x;
						verts[3].y = pos_br.y;
						gem.verticesNeedUpdate = true;
					} else {
						LigaMap.console.log("Debug camera object: No frustum intersection");
					}

					// Update radius debug-object
					containedRadiusDebugObject.scale.x = containedRadius*layers['debug'].quadtree.maxDistanceFactor;
					containedRadiusDebugObject.scale.y = containedRadiusDebugObject.scale.x;
					containedRadiusDebugObject.scale.z = containedRadiusDebugObject.scale.x;
					containedRadiusDebugObject.position.x = camera.position.x;
					containedRadiusDebugObject.position.y = camera.position.y;
					containedRadiusDebugObject.visible = true;
					containedRadiusDebugObject.updateMatrix();
					containedRadiusDebugObject.updateMatrixWorld();

					renderer.render(cameraHelperScene, config.useDebugCam? tempCamera : renderCamera);
				} else {
					containedRadiusDebugObject.visible = false;
					if (cameraHelperScene.children.length > 1) {
						renderer.render(cameraHelperScene, renderCamera);
					}
				}

				// Debug
				if (config.showDebugText && input) {
					input.drawDebug();
				}

				if (config.showDebugText) {
					var renderColor = "#ffff00";
					if (isDirty) renderColor = "#00ff00";
					var dilationColor = "#00ff00";
					if (timeDilationActive) dilationColor = "#ffff00";
					var fpsColor = "#ffff00";
					if (fpsHistoryAverage > config.fpsHistoryHighFPS) fpsColor = "#00ff00";
					if (fpsHistoryAverage < config.fpsHistoryLowFPS) fpsColor = "#ff0000";
					loadingStatusContainer.html(
						"Time/Render: "+(lastRenderTimestamp*0.001).toFixed(1)+"s, <span style=\"color:"+renderColor+"\">"+(lastRenderTimestampDilated*0.001).toFixed(1)+"s</span> "+
						"<span style=\"color:"+dilationColor+"\">("+currentTimeDilation.toFixed(2)+")</span>, "+
						"<span style=\"color:"+fpsColor+"\">"+fpsHistoryAverage.toFixed(2)+"fps</span><br>"+
						"quadtree measure: "+(debugDurationQuadtreePrintValue*1000/60).toFixed(2)+"ms/frame<br>"+
						"Loading: "+loadingCount+", Adding: "+LigaMap.mesh.addingCount+"<br>"+
						"Memory: "+renderer.info.memory.geometries+" geometries, "+renderer.info.memory.textures+" textures, "+renderer.info.programs.length+" programs<br>"+
						"Render: "+renderInfo.calls+" calls, "+renderInfo.faces+" faces, "+renderInfo.points+" points, "+renderInfo.vertices+" verts<br>"+
						"Labels: "+LigaMap.label.getStatDisplayed()+"/"+LigaMap.label.getStatOverall()+" ("+LigaMap.label.getStatRendered()+")"+", Materials: "+updatedMaterialCount+"/"+(updatedMaterialCount+skippedMaterialCount)+"<br>"+
						"Quadtree-Level: "+currentQuadtreeLevel+"<br>"+
						"CamSpeed: "+cameraSpeedReference.x.toFixed(1)+", "+cameraSpeedReference.y.toFixed(1)+", "+cameraSpeedReference.z.toFixed(1)+"<br>"+
						"CamPos: "+camera.position.x.toFixed(1)+", "+camera.position.y.toFixed(1)+", "+camera.position.z.toFixed(1)+(isZooming? " -> "+isZoomingEstimatedTargetZoom.toFixed(1) : "")+" + T"+(180*(input? input.getCameraTiltValue() : config.startTilt)/Math.PI).toFixed(1)+"° + Y"+(180*(input? input.getCameraYawValue() : 0)/Math.PI).toFixed(1)+"° ("+
						renderCamera.position.x.toFixed(1)+", "+renderCamera.position.y.toFixed(1)+", "+renderCamera.position.z.toFixed(1)+")<br>"+
						"<span style=\"color:"+(lastLayerUpdateSkipped? "#ff0000" : "#00ff00")+"\">LayerUpdateStatus</span><br>"+
						"dblClickZoomType: "+dblClickZoomType+", zoomValueNormalized: "+zoomValueNormalized.toFixed(3)+", zoomValueNormalizedLinear: "+zoomValueNormalizedLinear.toFixed(3)+(isZooming? ", isZoomingAlpha: "+isZoomingAlpha.toFixed(2) : "")
					);	
				}

				if (config.performance.logSlowFrames) {
					window.performance.mark('post-end');
					window.performance.measure('post', 'post-start', 'post-end');
					window.performance.measure('overall', 'prep-start', 'post-end');
					var overall = window.performance.getEntriesByName('overall', 'measure');
					if (overall[0].duration > 17) {
						LigaMap.console.log("Slow frame:");
						var items = window.performance.getEntriesByType('measure');
						for (var i = 0; i < items.length; ++i) {
							var req = items[i];
							if (req.duration > 1)
								LigaMap.console.log('  ', req.name, ':', req.duration.toFixed(2), 'ms');
						}
						LigaMap.console.log('  materialUpdateTime:', updatedMaterialsTime.toFixed(1), "ms");
					}
				}
			} else {
				if (config.showDebugText) {
					var renderColor = "#ff0000";
					var dilationColor = "#ff0000";
					var fpsColor = "#ffff00";
					if (fpsHistoryAverage > config.fpsHistoryHighFPS) fpsColor = "#00ff00";
					if (fpsHistoryAverage < config.fpsHistoryLowFPS) fpsColor = "#ff0000";
					loadingStatusContainer.html(
						"Time/Render: "+(lastRenderTimestamp*0.001).toFixed(1)+"s, <span style=\"color:"+renderColor+"\">"+(lastRenderTimestampDilated*0.001).toFixed(1)+"s</span> "+
						"<span style=\"color:"+dilationColor+"\">("+currentTimeDilation.toFixed(2)+")</span>, "+
						"<span style=\"color:"+fpsColor+"\">"+fpsHistoryAverage.toFixed(2)+"fps</span><br>"+
						"quadtree measure: "+(debugDurationQuadtreePrintValue*1000/60).toFixed(2)+"ms/frame<br>"+
						"Loading: "+loadingCount+", Adding: "+LigaMap.mesh.addingCount+"<br>"+
						"Memory: "+renderer.info.memory.geometries+" geometries, "+renderer.info.memory.textures+" textures, "+renderer.info.programs.length+" programs<br>"+
						"Render: -<br>"+
						"Labels: -<br>"+
						"Quadtree-Level: -<br>"+
						"CamSpeed: -<br>"+
						"CamPos: "+camera.position.x.toFixed(1)+", "+camera.position.y.toFixed(1)+", "+camera.position.z.toFixed(1)+(isZooming? " -> "+isZoomingEstimatedTargetZoom.toFixed(1) : "")+" + T"+(180*(input? input.getCameraTiltValue() : config.startTilt)/Math.PI).toFixed(1)+"° + Y"+(180*(input? input.getCameraYawValue() : 0)/Math.PI).toFixed(1)+"° ("+
						renderCamera.position.x.toFixed(1)+", "+renderCamera.position.y.toFixed(1)+", "+renderCamera.position.z.toFixed(1)+")<br>"+
						"<span style=\"color:"+(lastLayerUpdateSkipped? "#ff0000" : "#00ff00")+"\">LayerUpdateStatus</span><br>"+
						"dblClickZoomType: "+dblClickZoomType+", zoomValueNormalized: -, zoomValueNormalizedLinear: -"
					);	
				}
			}

			fpsHistoryFrameCounter++;

			if (config.renderActive) {
				requestAnimationFrame(renderLoop);
			}
		};

		var getQuadtreeLevel = function(zoomValueNormalized) {
			return Math.round(EasingFunctions.easeInQuint(1.0-zoomValueNormalized)*config.quadTreeDepth);
		};

		var getIsLayerForceDisabled = function(layername, zoomValueNormalized) {
			// when double-click-Zoom-Out and not almost finished, show only specific layers
			if ((dblClickZoomType>0) && (zoomValueNormalized < 0.9)) {
				if (
					(layername != 'bg') &&
					(layername != 'bld') &&
					(layername != 'bld-lb') &&
					(layername != 'bld-gr') &&
					(layername != 'krs') &&
					(layername != 'krs-lb') &&
					(layername != 'krs-gr') &&
					(layername != 'league') &&
					(layername != 'heatmap') &&
					(layername != 'connections')
				) {
					return true;
				}
			}
			// Hidden by config
			if (!config['leaguesVisible']) {
				if (layername == 'league') return true;
			}
			if (!config['usersVisible']) {
				if (layername == 'stadium_shadow') return true;
				if (layername == 'stadium_floor') return true;
				if (layername == 'stadium_lo') return true;
				if (layername == 'stadium') return true;
				if (layername == 'user') return true;
				if (layername == 'userpos') return true;
				if (layername == 'user-lb') return true;
				if (layername == 'heatmap') return true;
			}
			if (!config['gemVisible']) {
				if (layername == 'gem') return true;
				if (layername == 'gem-gr') return true;
				if (layername == 'gem-lb') return true;
			}
			if (!config['allLabelsVisible']) {
				if (layername == 'bld-lb') return true;
				if (layername == 'krs-lb') return true;
				if (layername == 'gem-lb') return true;
				if (layername == 'user-lb') return true;
			}

			return false;
		};

		var updateLayerVisiblity = function() {
			updateCameraFrustum();

			if (self.activeCameraAnimation) {	// Bei Kameraanimation überspringen
				lastLayerUpdateSkipped = true;
				return;
			}
			if (Math.abs(cameraSpeedReference.z/camera.position.z) > config.layerUpdateSkipToleranceRelative) {	// skip when zooming fast
				lastLayerUpdateSkipped = true;
				return;
			}
			lastLayerUpdateSkipped = false;

			var zoomValue = camera.position.z;
			var zoomValueNormalized = (zoomValue-config.minZoom)/(config.maxZoom-config.minZoom);
			var currentQuadtreeLevel = getQuadtreeLevel(zoomValueNormalized);
			var containedRadius = getContainedRadius(zoomValue, true);
			var doNotLoad = !LigaMap.mesh.allAssetsLoaded;	// warten bis alle Meshes/Texturen geladen sind
			var unloadHeavyLeaveBudget = config.performance.maxUnloadHeavyLeaves;
			for (var i in layers) {
				var l = layers[i];
				// LigaMap.console.log(i, layernames[i], l.alpha);
				l.quadtree.unloadHeavyLeaveBudget = unloadHeavyLeaveBudget;
				if (l.visible && !getIsLayerForceDisabled(i, zoomValueNormalized)) {
					if (!l.labelFont) {
						// Regular render
						l.quadtree.showContainedObjects(containedRadius, cameraFrustum, currentQuadtreeLevel, doNotLoad, config.layerRetryFailedLoadDelay, config.layerUnloadInvisibleDelay);	// Force loading
					} else {
						// TODO: Font-Layer Unload nach Sichtbarkeit durchführen
					}
				} else if (introFinished) {
					l.quadtree.checkUnloadAllLeaves(config.layerUnloadInvisibleDelay);
				}
				unloadHeavyLeaveBudget = l.quadtree.unloadHeavyLeaveBudget;
			}
			if ((debugDurationQuadtreePrintValueLastTimestamp <= 0) || ((debugDurationQuadtreePrintValueLastTimestamp+10000) < lastRenderTimestamp)) {
				debugDurationQuadtreePrintValue = LigaMap.debugDurationQuadtree/(lastRenderTimestamp-debugDurationQuadtreePrintValueLastTimestamp);
				LigaMap.debugDurationQuadtree = 0.0;
				debugDurationQuadtreePrintValueLastTimestamp = lastRenderTimestamp;
			}
			// update fps history
			var now = Date.now();
			if (fpsHistoryTimestamp+config.fpsHistoryInterval < now) {
				if ((fpsHistoryFrameCounter > 0) && (document.visibilityState != 'hidden')) {	// browser inactive in background -> 0 frames
					var currentFPS = fpsHistoryFrameCounter*1000/(now-fpsHistoryTimestamp);
					if (fpsHistory.length < config.fpsHistoryLength) {
						fpsHistory.push(currentFPS);
						fpsHistoryIndex = fpsHistory.length;
						fpsHistoryAverage = 0.0;
					} else {
						fpsHistoryIndex = (fpsHistoryIndex+1)%fpsHistory.length;
						fpsHistory[fpsHistoryIndex] = currentFPS;
						fpsHistoryAverage = 0.0;
						for (var i=0; i<fpsHistory.length; i++) {
							fpsHistoryAverage = fpsHistoryAverage + fpsHistory[i];
						}
						fpsHistoryAverage /= fpsHistory.length;
						if (introStarted) {
							var rating = 'ok';
							if (fpsHistoryAverage > config.fpsHistoryHighFPS) {
								rating = 'good';
							}
							if (fpsHistoryAverage < config.fpsHistoryLowFPS) {
								rating = 'bad';
							}
							if (rating != fpsHistoryLastRating) {
								fpsHistoryLastRating = rating;
								container.trigger("mapFPSChange", {FPS:fpsHistoryAverage, now:now, rating:rating});
							}
						}
					}
				}
				fpsHistoryFrameCounter = 0;
				fpsHistoryTimestamp = now;
			}
		};

		var loadLayersWhenInactive = function() {
		};

		// Constructor
		init();
	}
	
	self.createInstance = function(jQuery, container, _config, initFinishedCallback) {
		return new LigaMapInstance(jQuery, container, _config, initFinishedCallback);
	};

	self.getCameraFOVCorrection = function(aspect, fovPortraitCorrectionFactor) {
		return (1.0 + Math.max(0.0, (1.0 - aspect)) * fovPortraitCorrectionFactor);
	};

	return self;

}(LigaMap || {}));
