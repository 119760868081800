/**
 * Copyright Christian Kubitza
 * christian@ck3d.de
 * 2015-2018
 */

var LigaMap = (function(self) {
	"use strict";

	self.input = self.input || {};

	// Debug Helper Class
	var touchHelperClass = function(_color) {
		var startX = 0, startY = 0, currentX = 0, currentY = 0, lastX = 0, lastY = 0, color = _color, active = false, infotext = "";

		this.setStart = function(x, y) {
			startX = x;
			startY = y;
			currentX = x;
			currentY = y;
			lastX = x;
			lastY = y;
			infotext = "";
			active = true;
		};
		this.getStart = function() {
			return {x: startX, y: startY};
		};
		this.getLast = function() {
			return {x: lastX, y: lastY};
		};
		this.getVector = function() {
			return {x: currentX-startX, y: currentY-startY};
		};
		this.getVectorLast = function() {
			return {x: currentX-lastX, y: currentY-lastY};
		};
		this.setCurrent = function(x, y) {
			lastX = currentX;
			lastY = currentY;
			currentX = x;
			currentY = y;
		};
		this.deactivate = function() {
			active = false;
		};
		this.setInfo = function(text) {
			infotext = String(text);
		};
		this.draw = function(labelCanvasContext) {
			if (!active) {
				return;
			}
			labelCanvasContext.fillStyle = color;
			labelCanvasContext.strokeStyle = color;
			labelCanvasContext.lineWidth = 5;
			labelCanvasContext.beginPath();
        	labelCanvasContext.arc(startX, startY, 10, 0, Math.PI*2, true);
			labelCanvasContext.fill();
			labelCanvasContext.beginPath();
        	labelCanvasContext.arc(currentX, currentY, 10, 0, Math.PI*2, true);
			labelCanvasContext.fill();
			labelCanvasContext.beginPath();
			labelCanvasContext.moveTo(startX, startY);
		    labelCanvasContext.lineTo(currentX, currentY);
			labelCanvasContext.stroke();
			if (infotext.length > 0) {
				// if (outlineWidth > 0) {
				// 	context.strokeStyle = color;
				// 	context.lineWidth = outlineWidth;
				// 	context.lineJoin = "miter";
				// 	context.miterLimit = 3;
				// 	context.strokeText(text, textx, texty);
				// }
				labelCanvasContext.fillStyle = 'white';
				labelCanvasContext.fillText(infotext, currentX, currentY);

			}
		};
	};

	var Input = function(
			container,
			instanceConfig,
			setDoubleClickZoomOutMovement,
			getInteractionPlanePosition,
			selectAtScreenPosition,
			setSaveCameraPosition,
			touchEndAction,
			setZoomCamera,
			getCameraSpeedReference,
			setSelectedUser,
			setRenderingIsDirty,
			setSelectedConnection,
			set3DHelper,
			remove3DHelper
		) {

		var touchHelper1 = new touchHelperClass("#ff0000");
		var touchHelper2 = new touchHelperClass("#0000ff");
		var touchHelperCenter = new touchHelperClass("#880088");

		var inputEnabled = true;

		var startMouseX, startMouseY, startMouseProjectedX, startMouseProjectedY, mouseProjectedX, mouseProjectedY, mouseX, mouseY,
			mouseMovedWhilePressed, startTwoFingerDistance, lastClickTime, mouseDownCtrlPressed, twoFingerTouchModeFixed, twoFingerTouchModeIsTilt,
			twoFingerTouchStartTiltValue, twoFingerTouchStartYawValue, twoFingerTouchStartZoomValue, twoFingerTouchStartYawAngle, twoFingerTouchYawAmount, twoFingerTouchTiltAmount, twoFingerTouchZoomAmount;
		var cameraIsPinned = false, cameraIsPinnedForOneFrame = false;
		var cameraTiltValue = instanceConfig.startTilt, cameraYawValue = 0, cameraPosition = new THREE.Vector3();
		var keyPressActive = {up:false, down:false, left:false, right:false, zoomIn:false, zoomOut:false};
		var currentSmoothMotion = 0;
		
		var self = this;

		this.getCameraIsPinned = function(clearPinnedForOneFrame) {
			if (cameraIsPinnedForOneFrame) {
				if (clearPinnedForOneFrame) {
					cameraIsPinnedForOneFrame = false;
					window.setTimeout(function() {	// TODO: warum nötig?
						updateKeyPressActions();
					}, 10);
				}
				return true;
			}
			return cameraIsPinned;
		};

		this.getCameraTiltValue = function() {
			return cameraTiltValue;
		};

		this.getCameraYawValue = function() {
			return cameraYawValue;
		};

		this.setCameraTiltValue = function(value) {
			var newValue = Math.min(instanceConfig.maxTilt, Math.max(instanceConfig.minTilt, value));
//			if (Math.abs(newValue-cameraTiltValue) > 0.001) {
				setRenderingIsDirty("setCameraTiltValue");
//			}
			cameraTiltValue = newValue;
			container.trigger("mapUpdateCameraTilt", cameraTiltValue);
		};

		this.setCameraYawValue = function(value) {
			cameraYawValue = value;
			setRenderingIsDirty("setCameraYawValue");
			container.trigger("mapUpdateCameraYaw", value);
		};

		this.setCameraPosition = function(newPosition) {
			cameraPosition = newPosition;
		};

		this.setSmoothMotion = function(value) {
			if (value === undefined) return;
			currentSmoothMotion = Math.max(0, parseInt(value, 10));
		};
		
		this.getSmoothMotion = function() {
			return currentSmoothMotion;
		}

		var zoomCamera = function(direction, screenX, screenY) {
			var step = 0;
			if (direction > 0) {
				step = 1;
			} else if (direction < 0) {
				step = -1;
			} else {
				return;
			}
			//LigaMap.console.log("zoomCamera()", step);
			//LigaMap.console.log(cameraSpeedReference.z);
			self.setSmoothMotion(instanceConfig.smoothMotionZoom);
			var newZoomPos = cameraPosition.z * (1.0 + (step * instanceConfig.sensitivityZoom));
			newZoomPos += step * instanceConfig.zoomSpeedUpWhenZooming*Math.abs(getCameraSpeedReference().z);	// Additional speed when already in zooming movement
			if (step < 0) {	// zoom-in
				setZoomCamera(newZoomPos, screenX, screenY);
			} else {	// zoom-out
				// Kamera zentrieren
				// var zoomRange = (instanceConfig.maxZoom-instanceConfig.minZoom);
				// var factor = newZoomPos/instanceConfig.maxZoom;
				// var factor = Math.abs((newZoomPos-instanceConfig.minZoom-cameraPosition.z)/(instanceConfig.maxZoom-instanceConfig.minZoom-cameraPosition.z));
				// var targetZoomPos = cameraPosition.z + (newZoomPos-cameraPosition.z) / (1 - ((self.getSmoothMotion())/(self.getSmoothMotion()+1)));	// Wo werden wir landen?
				var targetZoomPos = cameraPosition.z + (newZoomPos-cameraPosition.z) * (self.getSmoothMotion()+1);	// Wo werden wir landen? (gekürzt)
				// LigaMap.console.log(targetZoomPos);
				var factor = Math.min(1, Math.max(0, (targetZoomPos-instanceConfig.minZoom)/(instanceConfig.maxZoom-instanceConfig.minZoom)));
				factor = factor*factor*factor;	// x^2: Perspective, x^3: with speedup at end
				// LigaMap.console.log(factor);
				setZoomCamera(newZoomPos, screenX, screenY, 1-factor);
				var dx = (instanceConfig.minPanX+instanceConfig.maxPanX)*0.5 - cameraPosition.x;
				var dy = (instanceConfig.minPanY+instanceConfig.maxPanY)*0.5 - cameraPosition.y;
				var xValue = factor*dx*(1/(self.getSmoothMotion()+1));	// wie viel bewegen, damit inkl. Nachziehen am richtigen Endpunkt?
				var yValue = factor*dy*(1/(self.getSmoothMotion()+1));	// wie viel bewegen, damit inkl. Nachziehen am richtigen Endpunkt?
				setSaveCameraPosition(cameraPosition.x + xValue, cameraPosition.y + yValue);
			}
		};

		var dblclickZoomCamera = function(screenX, screenY) {
			self.setSmoothMotion(instanceConfig.smoothMotionZoom);
			var zoomToleranceFactor = 1.1;
			var zoomOut = (cameraPosition.z <= (instanceConfig.minZoom*zoomToleranceFactor));
			var targetZoom = instanceConfig.maxZoom;
			if (!zoomOut) {
				for (var i in instanceConfig.dblclickZoomSteps) {
					targetZoom = instanceConfig.dblclickZoomSteps[i];
					if (cameraPosition.z > (targetZoom*zoomToleranceFactor)) {
						break;
					}
				}
				setDoubleClickZoomOutMovement(-1);
			} else {
				setDoubleClickZoomOutMovement(1);
			}

			var dz = targetZoom-cameraPosition.z;
			var zoomValue = dz*(1/(self.getSmoothMotion()+1));	// wie viel zoomen, damit inkl. Nachziehen am richtigen Endzoom?
			LigaMap.console.log("targetZoom: "+targetZoom+", zoomValue: "+zoomValue+", dz: "+dz+", "+cameraPosition.z+">"+(targetZoom*zoomToleranceFactor));
			if (zoomOut) {
				setZoomCamera(cameraPosition.z + zoomValue);
				// Kamera zentrieren
				var dx = (instanceConfig.minPanX+instanceConfig.maxPanX)*0.5 - cameraPosition.x;
				var dy = (instanceConfig.minPanY+instanceConfig.maxPanY)*0.5 - cameraPosition.y;
				var xValue = dx*(1/(self.getSmoothMotion()+1));	// wie viel bewegen, damit inkl. Nachziehen am richtigen Endpunkt?
				var yValue = dy*(1/(self.getSmoothMotion()+1));	// wie viel bewegen, damit inkl. Nachziehen am richtigen Endpunkt?
				setSaveCameraPosition(cameraPosition.x + xValue, cameraPosition.y + yValue);
			} else {
				setZoomCamera(cameraPosition.z + zoomValue, screenX, screenY);
			}
		};


		// Events
		var mouseDownHandler = function(e) {
			// LigaMap.console.log("mousedown, button:", e.button);
			e.preventDefault();
			if (!inputEnabled) return;
			container.focus();
			setDoubleClickZoomOutMovement(0);
			switch (e.button) {
				case 0:	// left mouse button
					mouseDownCtrlPressed = ((e.ctrlKey == true) || (e.metaKey === true));
					var containerOffset = container.offset();
					var posX = e.pageX - containerOffset.left;
					var posY = e.pageY - containerOffset.top;
					var projectedPosition = getInteractionPlanePosition(posX, posY, true);
					if (projectedPosition) {
						cameraIsPinned = true;
						startMouseProjectedX = projectedPosition.x;//e.clientX;
						startMouseProjectedY = projectedPosition.y;//e.clientY;
						startMouseX = posX;
						startMouseY = posY;
						mouseProjectedX = startMouseProjectedX;
						mouseProjectedY = startMouseProjectedY;
						mouseMovedWhilePressed = false;
						// container.css("cursor", "-webkit-grabbing").css("cursor", "grabbing");
					}
					break;
				case 1:	// middle mouse button
				case 2:	// right mouse button
					break;
				default:
			}
		};

		var mouseMoveHandler = function(e) {
			if (!inputEnabled) return;
			var containerOffset = container.offset();
			var posX = e.pageX - containerOffset.left;
			var posY = e.pageY - containerOffset.top;
			if (startMouseProjectedX != undefined) {
				container.css("cursor", "move");
				// LigaMap.console.log("move");
				self.setSmoothMotion(instanceConfig.smoothMotion);
				if (mouseDownCtrlPressed) {
					var delta = (mouseY - posY)/Math.max(1, container.innerHeight());
					self.setCameraTiltValue(Math.min(instanceConfig.maxTilt, Math.max(instanceConfig.minTilt, cameraTiltValue + delta*instanceConfig.sensitivityTilt)));
					delta = (mouseX - posX)/Math.max(1, container.innerWidth());
					self.setCameraYawValue(cameraYawValue + delta*instanceConfig.sensitivityTilt);
					mouseMovedWhilePressed = true;
					if (Math.abs(startMouseX-posX) > 2) {
						container.trigger("mapInputYaw", false);
					}
					if (Math.abs(startMouseY-posY) > 2) {
						container.trigger("mapInputTilt", false);
					}
				} else {
					var projectedPosition = getInteractionPlanePosition(posX, posY);
					if (projectedPosition) {
						if ((mouseProjectedX == undefined) || (mouseProjectedX != projectedPosition.x) || (mouseProjectedY == undefined) || (mouseProjectedY != projectedPosition.y)) {	// Chrome-Bug (Click führt zu falschem Mousemove-Event)
							// panning
							setSaveCameraPosition(cameraPosition.x-(projectedPosition.x-mouseProjectedX), cameraPosition.y-(projectedPosition.y-mouseProjectedY));
							mouseProjectedX = projectedPosition.x;//e.clientX;
							mouseProjectedY = projectedPosition.y;//e.clientY;
							//LigaMap.console.log("moved");
							mouseMovedWhilePressed = true;
							if ((Math.abs(startMouseX-posX) > 2) || (Math.abs(startMouseY-posY) > 2)) {
								container.trigger("mapInputPan", false);
						}
					}
				}
			}
			}
			mouseX = posX;
			mouseY = posY;
		};

		var singleClickTimeoutId = null;
		var doubleClickTime = 250;	// ms
		var doSingleClick = function(x, y) {
			// LigaMap.console.log("single-click (wait)");
			abortSingleClick();
			singleClickTimeoutId = window.setTimeout(function() {
				// LigaMap.console.log("single-click (execute)");
				LigaMap.label.getLabelAtPositionOnNextRender(x, y, function(foundLabelsAtPosition) {
					if (foundLabelsAtPosition && foundLabelsAtPosition.length > 0) {
//						console.log("clicked on label: ", foundLabelsAtPosition);
						// Connection-Details bevorzugen
						var foundConnectionLabel = false;
						for (var i=0; i<foundLabelsAtPosition.length; i++) {
							if (foundLabelsAtPosition[i].data) {
								if (/*(foundLabelsAtPosition[i].clickIndex === 2) &&*/ foundLabelsAtPosition[i].data["connectionObject"]) {
									setSelectedConnection(foundLabelsAtPosition[i].data["connectionObject"]);
									foundConnectionLabel = true;
									break;
								} else {
									if (foundLabelsAtPosition[i].data["id"]) {
										setSelectedUser(undefined, foundLabelsAtPosition[i].data);
										foundConnectionLabel = true;
										break;
					}
								}
							}
						}
						if (!foundConnectionLabel) {
							setSelectedUser(undefined, foundLabelsAtPosition[0].data);
						}
				} else {
						selectAtScreenPosition(x, y);
				}
				});
				setRenderingIsDirty("doSingleClick");
			}, doubleClickTime+1);
		};
		var abortSingleClick = function() {
			if (singleClickTimeoutId != null) {
				window.clearTimeout(singleClickTimeoutId);
				singleClickTimeoutId = null;
			}
		};

		var mouseUpHandler = function(e) {
//			LigaMap.console.log("mouseup, button:", e.button);
			if (!inputEnabled) return;
			cameraIsPinned = false;
			// container.css("cursor", "-webkit-grab").css("cursor", "grab");
			container.css("cursor", "default");
			if (startMouseProjectedX != undefined) {
				if (!mouseMovedWhilePressed) {	// Left-Mouse Click
					var clickTime = Date.now();
					if ((clickTime-lastClickTime) > doubleClickTime) {	// Klick und Doppelklick unterscheiden
//						LigaMap.console.log("single-click");
						doSingleClick(mouseX, mouseY);
					} else {	// ansonsten ist es ein Doppelklick
						// LigaMap.console.log("double-click");
						abortSingleClick();
						dblclickZoomCamera(mouseX, mouseY);
					}
					lastClickTime = clickTime;
				} else {
					// LigaMap.console.log("mouse were moved");
					//LigaMap.console.log("not left mouse click:", mouseMovedWhilePressed, mouseMoveIsPanning);
					//LigaMap.console.log("New Center: ", cameraPosition.x, cameraPosition.y);
				}
			}
			startMouseProjectedX = undefined;
			startMouseProjectedY = undefined;
		};

		var mouseWheelHandler = function(e) {
			e.preventDefault();
			e.stopPropagation();
			if (!inputEnabled) return;
			setDoubleClickZoomOutMovement(0);
			var event = e.originalEvent;

			var delta = 0;
			if (event.wheelDelta !== undefined ) { // WebKit / Opera / Explorer 9
				delta = - event.wheelDelta;
			} else if (event.deltaY !== undefined ) {
				delta = event.deltaY;
			} else if (event.detail !== undefined ) { // Firefox
				delta = - event.detail;
			}
			// container.css("cursor", "-webkit-zoom-in").css("cursor", "zoom-in");
			zoomCamera(delta, mouseX, mouseY);
			container.trigger("mapInputZoom", false);
		};

		var touchStartHandler = function(e) {
			var event = e.originalEvent;
			// LigaMap.console.log("touchstart: ", event.touches.length);
			e.preventDefault();
			event.preventDefault();
			if (!inputEnabled) return;
			container.focus();
			setDoubleClickZoomOutMovement(0);
			var containerOffset = container.offset();
			switch(event.touches.length) {
				case 1:	// one-fingered touch: pan
					//e.preventDefault();
					var posX = event.touches[0].pageX - containerOffset.left;
					var posY = event.touches[0].pageY - containerOffset.top;
					var projectedPosition = getInteractionPlanePosition(posX, posY, true);
					if (projectedPosition) {
						cameraIsPinned = true;
						startMouseProjectedX = projectedPosition.x;
						startMouseProjectedY = projectedPosition.y;
						mouseProjectedX = startMouseProjectedX;
						mouseProjectedY = startMouseProjectedY;
						// mouseMovedWhilePressed = false;
					}
					mouseX = posX;
					mouseY = posY;
					startMouseX = mouseX;
					startMouseY = mouseY;
					// Debug
					touchHelper1.setStart(posX, posY);
					break;
				case 2:	// two-fingered touch: zoom (& pan)
					//e.preventDefault();
					var dx = event.touches[0].pageX - event.touches[1].pageX;
					var dy = event.touches[0].pageY - event.touches[1].pageY;
					startTwoFingerDistance = Math.max(1, Math.sqrt( dx * dx + dy * dy ));
					// panning auch über 2 Finger
					var centerX = (event.touches[0].pageX + event.touches[1].pageX)*0.5 - containerOffset.left;
					var centerY = (event.touches[0].pageY + event.touches[1].pageY)*0.5 - containerOffset.top;
					var projectedPosition = getInteractionPlanePosition(centerX, centerY, true);
					if (projectedPosition) {
						cameraIsPinned = true;
						startMouseProjectedX = projectedPosition.x;
						startMouseProjectedY = projectedPosition.y;
						mouseProjectedX = startMouseProjectedX;
						mouseProjectedY = startMouseProjectedY;
						// mouseMovedWhilePressed = false;
					}
					mouseX = centerX;
					mouseY = centerY;
					startMouseX = mouseX;
					startMouseY = mouseY;
					// Debug
					touchHelper1.setStart(event.touches[0].pageX - containerOffset.left, event.touches[0].pageY - containerOffset.top);
					touchHelper2.setStart(event.touches[1].pageX - containerOffset.left, event.touches[1].pageY - containerOffset.top);
					touchHelperCenter.setStart(centerX, centerY);
					// Gestenerkennung
					twoFingerTouchModeFixed = false;
					twoFingerTouchModeIsTilt = false;
					twoFingerTouchStartTiltValue = cameraTiltValue;
					twoFingerTouchStartYawValue = cameraYawValue;
					twoFingerTouchStartZoomValue = cameraPosition.z;
					twoFingerTouchYawAmount = 0;
					var startPos1 = touchHelper1.getStart();
					var startPos2 = touchHelper2.getStart();
					var deltaVectorStart = {x:(startPos2.x-startPos1.x), y:(startPos2.y-startPos1.y)};
					twoFingerTouchStartYawAngle = Math.atan2(deltaVectorStart.y, deltaVectorStart.x);
					twoFingerTouchTiltAmount = 0;
					twoFingerTouchZoomAmount = 0;
					break;
				default:
			}
			mouseMovedWhilePressed = false;
		};

		var touchMoveHandler = function(e) {
			var event = e.originalEvent;
			// LigaMap.console.log("touchmove: ", event.touches.length);
			e.stopPropagation();
			e.preventDefault();
			if (!inputEnabled) return;
			if (startMouseProjectedX != undefined) {
				self.setSmoothMotion(instanceConfig.smoothMotion);
				var containerOffset = container.offset();
				switch(event.touches.length) {
					case 1:	// one-fingered touch: pan
						var posX = event.touches[0].pageX - containerOffset.left;
						var posY = event.touches[0].pageY - containerOffset.top;
						var projectedPosition = getInteractionPlanePosition(posX, posY);
						// panning
						if (projectedPosition) {
							setSaveCameraPosition(cameraPosition.x-(projectedPosition.x-mouseProjectedX), cameraPosition.y-(projectedPosition.y-mouseProjectedY));
							mouseProjectedX = projectedPosition.x;
							mouseProjectedY = projectedPosition.y;
							// Touch-Move tolerance for easier click
							if ((Math.abs(mouseX-startMouseX) > 2.0) || (Math.abs(mouseY-startMouseY) > 2.0)) {
								mouseMovedWhilePressed = true;
								// LigaMap.console.log("moved!");
								container.trigger("mapInputPan", true);
							}
						}
						mouseX = posX;
						mouseY = posY;
						// Debug
						touchHelper1.setCurrent(posX, posY);
						break;
					case 2:	// two-fingered touch: zoom (& pan)
						var centerX = (event.touches[0].pageX + event.touches[1].pageX)*0.5 - containerOffset.left;
						var centerY = (event.touches[0].pageY + event.touches[1].pageY)*0.5 - containerOffset.top;
						// Debug
						touchHelper1.setCurrent(event.touches[0].pageX - containerOffset.left, event.touches[0].pageY - containerOffset.top);
						touchHelper2.setCurrent(event.touches[1].pageX - containerOffset.left, event.touches[1].pageY - containerOffset.top);
						touchHelperCenter.setCurrent(centerX, centerY);
						// Yaw
						var deltaVectorCurrent = {x:(event.touches[1].pageX-event.touches[0].pageX), y:(event.touches[1].pageY-event.touches[0].pageY)};
						var lastTwoFingerTouchYawAmount = twoFingerTouchYawAmount;
						twoFingerTouchYawAmount = Math.atan2(deltaVectorCurrent.y,deltaVectorCurrent.x) - twoFingerTouchStartYawAngle;
						var deltaTouchYawAmount = twoFingerTouchYawAmount-lastTwoFingerTouchYawAmount;
						// Tilt
						var v1={x:0,y:0}, v2={x:0,y:0};
						if (!twoFingerTouchModeFixed || (twoFingerTouchModeFixed && twoFingerTouchModeIsTilt)) {
							v1 = touchHelper1.getVector();
							v2 = touchHelper2.getVector();
							twoFingerTouchTiltAmount = -0.5*(v1.y+v2.y)/Math.max(1, container.innerHeight());
							// LigaMap.console.log(v1.y/Math.max(1, Math.abs(v1.x)), v2.y/Math.max(1, Math.abs(v2.x)));
						}
						// Zoom
						var dx = event.touches[ 0 ].pageX - event.touches[ 1 ].pageX;
						var dy = event.touches[ 0 ].pageY - event.touches[ 1 ].pageY;
						var twoFingerDistance = Math.sqrt( dx * dx + dy * dy );
						twoFingerTouchZoomAmount = startTwoFingerDistance / Math.max(1, twoFingerDistance);

						// LigaMap.console.log("yaw:", twoFingerTouchYawAmount.toFixed(2), "tilt:", twoFingerTouchTiltAmount.toFixed(2), "zoom:", twoFingerTouchZoomAmount.toFixed(2));
						// touchHelperCenter.setInfo(newCamZ.toFixed(3)+", "+twoFingerTouchZoomAmount.toFixed(2));
						var doLastReset = false;
						// Entscheiden, ob Tilt oder nicht
//						console.log(v1, v2, v1.x*v1.x+v1.y*v1.y, v2.x*v2.x+v2.y*v2.y, (v1.x*v1.x+v1.y*v1.y + v2.x*v2.x+v2.y*v2.y));
						if (!twoFingerTouchModeFixed && ((v1.x*v1.x+v1.y*v1.y + v2.x*v2.x+v2.y*v2.y) > 200)) {	// sqrt() fehlt, nicht wichtig
							// Vertikalität
							var tiltPossibility1 = v1.y/Math.max(1, Math.abs(v1.x));
							var tiltPossibility2 = v2.y/Math.max(1, Math.abs(v2.x));
							var isUp1 = (tiltPossibility1>0);
							var isUp2 = (tiltPossibility2>0);
							var zoomStrength = 1.0-twoFingerTouchZoomAmount;
//							var difference = (Math.abs(tiltPossibility1)>Math.abs(tiltPossibility2))? (tiltPossibility2/tiltPossibility1) : (tiltPossibility1/tiltPossibility2);
//							console.log(tiltPossibility1, tiltPossibility2, zoomStrength);
							
							if ((isUp1 == isUp2) && (Math.abs(tiltPossibility1) > 1.2) && (Math.abs(tiltPossibility2) > 1.2) && (Math.abs(zoomStrength) < 0.05)) {
								 LigaMap.console.log("tilt");
								twoFingerTouchYawAmount = 0;
								deltaTouchYawAmount = 0;
								twoFingerTouchZoomAmount = 1;
								twoFingerTouchModeIsTilt = true;
								doLastReset = true;
								container.trigger("mapInputTilt", true);
							} else {
								 LigaMap.console.log("zoom/pan/yaw");
								twoFingerTouchTiltAmount = 0;
								container.trigger("mapInputZoom", true);
							}
							twoFingerTouchModeFixed = true;
						}
						if (!doLastReset && twoFingerTouchModeFixed && twoFingerTouchModeIsTilt) {
							self.setCameraTiltValue(Math.min(instanceConfig.maxTilt, Math.max(instanceConfig.minTilt, twoFingerTouchStartTiltValue + twoFingerTouchTiltAmount*instanceConfig.sensitivityTilt)));
						} else {
							var projectedPosition = getInteractionPlanePosition(centerX, centerY);
							var projectedPosition2 = getInteractionPlanePosition(centerX, centerY, false, undefined, true);
							// Yaw
							self.setCameraYawValue(twoFingerTouchStartYawValue + twoFingerTouchYawAmount);//*instanceConfig.sensitivityTilt
							var oldZoomPos = cameraPosition.z;
							// Zoom
							setZoomCamera(twoFingerTouchStartZoomValue * twoFingerTouchZoomAmount);
							var newZoomPos = cameraPosition.z;
							// Pan (and correct zoom/rotation center)
							if (projectedPosition) {
//								set3DHelper('t1', projectedPosition.x, projectedPosition.y, projectedPosition.z, newZoomPos*0.02);
//								set3DHelper('t2', projectedPosition2.x, projectedPosition2.y, projectedPosition2.z, newZoomPos*0.02);
//								set3DHelper('t3', cameraPosition.x, cameraPosition.y, 0, newZoomPos*0.04);
								var zoomPercentage = 1.0 - newZoomPos / Math.max(1, oldZoomPos);
								var centerZoomPosX = (cameraPosition.x - projectedPosition2.x)*zoomPercentage;
								var centerZoomPosY = (cameraPosition.y - projectedPosition2.y)*zoomPercentage;
//								touchHelperCenter.setInfo(zoomPercentage.toFixed(3));//centerZoomPosX.toFixed(3)+", "+centerZoomPosY.toFixed(3));

								var panOffset = {
									x: (projectedPosition.x-mouseProjectedX)*twoFingerTouchZoomAmount + centerZoomPosX,
									y: (projectedPosition.y-mouseProjectedY)*twoFingerTouchZoomAmount + centerZoomPosY
								};
								var panOffsetRotated = {
									x: panOffset.x * Math.cos(twoFingerTouchYawAmount) - panOffset.y * Math.sin(twoFingerTouchYawAmount),
									y: panOffset.x * Math.sin(twoFingerTouchYawAmount) + panOffset.y * Math.cos(twoFingerTouchYawAmount)
								};
								
								var rotationCenterOffset = {
									x: (projectedPosition2.x - cameraPosition.x),
									y: (projectedPosition2.y - cameraPosition.y)
								};
								var rotationCenterOffsetRotated = {
									x: rotationCenterOffset.x * Math.cos(deltaTouchYawAmount) - rotationCenterOffset.y * Math.sin(deltaTouchYawAmount),
									y: rotationCenterOffset.x * Math.sin(deltaTouchYawAmount) + rotationCenterOffset.y * Math.cos(deltaTouchYawAmount)
								};
								rotationCenterOffsetRotated.x -= rotationCenterOffset.x;
								rotationCenterOffsetRotated.y -= rotationCenterOffset.y;
								
//								touchHelperCenter.setInfo(rotationCenterOffsetRotated.x.toFixed(2)+", "+rotationCenterOffset.x.toFixed(2));
								setSaveCameraPosition(
									cameraPosition.x - rotationCenterOffsetRotated.x - panOffsetRotated.x,
									cameraPosition.y - rotationCenterOffsetRotated.y - panOffsetRotated.y,
									undefined, true);
								mouseProjectedX = projectedPosition.x;
								mouseProjectedY = projectedPosition.y;
							}
							if (Math.abs(twoFingerTouchYawAmount) > 0.1) {
								container.trigger("mapInputYaw", true);
						}
						}


						mouseMovedWhilePressed = true;
						// LigaMap.console.log("moved!");
						mouseX = centerX;
						mouseY = centerY;
						break;
					default:
				}
				//mouseMovedWhilePressed = true;
			}
		};

		var touchEndHandler = function(e) {
			var event = e.originalEvent;
			// LigaMap.console.log("touchend: ", event.touches.length);
			if (!inputEnabled) return;
			// LigaMap.console.log(cameraSpeedReference, cameraSpeedReferenceSmoothed);
			cameraIsPinned = false;
			touchEndAction();
			if (startMouseProjectedX != undefined) {
				if (!mouseMovedWhilePressed) {	// Left-Mouse Click
					var clickTime = Date.now();
					if ((clickTime-lastClickTime) > 10) {	// nur "clicks" alle X ms zulassen, sonst "falscher" Doppelklick (ipad)
						if ((clickTime-lastClickTime) > doubleClickTime) {	// Klick und Doppelklick unterscheiden
							doSingleClick(mouseX, mouseY);
						} else {	// ansonsten ist es ein Doppelklick
							// LigaMap.console.log("double-click", e);
							abortSingleClick();
							dblclickZoomCamera(mouseX, mouseY);
						}
					} else {
						// LigaMap.console.log("false double-click");
					}
					lastClickTime = clickTime;
				} else {
					// LigaMap.console.log("finger were moved");
					//LigaMap.console.log("not left mouse click:", mouseMovedWhilePressed, mouseMoveIsPanning);
					//LigaMap.console.log("New Center: ", cameraPosition.x, cameraPosition.y);
				}
			}
			startMouseProjectedX = undefined;
			startMouseProjectedY = undefined;
			// Debug
			touchHelper1.deactivate();
			touchHelper2.deactivate();
			touchHelperCenter.deactivate();
//			remove3DHelper('t1');
//			remove3DHelper('t2');
//			remove3DHelper('t3');
		};
		
		this.startInputAction = function(type) {
			if (type == undefined) {
				return;
			}
			switch (type) {
				case "zoomIn":
				case "zoomOut":
				case "up":
				case "down":
				case "left":
				case "right":
					break;
				default:
					return;
			}
			if (!keyPressActive[type]) {
				keyPressActive[type] = true;
				if (!cameraIsPinnedForOneFrame) {
					updateKeyPressActions();
				}
			}
		};
		this.stopInputAction = function(type) {
			if (type == undefined) {
				return;
			}
			if (keyPressActive[type] == true) {
				keyPressActive[type] = false;
			}
		};
		
		var keyDownHandler = function(e) {
			if (!inputEnabled) return;
//			LigaMap.console.log(e);
			var typePressed = undefined;
			switch (e.keyCode) {
				case 107:	// + (Num)
				case 187:	// +
					typePressed = "zoomIn";
					break;
				case 109:	// - (Num)
				case 189:	// -
					typePressed = "zoomOut";
					break;
				case 38:	// up
					typePressed = "up";
					break;
				case 40:	// down
					typePressed = "down";
					break;
				case 37:	// left
					typePressed = "left";
					break;
				case 39:	// right
					typePressed = "right";
					break;
			}
			if (typePressed != undefined) {
				self.startInputAction(typePressed);
			}
		};
		
		var keyUpHandler = function(e) {
			if (!inputEnabled) return;
			var typePressed = undefined;
			switch (e.keyCode) {
				case 107:	// + (Num)
				case 187:	// +
					typePressed = "zoomIn";
					break;
				case 109:	// - (Num)
				case 189:	// -
					typePressed = "zoomOut";
					break;
				case 38:	// up
					typePressed = "up";
					break;
				case 40:	// down
					typePressed = "down";
					break;
				case 37:	// left
					typePressed = "left";
					break;
				case 39:	// right
					typePressed = "right";
					break;
			}
			if (typePressed != undefined) {
				self.stopInputAction(typePressed);
			}
		};
		
		var updateKeyPressActions = function(e) {
			if (!inputEnabled) return;
			if (keyPressActive.up || keyPressActive.down || keyPressActive.left || keyPressActive.right) {
				cameraIsPinnedForOneFrame = true;
				var normalDeltaTime = 1000.0/60.0;
				var cameraSpeedReference = getCameraSpeedReference();
				var dx = cameraSpeedReference.x/normalDeltaTime;
				var dy = cameraSpeedReference.y/normalDeltaTime;
				if (keyPressActive.up) {
					dy += instanceConfig.sensitivityPan*cameraPosition.z;
				}
				if (keyPressActive.down) {
					dy -= instanceConfig.sensitivityPan*cameraPosition.z;
				}
				if (keyPressActive.left) {
					dx -= instanceConfig.sensitivityPan*cameraPosition.z;
				}
				if (keyPressActive.right) {
					dx += instanceConfig.sensitivityPan*cameraPosition.z;
				}
//				dx = Math.max(-instanceConfig.maxPanSpeed, Math.min(instanceConfig.maxPanSpeed, dx));
//				dy = Math.max(-instanceConfig.maxPanSpeed, Math.min(instanceConfig.maxPanSpeed, dy));
				setSaveCameraPosition(cameraPosition.x + dx, cameraPosition.y + dy);
			}
			if (keyPressActive.zoomIn || keyPressActive.zoomOut) {
				cameraIsPinnedForOneFrame = true;
				setDoubleClickZoomOutMovement(0);
				var zoomValue = 0;
				if (keyPressActive.zoomIn) {
					zoomValue -= 1;
				}
				if (keyPressActive.zoomOut) {
					zoomValue += 1;
				}
				var newZoomPos = cameraPosition.z * (1.0 + (zoomValue * instanceConfig.sensitivityZoomKeyboard));
				setZoomCamera(newZoomPos);
			}
		};
		

		this.drawDebug = function() {
			var labelCanvasContext = LigaMap.label.getLabelCanvasContext();
			if (!labelCanvasContext) return;
			touchHelper1.draw(labelCanvasContext);
			touchHelper2.draw(labelCanvasContext);
			touchHelperCenter.draw(labelCanvasContext);
		};

		this.setInputEnabled = function(enabled) {
			inputEnabled = enabled;
			// reset values
			startMouseProjectedX = undefined;
			startMouseProjectedY = undefined;
			container.css("cursor", "default");
		};
		
		this.getMouseMovedWhilePressed = function() {
			return mouseMovedWhilePressed;
		}
		
		this.abortMouseClick = function() {
			startMouseProjectedX = undefined;
			startMouseProjectedY = undefined;
		};

		container.css("cursor", "default");
		lastClickTime = 0;
		container
			.on('mousedown', mouseDownHandler)
			.on('touchstart', touchStartHandler)
			.on('mousemove', mouseMoveHandler)
			.on('touchmove', touchMoveHandler)
			.on('mouseup', mouseUpHandler)
			.on('touchend', touchEndHandler)
			.on('wheel mousewheel DOMMouseScroll', mouseWheelHandler)
			.on('keydown', keyDownHandler)
			.on('keyup', keyUpHandler);
		$(document).on('mouseleave', function(e) {
			startMouseProjectedX = undefined;
			startMouseProjectedY = undefined;
		});
	};


	self.input.setupControls = function(
			container,
			instanceConfig,
			setDoubleClickZoomOutMovement,
			getInteractionPlanePosition,
			selectAtScreenPosition,
			setSaveCameraPosition,
			touchEndAction,
			setZoomCamera,
			getCameraSpeedReference,
			setSelectedUser,
			setRenderingIsDirty,
			setSelectedConnection,
			set3DHelper,
			remove3DHelper
		) {
		return new Input(
			container,
			instanceConfig,
			setDoubleClickZoomOutMovement,
			getInteractionPlanePosition,
			selectAtScreenPosition,
			setSaveCameraPosition,
			touchEndAction,
			setZoomCamera,
			getCameraSpeedReference,
			setSelectedUser,
			setRenderingIsDirty,
			setSelectedConnection,
			set3DHelper,
			remove3DHelper
		);
	};

	return self;
}(LigaMap || {}));
