/**
 * Copyright Christian Kubitza
 * christian@ck3d.de
 * 2015-2019
 */

var LigaMap = (function(self) {
	"use strict";

	var globalNavigationScale = 50;	// =300/6;	// fov-abhängig
	self.globalNavigationScale = globalNavigationScale;

	var defaultConfig = {
		ajaxUrl: "ajax.mapdata.php",
		ajaxHeatmapUrl: "ajax.heatmapurls.php",
		assetBasePath: "./",
		localePath: "./",
        heatmapConfigUrl: "heatmap/11/heatmap_1484233167.json",
        heatmapTextureUrl: "heatmap/11/heatmap_1484233167.png",
		webWorkerPaths: {	// script: (relative to document), includes: (array, relative to worker script)
			FloorTiles: {
				script: "js/LigaMap/worker/FloorTiles.js",
				includes: ['../../threejs/three.js', '../../LigaMap.js.php']
			}
		},
		backgroundScaleX: 1744,
		backgroundScaleY: 1744,
		backgroundPositionX: 36950,
		backgroundPositionY: 51830,
		labelIconDetailOffsets: {	// TODO: in Composition mit aufnehmen
			transfer:		[-21, -22],
			versus:			[-28.5, -9.0],
			formerplayer:	[-27, -25],
			formerteam:		[-32.5,-26],
		},
		labelIcons: {
			friend: {
				url: "/imgs/maplabelicons/icon_friend.png",
				centerX: 26,	// xpos
				centerY: 89,	// ypos
			},
			friend_small: {
				url: "/imgs/maplabelicons/icon_friend_small.png"
			},
			enemy: {
				url: "/imgs/maplabelicons/icon_rival.png",
				centerX: 26,	// xpos
				centerY: 89,	// ypos
			},
			enemy_small: {
				url: "/imgs/maplabelicons/icon_rival_small.png"
			},
			fav_club: {
				url: "/imgs/maplabelicons/icon_fav_club.png"
			},
			formerteam: {
				url: "/imgs/maplabelicons/icon_formerteam.png",
				centerX: 60,
				centerY: 104
			},
			formerplayer: {
				url: "/imgs/maplabelicons/icon_formerplayer.png",
				centerX: 48,
				centerY: 101,
			},
			transfer: {
				url: "/imgs/maplabelicons/icon_transfer.png",
				centerX: 17,	// xpos
				centerY: 101,	// ypos
			},
			transfer_opened: {
				url: "/imgs/maplabelicons/icon_transfer_opened.png",
				centerX: 17,	// xpos
				centerY: 101,	// ypos
			},
			versus: {
				url: "/imgs/maplabelicons/icon_versus.png",
				centerX: 25,//116,
				centerY: 43,//75
			}, 
			versus_opened: {
				url: "/imgs/maplabelicons/icon_versus_opened.png",
				centerX: 25,//116,
				centerY: 43,//75
			}, 
			details: {
				url: "/imgs/maplabelicons/icon_details.png"
			},
			details_opened: {
				url: "/imgs/maplabelicons/icon_details_opened.png"
			}
		},
		labelCompositions: {
			/*
			labelType: [
				{
					// all properties are optional
					// values can be String/Numbers or object{default:..., selected:..., iconOnly:..., small:..., smallIconOnly:...} (properties can be omitted, then other less specific value is used)
					icon: {default:'versus', selected:'versus_opened', iconOnly:undefined, small:undefined, smallIconOnly:undefined},	// values are keys from "labelIcons" -> icon canvas; or undefined -> text canvas
					offsetX: 0.0,
					offsetY: 0.0
					scale: {default: 0.54, selected:0.73},
					alpha: 1.0,
					referenceX: undefined	// value is undefined(as single value) or null(as object property value) -> position relative to center; value is Number -> position relative to bounding box from previews canvas in composition)
					referenceY: 10.0
				},
				...
			],
			...
			 */
			friend: [
				{
					alpha: {default:1.0, iconOnly:0.0},
					offsetX: {default: 10.0, small: 0, iconOnly: 0},
					offsetY: {default: -47.0, small: 0, iconOnly: 0}
				}, {
					icon: {default:'friend', small:'friend_small'},
					scale: 0.35,
					offsetX: {default:0.0, small:12.0, iconOnly:0.0},
					referenceX: {small:1.0, iconOnly:null},
				}
			],
			enemy: [
				{
					alpha: {default:1.0, iconOnly:0.0},
					offsetX: {default: 10.0, small: 0, iconOnly: 0},
					offsetY: {default: -47.0, small: 0, iconOnly: 0}
				}, {
					icon: {default:'enemy', small:'enemy_small'},
					scale: 0.35,
					offsetX: {default:0.0, small:12.0, iconOnly:0.0},
					referenceX: {small:1.0, iconOnly:null},
				}
			],
			fav_club: [
				{
					alpha: {default:1.0, iconOnly:0.0},
				}, {
					icon: 'fav_club',
					scale: 0.2,
					offsetX: {default:0.0, small:12.0, iconOnly:0.0},
					referenceX: {small:1.0, iconOnly:null},
				}
			],
			transfer: [
				{
					icon: {default:'transfer', selected:'transfer_opened'},
					scale: {default: 0.35, selected:0.47},
				}, {
					icon: {default:'details', selected:'details_opened'},
					offsetX: -20.0,
					offsetY: -14.0,
					scale: 0.45,
				}, {
					offsetX: 16.0,
					offsetY: -50.0,
					alpha: {default:1.0, selected:0.0},
//					referenceX: 0.5,
				}
			],
			transfer_nodetails: [
				{
					icon: 'transfer',
					scale: 0.35,
				}, {
					offsetX: 16.0,
					offsetY: -50.0,
				}
			],
			versus: [
				{
					icon: {default:'versus', selected:'versus_opened'},
					scale: {default: 0.41, selected:0.6},
				}, {
					icon: {default:'details', selected:'details_opened'},
					offsetX: -28.0,
					scale: 0.45,
				}, {
					offsetX: 16.0,
					offsetY: -40.0,
					alpha: {default:1.0, selected:0.0},
				}
			],
			versus_nodetails: [
				{
					icon: 'versus',
					scale: 0.41,
				}, {
					offsetX: 16.0,
					offsetY: -40.0,
				}
			],
			formerplayer: [
				{
					icon: 'formerplayer',//{default:'formerplayer', selected:'formerplayer_opened'},
					scale: {default: 0.35, selected:0.47},
				}, {
					icon: {default:'details', selected:'details_opened'},
					offsetX: -27.0,
					offsetY: -17.0,
					scale: 0.45,
				}, {
					offsetX: 8.0,
					offsetY: -52.0,
					alpha: {default:1.0, selected:0.0},
				}
			],
			formerplayer_nodetails: [
				{
					icon: 'formerplayer',
					scale: 0.35,
				}, {
					offsetX: 8.0,
					offsetY: -52.0,
				}
			],
			formerteam: [
				{
					icon: 'formerteam',
					scale: {default: 0.35, selected:0.47},
				}, {
					icon: {default:'details', selected:'details_opened'},
					offsetX: -32.0,
					offsetY: -18.0,
					scale: 0.45,
				}, {
					offsetX: 0.0,
					offsetY: -54.0,
					alpha: {default:1.0, selected:0.0},
				}
			],
			formerteam_nodetails: [
				{
					icon: 'formerteam',
					scale: 0.35,
				}, {
					offsetX: 0.0,
					offsetY: -54.0,
				}
			],
		},
		labelIconScale: 1.65,		// *labelHeight
		labelIconScaleSmall: 0.9,	// *labelHeight
		labelIconMarginHorz: 0.1,	// *labelHeight
		labelIconMarginVert: 0.2,	// *labelHeight
		labelIconSmallNoLabelZoom: 500*globalNavigationScale,	// >x: only icon, no text
		labelIconSmallNoLabelOffsetFadeZoomMin: 20*globalNavigationScale,	// = layer[user-lb].maxZoom
		labelIconSmallNoLabelOffsetFadeZoomMax: 100*globalNavigationScale,
		labelKeptInViewScale: 0.6,
		labelKeptInViewScaleFadeSpeed: 0.05,
		labelKeptInViewMargin: 30,	// pixel
		labelRendering2D: false,	// do not enable both 2D and 3D (except for debugging)
		labelRendering3D: true,
		renderActive: true,
		lateInit: false,
		sensitivityZoom: 0.06,
		sensitivityZoomKeyboard: 0.01,
		sensitivityTilt: 6,
		sensitivityPan: 0.0002,	// for keyboard
		fov: 3.6*6/* * 10*/,
		fovPortraitCorrectionFactor: 1.3,	// FOV-Anpassung im Hochformat
		camNearClipFactor: 0.25,	// bei fov(3.6): *0.6	// bei fov(36): *0.3
		camFarClipFactor: 8,	// bei fov(3.6): *1.4	// bei fov(36): *15
		fogStartFactor: 0.5,
		startZoom: 6000*globalNavigationScale/* * 0.1*/,
		minZoom: 1*globalNavigationScale/* * 0.1*/,
		maxZoom: 6000 * globalNavigationScale/* * 0.1*/,
		layerZoomFactor: 1,
		startX: 37500,
		startY: 50000,
		borderFactorGem: 10.0,
		borderFactorKrs: 13.0,
		borderFactorBld: 17.0,
		borderFactorForeign: 17.0,
		borderMinuendGem: 0.96,
		borderMinuendKrs: 0.93,
		borderMinuendBld: 0.90,
		borderMinuendForeign: 0.90,
		dblclickZoomSteps: [300*globalNavigationScale, 12*globalNavigationScale, 1*globalNavigationScale],
		zoomSpeedUpWhenZooming: 0.05,
		startTilt: 0,
		minTilt: 0,
		maxTilt: Math.PI*0.43,//0.465,//0.43,
		introAnimation: [	// Parameter für animateCameraTo() (aber callback nicht benutzen!)
			{z: 5000*globalNavigationScale, duration: 2.0, easings: {time:'linear', z:'easeInOutQuad'}},
			{y: 40000, z: 4500*globalNavigationScale, tilt: Math.PI*0.3, duration: 2.0, easings: {time:'linear', x:'easeInOutQuad', y:'easeInOutQuad', z:'easeInOutQuad', tilt:'easeInOutQuad'}},
		],
		introFadeInLayers: [
			"bld", "bld-gr", "bld-lb","foreign", "foreign-gr", "connections", "cnnctns-lb", "userpos", "heatmap"
		],
		introFinishedFadeInLayers: [
			"league"
		],
		introStartDelay: 0,	// to compensate short loading times so that bg-layer is visible	// TODO: better set a minimum overall loading time
		defaultX: 37500,	// defaults should match start/intro/zoomstep values (see above)
		defaultY: 40000,
		defaultZoom: 4500*globalNavigationScale,
		defaultZoomUser: 12*globalNavigationScale,
		defaultTilt: Math.PI*0.3,
		animationAutodurationFactor: 3,
		smoothMotion: 12-1,
		smoothMotionZoom: 4-1,
		smoothMotionCamSpeedSmoothed: 4-1,
		smoothMotionTimeDilationFast: 12-1,
		smoothMotionTimeDilationSlow: 64-1,
		isZoomingAlphaAnimationSpeed: 2,	// is animating
		isZoomingSpeedToleranceFactor: 0.0001,	// x*camPosZ
		antialiasing: true,
		pixelRatio: ((typeof window != 'undefined') && window.devicePixelRatio)? window.devicePixelRatio : 1,
		colorGemGr: 0x555555,
		colorKrsGr: 0x444444,
		colorBldGr: 0xffffff,
		colorForeignGr: 0xf8f8f8,
		colorHeatmap: 0xffffff,
		colorHeatmapUserMin: 0xbfbfbf,
		colorHeatmapUserMax: 0xffffff,
		colorUserPreviewDots: 0xbfbfbf,
		objectFadeInDuration: 0.5,
		labelResolution: ((typeof window != 'undefined') && window.devicePixelRatio)? window.devicePixelRatio : 1,
		labelResolutionDPIAgnostic: true,
		labelResolutionDPIAgnosticReferenceHeight: 900,
		labelNoImportanceDistance: 4*globalNavigationScale,	// Radius vom Zentrum innerhalb dessen alle Labels gezeichnet werden, egal welche "importance" der Layer hat
		labelScreenUsageCheckCellSize: [20, 20],	// [x,y] (Pixel)
		labelScreenUsageMaxCellValue: [1, 2],	// max. x Labels pro Zelle (hierüber wird auch die Anzahl der möglichen Label-Layer definiert)
		labelScreenUsageDebug: -1,
		labelFadeDuration: 0.5,
		labelFadeDelay: 0.25,
		quadTreeDepth: 7,
		minPanX: 0,
		maxPanX: 80000,
		minPanY: 0,
		maxPanY: 100000,
		layerUpdateInterval: 100,	// (ms)
		layerUpdateSkipToleranceRelative: 0.2,
		layerBackgroundLoadInterval: 1000,	// (ms)
		layerRetryFailedLoadDelay: 10000,	// (ms)
		layerUnloadInvisibleDelay: 30000,	// (ms)
		// //layernames:		Array(	'preload',	'bg',	'foreign',	'foreign-gr',	'bld',	'user',	'userpos',	'krs',	'gem',	'gem-gr',	'krs-gr',	'bld-gr',	'league',	'heatmap',	'stadium_dots',	'stadium_floor',	'stadium_shadow',	'stadium_lo',	'stadium',	'bld-lb',		'krs-lb',		'gem-lb',		'user-lb',		'connections',	'cnnctns-lb',	'debug'	),
		layerMinZooms:			Array(	0,			40,		0,			0,				0,		0,		0,			0,		0,		0,			0,			0,			0,			300,		0,				0,					0,					16,				0,			1000,			0,				0,				0,				0,				0,				0		),
		layerMaxZooms:			Array(	99999,		99999,	3500,		3500,			99999,	300,	99999,		3500,	400,	400,		2500,		99999,		99999,		99999,		250,			250,				35,					250,			35,			99999,			1200,			180,			20,				99999,			99999,			99999	),	// steuert auch ab wann geladen wird
		layerLabelImportance: 	Array(	0,			0,		0,			0,				0,		0,		0,			0,		0,		0,			0,			0,			0,			0,			0,				0,					0,					0,				0,			60,				40,				20,				30,				0,				90,				0		),
		layerAlphaZoomLink: 	Array(	false,		false,	false,		false,			false,	false,	false,		true,	false,	false,		false,		false,		false,		true,		true,			true,				true,				true,			true,		false,			false,			false,			false,			false,			false,			false	),
		layerAlphaZLMinFade: 	Array(	0.33,		0.33,	0.33,		0.33,			0.33,	0.33,	0.33,		0.33,	0.33,	0.33,		0.33,		0.33,		0.33,		0.9,		0.5,			0.33,				0.33,				0.5,			0.33,		0.33,			0.33,			0.33,			0.33,			0.33,			0.33,			0.33	),	// Übergangsphase an MinZoom bei aktiviertem ZoomLink
		layerAlphaZLMaxFade:	Array(	0.33,		0.33,	0.33,		0.33,			0.33,	0.33,	0.33,		0.33,	0.33,	0.33,		0.33,		0.33,		0.33,		0.33,		0.66,			0.66,				0.5,				0.66,			0.9,		0.33,			0.33,			0.33,			0.33,			0.33,			0.33,			0.33	),	// Übergangsphase an MaxZoom bei aktiviertem ZoomLink
		layerLoadingLevel: 		Array(	-1,			-1,		0,			-1,				1,		4,		1,			3,		4,		-1,			-1,			-1,			-1,			-1,			-1,				-1,					-1,					-1,				-1,			-1,				-1,				-1,				-1,				-1,				-1,				-1		),	// welche Kachelgröße soll geladen werden (<0 deaktiviert)
		layerLoadingQTOffset: 	Array(	0,			0,		0,			0,				0,		0,		3,			0,		0,		0,			0,			0,			0,			0,			0,				0,					0,					0,				0,			0,				0,				0,				0,				0,				0,				0		),	// Objektkacheln kleiner als Ladekacheln? (derzeit nur bei "userpos")
		layerMaxLevel: 			Array(	0,			1,		1,			1,				1,		5,		5,			3,		4,		4,			3,			1,			2,			3,			5,				-1,					-1,					6,				-1,			1,				3,				4,				6,				2,				2,				-1		),	// maximale Quadtree-Tiefe begrenzen, um Speicherverbrauch zu reduzieren (z.B. bei großen Objekten) (<0 Standard verwenden)
		layerMaxDistanceFactor: Array( 	0,			0,		0,			0,				0,		1,		3,			3,		1,		1,			3,			0,			0,			0,			3,				3,					0.4,				3,				0.4,		0,				8,				2,				0.4,			0,				0,				1		),	// Entfernungsbegrenzung beim Zeichnen (1.0 = eingepasster Kreis in Topansicht, 0 = deaktiviert)
		maxLoadedLeavesCount: 16,
		maxConcurrentLoadCount: 3,	// startet on layerUpdateInterval
		sceneScale: 1,
		borderNormalFactor: 1,
		useDebugCam: false,
		showQuadtree: false,
		showBoundingBoxes: false,
		showDebugText: false,
		useDebugLayer: false,
		showLoadingStatus: false,
		leaguesVisible: true,
		usersVisible: true,
		gemVisible: true,
		allLabelsVisible: true,
		fogEnabled: true,
		generateFloortiles: true,
		generateFloortileBuildings: true,
		generateFloortilesOnlyStadium: false,
		useFloorDetailTexture: true,
		heatmapMaxUserDotSize: 6.5*globalNavigationScale,
		heatmapUserMinZoom: 900,
		heatmapDisableDots: false,
		heatmapDisableMap: true,
		userPreviewDotSize: 5*globalNavigationScale,
		shadowBoxSize: 300000,
		shadowMapSize: 512,
		shadowStrength: 0.5,
		showShadowHelper: false,
		useRealtimeShadow: true,
		floorTileGeneration: {
            densityBrushRadius: 4.0,    // stadium density
            densityBrushStrength: 2.0,    // stadium density
			densityShowDebug: false,
            branchCreationFromDensityStep: 8,        // on floorTileRaster
            branchCreationFromDensityPossibility: 0,//0.1,    // 0..1 (from density) (0 -> inactive)
            branchCreationFromDensityMinDensity: 0.1,    // 0..1 (from density)
            branchCreationFromDensityMaxDensity: 0.9,    // 0..1 (from density)
			branchCreationForcedStadiumConnectionDistance: 30,    // steps (0 -> inactive)
			branchCreationFillHolesActive: false,
			branchCreationPushIterations: 1,
			branchCreationPushPossibility: 0.25,
            branchDensityInfluence: 1.0/16.0,    // 0..1
            branchBuckling: 1.0/16.0,    // *radians
            branchMaxLength: 64,    // steps (~ 4*(1/branchStrengthDecrease))
            branchStrengthDecrease: 1.0/16.0,    // 0..1 (max per step)
            branchSubbranchPossibility: 1.0/8.0,    // 0..1
            branchSubbranchInclination: 1.0/4.0,    // radians
			branchSubbranchStrengthFactor: 0.5,    // 0..1 (0.5)
            branchRotationQuantizationSteps: 8,    // 360°/x
			branchMaxConsecutiveAddTileFailureCount: 9,	// steps (~ > 1.5*stadium-size)
			subdivideForQuadtree: 2,
		},
		floorTileSize: 22.5/4,
		floorTileAtlasRasterX: 26,
		floorTileAtlasRasterY: 26,
		floorTileAtlasTiles: [	// Kacheldefinitionen in Raster-Koordinaten
			{x: 0,	y: 0,	w: 4,	h: 4,	m: 'stadium_buildings-4x4_1'},
			{x: 4,	y: 0,	w: 4,	h: 4,	m: 'stadium_buildings-4x4_2'},
			{x: 8,	y: 0,	w: 4,	h: 4,	m: 'stadium_buildings-4x4_3'},
			{x: 12,	y: 0,	w: 4,	h: 4,	m: 'stadium_buildings-4x4_4'},
			{x: 16,	y: 0,	w: 4,	h: 2,	m: 'stadium_buildings-2x4_8'},
			{x: 16,	y: 2,	w: 4,	h: 2,	m: 'stadium_buildings-2x4_7'},
			{x: 20,	y: 0,	w: 2,	h: 4,	m: 'stadium_buildings-2x4_6'},
			{x: 22,	y: 0,	w: 2,	h: 4,	m: 'stadium_buildings-2x4_5'},

			{x: 0,	y: 4,	w: 2,	h: 3,	m: 'stadium_buildings-2x3_2'},
			{x: 2,	y: 4,	w: 2,	h: 3,	m: 'stadium_buildings-2x3_3'},
			{x: 4,	y: 4,	w: 2,	h: 3,	m: 'stadium_buildings-2x3_4'},
			{x: 6,	y: 4,	w: 4,	h: 3,	m: 'stadium_buildings-3x4_1'},
			{x: 10,	y: 4,	w: 4,	h: 3,	m: 'stadium_buildings-3x4_2'},
			{x: 14,	y: 4,	w: 4,	h: 3,	m: 'stadium_buildings-3x4_3'},
			{x: 18,	y: 4,	w: 4,	h: 3,	m: 'stadium_buildings-3x4_4'},
			{x: 22,	y: 4,	w: 2,	h: 4,	m: 'stadium_buildings-2x4_4'},

			{x: 0,	y: 7,	w: 3,	h: 3,	p: 'bp'},	// Bolzplatz
			{x: 3,	y: 7,	w: 3,	h: 3,	p: 'bp'},
			{x: 6,	y: 7,	w: 3,	h: 3,	p: 'bp'},
			{x: 9,	y: 7,	w: 3,	h: 3,	p: 'bp'},

			{x: 12,	y: 7,	w: 1,	h: 3,	m: 'stadium_buildings-1x3_1'},
			{x: 13,	y: 7,	w: 1,	h: 3,	m: 'stadium_buildings-1x3_2'},
			{x: 14,	y: 7,	w: 1,	h: 3,	m: 'stadium_buildings-1x3_3'},
			{x: 15,	y: 7,	w: 1,	h: 3,	m: 'stadium_buildings-1x3_4'},
			{x: 16,	y: 7,	w: 1,	h: 1,	m: 'stadium_buildings-1x1_8'},
			{x: 16,	y: 8,	w: 1,	h: 1,	m: 'stadium_buildings-1x1_5'},
			{x: 16,	y: 9,	w: 1,	h: 1,	m: 'stadium_buildings-1x1_1'},
			{x: 17,	y: 7,	w: 2,	h: 1,	m: 'stadium_buildings-1x2_1'},
			{x: 17,	y: 8,	w: 1,	h: 1,	m: 'stadium_buildings-1x1_6'},
			{x: 17,	y: 9,	w: 1,	h: 1,	m: 'stadium_buildings-1x1_2'},
			{x: 18,	y: 8,	w: 1,	h: 1,	m: 'stadium_buildings-1x1_7'},
			{x: 18,	y: 9,	w: 1,	h: 1,	m: 'stadium_buildings-1x1_3'},
			{x: 19,	y: 7,	w: 1,	h: 2,	m: 'stadium_buildings-1x2_2'},
			{x: 19,	y: 9,	w: 1,	h: 1,	m: 'stadium_buildings-1x1_4'},
			{x: 20,	y: 7,	w: 2,	h: 3,	m: 'stadium_buildings-2x3_1'},
			{x: 22,	y: 8,	w: 1,	h: 2,	m: 'stadium_buildings-1x2_3'},
			{x: 23,	y: 8,	w: 1,	h: 2,	m: 'stadium_buildings-1x2_4'},

			{x: 0,	y: 10,	w: 4,	h: 4,	m: 'stadium_buildings-SKS4',	p: 'sks'},	// Sehr kleines Stadion
			{x: 0,	y: 14,	w: 4,	h: 4,	m: 'stadium_buildings-SKS3',	p: 'sks'},
			{x: 0,	y: 18,	w: 4,	h: 4,	m: 'stadium_buildings-SKS2',	p: 'sks'},
			{x: 0,	y: 22,	w: 4,	h: 4,	m: 'stadium_buildings-SKS1',	p: 'sks'},

			{x: 4,	y: 10,	w: 4,	h: 4,	m: 'stadium_buildings-KS4',		p: 'ks'},	// Kleines Stadion
			{x: 4,	y: 14,	w: 4,	h: 4,	m: 'stadium_buildings-KS3',		p: 'ks'},
			{x: 4,	y: 18,	w: 4,	h: 4,	m: 'stadium_buildings-KS2',		p: 'ks'},
			{x: 4,	y: 22,	w: 4,	h: 4,	m: 'stadium_buildings-KS1',		p: 'ks'},

			{x: 8,	y: 10,	w: 4,	h: 4,	m: 'stadium_buildings-MuFu4',	p: 'mufu'},	// Multifunktionsarena
			{x: 8,	y: 14,	w: 4,	h: 4,	m: 'stadium_buildings-MuFu3',	p: 'mufu'},
			{x: 8,	y: 18,	w: 4,	h: 4,	m: 'stadium_buildings-MuFu2',	p: 'mufu'},
			{x: 8,	y: 22,	w: 4,	h: 4,	m: 'stadium_buildings-MuFu1',	p: 'mufu'},

			{x: 12,	y: 10,	w: 4,	h: 4,	m: 'stadium_buildings-ECO4',	p: 'eco'},	// Baukastenstadion
			{x: 12,	y: 14,	w: 4,	h: 4,	m: 'stadium_buildings-ECO3',	p: 'eco'},
			{x: 12,	y: 18,	w: 4,	h: 4,	m: 'stadium_buildings-ECO2',	p: 'eco'},
			{x: 12,	y: 22,	w: 4,	h: 4,	m: 'stadium_buildings-ECO1',	p: 'eco'},

			{x: 16,	y: 10,	w: 4,	h: 4,	m: 'stadium_buildings-LA4',		p: 'la'},	// Luxusarena
			{x: 16,	y: 14,	w: 4,	h: 4,	m: 'stadium_buildings-LA3',		p: 'la'},
			{x: 16,	y: 18,	w: 4,	h: 4,	m: 'stadium_buildings-LA2',		p: 'la'},
			{x: 16,	y: 22,	w: 4,	h: 4,	m: 'stadium_buildings-LA1',		p: 'la'},

			{x: 20,	y: 10,	w: 1,	h: 4,	m: 'stadium_buildings-1x4_4'},
			{x: 20,	y: 14,	w: 1,	h: 4,	m: 'stadium_buildings-1x4_3'},
			{x: 20,	y: 18,	w: 1,	h: 4,	m: 'stadium_buildings-1x4_2'},
			{x: 20,	y: 22,	w: 1,	h: 4,	m: 'stadium_buildings-1x4_1'},

			{x: 21,	y: 10,	w: 2,	h: 2,	m: 'stadium_buildings-2x2_8'},
			{x: 21,	y: 12,	w: 2,	h: 2,	m: 'stadium_buildings-2x2_7'},
			{x: 21,	y: 14,	w: 2,	h: 2,	m: 'stadium_buildings-2x2_6'},
			{x: 21,	y: 16,	w: 2,	h: 2,	m: 'stadium_buildings-2x2_5'},
			{x: 21,	y: 18,	w: 2,	h: 2,	m: 'stadium_buildings-2x2_4'},
			{x: 21,	y: 20,	w: 2,	h: 2,	m: 'stadium_buildings-2x2_3'},
			{x: 21,	y: 22,	w: 2,	h: 2,	m: 'stadium_buildings-2x2_2'},
			{x: 21,	y: 24,	w: 2,	h: 2,	m: 'stadium_buildings-2x2_1'},

			{x: 23,	y: 14,	w: 2,	h: 4,	m: 'stadium_buildings-2x4_3'},
			{x: 23,	y: 18,	w: 2,	h: 4,	m: 'stadium_buildings-2x4_2'},
			{x: 23,	y: 22,	w: 2,	h: 4,	m: 'stadium_buildings-2x4_1'}
		],
		stadiumTextureFiles: {
			connection_line: 'img/Connection-Line.png',
			detail_tex:	'img/floordetail.png',
			floorTiles:	'img/stadium_grounds.png',
			env:		'objects/Clouds_3.jpg',
			floorshadow_tex:	'img/Textur_Schatten_Stadien.png',
			la_tex:	'objects/la/LALoPoly_Texture_1k.png',
			la_norm:	'objects/la/LALoPoly_Normal_1k.png',
			la_spec:	'objects/la/LALoPoly_SpecMap_1k.png',
			la_mask:	'objects/la/LALoPoly_ColorMap_Primaer_1k.png',
			la_mask2:	'objects/la/LALoPoly_ColorMap_Sekundaer_1k.png',
			la_1_ao:	'objects/la/LA_1_AO.png',
			la_2_ao:	'objects/la/LA_2_AO.png',
			la_3_ao:	'objects/la/LA_3_AO.png',
			la_lo_tex:	'objects/la/LA_Lolopoly_Textur.png',
			la_lo_mask:	'objects/la/LA_Lolopoly_Farbbereich_Prim.png',
			la_lo_mask2:	'objects/la/LA_Lolopoly_Farbbereich_Sek.png',
			ks_tex:		'objects/ks/KS_LoPoly_Texture_1k.png',
			ks_norm:	'objects/ks/KS_LoPoly_Normal_1k.png',
			ks_spec:	'objects/ks/KS_LoPoly_Specular_1k.png',
			ks_mask:	'objects/ks/KS_LoPoly_ColorMap_1k.png',
			ks_1_dach_ao:	'objects/ks/KS_1_Dach_AO.png',
			ks_1_offen_ao:	'objects/ks/KS_1_Offen_AO.png',
			ks_2_dach_ao:	'objects/ks/KS_2_Dach_AO.png',
			ks_2_offen_ao:	'objects/ks/KS_2_Offen_AO.png',
			ks_3_dach_ao:	'objects/ks/KS_3_Dach_AO.png',
			ks_3_offen_ao:	'objects/ks/KS_3_Offen_AO.png',
			ks_lo_tex:	'objects/ks/KS_LoLoPoly_Texture.png',
			ks_lo_mask:	'objects/ks/KS_LoLoPoly_ColorMap.png',
			mufu_tex:	'objects/mufu/MuFuLoPoly_Textur_1k.png',
			mufu_norm:	'objects/mufu/MuFuLoPoly_Normal_1k.png',
			mufu_spec:	'objects/mufu/MuFuLoPoly_Specular_1k.png',
			mufu_mask:	'objects/mufu/MuFuLoPoly_Farbbereiche_1k.png',
			mufu_1_ao:		'objects/mufu/MFU_1_AO.png',
			mufu_2_ao:		'objects/mufu/MFU_2_AO.png',
			mufu_3_ao:		'objects/mufu/MuFu_3_AO.png',
			mufu_lo_tex:	'objects/mufu/MuFuLoLoPoly_Textur.png',
			mufu_lo_mask:	'objects/mufu/MuFuLoLoPoly_Farbbereiche.png',
			sks_tex:	'objects/sks/SKS_LoPoly_Texture_1k.png',
			sks_norm:	'objects/sks/SKS_LoPoly_Normal_1k.png',
			sks_spec:	'objects/sks/SKS_LoPoly_SpecMap_1k.png',
			sks_mask:	'objects/sks/SKS_LoPoly_ColorMap_1k.png',
			sks_0_ao:		'objects/sks/SKS_0_AO.png',
			sks_1_dach_ao:	'objects/sks/SKS_1_Dach_AO.png',
			sks_1_offen_ao:	'objects/sks/SKS_1_Offen_AO.png',
			sks_2_dach_ao:	'objects/sks/SKS_2_Dach_AO.png',
			sks_2_offen_ao:	'objects/sks/SKS_2_Offen_AO.png',
			sks_3_dach_ao:	'objects/sks/SKS_3_Dach_AO.png',
			sks_3_offen_ao:	'objects/sks/SKS_3_Offen_AO.png',
			sks_lo_tex:		'objects/sks/SKS_LoLoPoly_Texture.png',
			sks_lo_mask:	'objects/sks/SKS_LoLoPoly_ColorMap.png',
			eco_tex:	'objects/eco/EcoLoPoly_Texture_Glass50_1k.png',
			eco_norm:	'objects/eco/Eco_LoPoly_Normal_1k.png',
			eco_spec:	'objects/eco/EcoLoPoly_Specular_1k.png',
			eco_mask:	'objects/eco/EcoLoPoly_ColorMap_1k.png',
			eco_1_dach_ao:	'objects/eco/Eco_1a_Dach_AO.png',
			eco_1_offen_ao:	'objects/eco/Eco_1a_Offen_AO.png',
			eco_2_dach_ao:	'objects/eco/Eco_1b_Dach_AO.png',
			eco_2_offen_ao:	'objects/eco/Eco_1b_Offen_AO.png',
			eco_3_dach_ao:	'objects/eco/Eco_2_Dach_AO.png',
			eco_3_offen_ao:	'objects/eco/Eco_2_Offen_AO.png',
			eco_4_dach_ao:	'objects/eco/Eco_3_Dach_AO.png',
			eco_4_offen_ao:	'objects/eco/Eco_3_Offen_AO.png',
			eco_lo_tex:		'objects/eco/Eco_LoloPoly_Textur.png',
			eco_lo_mask:	'objects/eco/Eco_LoloPoly_ColorMap.png',
		},
		stadiumObjects: {
			stadium_floor:	{
				objFile:		'objects/stadium_floor.obj',
				texture:		'floorshadow_tex',
				layer:			'stadium_shadow',
				isShadowObject:	true
			},
			stadium_buildings:	{
				objFile:		'objects/Buildings_Collection_Pivoted.object.json',
				layer:			'stadium',
				shading:		'flat',
				castShadow:		false,
				receiveShadow:	false,
				isCollection:	true,	// -> objectname: [index]-[object name from file]
				opacity:		0.35,
				diffuse:		0xffffff,
			},

			// Fallback stadium
			
			sks_0:	{
				objFile:		'objects/sks/SKS_0_AO.json',
				shading:		'flat',
				environment:	'env',
				texture:		'sks_tex',
				normal:			'sks_norm',
				specular:		'sks_spec',
				mask:			'sks_mask',
				lightMap:		'sks_0_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'sks',
				shadowTileU:	4,
				shadowTileV:	4,
				loVersion:		'sks_0_lo'
			},
			sks_0_lo:	{
				objFile:		'objects/sks/SKS_LoLoPoly_0_Platz.json',
				texture:		'sks_lo_tex',
				mask:			'sks_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			
			// LoPoly

			la_1:	{
				objFile:		'objects/la/LA_1_AO.json',
				environment:	'env',
				texture:		'la_tex',
				normal:			'la_norm',
				specular:		'la_spec',
				mask:			'la_mask',
				mask2:			'la_mask2',
				lightMap:		'la_1_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'la',
				shadowTileU:	4,
				shadowTileV:	6,
				loVersion:		'la_1_lo'
			},
			la_2:	{
				objFile:		'objects/la/LA_2_AO.json',
				environment:	'env',
				texture:		'la_tex',
				normal:			'la_norm',
				specular:		'la_spec',
				mask:			'la_mask',
				mask2:			'la_mask2',
				lightMap:		'la_2_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'la',
				shadowTileU:	4,
				shadowTileV:	6,
				loVersion:		'la_2_lo'
			},
			la_3:	{
				objFile:		'objects/la/LA_3_AO.json',
				environment:	'env',
				texture:		'la_tex',
				normal:			'la_norm',
				specular:		'la_spec',
				mask:			'la_mask',
				mask2:			'la_mask2',
				lightMap:		'la_3_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'la',
				shadowTileU:	4,
				shadowTileV:	6,
				loVersion:		'la_3_lo'
			},
			ks_1_dach:	{
				objFile:		'objects/ks/KS_1_Dach_AO.json',
				shading:		'flat',
				environment:	'env',
				texture:		'ks_tex',
				normal:			'ks_norm',
				specular:		'ks_spec',
				mask:			'ks_mask',
				lightMap:		'ks_1_dach_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'ks',
				shadowTileU:	0,
				shadowTileV:	5,
				loVersion:		'ks_1_dach_lo'
			},
			ks_1_offen:	{
				objFile:		'objects/ks/KS_1_Offen_AO.json',
				shading:		'flat',
				environment:	'env',
				texture:		'ks_tex',
				normal:			'ks_norm',
				specular:		'ks_spec',
				mask:			'ks_mask',
				lightMap:		'ks_1_offen_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'ks',
				shadowTileU:	0,
				shadowTileV:	5,
				loVersion:		'ks_1_offen_lo'
			},
			ks_2_dach:	{
				objFile:		'objects/ks/KS_2_Dach_AO.json',
				shading:		'flat',
				environment:	'env',
				texture:		'ks_tex',
				normal:			'ks_norm',
				specular:		'ks_spec',
				mask:			'ks_mask',
				lightMap:		'ks_2_dach_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'ks',
				shadowTileU:	4,
				shadowTileV:	5,
				loVersion:		'ks_2_dach_lo'
			},
			ks_2_offen:	{
				objFile:		'objects/ks/KS_2_Offen_AO.json',
				shading:		'flat',
				environment:	'env',
				texture:		'ks_tex',
				normal:			'ks_norm',
				specular:		'ks_spec',
				mask:			'ks_mask',
				lightMap:		'ks_2_offen_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'ks',
				shadowTileU:	4,
				shadowTileV:	5,
				loVersion:		'ks_2_offen_lo'
			},
			ks_3_dach:	{
				objFile:		'objects/ks/KS_3_Dach_AO.json',
				shading:		'flat',
				environment:	'env',
				texture:		'ks_tex',
				normal:			'ks_norm',
				specular:		'ks_spec',
				mask:			'ks_mask',
				lightMap:		'ks_3_dach_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'ks',
				shadowTileU:	0,
				shadowTileV:	4,
				loVersion:		'ks_3_dach_lo'
			},
			ks_3_offen:	{
				objFile:		'objects/ks/KS_3_Offen_AO.json',
				shading:		'flat',
				environment:	'env',
				texture:		'ks_tex',
				normal:			'ks_norm',
				specular:		'ks_spec',
				mask:			'ks_mask',
				lightMap:		'ks_3_offen_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'ks',
				shadowTileU:	0,
				shadowTileV:	4,
				loVersion:		'ks_3_offen_lo'
			},
			mufu_1:	{
				objFile:		'objects/mufu/MuFu_1_AO.json',
				environment:	'env',
				texture:		'mufu_tex',
				normal:			'mufu_norm',
				specular:		'mufu_spec',
				mask:			'mufu_mask',
				lightMap:		'mufu_1_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'mufu',
				shadowTileU:	0,
				shadowTileV:	7,
				loVersion:		'mufu_lo'
			},
			mufu_2:	{
				objFile:		'objects/mufu/MuFu_2_AO.json',
				environment:	'env',
				texture:		'mufu_tex',
				normal:			'mufu_norm',
				specular:		'mufu_spec',
				mask:			'mufu_mask',
				lightMap:		'mufu_2_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'mufu',
				shadowTileU:	4,
				shadowTileV:	7,
				loVersion:		'mufu_lo'
			},
			mufu_3:	{
				objFile:		'objects/mufu/MuFu_3_AO.json',
				environment:	'env',
				texture:		'mufu_tex',
				normal:			'mufu_norm',
				specular:		'mufu_spec',
				mask:			'mufu_mask',
				lightMap:		'mufu_3_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'mufu',
				shadowTileU:	0,
				shadowTileV:	6,
				loVersion:		'mufu_lo'
			},
			sks_1_dach:	{
				objFile:		'objects/sks/SKS_1_Dach_AO.json',
				shading:		'flat',
				environment:	'env',
				texture:		'sks_tex',
				normal:			'sks_norm',
				specular:		'sks_spec',
				mask:			'sks_mask',
				lightMap:		'sks_1_dach_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'sks',
				shadowTileU:	0,
				shadowTileV:	3,
				loVersion:		'sks_1_dach_lo'
			},
			sks_1_offen:	{
				objFile:		'objects/sks/SKS_1_Offen_AO.json',
				shading:		'flat',
				environment:	'env',
				texture:		'sks_tex',
				normal:			'sks_norm',
				specular:		'sks_spec',
				mask:			'sks_mask',
				lightMap:		'sks_1_offen_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'sks',
				shadowTileU:	0,
				shadowTileV:	3,
				loVersion:		'sks_1_offen_lo'
			},
			sks_2_dach:	{
				objFile:		'objects/sks/SKS_2_Dach_AO.json',
				shading:		'flat',
				environment:	'env',
				texture:		'sks_tex',
				normal:			'sks_norm',
				specular:		'sks_spec',
				mask:			'sks_mask',
				lightMap:		'sks_2_dach_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'sks',
				shadowTileU:	4,
				shadowTileV:	3,
				loVersion:		'sks_2_dach_lo'
			},
			sks_2_offen:	{
				objFile:		'objects/sks/SKS_2_Offen_AO.json',
				shading:		'flat',
				environment:	'env',
				texture:		'sks_tex',
				normal:			'sks_norm',
				specular:		'sks_spec',
				mask:			'sks_mask',
				lightMap:		'sks_2_offen_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'sks',
				shadowTileU:	4,
				shadowTileV:	3,
				loVersion:		'sks_2_offen_lo'
			},
			sks_3_dach:	{
				objFile:		'objects/sks/SKS_3_Dach_AO.json',
				shading:		'flat',
				environment:	'env',
				texture:		'sks_tex',
				normal:			'sks_norm',
				specular:		'sks_spec',
				mask:			'sks_mask',
				lightMap:		'sks_3_dach_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'sks',
				shadowTileU:	0,
				shadowTileV:	2,
				loVersion:		'sks_3_dach_lo'
			},
			sks_3_offen:	{
				objFile:		'objects/sks/SKS_3_Offen_AO.json',
				shading:		'flat',
				environment:	'env',
				texture:		'sks_tex',
				normal:			'sks_norm',
				specular:		'sks_spec',
				mask:			'sks_mask',
				lightMap:		'sks_3_offen_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'sks',
				shadowTileU:	0,
				shadowTileV:	2,
				loVersion:		'sks_3_offen_lo'
			},
			eco_1_dach:	{
				objFile:		'objects/eco/Eco_1a_Dach_AO.json',
				shading:		'flat',
				environment:	'env',
				texture:		'eco_tex',
				normal:			'eco_norm',
				specular:		'eco_spec',
				mask:			'eco_mask',
				lightMap:		'eco_1_dach_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'eco',
				shadowTileU:	4,
				shadowTileV:	2,
				loVersion:		'eco_1_dach_lo'
			},
			eco_1_offen:	{
				objFile:		'objects/eco/Eco_1a_Offen_AO.json',
				shading:		'flat',
				environment:	'env',
				texture:		'eco_tex',
				normal:			'eco_norm',
				specular:		'eco_spec',
				mask:			'eco_mask',
				lightMap:		'eco_1_offen_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'eco',
				shadowTileU:	4,
				shadowTileV:	2,
				loVersion:		'eco_1_offen_lo'
			},
			eco_2_dach:	{
				objFile:		'objects/eco/Eco_1b_Dach_AO.json',
				shading:		'flat',
				environment:	'env',
				texture:		'eco_tex',
				normal:			'eco_norm',
				specular:		'eco_spec',
				mask:			'eco_mask',
				lightMap:		'eco_2_dach_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'eco',
				shadowTileU:	0,
				shadowTileV:	1,
				loVersion:		'eco_2_dach_lo'
			},
			eco_2_offen:	{
				objFile:		'objects/eco/Eco_1b_Offen.json',
				shading:		'flat',
				environment:	'env',
				texture:		'eco_tex',
				normal:			'eco_norm',
				specular:		'eco_spec',
				mask:			'eco_mask',
				lightMap:		'eco_2_offen_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'eco',
				shadowTileU:	0,
				shadowTileV:	1,
				loVersion:		'eco_2_offen_lo'
			},
			eco_3_dach:	{
				objFile:		'objects/eco/Eco_2_Dach_AO.json',
				shading:		'flat',
				environment:	'env',
				texture:		'eco_tex',
				normal:			'eco_norm',
				specular:		'eco_spec',
				mask:			'eco_mask',
				lightMap:		'eco_3_dach_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'eco',
				shadowTileU:	4,
				shadowTileV:	1,
				loVersion:		'eco_3_dach_lo'
			},
			eco_3_offen:	{
				objFile:		'objects/eco/Eco_2_Offen_AO.json',
				shading:		'flat',
				environment:	'env',
				texture:		'eco_tex',
				normal:			'eco_norm',
				specular:		'eco_spec',
				mask:			'eco_mask',
				lightMap:		'eco_3_offen_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'eco',
				shadowTileU:	4,
				shadowTileV:	1,
				loVersion:		'eco_3_offen_lo'
			},
			eco_4_dach:	{
				objFile:		'objects/eco/Eco_3_Dach_AO.json',
				shading:		'flat',
				environment:	'env',
				texture:		'eco_tex',
				normal:			'eco_norm',
				specular:		'eco_spec',
				mask:			'eco_mask',
				lightMap:		'eco_4_dach_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'eco',
				shadowTileU:	0,
				shadowTileV:	0,
				loVersion:		'eco_4_dach_lo'
			},
			eco_4_offen:	{
				objFile:		'objects/eco/Eco_3_Offen_AO.json',
				shading:		'flat',
				environment:	'env',
				texture:		'eco_tex',
				normal:			'eco_norm',
				specular:		'eco_spec',
				mask:			'eco_mask',
				lightMap:		'eco_4_offen_ao',
				layer:			'stadium',
				castShadow:		true,
				receiveShadow:	true,
				floorIndex:		'eco',
				shadowTileU:	0,
				shadowTileV:	0,
				loVersion:		'eco_4_offen_lo'
			},

			// LoLoPoly

			la_1_lo:	{
				objFile:		'objects/la/LA_LoLoPoly_Stufe1.json',
				texture:		'la_lo_tex',
				mask:			'la_lo_mask',
				mask2:			'la_lo_mask2',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			la_2_lo:	{
				objFile:		'objects/la/LA_LoLoPoly_Stufe2.json',
				texture:		'la_lo_tex',
				mask:			'la_lo_mask',
				mask2:			'la_lo_mask2',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			la_3_lo:	{
				objFile:		'objects/la/LA_LoLoPoly_Stufe3.json',
				texture:		'la_lo_tex',
				mask:			'la_lo_mask',
				mask2:			'la_lo_mask2',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			ks_1_dach_lo:	{
				objFile:		'objects/ks/KS_LoLoPoly_Stufe1_Dach.json',
				texture:		'ks_lo_tex',
				mask:			'ks_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			ks_1_offen_lo:	{
				objFile:		'objects/ks/KS_LoLoPoly_Stufe1_Offen.json',
				texture:		'ks_lo_tex',
				mask:			'ks_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			ks_2_dach_lo:	{
				objFile:		'objects/ks/KS_LoLoPoly_Stufe2_Dach.json',
				texture:		'ks_lo_tex',
				mask:			'ks_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			ks_2_offen_lo:	{
				objFile:		'objects/ks/KS_LoLoPoly_Stufe2_Offen.json',
				texture:		'ks_lo_tex',
				mask:			'ks_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			ks_3_dach_lo:	{
				objFile:		'objects/ks/KS_LoLoPoly_Stufe3_Dach.json',
				texture:		'ks_lo_tex',
				mask:			'ks_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			ks_3_offen_lo:	{
				objFile:		'objects/ks/KS_LoLoPoly_Stufe3_Offen.json',
				texture:		'ks_lo_tex',
				mask:			'ks_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			mufu_lo:	{
				objFile:		'objects/mufu/MuFuLoLoPoly_Stufe3.json',
				texture:		'mufu_lo_tex',
				mask:			'mufu_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			sks_1_dach_lo:	{
				objFile:		'objects/sks/SKS_LoLoPoly_1_Dach.json',
				texture:		'sks_lo_tex',
				mask:			'sks_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			sks_1_offen_lo:	{
				objFile:		'objects/sks/SKS_LoLoPoly_1_Offen.json',
				texture:		'sks_lo_tex',
				mask:			'sks_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			sks_2_dach_lo:	{
				objFile:		'objects/sks/SKS_LoLoPoly_2_Dach.json',
				texture:		'sks_lo_tex',
				mask:			'sks_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			sks_2_offen_lo:	{
				objFile:		'objects/sks/SKS_LoLoPoly_2_Offen.json',
				texture:		'sks_lo_tex',
				mask:			'sks_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			sks_3_dach_lo:	{
				objFile:		'objects/sks/SKS_LoLoPoly_3_Dach.json',
				texture:		'sks_lo_tex',
				mask:			'sks_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			sks_3_offen_lo:	{
				objFile:		'objects/sks/SKS_LoLoPoly_3_Offen.json',
				texture:		'sks_lo_tex',
				mask:			'sks_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			eco_1_dach_lo:	{
				objFile:		'objects/eco/Eco_Lolopoly_1a_Dach.json',
				texture:		'eco_lo_tex',
				mask:			'eco_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			eco_1_offen_lo:	{
				objFile:		'objects/eco/Eco_Lolopoly_1a_Offen.json',
				texture:		'eco_lo_tex',
				mask:			'eco_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			eco_2_dach_lo:	{
				objFile:		'objects/eco/Eco_Lolopoly_1b_Dach.json',
				texture:		'eco_lo_tex',
				mask:			'eco_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			eco_2_offen_lo:	{
				objFile:		'objects/eco/Eco_Lolopoly_1b_Offen.json',
				texture:		'eco_lo_tex',
				mask:			'eco_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			eco_3_dach_lo:	{
				objFile:		'objects/eco/Eco_Lolopoly_2_Dach.json',
				texture:		'eco_lo_tex',
				mask:			'eco_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			eco_3_offen_lo:	{
				objFile:		'objects/eco/Eco_Lolopoly_2_Offen.json',
				texture:		'eco_lo_tex',
				mask:			'eco_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			eco_4_dach_lo:	{
				objFile:		'objects/eco/Eco_Lolopoly_3_Dach.json',
				texture:		'eco_lo_tex',
				mask:			'eco_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
			eco_4_offen_lo:	{
				objFile:		'objects/eco/Eco_Lolopoly_3_Offen.json',
				texture:		'eco_lo_tex',
				mask:			'eco_lo_mask',
				layer:			'stadium_lo',
				diffuse:		0xcfcfcf,
				emissive:		0x303030
			},
		},
		whitelistStadiumObjects: false,	// or array of keys
		stadiumShadowTextureTileCount: 8,
		stadiumPositionOffset: [0, 0, 0],
		stadiumScale: [1.0, 1.0, 1.0],
		stadiumOnlyLoVersions: false,
		noForeignLayer: false,
		showLeagues: [],
		leagueAlpha: 0.8,
		leagueAlphaWithConnections: 0.15,
		leageLODLimits: [50*globalNavigationScale, 300*globalNavigationScale, 1400*globalNavigationScale],	// 0..[0]..[1]..[x]..999999
		connectionLineRadius: 1.0*0.5,
		connectionLineRadiusDistancePushStrength: 15.0*0.5,
		connectionShaderPushStrength: 150.0,
		connectionMeshSubdivisionDistance: 20*globalNavigationScale,
		connectionMeshUScale: 1.0,
		connectionMeshVScale: 1.0,
		connectionMeshVSpeed: 0.0002,
		connectionShowAllMaxZoom: 25*globalNavigationScale,
		connectionHeight: 0.1*globalNavigationScale,	// Höhe über dem Boden (auch für die User-Selektion)
		showConnections: [],	// [{color(optional):String, alpha(optional):float, positionsX:[float, ...], positionsY:[float, ...]}, ...]
		selectConnectionToleranceFactor: 0.015,
		selectUserToleranceRadius: 50.0,
		selectUserMaxZoom: 900*globalNavigationScale,
		userPosition: null,	// {x:float, y:float, id:int, name:string},
		centerView: null,	// {center:[float(x), float(y)], zoom: undefined|float(z), radius: undefined|float(auto-zoom nach radius), duration:undefined|float(seconds), yaw: undefined|float(radians), tilt: undefined|float(radians), doShortestYaw: undefined|boolean}
		useWebworker: true,
		lowResTextures: false,
		performance: {
			logSlowFrames: false,
			maxCreateLabelsPerFrame: 1,
			maxRenderLabelsPerFrame: 60,
			maxPrepareMeshesPerCall: 6,
			maxAddMeshesPerCall: 25,
			deferredPrepareMeshesDelay: 10,
			deferredAddMeshesDelay: 10,
			stadiumsSubdivideForQuadtree: 2,
			maxUnloadHeavyLeaves: 1,
		},
		fpsHistoryLowFPS: 15,
		fpsHistoryHighFPS: 55,
		fpsHistoryLength: 10,	// full history duration = length*Interval
		fpsHistoryInterval: 1000,	// (ms)
		preloadMaterials: true,
		preloadTextures: true,
		useTimeDilationWhenInactive: true,
		stopRenderingWhenTimeFullDilated: true,
		startTimeDilationWhenNotDirtyAfter: 0,	// (ms)
		startTimeDilationWhenNotDirtyAfterWithAnimatedConnections: 7500	// (ms)
	};
	self.getDefaultConfig = function() {
		return JSON.parse(JSON.stringify(defaultConfig));	// Funkioniert nicht mit Date Objekten
	};

	return self;
}(LigaMap || {}));
