/**
 * Copyright Christian Kubitza
 * christian@ck3d.de
 * 2015-2018
 */

var LigaMap = (function(self) {
	"use strict";

	self.QuadtreeLeave = function(parentLeave, minX, maxX, minY, maxY, level) {
		this.level = level;
		this.isToBeLoaded = false;
		this.inTemporaryLoadingQueue = false;
		this.isLoaded = false;
		this.hasLoadingError = false;
		this.minX = minX;
		this.minY = minY;
		this.maxX = maxX;
		this.maxY = maxY;
		this.centerX = 0.5*(maxX+minX);
		this.centerY = 0.5*(maxY+minY);
		this.radiusSquared = 0.25 * (Math.pow(maxX-minX, 2) + Math.pow(maxY-minY, 2));
		this.radius = Math.sqrt(this.radiusSquared);
		this.parent = parentLeave;
		this.q1 = null;
		this.q2 = null;
		this.q3 = null;
		this.q4 = null;
		this.objects = [];
		this.objectsLoaded = {};
		this.objectsLoadedCount = 0;
		this.debugObject = null;
		this.hasVisibleObjects = false;
		this.debugObject = null;
		this.timestamps = {
			lastVisible: 0,
			lastLoadChecked: 0,
			lastLoaded: 0
		};
		this.visible = false;
		this.cameraDistanceSquared = -1;
	};

	self.Quadtree = function(ligaMapInstance, minX, maxX, minY, maxY, maxLevel, loadingLevel, layerName, maxDistanceFactor, neverUnload, maxLoadedLeavesCount, setRenderingIsDirty) {
		// LigaMap.console.log("new Quadtree", ligaMapInstance, minX, maxX, minY, maxY, maxLevel, loadingLevel, layerName, maxDistanceFactor);
		var levelColors = [0xff0000, 0x00ff00, 0x0000ff, 0xff00ff, 0xffff00, 0x00ffff, 0x880000, 0x008800, 0x000088, 0x880088, 0x888800, 0x008888];
		var loadingLevel = (loadingLevel==undefined)? -1 : loadingLevel;
		var maxLoadedLeavesCount = (maxLoadedLeavesCount===undefined)? -1 : maxLoadedLeavesCount;
		var layerName = layerName;
		var map;
		var self = this;
		this.maxDistanceFactor = (maxDistanceFactor==undefined)? 0 : maxDistanceFactor;
		this.debugScene = null;
		this.unloadHeavyLeaveBudget = 0;

		var init = function() {
			//var overalltime = 0.0;
			//var overallcalls = 0;
			var divideMap = function(leave) {
				if (loadingLevel < 0) {
					leave.isLoaded = true;
				}
				var currentLevel = leave.level;
				if (currentLevel < maxLevel) {
					//var startTime = performance.now();
					// var centerX = (leave.maxX+leave.minX)*0.5;//leave.minX+(leave.maxX-leave.minX)*0.5;
					// var centerY = (leave.maxY+leave.minY)*0.5;//leave.minY+(leave.maxY-leave.minY)*0.5;
					var lq1 = new LigaMap.QuadtreeLeave(leave, leave.minX, leave.centerX, leave.minY, leave.centerY, currentLevel+1);
					var lq2 = new LigaMap.QuadtreeLeave(leave, leave.centerX, leave.maxX, leave.minY, leave.centerY, currentLevel+1);
					var lq3 = new LigaMap.QuadtreeLeave(leave, leave.minX, leave.centerX, leave.centerY, leave.maxY, currentLevel+1);
					var lq4 = new LigaMap.QuadtreeLeave(leave, leave.centerX, leave.maxX, leave.centerY, leave.maxY, currentLevel+1);
					leave.q1 = lq1;
					leave.q2 = lq2;
					leave.q3 = lq3;
					leave.q4 = lq4;
					//overalltime += performance.now()-startTime;
					//overallcalls++;
					divideMap(lq1);
					divideMap(lq2);
					divideMap(lq3);
					divideMap(lq4);
					//leave.isSubdivided = true;
					//return [divideMap(currentLevel-1), divideMap(currentLevel-1), divideMap(currentLevel-1), divideMap(currentLevel-1)];
				}
			};
			map = new LigaMap.QuadtreeLeave(null, minX, maxX, minY, maxY, 0);
			divideMap(map);
			// LigaMap.console.log(layerName, map);
			//LigaMap.console.log(overalltime,"ms", overallcalls,"times", (overalltime/overallcalls),"ms/each");
		};
		
		var leaveIsSubdivided = function(leave) {
			return (leave.q1 != null);
		};
		this.leaveSetIsLoaded = function(leave, success) {
			leave.isLoaded = true;
			leave.hasLoadingError = !success;
			removeLeaveObjectsLoaded(leave, true, "before-load", true);	// clean up before add
			leave.isToBeLoaded = false;
			if (leave.parent !== null) {
				leaveUpdateLoadedStateFromChildren(leave.parent);
			}
		};
		var leaveUpdateLoadedStateFromChildren = function(leave) {
			if (leaveIsSubdivided(leave)) {
				leave.isLoaded = ((leave.q1.isLoaded === true) && (leave.q2.isLoaded === true) && (leave.q3.isLoaded === true) && (leave.q4.isLoaded === true));
				leave.hasLoadingError = ((leave.q1.hasLoadingError === true) || (leave.q2.hasLoadingError === true) || (leave.q3.hasLoadingError === true) || (leave.q4.hasLoadingError === true));
				if (leave.isLoaded) leave.isToBeLoaded = false;
				if (leave.parent !== null) {
					leaveUpdateLoadedStateFromChildren(leave.parent);
				}
			}
		};
		var leaveUpdateVisibleTime = function(leave, timestamp) {
			leave.timestamps.lastVisible = timestamp? timestamp : Date.now();
		};
		var leaveUpdateLoadCheckedTime = function(leave, timestamp) {
			leave.timestamps.lastLoadChecked = timestamp? timestamp : Date.now();
		};
		this.leaveUpdateLoadedTime = function(leave, timestamp) {
			leave.timestamps.lastLoaded = timestamp? timestamp : Date.now();
		};
		var loadLeave = function(leave) {
			leave.isToBeLoaded = ligaMapInstance.loadData(layerName, leave);
			if (leave.isToBeLoaded) {
				leave.hasLoadingError = false;
			}
		};
		var intersectsLeave = function(leave, minX, maxX, minY, maxY) {
			return (maxX > leave.minX) && (minX < leave.maxX) && (maxY > leave.minY) && (minY < leave.maxY);
		}
		var insideLeave = function(leave, x, y) {
			return (x >= leave.minX) && (x < leave.maxX) && (y >= leave.minY) && (y < leave.maxY);
		}

		/**
		 * 
		 * @param {Object} obj
		 * @param {Number} minX
		 * @param {Number} maxX
		 * @param {Number} minY
		 * @param {Number} maxY
		 * @param {Number} [loadedMinX] wenn Objekt auch gleich über addObjectLoaded() hinzugefügt werden soll
		 * @param {Number} [loadedMinY]
		 * @returns {Boolean}
		 */
		this.addObject = function(obj, minX, maxX, minY, maxY, loadedMinX, loadedMinY) {
//			LigaMap.console.log("addObject()", layerName, obj.name, "x: ", [minX, maxX], "y: ", [minY, maxY], "loadedMinXY: ", [loadedMinX, loadedMinY]);
			var addToLeave = function(leave) {
				if (intersectsLeave(leave, minX, maxX, minY, maxY)) {
						leave.objects.push(obj);
						// if (!obj.userData['qtLeaves']) {
						// 	obj.userData['qtLeaves'] = [];
						// }
						// obj.userData['qtLeaves'].push(leave);	// store leave for faster deletion
						// if ((leave.level == 4) && (obj.name == "boundingBox")) {
						// 	LigaMap.console.log("leave", leave.minX+".."+leave.maxX+"x"+leave.minY+".."+leave.maxY, "object", minX+".."+maxX+"x"+minY+".."+maxY, "inside");
						// }
						if (leaveIsSubdivided(leave)) {
							addToLeave(leave.q1);
							addToLeave(leave.q2);
							addToLeave(leave.q3);
							addToLeave(leave.q4);
						} else {
							//LigaMap.console.log("inside (last)");
						}
						return true;
					} else {
						//LigaMap.console.log(leave, xpos, ypos, "NOT inside");
						return false;
					}
			};
			//var bB = obj.geometry.boundingBox;
			//return addToLeave(obj, (bB.max.x-bB.min.x)*0.5, (bB.max.y-bB.min.y)*0.5, map);
			if ((loadingLevel >= 0) && (loadedMinX !== undefined) && (loadedMinY !== undefined)) {
				if (!self.addObjectLoaded(obj, loadedMinX, loadedMinY)) {
					return false;
				}
			}
			setRenderingIsDirty('quadtreeAddObject');
			return addToLeave(map);
		};
		
		/**
		 * Objekte, die durch Laden dieser Kachel erstellt wurden (auch Fremdobjekte). Nötig fürs Entladen.
		 * @param {Object} obj ThreeJS Object
		 * @param {Number} minX minX of loading leave
		 * @param {Number} minY minY of loading leave
		 * @param {String} [otherLayerName] other layer name, when not in current layer
		 * @param {Boolean} [onlyWhenLoaded]
		 * @returns {Boolean}
		 */
		this.addObjectLoaded = function(obj, minX, minY, otherLayerName, onlyWhenLoaded) {
			onlyWhenLoaded = true;	// force ###
			var lname = otherLayerName? otherLayerName : layerName;
//			LigaMap.console.log("addObjectLoaded()", lname, obj.name, "minXY: ", [minX, minY]);
			var foundLeave = null;
			var addToLeave = function(leave, depthString) {
				if (!depthString) {
					depthString = '0';
				}
				if (insideLeave(leave, minX, minY)) {	// check if inside (assumption: no 0-sized tiles)
					if ((leave.level == loadingLevel) && (minX == leave.minX) && (minY == leave.minY)) {
						foundLeave = leave;
						if (onlyWhenLoaded && !leave.isLoaded) {
							return false;
						}
						if (!leave.objectsLoaded[lname]) {
							leave.objectsLoaded[lname] = [];
						}
						leave.objectsLoaded[lname].push(obj);
						leave.objectsLoadedCount++;
						return true;
					} else {
						if ((leave.level < loadingLevel) && leaveIsSubdivided(leave)) {
							return addToLeave(leave.q1, depthString+"1") || addToLeave(leave.q2, depthString+"2") || addToLeave(leave.q3, depthString+"3") || addToLeave(leave.q4, depthString+"4");
						}
						return false;
					}
				} else {
					return false;
				}
			};
			if (loadingLevel < 0) {
				LigaMap.console.warn("addObjectLoaded() on non-loading layer", lname);
				return false;
			}
			var returnVal = addToLeave(map);
			// if (!returnVal)	{
			// 	if (foundLeave)
			// 		LigaMap.console.log("addObjectLoaded() failed (leave NOT loaded)", getLeaveDebugPrefix(foundLeave), (obj.name? obj.name : '[unnamed object]'), minX, minY);
			// 	else 
			// 		LigaMap.console.log("addObjectLoaded() failed (no leave found)", layerName, (obj.name? obj.name : '[unnamed object]'), minX, minY);
			// }
//			else if (layerName == "user") LigaMap.console.log("addObjectLoaded()", foundLeave? getLeaveDebugPrefix(foundLeave) : layerName, (obj.name? obj.name : '[unnamed object]'));
			return returnVal;
		};
		
		var getLeaveDebugPrefix = function(leave) {
			var prefix = '';
			var collect = function(leave) {
				if (leave.parent) {
					if (leave === leave.parent.q1) prefix = "1"+prefix;
					if (leave === leave.parent.q2) prefix = "2"+prefix;
					if (leave === leave.parent.q3) prefix = "3"+prefix;
					if (leave === leave.parent.q4) prefix = "4"+prefix;
					collect(leave.parent);
				} else prefix = "0"+prefix;
			};
			collect(leave);
			return layerName+'_'+prefix;//+"("+leave.minX+".."+leave.maxX+", "+leave.minY+".."+leave.maxY+")";
		};
		
		var removeLeaveObjectsLoaded = function(leave, doNotUpdateLoadingState, unloadReason, ignoreHeavyLeaveBudget) {
			if (leave.level != loadingLevel) {
				LigaMap.console.log("removeLeaveObjectsLoaded()", getLeaveDebugPrefix(leave), doNotUpdateLoadingState, unloadReason, "Wrong loading level");
				return false;
			}
			if (!leave.isLoaded) {
//				LigaMap.console.log("removeLeaveObjectsLoaded()", getLeaveDebugPrefix(leave), doNotUpdateLoadingState, unloadReason, "leave not loaded, loadedObjects (should be 0): ", leave.objectsLoadedCount);
				return false;
			}
			if (leave.objectsLoadedCount > 0) {
				if (leave.objectsLoadedCount > 100) {	// heavy leave!
					if (!ignoreHeavyLeaveBudget && self.unloadHeavyLeaveBudget <= 0) {
						LigaMap.console.log("removeLeaveObjectsLoaded() no heavy leave unload budget", getLeaveDebugPrefix(leave));
						return false;
					}
					self.unloadHeavyLeaveBudget--;
				}
				// LigaMap.console.log("removeLeaveObjectsLoaded()", getLeaveDebugPrefix(leave), doNotUpdateLoadingState, unloadReason, leave.objectsLoadedCount+" loaded objects");
// window.performance.mark('layer-'+ln+'-end');
// window.performance.measure('layer-'+ln, 'layer-'+ln+'-start', 'layer-'+ln+'-end');
				ligaMapInstance.dataUnloaded(layerName, leave);
				// var p = "quadtree.removeLeaveObjectsLoaded "+getLeaveDebugPrefix(leave)+" "+leave.objectsLoadedCount;
				// console.time(p);
				leave.objectsLoadedCount = 0;	// jetzt schon setzen, damit es nicht in removeObject() anschlägt
				for (var lname in leave.objectsLoaded) {
					ligaMapInstance.removeObjectsFromLayer(lname, leave.objectsLoaded[lname], false);
				}
				leave.objectsLoaded = {};
				// console.timeEnd(p);
			// } else if (!leave.isToBeLoaded) {
			// 	LigaMap.console.log("removeLeaveObjectsLoaded()", getLeaveDebugPrefix(leave), doNotUpdateLoadingState, unloadReason, "NO loaded objects");
			}
			if (!doNotUpdateLoadingState) {
				resetLeaveLoadingState(leave, false);
			}
			return true;
		};
		
		/**
		 * Entfernt alle Objekte einer geladenen Kachel
		 * @param {Number} [minX]
		 * @param {Number} [minY]
		 * @param {Boolean} doNotUpdateLoadingState Wenn bereits gesetzt und nicht überschrieben werden darf
		 * @returns {Boolean}
		 */
		this.removeObjectsLoaded = function(minX, minY, doNotUpdateLoadingState, unloadReason) {
			var collectLeaves = function(leave) {
				if ((leave.level == loadingLevel) && (((minX === undefined) && (minY === undefined)) || ((minX == leave.minX) && (minY == leave.minY)))) {
					return removeLeaveObjectsLoaded(leave, doNotUpdateLoadingState, unloadReason);
				} else {
					if ((leave.level < loadingLevel) && leaveIsSubdivided(leave)) {
						collectLeaves(leave.q1);
						collectLeaves(leave.q2);
						collectLeaves(leave.q3);
						collectLeaves(leave.q4);
						return true;
					}
					return false;
				}
			};
			if (loadingLevel < 0) {
				return false;
			}
//			LigaMap.console.log("removeObjectsLoaded()", layerName, minX, minY);
			collectLeaves(map);
		};
		
		var resetLeaveLoadingState = function(leave, doNotUpdateParents) {
			leave.isToBeLoaded = false;
			leave.inTemporaryLoadingQueue = false;
			leave.isLoaded = (loadingLevel < 0);
			leave.timestamps.lastLoadChecked = 0;
			leave.timestamps.lastLoaded = 0;
			if (!doNotUpdateParents && (leave.parent !== null)) {
				leaveUpdateLoadedStateFromChildren(leave.parent);
			}
		};

		var clearLeave = function(leave) {
			var doesChange = false;
			if (loadingLevel >= 0) {
				if (leave.level < loadingLevel) {
					doesChange = true;
				} else {
					if (leave.objectsLoadedCount > 0) doesChange = true;
				}
			}
			if (leave.hasVisibleObjects) doesChange = true;
			if (leave.timestamps.lastVisible > 0) doesChange = true;
			if (leave.objects.length > 0) doesChange = true;
			if (doesChange) {
				if (leave.level == loadingLevel) {
					removeLeaveObjectsLoaded(leave, false, "clear-leave");
				}
				leave.objects.length = 0;
				leave.hasVisibleObjects = false;
				leave.timestamps.lastVisible = 0;
				resetLeaveLoadingState(leave, true);
				if (leaveIsSubdivided(leave)) {
					clearLeave(leave.q1);
					clearLeave(leave.q2);
					clearLeave(leave.q3);
					clearLeave(leave.q4);
				}
				setRenderingIsDirty('quadtreeClearLeave');
			// } else {
			// 	LigaMap.console.log(getLeaveDebugPrefix(leave), "quadtree.clearLeave() aborting, wouldn't change anything");
			}
			return true;
		};
		
		this.clear = function() {
			// LigaMap.console.log("quadtree.clear()", layerName);
			return clearLeave(map);
		};

		/**
		 * 
		 * @param {any[]} object
		 * @returns {Boolean}
		 */
		this.removeObjects = function(objects) {
			if (!objects) {
				return false;
			}
			// if (objects.length >= map.objects.length) {	// everything
			// 	self.clear();
			// }

			// var ret = true;
			// for (var i in objects) {
			// 	ret = ret && self.removeObject(objects[i]);
			// }
			// return ret;

			var i, index, indices, object, foundObjects, lname, newObjects;
			var foundIndexStart, foundIndexCount;
			var leavesCheckedCount = 0;
			var objectsLoadedCheckedCount = 0;
			var removeFromLeave = function(leave, currentObjects) {
				leavesCheckedCount++;
				if ((leave.level == loadingLevel) && (leave.objectsLoadedCount > 0)) {
					// Voraussetzung: ist in dem objectsLoaded-leave, wo es in dem parent zumindest sichtbar ist
					for (i=0; i<currentObjects.length; i++) {
						object = currentObjects[i];
						for (lname in leave.objectsLoaded) {
							foundObjects = [];
							if ((index = leave.objectsLoaded[lname].indexOf(object)) >= 0) {
								objectsLoadedCheckedCount++;
								foundObjects.push(object);
								leave.objectsLoaded[lname].splice(index, 1);
								leave.objectsLoadedCount--;
							}
							if (foundObjects.length > 0) {
								ligaMapInstance.removeObjectsFromLayer(lname, foundObjects, true);
							}
						}
					}
					if (leave.objectsLoadedCount <= 0) {
						resetLeaveLoadingState(leave, false);
					}
				}
				if (leave.objects.length < 1) {
					return false;
				}
				indices = [];
				foundIndexStart = -1;
				foundIndexCount = 0;
				for (i=0; i<leave.objects.length; i++) {
					if (currentObjects.indexOf(leave.objects[i]) >= 0) {
						if (foundIndexStart+foundIndexCount == i) {
							foundIndexCount++;
						} else {
							if (foundIndexStart >= 0) {
								indices.push([foundIndexStart, foundIndexCount]);
							}
							foundIndexStart = i;
							foundIndexCount = 1;
						}
					}
				}
				if (foundIndexStart >= 0) {
					indices.push([foundIndexStart, foundIndexCount]);
				}
				if (indices.length > 0) {
					// LigaMap.console.log(getLeaveDebugPrefix(leave), indices);
					// LigaMap.console.log(getLeaveDebugPrefix(leave), "objects found in leave", objects.length, leave.objects.length, (indices.length == leave.objects.length));

					if ((indices[0][0] == 0) && (indices[0][1] == leave.objects.length)) {	// all objects from leave
						clearLeave(leave);
					} else {
						newObjects = [];
						for (i=(indices.length-1); i>=0; i--) {
							// newObjects.push(leave.objects[i]);
							foundObjects = leave.objects.splice(indices[i][0], indices[i][1]);
							Array.prototype.push.apply(newObjects, foundObjects);
						}
						if (leaveIsSubdivided(leave)) {
							removeFromLeave(leave.q1, newObjects);
							removeFromLeave(leave.q2, newObjects);
							removeFromLeave(leave.q3, newObjects);
							removeFromLeave(leave.q4, newObjects);
						}
					}
					return true;
				}
				return false;
			};
			setRenderingIsDirty('quadtreeRemoveObject');
			var ret = removeFromLeave(map, objects);
			// LigaMap.console.log(layerName, "quadtree.removeObjects()", "leavesCheckedCount:", leavesCheckedCount, "objectsLoadedCheckedCount:", objectsLoadedCheckedCount, "found:", ret);
			return ret;
		};

		/**
		 * 
		 * @param {any} object
		 * @returns {Boolean}
		 */
		this.removeObject = function(object) {
			var index;
			var leavesCheckedCount = 0;
			var objectsLoadedCheckedCount = 0;
			var removeFromLeave = function(leave) {
				leavesCheckedCount++;
				if ((leave.level == loadingLevel) && (leave.objectsLoadedCount > 0)) {
					// Voraussetzung: ist in dem objectsLoaded-leave, wo es in dem parent zumindest sichtbar ist
					for (var lname in leave.objectsLoaded) {
						if ((index = leave.objectsLoaded[lname].indexOf(object)) >= 0) {
							objectsLoadedCheckedCount++;
							ligaMapInstance.removeObjectsFromLayer(lname, [object], true);
							leave.objectsLoaded[lname].splice(index, 1);
							leave.objectsLoadedCount--;
						}
					}
					if (leave.objectsLoadedCount <= 0) {
						resetLeaveLoadingState(leave, false);
					}
				}
				if (leave.objects.length < 1) {
					return false;
				}
				index = leave.objects.indexOf(object);
				if (index >= 0) {
					leave.objects.splice(index, 1);
					// LigaMap.console.log(leave.objects.length);
					if (leaveIsSubdivided(leave)) {
						removeFromLeave(leave.q1);
						removeFromLeave(leave.q2);
						removeFromLeave(leave.q3);
						removeFromLeave(leave.q4);
					}
					return true;
				}
				return false;
			};
			// var removeByLeaveArray = function(leaves) {
			// 	var index, leave;
			// 	for (var i in leaves) {
			// 		leave = leaves[i];
			// 		leavesCheckedCount++;
			// 		index = leave.objects.indexOf(object);
			// 		if (index >= 0) {
			// 			leave.objects.splice(index, 1);
			// 		}
			// 	}
			// 	return true;
			// };
			if (!object) {
				return false;
			}
			setRenderingIsDirty('quadtreeRemoveObject');
			// if (object.userData['qtLeaves']) {
			// 	var ret = removeByLeaveArray(object.userData['qtLeaves']);
			// 	delete object.userData['qtLeaves'];
			// } else {
				var ret = removeFromLeave(map);
			// }
			// LigaMap.console.log(layerName, "quadtree.removeObject()", "leavesCheckedCount:", leavesCheckedCount, "objectsLoadedCheckedCount:", objectsLoadedCheckedCount, "found:", ret);
			return ret;
		};

		this.getObjectsInRange = function(position, radius, onlyClosest, onlyWithUserDataKey) {
			var objects = [];
			var closestDistance = 0.0;
			var closestObject = null;
			var collectLeaves = function(leave) {
				// LigaMap.console.log("collectLeaves", leave.minX+"..."+leave.maxX, leave.minY+"..."+leave.maxY);
				if (	(leave.objects.length > 0) &&
						((position.x+radius) > leave.minX) &&
						((position.x-radius) < leave.maxX) &&
						((position.y+radius) > leave.minY) &&
						((position.y-radius) < leave.maxY)) {
//					LigaMap.console.log("leave has "+leave.objects.length+" object(s) and is in range", leave.minX+"..."+leave.maxX, leave.minY+"..."+leave.maxY);
					if (!leaveIsSubdivided(leave)) {
						for (var oi in leave.objects) {
							var o = leave.objects[oi];
							if ((onlyWithUserDataKey===undefined) || (o.userData && o.userData[onlyWithUserDataKey])) {
								var isInRange = false;
								if (o.userData && o.userData.worldBoundingBox) {
									isInRange = ((position.x+radius) > o.userData.worldBoundingBox.min.x) &&
										((position.x-radius) < o.userData.worldBoundingBox.max.x) &&
										((position.y+radius) > o.userData.worldBoundingBox.min.y) &&
										((position.y-radius) < o.userData.worldBoundingBox.max.y);
								} else {
									isInRange = ((position.x+radius) > o.position.x) &&
										((position.x-radius) < o.position.x) &&
										((position.y+radius) > o.position.y) &&
										((position.y-radius) < o.position.y);
								}
								if (isInRange)	 {
//									LigaMap.console.log("object is in range", position, o.position, o.userData.worldBoundingBox.min, '..', o.userData.worldBoundingBox.max);
									if (onlyClosest) {
										var dx = position.x-o.position.x;
										var dy = position.y-o.position.y;
										var distance = dx*dx+dy*dy;
										if ((closestObject == null) || (distance < closestDistance)) {
											closestObject = o;
											closestDistance = distance;
										}
									} else {
										objects.push(o);
									}
								} else {
//									LigaMap.console.log("object is NOT in range", position, o.position, o.userData.worldBoundingBox.min, '..', o.userData.worldBoundingBox.max);
								}
							} else {
//								LigaMap.console.log("object does not have needed userData ", onlyWithUserDataKey, o);
							}
						}
					} else {
						collectLeaves(leave.q1);
						collectLeaves(leave.q2);
						collectLeaves(leave.q3);
						collectLeaves(leave.q4);
					}
				}
			};
			collectLeaves(map);
			if (onlyClosest && (closestObject != null)) {
				objects.push(closestObject);
			}
			return objects;
		};

		this.getContainedObjects = function(containedRadius, frustum, maxLevel, updateDebugObjects) {
			var starttime = performance.now();
			//frustum.containsPoint(object.position)
			var camPos = ligaMapInstance.getCameraPosition();
			var containedRadiusWFactor = containedRadius*maxDistanceFactor;	// scale by factor
			var containedRadiusWFactorSquared = Math.pow(containedRadiusWFactor, 2);

			var objectsById = {};
			var disableRecursive = function(leave) {
				if (!leave.debugObject) {	// Debug-Objekt hinzufügen, falls nicht vorhanden
					leave.debugObject = new THREE.Mesh(
						new THREE.PlaneGeometry((leave.maxX-leave.minX), (leave.maxY-leave.minY)),
						// new THREE.CircleGeometry(leave.radius, 16),
						new THREE.MeshBasicMaterial( {color: levelColors[leave.level%levelColors.length], wireframe: true, transparent: true, opacity: 1 } )
					);
					// leave.debugObject = new THREE.Mesh( new THREE.CylinderGeometry(leave.radius, leave.radius, 0.001, 16, 1, true), new THREE.MeshBasicMaterial( {name:'quadtreeDebug', color: levelColors[leave.level%levelColors.length], wireframe: true } ) );
					// leave.debugObject.rotation.x = 0.5*Math.PI;
					leave.debugObject.position.x = leave.centerX;// + leave.level*5.0;
					leave.debugObject.position.y = leave.centerY;// + leave.level*5.0;
					leave.debugObject.position.z = 0 - leave.level*5.0;
					leave.debugObject.matrixAutoUpdate = false;
					leave.debugObject.frustumCulled = false;
					leave.debugObject.updateMatrix();
					leave.debugObject.updateMatrixWorld();

					if (self.debugScene == null) {
						self.debugScene = new THREE.Scene();
						self.debugScene.autoUpdate = false;
						self.debugScene.updateMatrix();
						self.debugScene.updateMatrixWorld();
					}
					self.debugScene.add(leave.debugObject);
				}
				leave.debugObject.visible = false;
				if (leaveIsSubdivided(leave)) {
					disableRecursive(leave.q1);
					disableRecursive(leave.q2);
					disableRecursive(leave.q3);
					disableRecursive(leave.q4);
				}
			};
			var collectLeaves = function(leave) {
				leave.cameraDistanceSquared = -1;
				leave.visible = false;
				if ((updateDebugObjects || (leave.objects.length > 0)) && frustum.intersectsBox({ min: new THREE.Vector3(leave.minX, leave.minY, 0), max: new THREE.Vector3(leave.maxX, leave.maxY, 0) })) {
					var hiddenByDistance = false;
					if (maxDistanceFactor > 0) {	// Entfernungscheck
						var logit = false;//(layerName == 'bld' && (leave.level==1) && (leave.minX==0) && (leave.minY==0));
						var distanceSquared = getDistanceToCenterSquared(camPos, leave, logit);
						leave.cameraDistanceSquared = distanceSquared;
						if (distanceSquared > containedRadiusWFactorSquared) {	// könnte außerhalb sein -> genauer rechnen
							var distance = getDistanceToRim(distanceSquared, leave, logit);
							hiddenByDistance = (distance > containedRadiusWFactor);
						}
						if (logit) {
							LigaMap.console.log(layerName, leave.level, leave.minX, leave.minY, distanceSquared.toFixed(1), containedRadiusWFactorSquared.toFixed(1), hiddenByDistance? 'hidden' : 'visible');
						}
					}
					if (!hiddenByDistance || updateDebugObjects) {
						if (updateDebugObjects) {
							// if ((leave.minX==0) && (leave.minY==0))
							leave.debugObject.visible = true;
							leave.debugObject.material.opacity = hiddenByDistance? 0.1 : 1.0;
						}
						leave.visible = true;
						if (!leaveIsSubdivided(leave) || (leave.level >= maxLevel)) {
							var objectid;
							for (var i=0; i<leave.objects.length; i++) {
								objectid = leave.objects[i].id;
								if (objectsById[objectid] === undefined) {
									objectsById[objectid] = leave.objects[i];
								}
							}
						} else {
							collectLeaves(leave.q1);
							collectLeaves(leave.q2);
							collectLeaves(leave.q3);
							collectLeaves(leave.q4);
						}
					}
				}
			};
			if (updateDebugObjects) {
				disableRecursive(map);
			}
			collectLeaves(map);
			var objects = [];
			for (var i in objectsById) {
				objects.push(objectsById[i]);
			}
			LigaMap.debugDurationQuadtree += (performance.now()-starttime);
			return objects;
		};

		var getDistanceToCenterSquared = function(camPos, leave, logit) {
			var dx = camPos.x-leave.centerX;
			var dy = camPos.y-leave.centerY;
			var dSquared = dx*dx+dy*dy;
			// if (logit) LigaMap.console.log(dx.toFixed(2), dy.toFixed(2));
			// if (layerName == 'bld') LigaMap.console.log(v.toFixed(2), leave.radiusSquared.toFixed(2));
			return dSquared;
		};

		var getDistanceToRim = function(distanceToCenterSquared, leave, logit) {
			var d = Math.sqrt(distanceToCenterSquared);
			return (d - leave.radius);
		};

		var checkAndAddLeaveToUnloadArray = function(leave, unloadTimestamp, unloadLeaves, keepLoadedLeaves, forceInvisible) {
//			if (/*(layerName != 'bld') && */(layerName != 'krs')) return;	// ####
			var lstring = leave.level+":"; for (var i=0; i<leave.level; i++) {lstring = lstring+"    ";} lstring = lstring+layerName+"("+leave.minX+".."+leave.maxX+", "+leave.minY+".."+leave.maxY+")";
//			if (layerName == 'user') LigaMap.console.log(lstring, "checkAndAddLeaveToUnloadArray()");
			if (loadingLevel < 0)	return;
			if (leave.level > loadingLevel) {
				LigaMap.console.log("checkAndAddLeaveToUnloadArray()", layerName, "Can not check unload of leave with depth deeper than loadingLevel!");
			}
//			if ((leave.objects.length < 1) && (leave.objectsLoadedCount < 1))	return;
//			if (layerName == 'user') LigaMap.console.log(lstring, "checkAndAddLeaveToUnloadArray() (after checks)", leave.timestamps.lastVisible, unloadTimestamp, leave.visible);
			if (leave.timestamps.lastVisible < unloadTimestamp) {
//				LigaMap.console.log(lstring, "checkAndAddLeaveToUnloadArray() (after visibility check)");
				unloadLeaves.push(leave);
			} else {
				if (forceInvisible || !leave.visible) {	// gerade nicht sichtbar
					keepLoadedLeaves.push(leave);
				}
			}
		};
		
		var unloadLeaveArray = function(unloadLeaves, keepNewestCount) {
//			LigaMap.console.log(lstring, "checkUnloadLeave()");
			var getLoadingLeavesToUnload = function(leave) {
				if (leave.level == loadingLevel) {
					if (!leave.isLoaded || leave.hasLoadingError)	return [];	// momentan nichts zu entladen
					if (leave.isToBeLoaded)	return [];
					if (leave.inTemporaryLoadingQueue)	return [];
//					if (leave.objectsLoadedCount <= 0)	return [];
					return [leave];
				}
				if ((leave.level < loadingLevel) && leaveIsSubdivided(leave)) {
					return [].concat(getLoadingLeavesToUnload(leave.q1), getLoadingLeavesToUnload(leave.q2), getLoadingLeavesToUnload(leave.q3), getLoadingLeavesToUnload(leave.q4));
				}
				// else not possible, see checkAndAddLeaveToUnloadArray()
				return [];
			};
			if (unloadLeaves.length <= 0) return;
			
			var loadingLeavesToUnload = [];
			for (var j=0; j<unloadLeaves.length; j++) {
				Array.prototype.push.apply(loadingLeavesToUnload, getLoadingLeavesToUnload(unloadLeaves[j]));
			}
			if ((keepNewestCount !== undefined) && (keepNewestCount >= 0)) {
				var findDistance = function(leave) {
					if (leave.cameraDistanceSquared === undefined) {
						LigaMap.console.warn("cameraDistanceSquared undefined?", leave);
					}
					if ((leave.cameraDistanceSquared < 0) && leave.parent) {
						return findDistance(leave.parent);
					}
					return leave.cameraDistanceSquared;
				};
				var sortedLoadingLeavesToUnload = loadingLeavesToUnload.sort(function(a, b) {
					var d = (b.timestamps.lastVisible - a.timestamps.lastVisible);	// nach zuletzt-sichtbar sortieren
					if (d == 0) {	// falls gleich, nach Distanz sortieren
						var distanceA = findDistance(a);
						var distanceB = findDistance(b);
						if ((distanceA >= 0) && (distanceB >= 0)) {	// beide haben eine Distanz
							d = (distanceA - distanceB);
						} else if (distanceA >= 0) {	// nur a hat eine Distanz
							d = -1;
						} else if (distanceB >= 0) {	// nur b hat eine Distanz
							d = 1;
						}	// ansonsten gleich
					}
//					console.log("sort", a.minX+".."+a.minY, a.timestamps.lastVisible, distanceA, b.minX+".."+b.minY, b.timestamps.lastVisible, distanceB, d);
					return d;
				});
//				LigaMap.console.log("unloading by count, keeping newest", keepNewestCount, "of", sortedLoadingLeavesToUnload.length);
				for (var i=keepNewestCount; i<sortedLoadingLeavesToUnload.length; i++) {
//					LigaMap.console.log("unloading by count:", "lastVisible:", sortedLoadingLeavesToUnload[i].timestamps.lastVisible, "hasVisibleObjects:", sortedLoadingLeavesToUnload[i].hasVisibleObjects);
					removeLeaveObjectsLoaded(sortedLoadingLeavesToUnload[i], undefined, "invisible-count");
				}
			} else {
				for (var i=0; i<loadingLeavesToUnload.length; i++) {
					removeLeaveObjectsLoaded(loadingLeavesToUnload[i], undefined, "invisible-time");
				}
			}
		};

		
		this.showContainedObjects = function(containedRadius, frustum, maxLevel, doNotLoad, retryLoadDelay, unloadInvisibleDelay, unloadLeaveBudget) {
			var starttime = performance.now();
			var camPos = ligaMapInstance.getCameraPosition();
			var containedRadiusWFactor = containedRadius*maxDistanceFactor;	// scale by factor
//			var containedRadiusWFactorSquared = Math.pow(containedRadiusWFactor, 2);
			var currentTimestamp = Date.now();
			var unloadTimestamp = currentTimestamp - unloadInvisibleDelay;

			var loadingQueue = [];
			var loadingPossible = ligaMapInstance.isLoadingPossible();
			var logit = false;//(layerName == 'stadium_shadow' && (leave.minX==20000) && (leave.minY==25000));
			// var logit = ((layerName == 'stadium_lo') && doNotLoad);
			// if (logit) LigaMap.console.log("showContainedObjects()");
			// var tempLeavesCollected = 0;
			// var tempLeavesVisibilitySet = 0;
			// var tempObjectsVisibilitySet = 0;
//			if (layerName == 'krs') LigaMap.console.log("showContainedObjects()");

			var setLeaveObjectVisibilities = function(leave, alpha, forceHidden) {
				//if (leave.isToBeLoaded) LigaMap.console.log(layerName, visible, ':', leave);
				//if (leave.level == maxLevel) LigaMap.console.log(layerName, ':', visible, leave);
				if (forceHidden) {
					if (!leave.hasVisibleObjects) {
						return;	// nichts zu tun
					}
					alpha = 0;
				}
				// tempLeavesVisibilitySet++;
				leave.hasVisibleObjects = false;
				for (var i=0; i<leave.objects.length; i++) {
					if (forceHidden && !leave.objects[i].visible) {
						continue;	// nichts zu tun, weiter
					}
					// tempObjectsVisibilitySet ++;
					if (!leave.objects[i].material) {
						LigaMap.console.log("Object has no material?!", leave.objects[i]);
						leave.objects[i].material = {};
					}
					if (!leave.objects[i].material.userData) {
						leave.objects[i].material.userData = {};
					}
					if (leave.objects[i].material.uniforms && leave.objects[i].material.uniforms.quadtreeFade) {
						if (forceHidden) {
							leave.objects[i].material.uniforms.quadtreeFade.value = alpha;
						} else {
							leave.objects[i].material.uniforms.quadtreeFade.value = Math.max(leave.objects[i].material.uniforms.quadtreeFade.value, alpha);
						}
						// if ((layerName == 'krs') && (i==0) && (leave.minX==40000) && (leave.minY==50000)) LigaMap.console.log(leave.level, leave.objects[i].material.uniforms.quadtreeFade.value);
						leave.objects[i].visible = (leave.objects[i].material.uniforms.quadtreeFade.value > 0.0001);
					} else {
						leave.objects[i].visible = (alpha > 0.0001);
					}
					if (leave.objects[i].visible || forceHidden) {
						leave.objects[i].material.userData.visible = leave.objects[i].visible;
					}
					if (leave.objects[i].visible) {
						leave.hasVisibleObjects = true;
					}
					// if (leave.level == 0) leave.objects[i].material.uniforms.color.value = new THREE.Color(levelColors[i%levelColors.length]);	// Random Color
				}
			};
			
			var addToLoadingQueue = function(leave) {
//				var lstring = leave.level+":"; for (var i=0; i<leave.level; i++) {lstring = lstring+"    ";} lstring = lstring+layerName+"("+leave.minX+".."+leave.maxX+", "+leave.minY+".."+leave.maxY+")";
//				if (layerName == 'bld')  LigaMap.console.log(lstring, "addToLoadingQueue() start");
				// bereits fertig, bzw. geht gerade nicht?
				if (!loadingPossible)	return;
				if (loadingLevel < 0)	return;
				if (leave.timestamps.lastLoadChecked >= currentTimestamp)	return;	// already checked
				leaveUpdateLoadCheckedTime(leave, currentTimestamp);
				if (leave.inTemporaryLoadingQueue)	return;
				if (leave.isToBeLoaded)	return;
				if (leave.isLoaded) {
					if (leave.hasLoadingError) {
//						LigaMap.console.log(lstring, "hasLoadingError", leave.timestamps.lastLoaded, (leave.timestamps.lastLoaded > 0), (leave.timestamps.lastLoaded + retryLoadDelay - Date.now()));
						if ((leave.timestamps.lastLoaded > 0) && ((leave.timestamps.lastLoaded + retryLoadDelay - currentTimestamp) > 0)) {
//							if (layerName == 'bld') LigaMap.console.log("skip");
							return;
						} else {
//							if (layerName == 'bld') LigaMap.console.log("continue");
						}
					} else {
//						if (layerName == 'bld') LigaMap.console.log("already done");
						return;
					}
				}
//				if (layerName == 'bld') LigaMap.console.log(lstring, "addToLoadingQueue() start (after checks)");
				
				if (leave.level == loadingLevel) {	// Nur Laden, wenn richtige Tiefe
					// leave.isToBeLoaded = loadData(layerName, leave);
					loadingQueue.push(leave);
					leave.inTemporaryLoadingQueue = true;
					leave.isToBeLoaded = false;
//					if (layerName == 'bld') LigaMap.console.log(lstring, "pushed to loading queue");
				} else if (leave.level < loadingLevel) {	// noch nicht tief genug
//					if (layerName == 'bld') LigaMap.console.log(lstring, "check children");
					if (leaveIsSubdivided(leave)) {	// Alle Kinder durchgehen um an entsprechender Tiefe zu laden
						addToLoadingQueue(leave.q1);
						addToLoadingQueue(leave.q2);
						addToLoadingQueue(leave.q3);
						addToLoadingQueue(leave.q4);
					}
				} else {	// zu tief
//					if (layerName == 'bld') LigaMap.console.log(lstring, "check parent");
					if (leave.parent) {
						addToLoadingQueue(leave.parent);
					}
				}
				// if (layerName == 'bld') LigaMap.console.log(lstring, "addToLoadingQueue() end, isLoaded:", leave.isToBeLoaded);
			};
			
			var unloadLeaves = [];
			var keepLoadedLeaves = [];
			var collectLeaves = function(leave) {
//				var lstring = leave.level+":"; for (var i=0; i<leave.level; i++) {lstring = lstring+"    ";} lstring = lstring+layerName+"("+leave.minX+".."+leave.maxX+", "+leave.minY+".."+leave.maxY+")";
				leave.cameraDistanceSquared = -1;
				if (leave.isToBeLoaded && !leave.hasLoadingError && (leave.objects.length == 0)) return;
				// tempLeavesCollected++;
				leave.visible = false;
				if (frustum.intersectsBox({ min: new THREE.Vector3(leave.minX, leave.minY, 0), max: new THREE.Vector3(leave.maxX, leave.maxY, 0) })) {
					var hiddenByDistance = false;
					var alpha = 1.0;
					if ((maxDistanceFactor > 0) /*&& (leave.level==4)*/) {	// Entfernungscheck
						var distanceSquared = getDistanceToCenterSquared(camPos, leave, logit);
						leave.cameraDistanceSquared = distanceSquared;
						var distance = getDistanceToRim(distanceSquared, leave, logit);
						// alpha = Math.max(0, Math.min(1.0, 1.0-(distance/containedRadiusWFactor)));
						alpha = Math.max(0, Math.min(1.0, 1.0-((distance/containedRadiusWFactor-0.5)*2)));
						hiddenByDistance = !(alpha > 0.0001);
						// if (logit) {
						// 	LigaMap.console.log(layerName, leave.level, leave.minX, leave.minY, distanceSquared.toFixed(1), containedRadiusWFactorSquared.toFixed(1), hiddenByDistance? 'hidden' : 'visible');
						// }
					}
					if (!hiddenByDistance) {
						leave.visible = true;
						leaveUpdateVisibleTime(leave, currentTimestamp);
						if (!leaveIsSubdivided(leave) || (leave.level >= maxLevel)) {
							// tiefer gehen wir nicht
							setLeaveObjectVisibilities(leave, alpha);
							if (!doNotLoad) {
								// if (layerName == 'bld') LigaMap.console.log("start loading", layerName, leave);
								addToLoadingQueue(leave);
							}
						} else {
							collectLeaves(leave.q1);
							collectLeaves(leave.q2);
							collectLeaves(leave.q3);
							collectLeaves(leave.q4);
							leave.hasVisibleObjects = (leave.q1.hasVisibleObjects || leave.q2.hasVisibleObjects || leave.q3.hasVisibleObjects || leave.q4.hasVisibleObjects);
						}
					}
				}
				if (!neverUnload && !doNotLoad && (leave.level <= Math.min(loadingLevel, maxLevel))) {
					// unload invisible leaves (not intersected or hidden by distance)
					if ((leave.level == Math.min(loadingLevel, maxLevel)) || !leaveIsSubdivided(leave) || !leave.visible) {	// nur unload checken, wenn tiefe = loadinglevel oder wir niemals bis loadinglevel kommen
						checkAndAddLeaveToUnloadArray(leave, unloadTimestamp, unloadLeaves, keepLoadedLeaves);
					}
				}
			};
			
			setLeaveObjectVisibilities(map, 0, true);
			collectLeaves(map);
			// if (logit) LigaMap.console.log("leavesCollected: ", tempLeavesCollected, ", leavesVisibilitySet: ", tempLeavesVisibilitySet, ", objectsVisibilitySet: ", tempObjectsVisibilitySet);
			// Prefer Leaves centered in view for loading
			var cameraPosition = ligaMapInstance.getCameraPosition();
			var centerViewX = cameraPosition.x;
			var centerViewY = cameraPosition.y;
			var dx, dy, distanceA, distanceB, offX, offY;
			loadingQueue.sort(function(a, b) {	// sort by center distance (from min values)
				if (offX == undefined) {	// offset to center is the same for alle leaves
					offX = (a.maxX-a.minX)*0.5;
					offY = (a.maxY-a.minY)*0.5;
				}
				dx = a.minX + offX - centerViewX;
				dy = a.minY + offY - centerViewY;
				distanceA = dx*dx + dy*dy;
				dx = b.minX + offX - centerViewX;
				dy = b.minY + offY - centerViewY;
				distanceB = dx*dx + dy*dy;	// no need to sqrt()
				return (distanceA - distanceB);
			});
			// if (loadingQueue.length > 0) LigaMap.console.log(loadingQueue);
			for (var i in loadingQueue) {
				var leave = loadingQueue[i];
				loadLeave(leave);
				leave.inTemporaryLoadingQueue = false;
			}
			// unloading
			unloadLeaveArray(unloadLeaves);
			if (maxLoadedLeavesCount >= 0) unloadLeaveArray(keepLoadedLeaves, maxLoadedLeavesCount);
			LigaMap.debugDurationQuadtree += (performance.now()-starttime);
		};

		this.unloadLeaveByPrefix = function(prefix) {
			var cmpPrefix = layerName+"_"+prefix;
			if ((loadingLevel < 0) || (!ligaMapInstance.isLoadingPossible())) {
				return;
			}
			var collectLeaves = function(leave) {
				if (leave.isToBeLoaded) {
					return;
				}
				if (leave.level == loadingLevel) {
					// if ((x >= leave.minX) && (x < leave.maxX) &&
					// 	(y >= leave.minY) && (y < leave.maxY)) {
					if (getLeaveDebugPrefix(leave) == cmpPrefix) {
						removeLeaveObjectsLoaded(leave, undefined, "manual-leave-unload");
					}
				} else if (leave.level < loadingLevel) {	// noch nicht tief genug
					if (leaveIsSubdivided(leave)) {
						collectLeaves(leave.q1);
						collectLeaves(leave.q2);
						collectLeaves(leave.q3);
						collectLeaves(leave.q4);
					}
				}
			};
			collectLeaves(map);
		};

		// this.loadLeaveByPosition = function(x, y) {
		this.loadLeaveByPrefix = function(prefix) {
			var cmpPrefix = layerName+"_"+prefix;
			if ((loadingLevel < 0) || (!ligaMapInstance.isLoadingPossible())) {
				return;
			}
			var collectLeaves = function(leave) {
				if (leave.isToBeLoaded) {
					return;
				}
				if (leave.level == loadingLevel) {
					// if ((x >= leave.minX) && (x < leave.maxX) &&
					// 	(y >= leave.minY) && (y < leave.maxY)) {
					if (getLeaveDebugPrefix(leave) == cmpPrefix) {
						loadLeave(leave);
					}
				} else if (leave.level < loadingLevel) {	// noch nicht tief genug
					if (leaveIsSubdivided(leave)) {	// Alle Kinder durchgehen um an entsprechender Tiefe zu laden
						collectLeaves(leave.q1);
						collectLeaves(leave.q2);
						collectLeaves(leave.q3);
						collectLeaves(leave.q4);
						leave.isToBeLoaded = ((leave.q1.isToBeLoaded === true) && (leave.q2.isToBeLoaded === true) && (leave.q3.isToBeLoaded === true) && (leave.q4.isToBeLoaded === true));
					} else {
						leave.isToBeLoaded = true;	// Keine Kinder, und noch nicht tief genug -> fertig
					}
				}
			};
			collectLeaves(map);
		};

		this.loadAllLeaves = function() {
			// TODO: fehlerbehandlung einbauen?
			if ((loadingLevel < 0) || (!ligaMapInstance.isLoadingPossible())) {
				return;
			}
			var collectLeaves = function(leave) {
				if (leave.isToBeLoaded) {
					return;
				}
				if (leave.level == loadingLevel) {
					loadLeave(leave);
				} else if (leave.level < loadingLevel) {	// noch nicht tief genug
					if (leaveIsSubdivided(leave)) {	// Alle Kinder durchgehen um an entsprechender Tiefe zu laden
						collectLeaves(leave.q1);
						collectLeaves(leave.q2);
						collectLeaves(leave.q3);
						collectLeaves(leave.q4);
						leave.isToBeLoaded = ((leave.q1.isToBeLoaded === true) && (leave.q2.isToBeLoaded === true) && (leave.q3.isToBeLoaded === true) && (leave.q4.isToBeLoaded === true));
					} else {
						leave.isToBeLoaded = true;	// Keine Kinder, und noch nicht tief genug -> fertig
					}
				}
			};
			collectLeaves(map);
		};
		
		this.checkUnloadAllLeaves = function(unloadInvisibleDelay) {
			if (neverUnload)	return;
			if (!this.isLoadable())	return;
			var unloadLeaves = [];
			var keepLoadedLeaves = [];
			checkAndAddLeaveToUnloadArray(map, Date.now() - unloadInvisibleDelay, unloadLeaves, keepLoadedLeaves, true);
//			if (layerName=="user") console.log("checkUnloadAllLeaves()", unloadInvisibleDelay, unloadLeaves.length, keepLoadedLeaves.length);
			unloadLeaveArray(unloadLeaves);
			if (maxLoadedLeavesCount >= 0) unloadLeaveArray(keepLoadedLeaves, maxLoadedLeavesCount);
		};

		this.getAllObjects = function() {
			return map.objects;
		};

		this.print = function() {
			LigaMap.console.log(map);
		};

		this.isLoadable = function() {
			return (loadingLevel>=0);
		};

		this.isAllLoaded = function() {
			if (loadingLevel < 0) {
				return true;
			}
			// LigaMap.console.log("allloaded?", map.isLoaded);
			return map.isLoaded;
		};
		
		this.getLoadedLeavesCount = function() {
			var loadedCount = 0;
			var notLoadedCount = 0;
			var loadedObjectsCount = 0;
			if (loadingLevel >= 0) {
				var searchLoadedLeaves = function(leave) {
					if ((leave.level < loadingLevel) && leaveIsSubdivided(leave)) {
						searchLoadedLeaves(leave.q1);
						searchLoadedLeaves(leave.q2);
						searchLoadedLeaves(leave.q3);
						searchLoadedLeaves(leave.q4);
					}
					if (leave.level === loadingLevel) {
						loadedObjectsCount += leave.objectsLoadedCount;
						if (leave.isLoaded) {
							loadedCount++;
						} else {
							notLoadedCount++;
						}
					}
				};
				searchLoadedLeaves(map);
			}
//			LigaMap.console.log("loadedCount:", loadedCount, "notLoadedCount:", notLoadedCount);
			return {loadedLeavesCount:loadedCount, loadedObjectsCount:loadedObjectsCount};
		};

		/**
		 * 
		 * @param {String} [cmpDepthString]
		 * @returns {undefined}
		 */
		this.printTimestamps = function(cmpDepthString) {
			var maxPrints = 1000;
			var printCount = 0;
			var maxDepthStringLength = cmpDepthString? Math.max(5, cmpDepthString.length) : 5;
//			var cmpDepthString = "0341";
			var printMapTemp = function(leave, depthString) {
				if (printCount > maxPrints) {
					return;
				}
				if (!depthString) {
					depthString = '0';
				}
				if (depthString.length > maxDepthStringLength) {
					return;
				}
				if (!cmpDepthString || depthString.startsWith(cmpDepthString) || cmpDepthString.startsWith(depthString))
					console.log(depthString, "objects:", leave.objects.length, "objectsLoaded:", leave.objectsLoadedCount, "isLoaded:", leave.isLoaded, "hasLoadingError:", leave.hasLoadingError, "lastLoadChecked:", leave.timestamps.lastLoadChecked, "lastLoaded:", leave.timestamps.lastLoaded, "lastVisible:", leave.timestamps.lastVisible, "visible:", leave.visible);
				printCount++;
				if (leaveIsSubdivided(leave)) {
					printMapTemp(leave.q1, depthString+"1");
					printMapTemp(leave.q2, depthString+"2");
					printMapTemp(leave.q3, depthString+"3");
					printMapTemp(leave.q4, depthString+"4");
				}
			};
			printMapTemp(map);	
		};

		init();
	};

	return self;
}(LigaMap || {}));
