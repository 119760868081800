/**
 * Copyright Christian Kubitza
 * christian@ck3d.de
 * 2016-2018
 */

 var LigaMap = (function(self) {
 	"use strict";

	self.floorTiles = self.floorTiles || {};

	var currentFloorTileData = {};

	var connectFloorTiles = function(config, floorTileData) {	// Stadien mit Nachbarstadien verbinden
		var ftx, fty, th, tw;
		for (ftx=0; ftx<(floorTileData.floorTileCountX-2); ftx++) {
			for (fty=0; fty<(floorTileData.floorTileCountY-2); fty++) {
				if (floorTileData.floortiles[ftx][fty] !== undefined) {
					if ((floorTileData.floortiles[ftx+1][fty] === undefined) && (floorTileData.floortiles[ftx+2][fty] !== undefined)) {	// rechts mit 1 Abstand ist ein Stadion
						// wie weit nach oben hängen die Stadien zusammen?
						th = 1;
						while (
							(th < 4) &&	// mehr als 4x gibts nicht
							(floorTileData.floortiles[ftx+0][fty+th] !== undefined) &&
							(floorTileData.floortiles[ftx+1][fty+th] === undefined) &&
							(floorTileData.floortiles[ftx+2][fty+th] !== undefined)
						) {
							th++;
						}
						switch (th) {
							case 2:
								self.floorTiles.addFloorTile(config, floorTileData, ftx+1, fty, 1, Math.round(Math.random())*2);
								break;
							case 3:
								self.floorTiles.addFloorTile(config, floorTileData, ftx+1, fty, 2, Math.round(Math.random())*2);
								break;
							case 4:
								self.floorTiles.addFloorTile(config, floorTileData, ftx+1, fty, 6, Math.round(Math.random())*2);
								break;
							default:
								self.floorTiles.addFloorTile(config, floorTileData, ftx+1, fty, 8+Math.floor(Math.random()*9), Math.floor(Math.random()*4));
						}
						// self.floorTiles.addFloorTile(config, floorTileData, ftx+1, fty, 8+Math.floor(Math.random()*9), Math.floor(Math.random()*4));
					}
					if ((floorTileData.floortiles[ftx][fty+1] === undefined) && (floorTileData.floortiles[ftx][fty+2] !== undefined)) {	// oben mit 1 Abstand ist ein Stadion
						// wie weit nach rechts hängen die Stadien zusammen?
						tw = 1;
						while (
							(tw < 4) &&	// mehr als 4x gibts nicht
							(floorTileData.floortiles[ftx+tw][fty+0] !== undefined) &&
							(floorTileData.floortiles[ftx+tw][fty+1] === undefined) &&
							(floorTileData.floortiles[ftx+tw][fty+2] !== undefined)
						) {
							tw++;
						}
						switch (tw) {
							case 2:
								self.floorTiles.addFloorTile(config, floorTileData, ftx, fty+1, 1, 1+Math.round(Math.random())*2);
								break;
							case 3:
								self.floorTiles.addFloorTile(config, floorTileData, ftx, fty+1, 2, 1+Math.round(Math.random())*2);
								break;
							case 4:
								self.floorTiles.addFloorTile(config, floorTileData, ftx, fty+1, 6, 1+Math.round(Math.random())*2);
								break;
							default:
								self.floorTiles.addFloorTile(config, floorTileData, ftx, fty+1, 8+Math.floor(Math.random()*9), Math.floor(Math.random()*4));
						}
					}
				}
			}
		}
	};

	var fillFloorTiles = function(config, floorTileAtlasTilesSizeLookup, floorTileData) {	// große Lücken verbinden
		var getBestEmptyQuad = function(x, y, checkRightNotTop) {
			var maxnx, maxny, nx, ny, foundEmptyQuads, bestEmptyQuad, qi, connectedTileCount, allConnected;
			foundEmptyQuads = [];
			maxnx = 5;
			maxny = 5;
			for (nx=0; nx<maxnx; nx++) if ((x+nx) < floorTileData.floorTileCountX) {
				for (ny=0; ny<maxny; ny++) if ((y+ny) < floorTileData.floorTileCountY) {
					if (floorTileData.floortiles[x+nx][y+ny] === undefined) {
						foundEmptyQuads.push([(nx+1), (ny+1), (nx+1)*(ny+1)]);
					} else {
						maxny = ny;
						// maxnx = nx;
					}
				}
			}
			// LigaMap.console.log("foundEmptyQuads", x, y, foundEmptyQuads);
			bestEmptyQuad = undefined;
			for (qi in foundEmptyQuads) {
				// LigaMap.console.log("check ",qi);
				if (
					(	// gibt es so ein Tile überhaupt?
						(floorTileAtlasTilesSizeLookup[foundEmptyQuads[qi][0]+"x"+foundEmptyQuads[qi][1]] !== undefined) ||
						(floorTileAtlasTilesSizeLookup[foundEmptyQuads[qi][1]+"x"+foundEmptyQuads[qi][0]] !== undefined)
					) &&
					(	// gabs schon bessere?
						(bestEmptyQuad === undefined) ||
						(bestEmptyQuad[2] < foundEmptyQuads[qi][2])
					)
				) {
					// Es muss ein Verbindungsstück sein
					connectedTileCount = 0;
					if (checkRightNotTop) {
						nx = foundEmptyQuads[qi][0];
						if ((x+nx) < floorTileData.floorTileCountX) {
							for (ny=0; ny<foundEmptyQuads[qi][1]; ny++) if ((y+ny) < floorTileData.floorTileCountY) {
								if (floorTileData.floortiles[x+nx][y+ny] !== undefined) {
									connectedTileCount++;
									// LigaMap.console.log("connected +1");
								}
							}
						}
						allConnected = (connectedTileCount==foundEmptyQuads[qi][1]);
					} else {
						ny = foundEmptyQuads[qi][1];
						if ((y+ny) < floorTileData.floorTileCountY) {
							for (nx=0; nx<foundEmptyQuads[qi][0]; nx++) if ((x+nx) < floorTileData.floorTileCountX) {
								if (floorTileData.floortiles[x+nx][y+ny] !== undefined) {
									connectedTileCount++;
									// LigaMap.console.log("connected +1");
								}
							}
						}
						allConnected = (connectedTileCount==foundEmptyQuads[qi][0]);
					}
					if (allConnected) {
						// LigaMap.console.log("set as best");
						bestEmptyQuad = foundEmptyQuads[qi];
					}
				}
			}
			// LigaMap.console.log("bestEmptyQuad", x, y, bestEmptyQuad);
			return bestEmptyQuad;
		};
		var ftx, fty, possibleTiles, bestEmptyQuad, rot, ti, tx, ty;
		var testPositions = [
			[0, 1],
			[1, 0]
		];
		for (ftx=0; ftx<(floorTileData.floorTileCountX-1); ftx++) {
			for (fty=0; fty<(floorTileData.floorTileCountY-1); fty++) {
				// LigaMap.console.log("fill:",ftx,fty);
				if (floorTileData.floortiles[ftx][fty] !== undefined) {
					for (ti in testPositions) {
						tx = ftx+testPositions[ti][0];
						ty = fty+testPositions[ti][1];
						bestEmptyQuad = getBestEmptyQuad(tx, ty, (testPositions[ti][0]>0));
						if (bestEmptyQuad !== undefined) {
							possibleTiles = floorTileAtlasTilesSizeLookup[bestEmptyQuad[0]+"x"+bestEmptyQuad[1]];
							rot = 0;
							// LigaMap.console.log(possibleTiles);
							if (!possibleTiles) {	// gedreht verfügbar?
								possibleTiles = floorTileAtlasTilesSizeLookup[bestEmptyQuad[1]+"x"+bestEmptyQuad[0]];
								rot = 1;
							}
							if (possibleTiles) {
								if (bestEmptyQuad[0] == bestEmptyQuad[1]) {	// quadratisch: 90° Drehung
									rot = Math.floor(Math.random()*4);
								} else {	// ansonsten: nur 180° Drehung
									rot += Math.round(Math.random())*2;	// 0° oder 180° (also 0 oder 2 bzw. 1 oder 3)
								}
								self.floorTiles.addFloorTile(config, floorTileData, tx, ty, possibleTiles[Math.floor(Math.random()*possibleTiles.length)], rot);
							} else {
								LigaMap.console.log("No tile with size "+bestEmptyQuad[0]+"x"+bestEmptyQuad[1]+" or "+bestEmptyQuad[1]+"x"+bestEmptyQuad[0]+" available");
							}
						}
					}
				}
				// self.floorTiles.addFloorTile(config, floorTileData, ftx, fty, floorTileAtlasTilesSizeLookup["1x1"][0], 0);
			}
		}
	};

	var pushFloorTiles = function(config, floorTileAtlasTilesSizeLookup, floorTileData, possibillity) {
		var setTile = new Array(floorTileData.floorTileCountX*floorTileData.floorTileCountY);
		var pushPattern = [
			[-1, 0],
			[0, -1],
			[1, 0],
			[0, 1],
		];
		var possibleTiles = floorTileAtlasTilesSizeLookup["1x1"];
		var ftx, fty, pi, nx, ny;
		for (ftx=0; ftx<floorTileData.floorTileCountX; ftx++) {
			for (fty=0; fty<floorTileData.floorTileCountY; fty++) {
				if ((floorTileData.floortiles[ftx][fty] !== undefined) && !setTile[fty*floorTileData.floorTileCountX+ftx]) {
					for (pi=0; pi<pushPattern.length; pi++) {
						nx = ftx+pushPattern[pi][0];
						ny = fty+pushPattern[pi][1];
						if (
							(nx >= 0) &&
							(nx < floorTileData.floorTileCountX) &&
							(ny >= 0) &&
							(ny < floorTileData.floorTileCountY) &&
							(floorTileData.floortiles[nx][ny] === undefined)
						) {
							if ((possibillity==undefined) || (Math.random() < possibillity)) {
								self.floorTiles.addFloorTile(config, floorTileData, nx, ny, possibleTiles[Math.floor(Math.random()*possibleTiles.length)], Math.floor(Math.random()*4));
								setTile[ny*floorTileData.floorTileCountX+nx] = true;
							}
						}
					}
				}
			}
		}
	};

	var growFloorTileBranches = function(config, floorTileAtlasTilesSizeLookup, floorTileData) {

		var coloredBranches = false;	// Debug

		// LigaMap.console.log(floorTileAtlasTilesSizeLookup);
		var possibleTiles1 = new Array();
		var possibleTiles2 = new Array();
		var possibleTiles3 = new Array();
		var possibleTiles4 = new Array();
		possibleTiles1 = possibleTiles1.concat(floorTileAtlasTilesSizeLookup["1x1"]);
		possibleTiles1 = possibleTiles1.concat(floorTileAtlasTilesSizeLookup["1x2"]);
		possibleTiles1 = possibleTiles1.concat(floorTileAtlasTilesSizeLookup["2x1"]);

		possibleTiles2 = possibleTiles2.concat(floorTileAtlasTilesSizeLookup["2x2"]);
		possibleTiles2 = possibleTiles2.concat(floorTileAtlasTilesSizeLookup["1x3"]);
		possibleTiles2 = possibleTiles2.concat(floorTileAtlasTilesSizeLookup["1x4"]);

		possibleTiles3 = possibleTiles3.concat(floorTileAtlasTilesSizeLookup["2x3"]);
		possibleTiles3 = possibleTiles3.concat(floorTileAtlasTilesSizeLookup["2x4"]);
		possibleTiles3 = possibleTiles3.concat(floorTileAtlasTilesSizeLookup["4x2"]);

		possibleTiles4 = possibleTiles4.concat(floorTileAtlasTilesSizeLookup["4x3"]);
		possibleTiles4 = possibleTiles4.concat(floorTileAtlasTilesSizeLookup["4x4"]);

		// possibleTiles1 = possibleTiles1.concat(floorTileAtlasTilesSizeLookup["4x3"]);
		// possibleTiles1.push(floorTileAtlasTilesSizeLookup["4x3"][0]);
		// possibleTiles1.push(floorTileAtlasTilesSizeLookup["4x4"][0]);

		var ftx, fty, pi, nx, ny;
		var branches = new Array();

		var makeBranch = function(x, y, rot, active, length, strength, color) {
			return {x:x, y:y, rot:rot, active:active, length:length, strength:strength, color:color, addFailures:0};
		};

		var getForcedBranchDirectionsInRange = function(index, rangeSquared) {
			var resultDirections = [];
			var dx, dy;
			var x = floorTileData.forcedBranchPositions[index][0];
			var y = floorTileData.forcedBranchPositions[index][1];
			for (var i=0; i<floorTileData.forcedBranchPositions.length; i++) if (i != index) {
				dx = floorTileData.forcedBranchPositions[i][0] - x;
				dy = floorTileData.forcedBranchPositions[i][1] - y;
				if ( (Math.pow(dx, 2)+Math.pow(dy, 2)) < rangeSquared) {
					resultDirections.push(Math.atan2(dy, dx));
				}
			}
			return resultDirections;
		};

		// Suche Startpositionen
		var directionsInRange;
		var i, j, rot;
		if (config.floorTileGeneration.branchCreationForcedStadiumConnectionDistance > 0) {
			var rangeSquared = Math.pow(config.floorTileGeneration.branchCreationForcedStadiumConnectionDistance, 2);
			for (i=0; i<floorTileData.forcedBranchPositions.length; i++) {
				ftx = floorTileData.forcedBranchPositions[i][0];
				fty = floorTileData.forcedBranchPositions[i][1];
				directionsInRange = getForcedBranchDirectionsInRange(i, rangeSquared);
				// LigaMap.console.log(directionsInRange.length);
				if (directionsInRange.length > 0) {
					for (j=0; j<directionsInRange.length; j++) {
						branches.push(makeBranch(ftx, fty, directionsInRange[j], true, 0, 1.0, [Math.random(), Math.random(), Math.random()]));
					}
				}// else {
					branches.push(makeBranch(ftx, fty, Math.random()*2*Math.PI, true, 0, 1.0, [Math.random(), Math.random(), Math.random()]));
				//}
			}
		}
		if (config.floorTileGeneration.branchCreationFromDensityPossibility > 0) {
			for (ftx=0; ftx<floorTileData.floorTileCountX; ftx+=config.floorTileGeneration.branchCreationFromDensityStep) {
				for (fty=0; fty<floorTileData.floorTileCountY; fty+=config.floorTileGeneration.branchCreationFromDensityStep) {
					if (
						(floorTileData.floortilesdensity[ftx][fty] >= config.floorTileGeneration.branchCreationFromDensityMinDensity) &&
						(floorTileData.floortilesdensity[ftx][fty] <= config.floorTileGeneration.branchCreationFromDensityMaxDensity)
					) {
						if (Math.random() < (config.floorTileGeneration.branchCreationFromDensityPossibility * Math.min(1.0, floorTileData.floortilesdensity[ftx][fty]))) {
						// if (Math.random() < Math.pow(floorTileData.floortilesdensity[ftx][fty], 4)) {
						// if (floorTileData.floortilesdensity[ftx][fty] > 0.8) {
							branches.push(makeBranch(ftx, fty, Math.random()*2*Math.PI, true, 0, 1.0, [Math.random(), Math.random(), Math.random()]));
						}
					}
				}
			}
		}

		var getBestHighDensityDirection = function(x, y) {
			var ix, iy, highestDensity = 0, highestDensityPositions = Array(), currentDensity;
			// var s = '#'+Math.max(0, x-1)+'..'+Math.min(floorTileData.floorTileCountX-1, x+1);
			for (ix = Math.max(0, x-1); ix<=Math.min(floorTileData.floorTileCountX-1, x+1); ix++) {
				for (iy = Math.max(0, y-1); iy<=Math.min(floorTileData.floorTileCountY-1, y+1); iy++) {
					if ((ix!=x) && (iy!=y)) {
						currentDensity = (floorTileData.floortilesdensity[ix][iy]!==undefined)? Math.min(1.0, floorTileData.floortilesdensity[ix][iy]) : 0;
						if (highestDensity < currentDensity) {
							highestDensity = currentDensity;
							highestDensityPositions = Array();
							highestDensityPositions.push({x:ix, y:iy});
						} else if (highestDensity == currentDensity) {
							highestDensityPositions.push({x:ix, y:iy});
						}
						// s += ''+ix+','+iy+':'+currentDensity+'('+highestDensity+') | ';
					} else {
						// s += '| ';
					}
				}
			}
			if (highestDensityPositions.length < 1) {
				LigaMap.console.warn("getBestHighDensityDirection() no positions, ", x, y/*, s, floorTileData*/);
				return 0;
			}
			var i = Math.floor(Math.random()*highestDensityPositions.length);
			var dx = highestDensityPositions[i].x-x;
			var dy = highestDensityPositions[i].y-y;
			return Math.atan2(dy, dx);
		};

		var lerpRotations = function(rot1, rot2, alpha) {
			var getRotZeroTwoPI = function(r) {
				r = r % (2*Math.PI);
				if (r < 0) {
					r += 2*Math.PI;
				}
				return r;
			};

			rot1 = getRotZeroTwoPI(rot1);
			rot2 = getRotZeroTwoPI(rot2);
			if ((rot1-rot2) > Math.PI) {
				rot2 += 2*Math.PI;
			} else if ((rot2-rot1) > Math.PI) {
				rot1 += 2*Math.PI;
			}
			return getRotZeroTwoPI(rot1*alpha + rot2*(1-alpha));
		};

		// LigaMap.console.log("branches: ", branches);
		var i = 0, dx, dy, bx, by, nr, br, snappedRot, subbranchDirection;
		var activeBranches, tile;
		var iteration = 0;
		var branchCalculations = 0;
		do {
			activeBranches = 0;
			for (i=0; i<branches.length; i++) if (branches[i].strength > 0) {
				// if (i==0) LigaMap.console.log("branch strength: ", branches[i].strength);
				activeBranches++;
				branchCalculations++;
				nr = branches[i].rot + config.floorTileGeneration.branchBuckling * 2.0*Math.PI*2*(Math.random()-0.5);	// add slight random direction
				br = getBestHighDensityDirection(Math.round(branches[i].x), Math.round(branches[i].y));
				branches[i].rot = lerpRotations(br, nr, config.floorTileGeneration.branchDensityInfluence);
				// branches[i].rot = nr;
				if (config.floorTileGeneration.branchRotationQuantizationSteps > 0) {
					snappedRot = (Math.round( config.floorTileGeneration.branchRotationQuantizationSteps * branches[i].rot/(2*Math.PI) ) / config.floorTileGeneration.branchRotationQuantizationSteps) * (2*Math.PI);
				} else {
					snappedRot = branches[i].rot;
				}
				dx = Math.cos(snappedRot);
				dy = Math.sin(snappedRot);
				branches[i].x += dx;
				branches[i].y += dy;
				ftx = Math.round(branches[i].x);
				fty = Math.round(branches[i].y);
				if ((ftx < 0) || (ftx >= floorTileData.floorTileCountX) || (fty < 0) || (fty >= floorTileData.floorTileCountY)) {	// out of floortile
					branches[i].strength = 0;
					// LigaMap.console.log("branch "+i+" left floortile");
				} else {
					if (branches[i].strength > 0.75) {
						tile = possibleTiles4[Math.floor(Math.random()*possibleTiles4.length)];
					} else if (branches[i].strength > 0.5) {
						tile = possibleTiles3[Math.floor(Math.random()*possibleTiles3.length)];
					} else if (branches[i].strength > 0.25) {
						tile = possibleTiles2[Math.floor(Math.random()*possibleTiles2.length)];
					} else {
						tile = possibleTiles1[Math.floor(Math.random()*possibleTiles1.length)];
					}
					// tile = possibleTiles1[0];//i%possibleTiles1.length];	// debug
					if (self.floorTiles.addFloorTile(config, floorTileData, ftx, fty, tile, Math.floor(Math.random()*4), coloredBranches? branches[i].color : undefined, undefined)) {
						branches[i].addFailures = 0;
					} else {
						branches[i].addFailures++;
					}
					branches[i].strength -= Math.random() * config.floorTileGeneration.branchStrengthDecrease;
					branches[i].length++;
					if (Math.random() < config.floorTileGeneration.branchSubbranchPossibility) {	// subbranch
						branches[i].strength *= config.floorTileGeneration.branchSubbranchStrengthFactor;
						subbranchDirection = (Math.random()<0.5)? 1 : -1;
						branches.push(makeBranch(ftx, fty, (branches[i].rot+subbranchDirection*config.floorTileGeneration.branchSubbranchInclination*2*Math.PI), true, branches[i].length, branches[i].strength, branches[i].color));
						// branches[i].rot = branches[i].rot-config.floorTileGeneration.branchSubbranchInclination*2*Math.PI;
					}

					// branch abbrechen?
					if (branches[i].addFailures >= config.floorTileGeneration.branchMaxConsecutiveAddTileFailureCount) {
						// LigaMap.console.log("branch "+i+" killed by branchMaxConsecutiveAddTileFailureCount");
						branches[i].strength = 0;
					}
					if (branches[i].length >= config.floorTileGeneration.branchMaxLength) {
						// LigaMap.console.log("branch "+i+" killed by branchMaxLength");
						branches[i].strength = 0;
					}
				}
			}
			iteration++;
			// LigaMap.console.log("pos", floorTileData.floorTileMinX, floorTileData.floorTileMinY, "iteration", iteration, "activeBranches", activeBranches);
		} while (activeBranches > 0);
		LigaMap.console.log("FloorTile pos:", floorTileData.floorTileMinX, floorTileData.floorTileMinY, ", branchCalculations:",  branchCalculations);
	};

	var brushCache = {};
	var getBrushQuarter = function(radius, strength) {
		var cacheName = 'r'+radius+'s'+strength;
		if (brushCache[cacheName]) {
			return brushCache[cacheName];
		}
		// LigaMap.console.log("getBrush()", radius, strength);
		var safeRadius = Math.ceil(radius);
		var brush = Array();
		var x, y, d, dr;
		for (x=0; x<safeRadius; x++) {
			brush[x] = Array();
			for (y=0; y<safeRadius; y++) {
				d = Math.sqrt(Math.pow(x, 2)+Math.pow(y, 2));
				dr = 1.0 - d/radius;
				//dr = dr*dr*dr;
				brush[x][y] = Math.max(0, Math.min(1.0, strength * dr));
			}
		}
		// LigaMap.console.log(brush);
		brushCache[cacheName] = brush;
		return brush;
	};

	var paintDensity = function(config, floorTileData, x, y, radius, density) {
		// LigaMap.console.log("paintDensity()", floorTileData, x, y, radius, density);

		var addDensity = function(ftx, fty, value) {
			if (ftx < 0)								return;
			if (ftx >= floorTileData.floorTileCountX)	return;
			if (fty < 0)								return;
			if (fty >= floorTileData.floorTileCountY)	return;
			if (!floorTileData.floortilesdensity[ftx][fty]) {
				floorTileData.floortilesdensity[ftx][fty] = 0;
			}
			floorTileData.floortilesdensity[ftx][fty] += density*value;
		};

		var brushQuarter = getBrushQuarter(config.floorTileGeneration.densityBrushRadius*radius, config.floorTileGeneration.densityBrushStrength);
		var bx, by, ftx, fty;
		for (bx=0; bx<brushQuarter.length; bx++) {
			for (by=0; by<brushQuarter[bx].length; by++) {
				addDensity(x+bx, y+by, brushQuarter[bx][by]);
				if (bx>0) {
					addDensity(x-bx, y+by, brushQuarter[bx][by]);
				}
				if (by>0) {
					addDensity(x+bx, y-by, brushQuarter[bx][by]);
				}
				if ((bx>0) && (by>0)) {
					addDensity(x-bx, y-by, brushQuarter[bx][by]);
				}
			}
		}
	};

	self.floorTiles.addFloorTile = function(config, floorTileData, rasterX, rasterY, tileNum, tileRot, tileColor, density) {
		// if ((floorTileData.floorTileMinX != 4995) || (floorTileData.floorTileMinY != 43745.625)) return null;	// debug
		// LigaMap.console.log("addFloorTile()", config, floorTileData, rasterX, rasterY, tileNum, tileRot, tileColor, density);
		// tileNum = 5;
		// tileRot = 3;//Math.floor(Math.random()*4);
		var tile = config.floorTileAtlasTiles[tileNum];
		if (!tile) {
			LigaMap.console.log("addFloorTile() tile not found: ", tileNum);
			return false;
		}
		var tx,ty,nx,ny,cx,cy;
		var addList = Array();	// verzögertes Hinzufügen, nur wenn alles frei
		for (tx=0; tx<tile.w; tx++)  {
			for (ty=0; ty<tile.h; ty++) {
				nx = rasterX+tx;
				ny = rasterY+ty;
				cx = tile.x+tx;
				cy = tile.y+ty;
				// ganze Kachel rotieren
				if (tileRot == 1) {
					cx = tile.x+tile.w-1-tx;
					nx = rasterX+ty;
					ny = rasterY+tx;
				} else if (tileRot == 2) {
					cx = tile.x+tile.w-1-tx;
					cy = tile.y+tile.h-1-ty;
				} else if (tileRot == 3) {
					cy = tile.y+tile.h-1-ty;
					nx = rasterX+ty;
					ny = rasterY+tx;
				}
				if (
					(nx >= 0) &&
					(nx < floorTileData.floorTileCountX) &&
					(ny >= 0) &&
					(ny < floorTileData.floorTileCountY)
				) {
					if (floorTileData.floortiles[nx][ny] == undefined) {
						addList.push({x:nx, y:ny, tv:cy*config.floorTileAtlasRasterX + cx, tr:tileRot});
					} else {
						return false;
					}
				} else {
					// LigaMap.console.log("addFloorTile() out of range", floorTileData.floorTileCountX, floorTileData.floorTileCountY, nx, ny);
					return false;
				}
			}
		}
		var centerX = 0.5*(rasterX+nx+1);
		var centerY = 0.5*(rasterY+ny+1);

		for (var i=0; i<addList.length; i++) {
			nx = addList[i].x;
			ny = addList[i].y;
			floorTileData.floortiles[nx][ny] = addList[i].tv;
			floorTileData.floortilesrot[nx][ny] = addList[i].tr;
			floorTileData.visibleTileCount++;
		}

		if (density !== undefined) {
			paintDensity(config, floorTileData, Math.round(centerX), Math.round(centerY), 0.5*(tile.w+tile.h), density);
		}

		if (config.generateFloortileBuildings && tile.m) {	// building-objekt zugewiesen
			if (!floorTileData.buildings[tile.m]) {
				floorTileData.buildings[tile.m] = [];
			}
			// var color = [(1.0*tileNum)/config.floorTileAtlasTiles.length, 0.5, 0.0];
			floorTileData.buildings[tile.m].push({x:centerX, y:centerY, r:tileRot, c:(tileColor? tileColor : [1.0, 1.0, 1.0])});
		}
		return true;
	};


	self.floorTiles.addRandomFloorTileWithId = function(config,	id, floorTileAtlasTilesSizeLookup, positionX, positionY, rotation, tileIndex, density) {
		var floorTileData = currentFloorTileData[id];
		if (!floorTileData) {
			LigaMap.console.warn('floorTileData with id "'+id+'" not found');
			return false;
		}
		var ftx = Math.floor((positionX-floorTileData.floorTileMinX-0.5*config.floorTileSize)/config.floorTileSize);
		var fty = Math.floor((positionY-floorTileData.floorTileMinY-0.5*config.floorTileSize)/config.floorTileSize);
		// LigaMap.console.log(floorTileData.floorTileCountX, floorTileData.floorTileCountY, ftx, fty);
		if ((ftx<0) || (ftx>=(floorTileData.floorTileCountX)) || (fty<0) || (fty>=(floorTileData.floorTileCountY))) {
			LigaMap.console.log("out of range with id: ", id, floorTileData.floorTileCountX, floorTileData.floorTileCountY, ftx, fty);
			return false;
		} else {
			var possibleTiles = floorTileAtlasTilesSizeLookup[tileIndex];
			var tileNum = possibleTiles[Math.floor(Math.random()*possibleTiles.length)];

// possibleTiles = floorTileAtlasTilesSizeLookup['sks'];
// tileNum = possibleTiles[3];
// rotation = 2;

			if (density !== undefined) {	// no density, no branch
				floorTileData.forcedBranchPositions.push([ftx + 0.25*config.floorTileSize, fty + 0.25*config.floorTileSize]);	// fixed center offset (todo)
			}

			return self.floorTiles.addFloorTile(config, floorTileData, ftx, fty, tileNum, rotation, [1.0, 1.0, 1.0], density);
		}
	};

	var splitFloorTileData = function(config, floorTileData, splitIntoQuadIterations) {
		if (splitIntoQuadIterations < 1) {
			return floorTileData;
		} else {
			var floorTileDataSplitted = Array();
			var x, y;
			var rcx = floorTileData.floorTileCountX*0.5;
			var rcy = floorTileData.floorTileCountY*0.5;
			// prepare
			for (y=0;y<2;y++) {
				for (x=0;x<2;x++) {
					var floorTileMinX = floorTileData.floorTileMinX + Math.round((x+0)*rcx)*config.floorTileSize;
					var floorTileMinY = floorTileData.floorTileMinY + Math.round((y+0)*rcy)*config.floorTileSize;
					var floorTileMaxX = floorTileData.floorTileMinX + Math.round((x+1)*rcx)*config.floorTileSize;
					var floorTileMaxY = floorTileData.floorTileMinY + Math.round((y+1)*rcy)*config.floorTileSize;
					var floorTileCountX = Math.round((x+1)*rcx) - Math.round((x+0)*rcx);
					var floorTileCountY = Math.round((y+1)*rcy) - Math.round((y+0)*rcy);
					var floortiles = new Array(floorTileCountX);
					var floortilesrot = new Array(floorTileCountX);
					for (var ftx=0; ftx<floorTileCountX; ftx++) {
						floortiles[ftx] = new Array(floorTileCountY);
						floortilesrot[ftx] = new Array(floorTileCountY);
					}
					floorTileDataSplitted.push({
						floorTileMinX: floorTileMinX,
						floorTileMinY: floorTileMinY,
						floorTileMaxX: floorTileMaxX,
						floorTileMaxY: floorTileMaxY,
						floorTileCountX: floorTileCountX,
						floorTileCountY: floorTileCountY,
						floorTileTexelWidth: floorTileData.floorTileTexelWidth,
						floorTileTexelHeight: floorTileData.floorTileTexelHeight,
						floortiles: floortiles,
						floortilesrot: floortilesrot,
						visibleTileCount: 0,
						buildings: {}
					});
				}
			}
			// copy data
			var ftx, fty, d;
			var firstCountX = Math.round(rcx);
			var firstCountY = Math.round(rcy);
			for (fty=0; fty<floorTileData.floorTileCountY; fty++) {
				y = fty<firstCountY? 0 : 1;
				for (ftx=0; ftx<floorTileData.floorTileCountX; ftx++) {
					x = ftx<firstCountX? 0 : 1;
					d = y*2+x;
					floorTileDataSplitted[d].floortiles[ftx-x*firstCountX][fty-y*firstCountY] = floorTileData.floortiles[ftx][fty];
					floorTileDataSplitted[d].floortilesrot[ftx-x*firstCountX][fty-y*firstCountY] = floorTileData.floortilesrot[ftx][fty];
					floorTileDataSplitted[d].visibleTileCount += (floorTileData.floortiles[ftx][fty]===undefined)? 0 : 1;
					// LigaMap.console.log(ftx, fty, d);
				}
			}
			for (var bn in floorTileData.buildings) {
				for (var i=0; i<floorTileData.buildings[bn].length; i++) {
					var building = floorTileData.buildings[bn][i];
					x = building.x<firstCountX? 0 : 1;
					y = building.y<firstCountY? 0 : 1;
					d = y*2+x;
					if (!floorTileDataSplitted[d].buildings[bn]) {
						floorTileDataSplitted[d].buildings[bn] = Array();
					}
					// LigaMap.console.log(building);
					building.x -= x*firstCountX;
					building.y -= y*firstCountY;
					floorTileDataSplitted[d].buildings[bn].push(building);
					// LigaMap.console.log(building, d);
				}
			}
			// LigaMap.console.log(floorTileData);
			// LigaMap.console.log(floorTileDataSplitted);
			return Array()
				.concat(splitFloorTileData(config, floorTileDataSplitted[0], splitIntoQuadIterations-1))
				.concat(splitFloorTileData(config, floorTileDataSplitted[1], splitIntoQuadIterations-1))
				.concat(splitFloorTileData(config, floorTileDataSplitted[2], splitIntoQuadIterations-1))
				.concat(splitFloorTileData(config, floorTileDataSplitted[3], splitIntoQuadIterations-1));
		}
	};

	self.floorTiles.getFloorTileObject = function(config, id, floorTileAtlasTilesSizeLookup, doExpensiveCalculations) {
		var floorTileData = currentFloorTileData[id];
		// LigaMap.console.log("getFloorTileObject()", floorTileData.floorTileMinX, floorTileData.floorTileMinY);
		if (!floorTileData) {
			LigaMap.console.warn('floorTileData with id "'+id+'" not found');
			return null;
		}
		if (floorTileData.visibleTileCount < 1) {
			return null;
		}
		var origMinX = floorTileData.origMinX;
		var origMinY = floorTileData.origMinY;
		if (doExpensiveCalculations) {
			// connectFloorTiles(floorTileData);	// unnötig, wegen fillFloorTiles()
			// fillFloorTiles(config, floorTileAtlasTilesSizeLookup, floorTileData);
			// pushFloorTiles(config, floorTileAtlasTilesSizeLookup, floorTileData, 0.5);

			growFloorTileBranches(config, floorTileAtlasTilesSizeLookup, floorTileData);
			if (config.floorTileGeneration.branchCreationFillHolesActive) {
				fillFloorTiles(config, floorTileAtlasTilesSizeLookup, floorTileData);
			}
			for (var i=0; i<config.floorTileGeneration.branchCreationPushIterations; i++) {
				pushFloorTiles(config, floorTileAtlasTilesSizeLookup, floorTileData, config.floorTileGeneration.branchCreationPushPossibility);
			}
		}

		// leere Stellen mit DensityMap besetzen (debug)
		if (config.floorTileGeneration.densityShowDebug) {
			var possibleTiles = floorTileAtlasTilesSizeLookup["1x1"];
			var ftx, fty, d;
			for (ftx=0; ftx<floorTileData.floorTileCountX; ftx+=2) {
				for (fty=0; fty<floorTileData.floorTileCountY; fty+=2) {
					if (floorTileData.floortiles[ftx][fty] == undefined) {
						d = floorTileData.floortilesdensity[ftx][fty];// - 1.0;
						d = Math.max(0, Math.min(1.0, d));
						// if (d > Math.random()) {
						// Debug-Output
						if (d > 0.001) {
							self.floorTiles.addFloorTile(config, floorTileData, ftx, fty, possibleTiles[0], 0, [(d>0.333)? 1.0 : 0.0, 0.5, (d>0.666)? 1.0 : 0.0], 0);
						}
					}
				}
			}
		}

		// split?
		var floorTileDataSplitted = [floorTileData];
		//if (floorTileData.floorTileMinX == 29998.125 && floorTileData.floorTileMinY == 0) {
		floorTileDataSplitted = splitFloorTileData(config, floorTileData, config.floorTileGeneration.subdivideForQuadtree);
		// floorTileDataSplitted = floorTileDataSplitted.concat(splitFloorTileData(config, floorTileData, splitIntoQuadIterations));
		if (id == 'floortest') {
			// LigaMap.console.log(floorTileData);
			LigaMap.console.log(floorTileDataSplitted);
		}

		var returnData = Array();
		for (var i in floorTileDataSplitted) {
			floorTileData = floorTileDataSplitted[i];
			// Boden-Kachel-Objekte erstellen
			// TODO: Planes nach Kachel-Definition erstellen nicht nach Raster in Quadrate gestückelt
			var geometry = new THREE.BufferGeometry();
			var triangleCount = floorTileData.visibleTileCount * 2;
			var vertexCount = floorTileData.visibleTileCount * 4;
			var indices = new Uint32Array( triangleCount * 3 );
			var positions = new Float32Array( vertexCount * 3 );
			var uvs = new Float32Array( vertexCount * 2 );
			var fti = 0;
			var uvPositionsX = [	// "rotierbare" UVs
				0.0+0.5*floorTileData.floorTileTexelWidth,
				1.0/config.floorTileAtlasRasterX-0.5*floorTileData.floorTileTexelWidth,
				1.0/config.floorTileAtlasRasterX-0.5*floorTileData.floorTileTexelWidth,
				0.0+0.5*floorTileData.floorTileTexelWidth
			];
			var uvPositionsY = [	// "rotierbare" UVs
				0.0+0.5*floorTileData.floorTileTexelHeight,
				0.0+0.5*floorTileData.floorTileTexelHeight,
				1.0/config.floorTileAtlasRasterY-0.5*floorTileData.floorTileTexelHeight,
				1.0/config.floorTileAtlasRasterY-0.5*floorTileData.floorTileTexelHeight
			];
			for (var ftx=0; ftx<floorTileData.floorTileCountX; ftx++) {
				for (var fty=0; fty<floorTileData.floorTileCountY; fty++) {
					if (floorTileData.floortiles[ftx][fty] !== undefined) {	// has a tile
						var tileNum = floorTileData.floortiles[ftx][fty];
						var uvOffsetX = tileNum % config.floorTileAtlasRasterY;
						var uvOffsetY = Math.floor(tileNum / config.floorTileAtlasRasterY);
						uvOffsetX /= config.floorTileAtlasRasterX;
						uvOffsetY /= config.floorTileAtlasRasterY;
						var rot = (floorTileData.floortilesrot[ftx][fty]===undefined)? 0 : Math.max(0, Math.min(3, floorTileData.floortilesrot[ftx][fty]));//Math.floor(Math.random()*4);	// Rotation: rot * 90°
						// LigaMap.console.log(uvOffsetX.toFixed(2), uvOffsetY.toFixed(2));
						// vert1
						positions[fti*12+0] = (ftx+0)*config.floorTileSize;
						positions[fti*12+1] = (fty+0)*config.floorTileSize;
						positions[fti*12+2] = 0.0;
						uvs[fti*8+0] = uvOffsetX + uvPositionsX[(0+rot)%4];
						uvs[fti*8+1] = uvOffsetY + uvPositionsY[(0+rot)%4];
						// vert2
						positions[fti*12+3] = (ftx+1)*config.floorTileSize;
						positions[fti*12+4] = (fty+0)*config.floorTileSize;
						positions[fti*12+5] = 0.0;
						uvs[fti*8+2] = uvOffsetX + uvPositionsX[(1+rot)%4];
						uvs[fti*8+3] = uvOffsetY + uvPositionsY[(1+rot)%4];
						// vert3
						positions[fti*12+6] = (ftx+1)*config.floorTileSize;
						positions[fti*12+7] = (fty+1)*config.floorTileSize;
						positions[fti*12+8] = 0.0;
						uvs[fti*8+4] = uvOffsetX + uvPositionsX[(2+rot)%4];
						uvs[fti*8+5] = uvOffsetY + uvPositionsY[(2+rot)%4];
						// vert4
						positions[fti*12+9] = (ftx+0)*config.floorTileSize;
						positions[fti*12+10] = (fty+1)*config.floorTileSize;
						positions[fti*12+11] = 0.0;
						uvs[fti*8+6] = uvOffsetX + uvPositionsX[(3+rot)%4];
						uvs[fti*8+7] = uvOffsetY + uvPositionsY[(3+rot)%4];
						// index
						indices[fti*6+0] = fti*4+0;
						indices[fti*6+1] = fti*4+1;
						indices[fti*6+2] = fti*4+2;
						indices[fti*6+3] = fti*4+0;
						indices[fti*6+4] = fti*4+2;
						indices[fti*6+5] = fti*4+3;

						fti++;
					}
				}
			}
			returnData.push({
				origMinX: origMinX,
				origMinY: origMinY,
				indices:indices,
				positions:positions,
				uvs:uvs,
				floorTileMinX:floorTileData.floorTileMinX,
				floorTileMaxX:floorTileData.floorTileMaxX,
				floorTileMinY:floorTileData.floorTileMinY,
				floorTileMaxY:floorTileData.floorTileMaxY,
				position:[floorTileData.floorTileMinX-1.0*config.floorTileSize, floorTileData.floorTileMinY-1.0*config.floorTileSize, 0],
				buildings:floorTileData.buildings,
			});
		}
		delete currentFloorTileData[id];
		// LigaMap.console.log(currentFloorTileData);
//		console.log("getFloorTileObject", returnData);
		return returnData;
	};

	var createEmptyFloorTileData = function(config, floorTileTextureWidth, floorTileTextureHeight, origMinX, origMaxX, origMinY, origMaxY) {
		// LigaMap.console.log("createEmptyFloorTileData()", floorTileTextureWidth, floorTileTextureHeight, origMinX, origMaxX, origMinY, origMaxY);
		var floorTileMinX = Math.floor(origMinX/config.floorTileSize) * config.floorTileSize;	// Quantize Min/Max
		var floorTileMinY = Math.floor(origMinY/config.floorTileSize) * config.floorTileSize;
		var floorTileMaxX = (Math.floor(origMaxX/config.floorTileSize) + 5) * config.floorTileSize;
		var floorTileMaxY = (Math.floor(origMaxY/config.floorTileSize) + 5) * config.floorTileSize;
		var floorTileCountX = Math.floor((floorTileMaxX-floorTileMinX)/config.floorTileSize);
		var floorTileCountY = Math.floor((floorTileMaxY-floorTileMinY)/config.floorTileSize);
		// LigaMap.console.log("Floor Tiles: ", floorTileCountX,"x",floorTileCountY, origMinX+"..."+origMaxX, " -> ", floorTileMinX+"..."+floorTileMaxX);
		var floorTileTexelWidth = 1.0/Math.max(1, floorTileTextureWidth);	// prevent bleeding across tile edges
		var floorTileTexelHeight = 1.0/Math.max(1, floorTileTextureHeight);	// prevent bleeding across tile edges
		// var maxFloorTileId = config.floorTileAtlasRasterX*config.floorTileAtlasRasterY;
		var floortiles = new Array(floorTileCountX);
		var floortilesrot = new Array(floorTileCountX);
		var floortilesdensity = new Array(floorTileCountX);
		for (var ftx=0; ftx<floorTileCountX; ftx++) {
			floortiles[ftx] = new Array(floorTileCountY);
			floortilesrot[ftx] = new Array(floorTileCountY);
			floortilesdensity[ftx] = new Array(floorTileCountY);
		}
		return {
			origMinX: origMinX,
			origMinY: origMinY,
			floorTileMinX: floorTileMinX,
			floorTileMinY: floorTileMinY,
			floorTileMaxX: floorTileMaxX,
			floorTileMaxY: floorTileMaxY,
			floorTileCountX: floorTileCountX,
			floorTileCountY: floorTileCountY,
			floorTileTexelWidth: floorTileTexelWidth,
			floorTileTexelHeight: floorTileTexelHeight,
			// maxFloorTileId: maxFloorTileId,
			floortiles: floortiles,
			floortilesrot: floortilesrot,
			floortilesdensity: floortilesdensity,
			visibleTileCount: 0,
			forcedBranchPositions: [],
			buildings: {}
		};
	};

	self.floorTiles.buildFloorTileData = function(config, id, floorTileTextureWidth, floorTileTextureHeight, origMinX, origMaxX, origMinY, origMaxY) {
		// LigaMap.console.log("buildFloorTileData()", id, floorTileTextureWidth, floorTileTextureHeight, origMinX, origMaxX, origMinY, origMaxY);
		var floorTileData = createEmptyFloorTileData(config, floorTileTextureWidth, floorTileTextureHeight, origMinX, origMaxX, origMinY, origMaxY);
		// Debug Boden-Kacheln
		/*var t;
		for (var ftx=0; ftx<floorTileCountX; ftx++) {
			for (var fty=0; fty<floorTileCountY; fty++) if ((ftx+fty)%8 == 0){
				// t = (fty*floorTileCountX+ftx) % (maxFloorTileId+1);
				// t = Math.max(0, Math.ceil(Math.random()*5*maxFloorTileId) - 4*maxFloorTileId);
				t = 24+Math.round(Math.random()*2);
				self.floorTiles.addFloorTile(config, floorTileData, ftx, fty, t, 0);
			}
		}*/
		currentFloorTileData[id] = floorTileData;
		// LigaMap.console.log("buildFloorTileData()", floorTileData);
		return true;
	};

	self.floortest = function() {
		var config = LigaMap.getDefaultConfig();
		var floorTileAtlasTilesSizeLookup = self.mesh.getFloorTileAtlasTilesSizeLookup()
		self.floorTiles.buildFloorTileData(config, 'floortest', 512, 512, 10, 50, 10, 50);
		var possibleTiles = floorTileAtlasTilesSizeLookup["1x1"];
		self.floorTiles.addFloorTile(config, currentFloorTileData['floortest'], 8, 8, possibleTiles[0], 0);
		// LigaMap.console.log(currentFloorTileData['floortest']);
		var object = self.floorTiles.getFloorTileObject(config, 'floortest', floorTileAtlasTilesSizeLookup, false);
		return object;
	};


	return self;
}(LigaMap || {}));
