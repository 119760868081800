/**
 * Copyright Christian Kubitza
 * christian@ck3d.de
 * 2015-2018
 */

var LigaMap = (function(self) {
	"use strict";

	self.shader = self.shader || {};



	var fadeInFragmentPars =
		"#ifdef USE_FADEIN\n"+
			"uniform float fadeInStartTime;\n"+
			"uniform float currentTime;\n"+
			"uniform float quadtreeFade;\n"+
		"#endif\n";



	var getFadeInFragment = function(objectFadeInDuration) {
		return "#ifdef USE_FADEIN\n"+
					"gl_FragColor.a *= quadtreeFade*smoothstep(fadeInStartTime, fadeInStartTime+"+(objectFadeInDuration*1000.0).toFixed(1)+", currentTime);\n"+
					// "gl_FragColor.a *= smoothstep(fadeInStartTime, fadeInStartTime+"+(objectFadeInDuration*1000.0).toFixed(1)+", currentTime);\n"+
					// "gl_FragColor.r = quadtreeFade;\n"+
				"#endif\n";
	};



	var getFogVertexPars = function() {
		return "varying float vViewZDepth;\n";
	};
	
	var getFogVertex = function() {
		return "#include <begin_vertex>\n"+
				"#include <project_vertex>\n"+
				"vViewZDepth = - mvPosition.z;\n";
	};
	
	var getFogFragmentPars = function(fogStartFactor, minZoom, maxZoom) {
		return "uniform float fogFactor;\n"+
		"uniform vec2 resolution;\n"+
		"varying float vViewZDepth;\n"+
		"const float vignetteRadius = 1.6;\n"+
		"const float vignetteSoftness = 1.6;\n"+
		"const vec3 colorFar1 = vec3(217.0/255.0, 220.0/255.0, 255.0/255.0);\n"+	// 0.0:		217, 220, 255
		"const float posFar1 = 0.0;\n"+
		"const vec3 colorFar2 = vec3(111.0/255.0, 151.0/255.0, 212.0/255.0);\n"+	// 0.77:	111, 151, 212
		"const float posFar2 = 0.77;\n"+
		"const vec3 colorFar3 = vec3(126.0/255.0, 177.0/255.0, 217.0/255.0);\n"+	// 0.81:	126, 177, 217
		"const float posFar3 = 0.81;\n"+
		"const vec3 colorFar4 = vec3(25.0/255.0, 27.0/255.0, 57.0/255.0);\n"+		// 1.0:		25, 27, 57
		"const float posFar4 = 1.0;\n"+
		"const vec3 colorNear1 = vec3(217.0/255.0, 220.0/255.0, 255.0/255.0);\n"+	// 0.44:	217, 220, 255
		"const float posNear1 = 0.44;\n"+
		"const vec3 colorNear2 = vec3(154.0/255.0, 197.0/255.0, 255.0/255.0);\n"+	// 0.77:	154, 197, 255
		"const float posNear2 = 1.0;\n"+
		"const float fogMultiplier = 1.0;\n"+
		"vec3 mixInFog(in vec4 coord, in vec3 color) {\n"+
			"float mappedDepth = smoothstep("+fogStartFactor.toFixed(2)+", 1.0, coord.z);\n"+
			"float mappedDepthReal = smoothstep("+minZoom.toFixed(1)+", "+maxZoom.toFixed(1)+", vViewZDepth);\n"+
			"float fogValue = mappedDepth*mappedDepth;\n"+
			"float fogValueReal = mappedDepthReal*mappedDepthReal;\n"+

			"vec2 position;\n"+
			"position.x = ((coord.x / resolution.x) - 0.5) * (resolution.x/resolution.y);\n"+
			"position.y = (coord.y / resolution.y) - 0.5;\n"+
			"float len = length(position);\n"+
			"float vignette = 0.5-smoothstep(vignetteRadius, vignetteRadius-vignetteSoftness, len);\n"+
			"float fogVignetteValue = max(0.0, min(1.0, fogValue + 0.2*vignette));\n"+
			// "fogVignetteValue = mix(fogValue, vignette, 0.5);\n"+

			"vec3 colorFarMix1 = mix(colorFar1, colorFar2, smoothstep(posFar1, posFar2, fogVignetteValue));\n"+
			"vec3 colorFarMix2 = mix(colorFarMix1, colorFar3, smoothstep(posFar2, posFar3, fogVignetteValue));\n"+
			"vec3 colorFarMix3 = mix(colorFarMix2, colorFar4, smoothstep(posFar3, posFar4, fogVignetteValue));\n"+

			"vec3 colorNearMix1 = mix(colorNear1, colorNear2, smoothstep(posNear1, posNear2, fogVignetteValue));\n"+

			"vec3 fogColor = mix(colorNearMix1, colorFarMix3, smoothstep(0.0, 1.0, mappedDepthReal));\n"+
			// "fogColor *= vignette;\n"+

//			"return vec3(mappedDepthReal);\n"+
			"return mix(color, fogColor, 0.7*fogMultiplier*fogFactor*fogVignetteValue);\n"+
			// "return vec3(gl_FragCoord.x/resolution.x, gl_FragCoord.y/resolution.y, gl_FragCoord.z);\n"+
			// "return color*(1.0-fogFactor)+fogFactor*vec3(mappedDepthReal);\n"+	// Show Depth-Buffer
		"}\n";
	};



	var getCustomLightFragment = function(shadowStrength) {	// lights_template.glsl mit Schatten-Intensität
		return "GeometricContext geometry;\n"+
			"geometry.position = - vViewPosition;\n"+
			"geometry.normal = normal;\n"+
			"geometry.viewDir = normalize( vViewPosition );\n"+
			"IncidentLight directLight;\n"+
			"#if ( NUM_POINT_LIGHTS > 0 ) && defined( RE_Direct )\n"+
				"PointLight pointLight;\n"+
				"for ( int i = 0; i < NUM_POINT_LIGHTS; i ++ ) {\n"+
					"pointLight = pointLights[ i ];\n"+
					"getPointDirectLightIrradiance( pointLight, geometry, directLight );\n"+
					"#ifdef USE_SHADOWMAP\n"+
					"directLight.color *= all( bvec2( pointLight.shadow, directLight.visible ) ) ? 0.5+0.5*getPointShadow( pointShadowMap[ i ], pointLight.shadowMapSize, pointLight.shadowBias, pointLight.shadowRadius, vPointShadowCoord[ i ] ) : 1.0;\n"+
					"#endif\n"+
					"RE_Direct( directLight, geometry, material, reflectedLight );\n"+
				"}\n"+
			"#endif\n"+
			"#if ( NUM_SPOT_LIGHTS > 0 ) && defined( RE_Direct )\n"+
				"SpotLight spotLight;\n"+
				"for ( int i = 0; i < NUM_SPOT_LIGHTS; i ++ ) {\n"+
					"spotLight = spotLights[ i ];\n"+
					"getSpotDirectLightIrradiance( spotLight, geometry, directLight );\n"+
					"#ifdef USE_SHADOWMAP\n"+
					"directLight.color *= all( bvec2( spotLight.shadow, directLight.visible ) ) ? 0.5+0.5*getShadow( spotShadowMap[ i ], spotLight.shadowMapSize, spotLight.shadowBias, spotLight.shadowRadius, vSpotShadowCoord[ i ] ) : 1.0;\n"+
					"#endif\n"+
					"RE_Direct( directLight, geometry, material, reflectedLight );\n"+
				"}\n"+
			"#endif\n"+
			"#if ( NUM_DIR_LIGHTS > 0 ) && defined( RE_Direct )\n"+
				"DirectionalLight directionalLight;\n"+
				"for ( int i = 0; i < NUM_DIR_LIGHTS; i ++ ) {\n"+
					"directionalLight = directionalLights[ i ];\n"+
					"getDirectionalDirectLightIrradiance( directionalLight, geometry, directLight );\n"+
					"#ifdef USE_SHADOWMAP\n"+
					"directLight.color *= all( bvec2( directionalLight.shadow, directLight.visible ) ) ? "+(1.0-shadowStrength).toFixed(1)+"+"+shadowStrength.toFixed(1)+"*getShadow( directionalShadowMap[ i ], directionalLight.shadowMapSize, directionalLight.shadowBias, directionalLight.shadowRadius, vDirectionalShadowCoord[ i ] ) : 1.0;\n"+
					"#endif\n"+
					"RE_Direct( directLight, geometry, material, reflectedLight );\n"+
				"}\n"+
			"#endif\n"+
			"#if defined( RE_IndirectDiffuse )\n"+
				"vec3 irradiance = getAmbientLightIrradiance( ambientLightColor );\n"+
				"#ifdef USE_LIGHTMAP\n"+
					"vec3 lightMapIrradiance = texture2D( lightMap, vUv2 ).xyz * lightMapIntensity;\n"+
					"#ifndef PHYSICALLY_CORRECT_LIGHTS\n"+
						"lightMapIrradiance *= PI; // factor of PI should not be present; included here to prevent breakage\n"+
					"#endif\n"+
					"irradiance += lightMapIrradiance;\n"+
				"#endif\n"+
				"#if ( NUM_HEMI_LIGHTS > 0 )\n"+
					"for ( int i = 0; i < NUM_HEMI_LIGHTS; i ++ ) {\n"+
						"irradiance += getHemisphereLightIrradiance( hemisphereLights[ i ], geometry );\n"+
					"}\n"+
				"#endif\n"+
				"#if defined( USE_ENVMAP ) && defined( PHYSICAL ) && defined( ENVMAP_TYPE_CUBE_UV )\n"+
					// TODO, replace 8 with the real maxMIPLevel
				 	"irradiance += getLightProbeIndirectIrradiance( /*lightProbe,*/ geometry, 8 );\n"+
				"#endif\n"+
				"RE_IndirectDiffuse( irradiance, geometry, material, reflectedLight );\n"+
			"#endif\n"+
			"#if defined( USE_ENVMAP ) && defined( RE_IndirectSpecular )\n"+
				// TODO, replace 8 with the real maxMIPLevel
				"vec3 radiance = getLightProbeIndirectRadiance( /*specularLightProbe,*/ geometry, Material_BlinnShininessExponent( material ), 8 );\n"+
				"#ifndef STANDARD\n"+
					"vec3 clearCoatRadiance = getLightProbeIndirectRadiance( /*specularLightProbe,*/ geometry, Material_ClearCoat_BlinnShininessExponent( material ), 8 );\n"+
				"#else\n"+
					"vec3 clearCoatRadiance = vec3( 0.0 );\n"+
				"#endif\n"+
				"RE_IndirectSpecular( radiance, clearCoatRadiance, geometry, material, reflectedLight );\n"+
			"#endif";
	}



	self.shader.getFillShaderVertex = function() {
		return "precision highp float;\n"+
			"varying vec2 vUv;\n"+
			"varying vec2 pUv;\n"+
			getFogVertexPars()+
    		"void main() {\n"+
				getFogVertex()+
        		"vUv = uv;\n"+
				"pUv = position.xy*0.12;\n"+
				"gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);\n"+
			"}";
	}

	self.shader.getFillShaderFragment = function(fogStartFactor, minZoom, maxZoom, objectFadeInDuration) {
		return "precision highp float;\n"+
			"uniform vec3 color;\n"+
			"uniform float layerAlpha;\n"+
			"uniform float layerMaxAlpha;\n"+
			"uniform sampler2D map;\n"+
			"varying vec2 vUv;\n"+
			"varying vec2 pUv;\n"+

			"#ifdef USE_DETAILMAP\n"+
				"uniform sampler2D detailTex;\n"+
			"#endif\n"+

			getFogFragmentPars(fogStartFactor, minZoom, maxZoom)+
			fadeInFragmentPars+
            "void main(){\n"+
				// "vec4 fragColor = vec4(1.0-z, n, n, 1.0);\n"+
				"vec4 fragColor = vec4(color, 1.0);\n"+
				"#ifdef USE_MAP\n"+
					"fragColor = texture2D( map, vUv );\n"+
				"#endif\n"+
				"#ifdef USE_DETAILMAP\n"+
					"vec4 detailColor = texture2D( detailTex, pUv );\n"+
					"float z = smoothstep(70.0, 200.0, gl_FragCoord.z/gl_FragCoord.w);\n"+
					"float detailStrength = 0.5;\n"+
					// "gl_FragColor = vec4( mixInFog(gl_FragCoord.z, fragColor.rgb*(1.0+(detailStrength*(detailColor.r-0.5)*(1.0-z)))), fragColor.a*layerAlpha*layerMaxAlpha );\n"+
					"gl_FragColor = vec4( mixInFog(gl_FragCoord, fragColor.rgb + (detailStrength*(detailColor.r-0.5)*(1.0-z))), fragColor.a*layerAlpha*layerMaxAlpha );\n"+
				"#else\n"+
					"gl_FragColor = vec4( mixInFog(gl_FragCoord, fragColor.rgb), fragColor.a*layerAlpha*layerMaxAlpha );\n"+
				"#endif\n"+
				getFadeInFragment(objectFadeInDuration)+
            "}";
    };

	self.shader.getFloorShaderVertex = function() {
		return "precision highp float;\n"+
			getFogVertexPars()+
			"varying vec2 vUv;\n"+
    		"void main() {\n"+
				getFogVertex()+
        		"vUv = uv;\n"+
				"gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);\n"+
			"}";
	}

	self.shader.getFloorShaderFragment = function(fogStartFactor, minZoom, maxZoom, objectFadeInDuration, fadeGreyValue) {
		return "precision highp float;\n"+
			"uniform vec3 color;\n"+
			"uniform float layerAlpha;\n"+
			"uniform float layerMaxAlpha;\n"+
			"uniform float colorFadeStrength;\n"+
			"uniform sampler2D map;\n"+
			"varying vec2 vUv;\n"+

			"#ifdef USE_DETAILMAP\n"+
				"uniform sampler2D detailTex;\n"+
			"#endif\n"+

			getFogFragmentPars(fogStartFactor, minZoom, maxZoom)+
			fadeInFragmentPars+
            "void main(){\n"+
				"vec4 fragColor = vec4(color, 1.0);\n"+
				"#ifdef USE_MAP\n"+
					"fragColor = texture2D( map, vUv );\n"+
				"#endif\n"+
				"fragColor = vec4(mix(vec3("+fadeGreyValue.toFixed(1)+"), fragColor.rgb, colorFadeStrength), fragColor.a);\n"+
				"gl_FragColor = vec4( mixInFog(gl_FragCoord, fragColor.rgb), fragColor.a*layerAlpha*layerMaxAlpha );\n"+
				getFadeInFragment(objectFadeInDuration)+
            "}";
    };



	self.shader.getHeatmapShaderVertex = function() {
		return "precision highp float;\n"+
			getFogVertexPars()+
			"varying vec2 vUv;\n"+
    		"void main() {\n"+
				getFogVertex()+
        		"vUv = uv;\n"+
				"gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);\n"+
			"}";
	};

	self.shader.getHeatmapShaderFragment = function(fogStartFactor, minZoom, maxZoom, objectFadeInDuration) {
		return "precision highp float;\n"+
			"uniform vec3 color;\n"+
			"uniform float layerAlpha;\n"+
			"uniform float layerMaxAlpha;\n"+
			"uniform sampler2D map;\n"+
			"varying vec2 vUv;\n"+
			"uniform int heatmapSteps;\n"+
			"uniform float heatmapGreyMin;\n"+
			"uniform float heatmapGreyMax;\n"+
			"uniform float heatmapThresh;\n"+
			"uniform float heatmapWidth;\n"+
			"uniform float heatmapHeight;\n"+
			getFogFragmentPars(fogStartFactor, minZoom, maxZoom)+
			fadeInFragmentPars+
			"float getInterpValue(in vec2 tuv){\n"+
				"float cu = floor(heatmapWidth * tuv.x);\n"+
				"float cv = floor(heatmapHeight * tuv.y);\n"+
				"float uMin = cu/heatmapWidth;\n"+
				"float vMin = cv/heatmapHeight;\n"+
				"float uMax = min(1.0, (cu+1.0)/heatmapWidth);\n"+
				"float vMax = min(1.0, (cv+1.0)/heatmapHeight);\n"+
				"float cTL = texture2D( map, vec2(uMin, vMin) ).r;\n"+
				"float cTR = texture2D( map, vec2(uMax, vMin) ).r;\n"+
				"float cBL = texture2D( map, vec2(uMin, vMax) ).r;\n"+
				"float cBR = texture2D( map, vec2(uMax, vMax) ).r;\n"+
				"float greaterThreshold = max(sign(cTL - heatmapThresh), 0.0);\n"+
				"cTL = mix(0.0, cTL, greaterThreshold);\n"+
				"greaterThreshold = max(sign(cTR - heatmapThresh), 0.0);\n"+
				"cTR = mix(0.0, cTR, greaterThreshold);\n"+
				"greaterThreshold = max(sign(cBL - heatmapThresh), 0.0);\n"+
				"cBL = mix(0.0, cBL, greaterThreshold);\n"+
				"greaterThreshold = max(sign(cBR - heatmapThresh), 0.0);\n"+
				"cBR = mix(0.0, cBR, greaterThreshold);\n"+
				"float aH = (tuv.x - uMin) * heatmapWidth;\n"+
				"float aV = (tuv.y - vMin) * heatmapHeight;\n"+
				// TODO: per texelFetch funktioniert nicht
				// "int uMin = int(floor(heatmapWidth * tuv.x));\n"+
				// "int vMin = int(floor(heatmapHeight * tuv.y));\n"+
				// "int uMax = int(ceil(heatmapWidth * tuv.x));\n"+
				// "int vMax = int(ceil(heatmapHeight * tuv.y));\n"+
				// "vec4 temp = texelFetch( map, ivec2(0, 0), 0 );\n"+//texelFetch( map, ivec2(uMin, vMin) );\n"+
				// "vec4 cTL = vec4(0.0);\n"+//texelFetch( map, ivec2(uMin, vMin) );\n"+
				// "vec4 cTR = vec4(0.0);\n"+//texelFetch( map, ivec2(uMax, vMin) );\n"+
				// "vec4 cBL = vec4(0.0);\n"+//texelFetch( map, ivec2(uMin, vMax) );\n"+
				// "vec4 cBR = vec4(0.0);\n"+//texelFetch( map, ivec2(uMax, vMax) );\n"+
				// "float aH = tuv.x * heatmapWidth - float(uMin);\n"+
				// "float aV = tuv.y * heatmapHeight - float(vMin);\n"+
				"return mix(mix(cTL, cTR, aH), mix(cBL, cBR, aH), aV);\n"+
			"}\n"+
            "void main(){\n"+
				"float hmValue = getInterpValue(vUv);\n"+
				"float steps_ = float(heatmapSteps) + 1.0;\n"+
				"float opacityStep = 1.0 / steps_;\n"+
				"float norm = floor(1.0 + hmValue * float(heatmapSteps));\n"+
				"float opacity = opacityStep * norm;\n"+
				"float fragValue = norm / steps_;\n"+
				"float grey = (float(heatmapGreyMin) + fragValue * float(heatmapGreyMax - heatmapGreyMin));\n"+
				// filter out low opacity (< 1*opacityStep)
				"float greaterThreshold = max(sign(opacity - opacityStep), 0.0);\n"+
				"opacity = mix(0.0, opacity, greaterThreshold);\n"+
				"vec4 fragColor = vec4(color.r*grey, color.g*grey, color.b*grey, opacity);\n"+
				"gl_FragColor = vec4( mixInFog(gl_FragCoord, fragColor.rgb), fragColor.a*layerAlpha*layerMaxAlpha );\n"+
				// "gl_FragColor = vec4(hmValue, hmValue, hmValue, 1.0);\n"+
				getFadeInFragment(objectFadeInDuration)+
            "}";
    };



	self.shader.getDotShaderVertex = function() {
		return "precision highp float;\n"+
			getFogVertexPars()+
			"attribute vec3 offset;\n"+
			"uniform float dotScale;\n"+
			"varying vec2 vUv;\n"+
			"varying float dotScalePow;\n"+
			"void main() {\n"+
				getFogVertex()+
				"vec3 vPosition = position;\n"+
				"vUv = uv;\n"+
				"gl_Position = projectionMatrix * modelViewMatrix * vec4( offset + vPosition, 1.0 );\n"+
				"dotScalePow = dotScale*dotScale;\n"+
			"}\n";
	};

	self.shader.getDotShaderFragment = function(fogStartFactor, minZoom, maxZoom, objectFadeInDuration) {
		return "precision highp float;\n"+
			"uniform vec3 color;\n"+
			"uniform float layerAlpha;\n"+
			"uniform float layerMaxAlpha;\n"+
			"uniform float dotSharpness;\n"+
			"varying float dotScalePow;\n"+
			"varying vec2 vUv;\n"+
			getFogFragmentPars(fogStartFactor, minZoom, maxZoom)+
			fadeInFragmentPars+
			"void main() {\n"+
				"float ux = 2.0*(vUv.x-0.5);\n"+
				"float uy = 2.0*(vUv.y-0.5);\n"+
				"float uvCenterDist = ux*ux + uy*uy;\n"+
				"float t = max(0.0, min(1.0, (dotScalePow-uvCenterDist)*(dotSharpness/dotScalePow)));\n"+
				// "gl_FragColor = vec4(vUv.x, vUv.y, dotScalePow, t);\n"+
				"gl_FragColor = vec4( mixInFog(gl_FragCoord, color), t*layerAlpha*layerMaxAlpha );\n"+
				getFadeInFragment(objectFadeInDuration)+
			"}\n";
    };



	self.shader.getOutlineShaderVertex = function() {
        return "precision highp float;\n"+
			getFogVertexPars()+
            "uniform float offset;\n"+
			"#ifdef USE_SHADING\n"+
				"attribute vec3 pushNormal;\n"+
				"varying vec3 vertexNormal;\n"+
				"varying vec3 lightNormal;\n"+
			"#endif\n"+
			"#ifdef USE_ZOOMFADE\n"+
	            "uniform float zoomValue;\n"+
	            "uniform float zoomMinValue;\n"+
	            "uniform float zoomMaxValue;\n"+
				"varying float zoomValueAlpha;\n"+
			"#endif\n"+
			"#ifdef USE_MAP\n"+
				"varying vec2 vUv;\n"+
				"#if defined(MAP_DIRECTION_1) || defined(MAP_DIRECTION_3)\n"+
					"varying vec2 vUv2;\n"+
				"#endif\n"+
				"#if defined(MAP_DIRECTION_2) || defined(MAP_DIRECTION_3)\n"+
					"varying vec2 vUv3;\n"+
				"#endif\n"+
				"uniform float currentTimeDilated;\n"+
				"uniform float mapVSpeed;\n"+
				"uniform float mapUSize;\n"+
				"uniform float mapUStartLine;\n"+
				"uniform float mapUStartArrow;\n"+
				"uniform float uvOffset;\n"+
				"uniform float isZoomingAlpha;\n"+
			"#endif\n"+
			"void main() {\n"+
				getFogVertex()+
				"#ifdef USE_SHADING\n"+
					"vec4 pos = modelViewMatrix * vec4( position + pushNormal * offset, 1.0 );\n"+
					"vertexNormal = normalize((modelMatrix * vec4(normal, 0.0)).xyz);\n"+
					"lightNormal = normalize(vec3(-1, -1, 1));\n"+
				"#else\n"+
					"vec4 pos = modelViewMatrix * vec4( position + normal * offset, 1.0 );\n"+
				"#endif\n"+
				"gl_Position = projectionMatrix * pos;\n"+
				"#ifdef USE_ZOOMFADE\n"+
					"zoomValueAlpha = smoothstep(zoomMinValue, zoomMaxValue, zoomValue);\n"+
				"#endif\n"+
				"#ifdef USE_MAP\n"+
					//"vUv = vec2(uv.x, uv.y*(1.0+0.5*zoomValue)*0.001);\n"+
					"vUv = vec2(mapUStartLine + uv.x*mapUSize, uv.y/(1.0+20.0*offset));\n"+
					"#if defined(MAP_DIRECTION_1) || defined(MAP_DIRECTION_3)\n"+
						"vUv2 = vec2(mapUStartArrow + uv.x*mapUSize, uv.y/(1.0+20.0*uvOffset) - mapVSpeed*currentTimeDilated);\n"+
					"#endif\n"+
					"#if defined(MAP_DIRECTION_2) || defined(MAP_DIRECTION_3)\n"+
						"vUv3 = vec2(mapUStartArrow + uv.x*mapUSize, -1.0*uv.y/(1.0+20.0*uvOffset) - mapVSpeed*currentTimeDilated);\n"+
					"#endif\n"+
//					"vUv = uv;\n"+
				"#endif\n"+
            "}";
	};

	self.shader.getOutlineShaderFragment = function(fogStartFactor, minZoom, maxZoom, objectFadeInDuration) {
        return "precision highp float;\n"+
			"uniform vec3 color;\n"+
			"uniform float layerAlpha;\n"+
			"uniform float layerMaxAlpha;\n"+
			"#ifdef USE_SHADING\n"+
				"varying vec3 vertexNormal;\n"+
				"varying vec3 lightNormal;\n"+
			"#endif\n"+
			"#ifdef USE_ZOOMFADE\n"+
				"varying float zoomValueAlpha;\n"+
			"#endif\n"+
			"#ifdef USE_MAP\n"+
				"uniform sampler2D map;\n"+
				"varying vec2 vUv;\n"+
				"#if defined(MAP_DIRECTION_1) || defined(MAP_DIRECTION_3)\n"+
					"varying vec2 vUv2;\n"+
				"#endif\n"+
				"#if defined(MAP_DIRECTION_2) || defined(MAP_DIRECTION_3)\n"+
					"varying vec2 vUv3;\n"+
				"#endif\n"+
				"uniform float isZoomingAlpha;\n"+
			"#endif\n"+
			getFogFragmentPars(fogStartFactor, minZoom, maxZoom)+
			fadeInFragmentPars+
            "void main(){\n"+
				"float light = 1.0;\n"+
				"#ifdef USE_SHADING\n"+
					"float diffuseDot = dot(vertexNormal, lightNormal);\n"+
	        		"light = clamp(diffuseDot, 0.0, 1.0);\n"+
				"#endif\n"+
				"float alpha = layerAlpha*layerMaxAlpha;\n"+
				"#ifdef USE_ZOOMFADE\n"+
					"alpha *= zoomValueAlpha;\n"+
				"#endif\n"+
				"vec4 fragColor = vec4(1.0, 1.0, 1.0, 1.0);\n"+
				"#ifdef USE_MAP\n"+
					"fragColor = texture2D( map, vUv );\n"+
//					"fragColor.r = vUv.y;\n"+
//					"fragColor.a = max(fragColor.a, 0.5);\n"+
					"#if defined(MAP_DIRECTION_1) || defined(MAP_DIRECTION_3)\n"+
						"vec4 secondFragColor = texture2D( map, vUv2 );\n"+
						"fragColor = mix(fragColor, secondFragColor, isZoomingAlpha*secondFragColor.a);\n"+
					"#endif\n"+
					"#if defined(MAP_DIRECTION_2) || defined(MAP_DIRECTION_3)\n"+
						"vec4 thirdFragColor = texture2D( map, vUv3 );\n"+
						"fragColor = mix(fragColor, thirdFragColor, isZoomingAlpha*thirdFragColor.a);\n"+
					"#endif\n"+
				"#endif\n"+
				"gl_FragColor = vec4( mixInFog(gl_FragCoord, color*fragColor.rgb * (0.75+0.25*light)), alpha*fragColor.a );\n"+
				getFadeInFragment(objectFadeInDuration)+
            "}"
    };



	self.shader.getStadiumDepthShaderVertex = function() {
		return "attribute vec3 offset;\n"+
			"attribute vec4 orientation;\n"+
			"varying vec2 vUV;\n"+
			"void main() {\n"+
				"vUV = uv;\n"+
				"vec3 transformed = vec3( position );\n"+

				"vec3 vPosition = transformed;\n"+
				"vec3 vcV = cross(orientation.xyz, vPosition);\n"+
				"transformed = vcV * (2.0 * orientation.w) + (cross(orientation.xyz, vcV) * 2.0 + vPosition);\n"+
				"transformed += offset;\n"+

				"vec4 mvPosition = modelViewMatrix * vec4( transformed, 1.0 );\n"+
				"gl_Position = projectionMatrix * mvPosition;\n"+
			"}"
		};

	self.shader.getStadiumDepthShaderFragment = function() {
		return "#include <packing>\n"+
			"uniform sampler2D alphaMap;\n"+
			"varying vec2 vUV;\n"+
			"void main() {\n"+
				"vec4 pixel = texture2D( alphaMap, vUV );\n"+
				"if ( pixel.a < 0.6 ) discard;\n"+								// alphatest: 0.6
				"gl_FragData[ 0 ] = packDepthToRGBA( gl_FragCoord.z );\n"+
			"}";
	};

	self.shader.getStadiumShaderVertex = function() {
		return "precision highp float;\n"+
			"attribute vec3 offset;\n"+
			"attribute vec4 orientation;\n"+
			"attribute vec4 userColors;\n"+
			"varying vec4 cUserColor;\n"+
			"#ifdef USE_MASK2\n"+
				"attribute vec4 userColors2;\n"+
				"varying vec4 cUserColor2;\n"+
			"#endif\n"+

			"#define PHONG\n"+
			"varying vec3 vViewPosition;\n"+
			"#ifndef FLAT_SHADED\n"+
				"varying vec3 vNormal;\n"+
			"#endif\n"+
			"#include <common>\n"+
			"#include <uv_pars_vertex>\n"+
			"#include <uv2_pars_vertex>\n"+
			"#include <displacementmap_pars_vertex>\n"+
			"#include <envmap_pars_vertex>\n"+
			"#include <color_pars_vertex>\n"+
			"#include <morphtarget_pars_vertex>\n"+
			"#include <skinning_pars_vertex>\n"+
			"#include <shadowmap_pars_vertex>\n"+
			"#include <logdepthbuf_pars_vertex>\n"+
			"#include <clipping_planes_pars_vertex>\n"+
			getFogVertexPars()+
			"void main() {\n"+
				"#include <uv_vertex>\n"+
				"#include <uv2_vertex>\n"+
				"#include <color_vertex>\n"+
				"#include <beginnormal_vertex>\n"+

				// "#ifdef USE_NORMALFIX\n"+	// vertauschte Normalen-Koordinaten bei JSON-Format
					// "objectNormal = vec3( objectNormal.x, -objectNormal.z, objectNormal.y );\n"+
				// "#endif\n"+

				"#include <morphnormal_vertex>\n"+
				"#include <skinbase_vertex>\n"+
				"#include <skinnormal_vertex>\n"+

				"vec3 vcVn = cross(orientation.xyz, objectNormal);\n"+
				"objectNormal = vcVn * (2.0 * orientation.w) + (cross(orientation.xyz, vcVn) * 2.0 + objectNormal);\n"+

				"#include <defaultnormal_vertex>\n"+
			"#ifndef FLAT_SHADED // Normal computed with derivatives when FLAT_SHADED\n"+
				"vNormal = normalize( transformedNormal );\n"+
			"#endif\n"+
				"#include <begin_vertex>\n"+
				"#include <displacementmap_vertex>\n"+
				"#include <morphtarget_vertex>\n"+
				"#include <skinning_vertex>\n"+

				"vec3 vPosition = transformed;\n"+
				"vec3 vcV = cross(orientation.xyz, vPosition);\n"+
				"transformed = vcV * (2.0 * orientation.w) + (cross(orientation.xyz, vcV) * 2.0 + vPosition);\n"+
				"transformed += offset;\n"+
				"cUserColor = userColors;\n"+
				"#ifdef USE_MASK2\n"+
					"cUserColor2 = userColors2;\n"+
				"#endif\n"+

				"#include <project_vertex>\n"+
				"#include <logdepthbuf_vertex>\n"+
				"#include <clipping_planes_vertex>\n"+
				"vViewPosition = - mvPosition.xyz;\n"+
				"#include <worldpos_vertex>\n"+
				"#include <envmap_vertex>\n"+
				"#include <shadowmap_vertex>\n"+
			"}";
	};

	self.shader.getStadiumShaderFragment = function(fogStartFactor, minZoom, maxZoom, objectFadeInDuration, shadowStrength) {
		return "precision highp float;\n"+
			// "uniform vec3 userColor;\n"+
			"uniform sampler2D userColorMask;\n"+
			"#ifdef USE_MASK2\n"+
				"uniform sampler2D userColorMask2;\n"+
				"varying vec4 cUserColor2;\n"+
			"#endif\n"+
			"uniform float layerAlpha;\n"+
			"uniform float layerMaxAlpha;\n"+
			"varying vec4 cUserColor;\n"+
			"const float aoMapBrightness = 1.75;\n"+

			"#define PHONG\n"+
			"uniform vec3 diffuse;\n"+
			"uniform vec3 emissive;\n"+
			"uniform vec3 specular;\n"+
			"uniform float shininess;\n"+
			"uniform float opacity;\n"+
			"#include <common>\n"+
			"#include <packing>\n"+
			"#include <color_pars_fragment>\n"+
			"#include <uv_pars_fragment>\n"+
			"#include <uv2_pars_fragment>\n"+
			"#include <map_pars_fragment>\n"+
			"#include <alphamap_pars_fragment>\n"+
			"#include <aomap_pars_fragment>\n"+
			"#include <lightmap_pars_fragment>\n"+
			"#include <emissivemap_pars_fragment>\n"+
			"#include <envmap_pars_fragment>\n"+
			"#include <fog_pars_fragment>\n"+
			"#include <bsdfs>\n"+
			"#include <lights_pars>\n"+
			"#include <lights_phong_pars_fragment>\n"+
			"#include <shadowmap_pars_fragment>\n"+
			"#include <bumpmap_pars_fragment>\n"+
			"#include <normalmap_pars_fragment>\n"+
			"#include <specularmap_pars_fragment>\n"+
			"#include <logdepthbuf_pars_fragment>\n"+
			"#include <clipping_planes_pars_fragment>\n"+
			getFogFragmentPars(fogStartFactor, minZoom, maxZoom)+
			fadeInFragmentPars+
			"void main() {\n"+
				"#include <clipping_planes_fragment>\n"+
				"vec4 diffuseColor = vec4( diffuse, opacity );\n"+
				"ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );\n"+
				"vec3 totalEmissiveRadiance = emissive;\n"+
				"#include <logdepthbuf_fragment>\n"+

				"#ifndef IS_SHADOW\n"+
				"#include <map_fragment>\n"+
				"#endif\n"+

				"#include <color_fragment>\n"+

				"#include <alphamap_fragment>\n"+
				"#include <alphatest_fragment>\n"+
				"#include <specularmap_fragment>\n"+
				"#include <normal_flip>\n"+
				"#include <normal_fragment>\n"+
				"#include <emissivemap_fragment>\n"+
				"// accumulation\n"+
				"#include <lights_phong_fragment>\n"+
				// "#include <lights_template>\n"+
				getCustomLightFragment(shadowStrength)+
				"// modulation\n"+
				// "#include <aomap_fragment>\n"+
				"vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular + totalEmissiveRadiance;\n"+
				"#include <envmap_fragment>\n"+
				"gl_FragColor = vec4( outgoingLight, diffuseColor.a );\n"+
				"#include <premultiplied_alpha_fragment>\n"+
				"#include <tonemapping_fragment>\n"+
				"#include <encodings_fragment>\n"+
				// "#include <fog_fragment>\n"+

				"#ifdef USE_MAP\n"+
					"vec3 userColorMaskValue = texture2D( userColorMask, vUv ).rgb;\n"+
					"float diffuseGrayValue = (gl_FragColor.r + gl_FragColor.g + gl_FragColor.b)/3.0;\n"+
					"gl_FragColor.rgb = userColorMaskValue * (vec3(diffuseGrayValue"+/*"*3.0"+*/") * cUserColor.rgb) + (vec3(1.0) - userColorMaskValue) * gl_FragColor.rgb;\n"+
					"#ifdef USE_MASK2\n"+
						"vec3 userColorMaskValue2 = texture2D( userColorMask2, vUv ).rgb;\n"+
						"gl_FragColor.rgb = userColorMaskValue2 * (vec3(diffuseGrayValue) * cUserColor2.rgb) + (vec3(1.0) - userColorMaskValue2) * gl_FragColor.rgb;\n"+
					"#endif\n"+
					"gl_FragColor.rgb = cUserColor.a * gl_FragColor.rgb + (1.0-cUserColor.a) * vec3(1.0);\n"+	// zu "Geist" blenden
					"gl_FragColor.a *= cUserColor.a;\n"+
				"#else\n"+
					"gl_FragColor.rgba *= cUserColor.rgba;\n"+	// ohne Map immer userColor nehmen (Buildings, aber auch Schatten (kein Problem, da fest auf schwarz))
				"#endif\n"+

				"float ambientOcclusion = 1.0;\n"+
				"#ifdef USE_AOMAP\n"+
					"ambientOcclusion = ( aoMapBrightness*texture2D( aoMap, vUv2 ).r - 1.0 ) * aoMapIntensity + 1.0;\n"+
				"#endif\n"+
				"#ifdef IS_SHADOW\n"+
					"vec4 texelColor = texture2D( map, vec2((vUv.x+cUserColor.r)*cUserColor.b, (vUv.y+cUserColor.g)*cUserColor.b) );\n"+
					"texelColor = mapTexelToLinear( texelColor );\n"+
					"gl_FragColor = vec4( mixInFog(gl_FragCoord, vec3(0, 0, 0)), texelColor.r*layerAlpha*layerMaxAlpha );\n"+
				"#else\n"+
					"gl_FragColor = vec4( mixInFog(gl_FragCoord, gl_FragColor.rgb*ambientOcclusion), gl_FragColor.a*layerAlpha*layerMaxAlpha );\n"+
				"#endif\n"+

				// "gl_FragColor = vec4( packNormalToRGB( vNormal ), gl_FragColor.a*layerAlpha*layerMaxAlpha );\n"+

				getFadeInFragment(objectFadeInDuration)+
				// "gl_FragColor = vec4( vNormal.xyz, gl_FragColor.a*layerAlpha*layerMaxAlpha );\n"+

			"}";
	};

	self.shader.getLabelShaderVertex = function() {
		return "precision highp float;\n"+
			"varying vec2 vUv;\n"+
    		"void main() {\n"+
        		"vUv = uv;\n"+
				"gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);\n"+
			"}";
	}

	self.shader.getLabelShaderFragment = function() {
		return "precision highp float;\n"+
			"uniform vec3 color;\n"+
			"uniform float alpha;\n"+
			"uniform sampler2D texture;\n"+
			"varying vec2 vUv;\n"+
            "void main(){\n"+
				"vec4 fragColor = texture2D( texture, vUv );\n"+
				"gl_FragColor = vec4( fragColor.rgb, fragColor.a*alpha );\n"+
            "}";
    };

	return self;
}(LigaMap || {}));
