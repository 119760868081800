/**
 * Copyright Christian Kubitza
 * christian@ck3d.de
 * 2015-2018
 */

var LigaMap = (function(self) {
	"use strict";

	self.mesh = self.mesh || {};

	var floorTileAtlasTilesSizeLookup = {};
	var floorTileTexture = null, detailTexture = null, connectionTexture = null;
	var stadiumObjects = [];
	var workerFloorTiles = null;

	self.mesh.addingCount = 0;
	self.mesh.loadedStadiumDots = {};
	self.mesh.allAssetsLoaded = false;
	self.mesh.allAssetsLoadingProgress = 0.0;

	self.debugColors = [0xff0000, 0x00ff00, 0x0000ff, 0xff00ff, 0xffff00, 0x00ffff, 0x880000, 0x008800, 0x000088, 0x880088, 0x888800, 0x008888];


	var instantiateMaps = function(sourceMaterial, destMaterial) {
		// Texturen nicht klonen, sondern instanzieren (klonen funktioniert eh nicht)
		for (var ui in sourceMaterial.uniforms) {
			var us = sourceMaterial.uniforms[ui];
			var ud = destMaterial.uniforms[ui];
			if (us.value instanceof THREE.Texture) {
				ud.value = us.value;
			}
		}
	};


	self.mesh.init = function(instanceConfig, getLayers, getLastRenderTimestamp) {
		// Build floorTileAtlasTilesSizeLookup-Table
		for (var i in instanceConfig.floorTileAtlasTiles) {
			var tile = instanceConfig.floorTileAtlasTiles[i];
			var index = tile.w+"x"+tile.h;
			if (tile.p != undefined) {
				index = tile.p;
			}
			if (!floorTileAtlasTilesSizeLookup[index]) {
				floorTileAtlasTilesSizeLookup[index] = Array();
			}
			floorTileAtlasTilesSizeLookup[index].push(i);
		}
		// LigaMap.console.log(floorTileAtlasTilesSizeLookup);

		// init webworker
		if (instanceConfig.useWebworker && instanceConfig.generateFloortiles) {
			workerFloorTiles = new Worker(instanceConfig.webWorkerPaths.FloorTiles.script);
			workerFloorTiles.onmessage = function(e) {
//				 LigaMap.console.log('Message received from worker', e.data);
				// LigaMap.console.time("workerFloorTiles.onmessage "+a);
				var a = e.data.action;
				var d = e.data.data;
				var origMinX = e.data.origMinX;
				var origMinY = e.data.origMinY;
				if (a == 'getFloorTileObject') {
					addFloorTileObjects(instanceConfig, getLayers(), getLastRenderTimestamp, d);
				}
				// LigaMap.console.timeEnd("workerFloorTiles.onmessage "+a);
			};
			workerFloorTiles.onerror = function(e) {
				LigaMap.console.error("worker onerror:", e.data);
			};
			if ((instanceConfig.webWorkerPaths.FloorTiles.includes != undefined) && (instanceConfig.webWorkerPaths.FloorTiles.includes.length != undefined) && (instanceConfig.webWorkerPaths.FloorTiles.includes.length > 0)) {
				workerFloorTiles.postMessage({action: "includes", params: instanceConfig.webWorkerPaths.FloorTiles.includes});
			}
		}
	};


	self.mesh.loadStadiums = function(instanceConfig, layers, maxAnisotropy, finishedCallback, updateLoadingCallback) {
		var buildStadiumObject = function(stadiumConfigName, stadiumRealName, bufferGeometry, useNormalFix) {
			// LigaMap.console.log("buildStadiumObject(", stadiumConfigName, stadiumRealName, bufferGeometry, useNormalFix, ")");
			var uniformsToJoin = [];
			for(var key in THREE.UniformsLib) {
				uniformsToJoin.push(THREE.UniformsLib[key]);
			}
			uniformsToJoin.push({
				"diffuse" : { type: "c", value: new THREE.Color( (instanceConfig.stadiumObjects[stadiumConfigName].diffuse != undefined)? instanceConfig.stadiumObjects[stadiumConfigName].diffuse : 0xffffff ) },
				"emissive" : { type: "c", value: new THREE.Color( (instanceConfig.stadiumObjects[stadiumConfigName].emissive != undefined)? instanceConfig.stadiumObjects[stadiumConfigName].emissive : 0x000000 ) },
				"specular" : { type: "c", value: new THREE.Color( 0x000000 ) },
				"shininess": { type: "1f", value: 7 }
			});
			uniformsToJoin.push({
				layerAlpha: {type: "f", value: 0.0},
				layerMaxAlpha: {type: "f", value: (instanceConfig.stadiumObjects[stadiumConfigName].opacity != undefined)? instanceConfig.stadiumObjects[stadiumConfigName].opacity : 1.0},
				fogFactor: {type: "f", value: 1.0},
				resolution: {type: "v2", value: new THREE.Vector2(1.0, 1.0) },
				// userColor: {type: "c", value: new THREE.Color(0xff0000)},
				userColorMask: { type: "t" },
				fadeInStartTime: {type: "f", value: 0.0},
				currentTime: {type: "f", value: 0.0},
				quadtreeFade: {type: "f", value: 1.0}
			});
			// Material
			var shaderPrefix = '';
			if (instanceConfig.stadiumObjects[stadiumConfigName].texture != undefined) shaderPrefix += "#define USE_MAP\n";
			if (instanceConfig.stadiumObjects[stadiumConfigName].normal != undefined) shaderPrefix += "#define USE_NORMALMAP\n";
			if (instanceConfig.stadiumObjects[stadiumConfigName].environment != undefined) {
				shaderPrefix += "#define USE_ENVMAP\n";
				shaderPrefix += "#define ENVMAP_MODE_REFLECTION\n";
				shaderPrefix += "#define ENVMAP_TYPE_SPHERE\n";
				shaderPrefix += "#define ENVMAP_BLENDING_MIX\n";
			}
			if ((typeof instanceConfig.stadiumObjects[stadiumConfigName].shading != 'undefined') && (instanceConfig.stadiumObjects[stadiumConfigName].shading.toLowerCase() == 'flat')) {
				shaderPrefix += "#define FLAT_SHADED\n";
			}
			if (instanceConfig.stadiumObjects[stadiumConfigName].specular != undefined) shaderPrefix += "#define USE_SPECULARMAP\n";
			// if (instanceConfig.stadiumObjects[stadiumConfigName].lightMap != undefined) shaderPrefix += "#define USE_LIGHTMAP\n";
			if (instanceConfig.stadiumObjects[stadiumConfigName].lightMap != undefined) shaderPrefix += "#define USE_AOMAP\n";
			if (instanceConfig.stadiumObjects[stadiumConfigName].isShadowObject == true) shaderPrefix += "#define IS_SHADOW\n";
			if (instanceConfig.stadiumObjects[stadiumConfigName].mask2 != undefined) {
				shaderPrefix += "#define USE_MASK2\n";
				uniformsToJoin.push({
					userColorMask2: { type: "t" },
				});
			}
			shaderPrefix += "#define USE_FADEIN\n";
			if (useNormalFix)	shaderPrefix += "#define USE_NORMALFIX\n";

			var shaderMaterial = new THREE.ShaderMaterial({
				name: 'stadium-'+stadiumRealName,
				uniforms: THREE.UniformsUtils.merge(uniformsToJoin),
				vertexShader: shaderPrefix+LigaMap.shader.getStadiumShaderVertex(),
				fragmentShader: shaderPrefix+LigaMap.shader.getStadiumShaderFragment(instanceConfig.fogStartFactor, instanceConfig.minZoom, instanceConfig.maxZoom, instanceConfig.objectFadeInDuration, instanceConfig.shadowStrength),
				// clipping: true,
				lights: true,
				// depthWrite: false,
				// depthTest: false,
				alphaTest: 0.1,
				// side: THREE.BackSide,
				side: THREE.DoubleSide,	// TODO: temporär
				// shading: THREE.FlatShading,
				transparent: true
			});
			shaderMaterial.extensions.derivatives = true;
			shaderMaterial.extensions.shaderTextureLOD = true;
			// shaderMaterial.transparent = true;
			shaderMaterial.depthTest = shaderMaterial.DepthWrite = (instanceConfig.stadiumObjects[stadiumConfigName].isShadowObject != true);
			// LigaMap.console.log("material", shaderMaterial);
			// Mesh
			var geometry = new THREE.InstancedBufferGeometry();
			geometry.name = stadiumRealName+"-geo";
			// LigaMap.console.log("UV2", bufferGeometry.getAttribute('uv2'));
			// Default Attributes
			geometry.addAttribute( 'position', bufferGeometry.getAttribute('position'));
			if (bufferGeometry.getAttribute('uv') != undefined) {
				geometry.addAttribute( 'uv', bufferGeometry.getAttribute('uv') );
			}
			// geometry.addAttribute( 'uv2', bufferGeometry.getAttribute('uv2')? bufferGeometry.getAttribute('uv2') : bufferGeometry.getAttribute('uv').clone() );
			if (bufferGeometry.getAttribute('uv2') != undefined) {
				geometry.addAttribute( 'uv2', bufferGeometry.getAttribute('uv2'));
			}
			if (bufferGeometry.getAttribute('normal') != undefined) {
				geometry.addAttribute( 'normal', bufferGeometry.getAttribute('normal') );
			}
			// LigaMap.console.log("OBJ Geometry: ", bufferGeometry);
			// geometry.fromGeometry(bufferGeometry);
			var object = new THREE.Mesh(geometry, shaderMaterial);
			object.name = stadiumRealName;
			if (instanceConfig.useRealtimeShadow) {
				object.castShadow = (instanceConfig.stadiumObjects[stadiumConfigName].castShadow == true);
				object.receiveShadow = (instanceConfig.stadiumObjects[stadiumConfigName].receiveShadow == true);
			}
			// LigaMap.console.log("source geo", bufferGeometry, "target geo", geometry);

			var depthMaterial = new THREE.ShaderMaterial({
					name: 'stadiumDepth-'+stadiumRealName,
					vertexShader: LigaMap.shader.getStadiumDepthShaderVertex(),
					fragmentShader: LigaMap.shader.getStadiumDepthShaderFragment(),
					uniforms: shaderMaterial.uniforms,
				});
			depthMaterial.depthPacking = THREE.RGBADepthPacking;
			depthMaterial.clipping = true;
			object.customDepthMaterial = depthMaterial;
			object.userData.stadiumConfigName = stadiumConfigName;
			return object;
		};

		var textures = {};
		var fallBackTexture = '';
		var getTexture = function(name) {
			return textures[name]? textures[name] : textures[fallBackTexture];
		};
		
		var loadFinishedAction = function() {
			var stadiumConfigName;
			for (var oname in stadiumObjects) if (stadiumObjects[oname].geometry) {
				stadiumConfigName = stadiumObjects[oname].userData.stadiumConfigName;
				if (instanceConfig.stadiumObjects[stadiumConfigName].texture != undefined) {
					stadiumObjects[oname].material.uniforms.map.value = getTexture(instanceConfig.stadiumObjects[stadiumConfigName].texture);
					if (stadiumObjects[oname].customDepthMaterial) {
						stadiumObjects[oname].customDepthMaterial.uniforms.alphaMap.value = getTexture(instanceConfig.stadiumObjects[stadiumConfigName].texture);
					}
				}
				if (instanceConfig.stadiumObjects[stadiumConfigName].normal != undefined)
					stadiumObjects[oname].material.uniforms.normalMap.value = getTexture(instanceConfig.stadiumObjects[stadiumConfigName].normal);
				if (instanceConfig.stadiumObjects[stadiumConfigName].specular != undefined)
					stadiumObjects[oname].material.uniforms.specularMap.value = getTexture(instanceConfig.stadiumObjects[stadiumConfigName].specular);
				if (instanceConfig.stadiumObjects[stadiumConfigName].environment != undefined)
					stadiumObjects[oname].material.uniforms.envMap.value = getTexture(instanceConfig.stadiumObjects[stadiumConfigName].environment);
				if (instanceConfig.stadiumObjects[stadiumConfigName].mask != undefined)
					stadiumObjects[oname].material.uniforms.userColorMask.value = getTexture(instanceConfig.stadiumObjects[stadiumConfigName].mask);
				if (instanceConfig.stadiumObjects[stadiumConfigName].mask2 != undefined)
					stadiumObjects[oname].material.uniforms.userColorMask2.value = getTexture(instanceConfig.stadiumObjects[stadiumConfigName].mask2);
				if (instanceConfig.stadiumObjects[stadiumConfigName].lightMap != undefined) {
					stadiumObjects[oname].material.uniforms.aoMap.value = getTexture(instanceConfig.stadiumObjects[stadiumConfigName].lightMap);
					// stadiumObjects[oname].material.uniforms.lightMapIntensity = 1.0;
				}
				if (instanceConfig.preloadMaterials && (typeof(layers['preload'])!=='undefined')) {	// Preload: Materialien
	//				LigaMap.console.log("add preload object:", oname, stadiumObjects[oname].geometry);
					var instances = 1;
					var geometry = (new THREE.InstancedBufferGeometry()).copy(stadiumObjects[oname].geometry);
					// per instance data
					var offsets = new THREE.InstancedBufferAttribute( new Float32Array( instances * 3 ), 3, 1 );
					var orientations = new THREE.InstancedBufferAttribute( new Float32Array( instances * 4 ), 4, 1 );
					var userColors = new THREE.InstancedBufferAttribute( new Float32Array( instances * 3 ), 3, 1 );
					geometry.addAttribute( 'offset', offsets ); // per mesh translation
					geometry.addAttribute( 'orientation', orientations ); // per mesh orientation
					geometry.addAttribute( 'userColors', userColors );
					var material = stadiumObjects[oname].material.clone();
					instantiateMaps(stadiumObjects[oname].material, material);
					var stadiumMesh = new THREE.Mesh(geometry, material);
					stadiumMesh.customDepthMaterial = stadiumObjects[oname].customDepthMaterial;
					if (instanceConfig.useRealtimeShadow) {
						stadiumMesh.castShadow = stadiumObjects[oname].castShadow;
						stadiumMesh.receiveShadow = stadiumObjects[oname].receiveShadow;
					}
					geometry.computeBoundingSphere();
					stadiumMesh.position.x = -100000;
					// LigaMap.console.log(stadiumMesh.position);
					// stadiumMesh.scale.set(100, 100, 100);
					stadiumMesh.matrixAutoUpdate = false;
					stadiumMesh.frustumCulled = false;
					stadiumMesh.updateMatrix();
					stadiumMesh.updateMatrixWorld();
					stadiumMesh.material.uniforms.fadeInStartTime.value = 0;
					stadiumMesh.material.uniforms.currentTime.value = 10*1000;
					// layers[instanceConfig.stadiumObjects[stadiumConfigName].layer].scene.add(stadiumMesh);
					layers['preload'].scene.add(stadiumMesh);
					layers['preload'].visible = true;
					// verzögert entfernen
					// (function(obj, layername) {
					// 	window.setTimeout(function() {
					// 		layers[layername].scene.remove(obj);
					// 	}, 100);
					// }) (stadiumMesh, instanceConfig.stadiumObjects[stadiumConfigName].layer);
				}
			}
			floorTileTexture = getTexture('floorTiles');
			if (instanceConfig.useFloorDetailTexture) {
				detailTexture = getTexture('detail_tex');
				detailTexture.wrapS = THREE.RepeatWrapping;
				detailTexture.wrapT = THREE.RepeatWrapping;
			}

			connectionTexture = getTexture('connection_line');
//			connectionTexture.wrapS = THREE.RepeatWrapping;
			connectionTexture.wrapT = THREE.RepeatWrapping;
			connectionTexture.magFilter = THREE.LinearFilter;
//			connectionTexture.minFilter = THREE.LinearFilter;
//			connectionTexture.minFilter = THREE.NearestMipMapLinearFilter;
			connectionTexture.anisotropy = Math.max(1, Math.min(maxAnisotropy, 4));
//			connectionTexture.generateMipmaps = false;
//			connectionTexture.needsUpdate = true;

			// textures['mufu_ao'].minFilter = THREE.NearestFilter;
			// textures['mufu_ao'].magFilter = THREE.NearestFilter;
			// textures['mufu_ao'].generateMipmaps = false;

			if (instanceConfig.preloadTextures && (typeof(layers['preload'])!=='undefined')) {	// Preload: Texturen in Grafikspeicher vorladen (dazu nur kurz anzeigen)
				var i = 0;
				for (var tname in instanceConfig.stadiumTextureFiles) if (textures[tname]) {
					var object = new THREE.Mesh(new THREE.PlaneGeometry(1, 1), new THREE.MeshBasicMaterial({name:'stadiumTexturePreload-'+tname, map: textures[tname], transparent: true}));
					object.position.x = -100000;
					// object.position.x = i*1000;
					// object.scale.x = object.scale.y = object.scale.z = 1000;
					object.frustumCulled = false;
					object.matrixAutoUpdate = false;
					object.updateMatrix();
					object.updateMatrixWorld();
					layers['preload'].scene.add( object );
					layers['preload'].visible = true;
					// verzögert entfernen
					// (function(obj) {
					// 	window.setTimeout(function() {
					// 		layers['debug'].scene.remove(obj);
					// 	}, 100);
					// }) (object);
					i++;
				}
			}
			self.mesh.allAssetsLoaded = true;
			if (finishedCallback != undefined) {
				finishedCallback();
			}
		};

		var loadingQueue = [];
		var loadingNum = -1;
		var loadedCount = 0;
		var loadNextItem = function() {
			loadingNum++;
			if (loadingQueue[loadingNum] !== undefined) {
				loadingQueue[loadingNum].loadfunction(loadingQueue[loadingNum].loadparam);
				if (updateLoadingCallback != undefined) {	// um evtl. Fehlermeldungen zu entfernen
					updateLoadingCallback({success:true, loaded:loadedCount, total:loadingQueue.length});
				}
				return true;
			}
			return false;
		};
		var itemLoaded = function(item) {
//			LigaMap.console.log("itemLoaded()", item, loadedCount+"/"+loadingQueue.length);
			loadedCount++;
			self.mesh.allAssetsLoadingProgress = (loadingQueue.length>0)? Math.min(1.0, (loadedCount/loadingQueue.length)) : 0.0;
			if (updateLoadingCallback != undefined) {
				updateLoadingCallback({success:true, loaded:loadedCount, total:loadingQueue.length});
			}
			loadNextItem();
			if (loadedCount >= loadingQueue.length) {
				LigaMap.console.log("Finished loading "+loadedCount+" mesh/texture files");
				loadFinishedAction();
			}
		};
		var itemError = function(item) {
			LigaMap.console.warn("itemError()", item, loadedCount+"/"+loadingQueue.length);
			if (updateLoadingCallback != undefined) {
				updateLoadingCallback({success:false, loaded:loadedCount, total:loadingQueue.length});
			}
			// retry
			setTimeout(function() {
				loadingNum--;
				loadNextItem();
			}, instanceConfig.layerRetryFailedLoadDelay);
		};
		// textures
		var usedTextures = {};
		if (instanceConfig.generateFloortiles) {
			usedTextures['floorTiles'] = true;
		}
		if (instanceConfig.useFloorDetailTexture) {
			usedTextures['detail_tex'] = true;
		}
		usedTextures['connection_line'] = true;
		var possibleTextureKeys = Array('environment', 'texture', 'normal', 'specular', 'mask', 'mask2', 'lightMap');
		var tki;
		var useStadium;
		for (var stadiumConfigName in instanceConfig.stadiumObjects) {	// nur benutzte Texturen sollen geladen werden
			useStadium = true;
			if (instanceConfig.stadiumOnlyLoVersions) {
				if (instanceConfig.stadiumObjects[stadiumConfigName].loVersion != undefined) {
					useStadium = false;
				}
				if (stadiumConfigName == 'stadium_floor') {
					useStadium = false;
				}
			}
			if (useStadium && instanceConfig.whitelistStadiumObjects !== false && (instanceConfig.whitelistStadiumObjects.indexOf(stadiumConfigName) < 0)) {
				// not in whitelist
				useStadium = false;
			}
			if (useStadium) {
				for (tki in possibleTextureKeys) {
					if (instanceConfig.stadiumObjects[stadiumConfigName][possibleTextureKeys[tki]]) {
						usedTextures[instanceConfig.stadiumObjects[stadiumConfigName][possibleTextureKeys[tki]]] = true;
					}
				}
			}
		}
		var imageloader = new THREE.ImageLoader();
		var imageName, dotPos;
		var textureLoadFunction = function(tname) {
			imageName = instanceConfig.stadiumTextureFiles[tname];
			if (
					instanceConfig.lowResTextures &&
					(tname != 'connection_line') &&	// no min versions on server...
					(tname != 'floorTiles') &&
					(tname != 'detail_tex')
			) {
				dotPos = imageName.lastIndexOf('.');
				imageName = imageName.substring(0, dotPos) + '_min' + imageName.substring(dotPos);
			}
//			LigaMap.console.log("loading texture:", instanceConfig.assetBasePath+imageName);
			imageloader.load(instanceConfig.assetBasePath+imageName, function(image) {
				textures[tname].image = image;
				textures[tname].needsUpdate = true;
				fallBackTexture = tname;
				itemLoaded(instanceConfig.assetBasePath+imageName);
			}, undefined, itemError );
		};
		// Test: Texturanzahl begrenzen
		var textureCountLimit = -1;
		var textureCount = 0;
		if (location.search.toLowerCase().indexOf('limittexturecount=')>=0) {
			var spos = location.search.toLowerCase().indexOf('limittexturecount=');
			textureCountLimit = Math.max(1, parseInt(location.search.substr(spos+('limittexturecount=').length), 10));	// min. 1
		}
		for (var tname in instanceConfig.stadiumTextureFiles) if (usedTextures[tname]) {	
			if ((textureCountLimit < 0) || (textureCount < textureCountLimit)) {
				textures[tname] = new THREE.Texture();
				loadingQueue.push({loadparam:tname, loadfunction:textureLoadFunction});
			} else {
				LigaMap.console.log("skip loading texture:", tname);
			}
			textureCount++;
		}
		// models
		var loader = new THREE.OBJLoader();
		var jsonloader = new THREE.JSONLoader();
		var objectloader = new THREE.ObjectLoader();
		var meshLoadFunction = function(stadiumConfigName) {
			var url = instanceConfig.assetBasePath+instanceConfig.stadiumObjects[stadiumConfigName].objFile;
//			console.log("loading mesh:", url);
			if (instanceConfig.stadiumObjects[stadiumConfigName].objFile.substr(-12).toLowerCase() == '.object.json') {
				// LigaMap.console.log("load json(object)");
				objectloader.load(url, function(loadedScene) {
					var childfound = false;
					loadedScene.traverse( function( child ) {
						if (!childfound && (child instanceof THREE.Mesh)) {
							var stadiumName = stadiumConfigName;
							if (instanceConfig.stadiumObjects[stadiumConfigName].isCollection) {
								stadiumName = stadiumConfigName+'-'+child.name;
							} else {
								childfound = true;
							}
							// LigaMap.console.log("jsonobject geometry: ", child.name);
							var geometry = new THREE.InstancedBufferGeometry();
							geometry.fromGeometry(child.geometry);
							stadiumObjects[stadiumName] = buildStadiumObject(stadiumConfigName, stadiumName, geometry, false);
						}
					});
					itemLoaded(url);
				}, undefined, itemError);
			} else if (instanceConfig.stadiumObjects[stadiumConfigName].objFile.substr(-5).toLowerCase() == '.json') {
				// LigaMap.console.log("load json");
				jsonloader.load(url, function(jsongeometry, jsonmaterials) {
					var geometry = new THREE.InstancedBufferGeometry();
					geometry.fromGeometry(jsongeometry);
					// LigaMap.console.log(jsongeometry, geometry);
					stadiumObjects[stadiumConfigName] = buildStadiumObject(stadiumConfigName, stadiumConfigName, geometry, true);
					itemLoaded(url);
				}, undefined, itemError);
			} else {
				// LigaMap.console.log("load obj");
				loader.load(url, function(object) {
					var childfound = false;
					object.traverse( function( child ) {
						// LigaMap.console.log("obj traverse", child);
						if (!childfound && (child instanceof THREE.Mesh)) {
							// LigaMap.console.log("is mesh, append to ", stadiumConfigName);
							stadiumObjects[stadiumConfigName] = buildStadiumObject(stadiumConfigName, stadiumConfigName, child.geometry, false);
							childfound = true;
						}
					});
					itemLoaded(url);
				}, undefined, itemError);
			}
		};
		// Test: Objektanzahl begrenzen
		var objectCountLimit = -1;
		var objectCount = 0;
		if (location.search.toLowerCase().indexOf('limitobjectcount=')>=0) {
			var spos = location.search.toLowerCase().indexOf('limitobjectcount=');
			objectCountLimit = Math.max(1, parseInt(location.search.substr(spos+('limitobjectcount=').length), 10));	// min. 1
		}
		for (var stadiumConfigName in instanceConfig.stadiumObjects) {
			if ((objectCountLimit < 0) || (objectCount < objectCountLimit)) {
				if (!instanceConfig.generateFloortileBuildings && (stadiumConfigName == "stadium_buildings")) {
					continue;
				}
				if (instanceConfig.stadiumOnlyLoVersions && instanceConfig.stadiumObjects[stadiumConfigName].isShadowObject) {	// skip shadow object when only lo stadiums
					continue;
				}
				if (instanceConfig.whitelistStadiumObjects !== false && (instanceConfig.whitelistStadiumObjects.indexOf(stadiumConfigName) < 0)) {
//					LigaMap.console.log("skip loading object (not in whitelist):", stadiumConfigName);
					// not in whitelist
					continue;
				}
				if (!instanceConfig.stadiumOnlyLoVersions || (instanceConfig.stadiumObjects[stadiumConfigName].loVersion == undefined)) {				
					loadingQueue.push({loadparam:stadiumConfigName, loadfunction:meshLoadFunction});
				} else {
					stadiumObjects[stadiumConfigName] = {userData:{stadiumConfigName: stadiumConfigName}};
				}
			} else {
				LigaMap.console.log("skip loading object:", stadiumConfigName);
			}
			objectCount++;
		}
		// start loading queue
		loadNextItem();
	};


	self.mesh.buildConnections = function(instanceConfig, layers, getLastRenderTimestamp) {
		if (typeof(layers['connections']) === 'undefined') return;
		// clear old connections
		layers['connections'].clear();
		if (typeof(layers['cnnctns-lb']) !== 'undefined') layers['cnnctns-lb'].clear();

		var protoMaterial = new THREE.ShaderMaterial({
			name: 'connection',
			uniforms: {
				map: {type: "t", value: connectionTexture},
				offset: {type: "f", value: 1000.0},
				layerAlpha: {type: "f", value: 0.0},
				layerMaxAlpha: {type: "f", value: 1.0},
				fogFactor: {type: "f", value: 1.0},
				resolution: {type: "v2", value: new THREE.Vector2(1.0, 1.0) },
				color: {type: "c", value: new THREE.Color(0xFFFFFF)},
				fadeInStartTime: {type: "f", value: getLastRenderTimestamp()},
				currentTime: {type: "f", value: getLastRenderTimestamp()},
				currentTimeDilated: {type: "f", value: getLastRenderTimestamp(true)},
				quadtreeFade: {type: "f", value: 1.0},
//				zoomValue: {type: "f", value: 1.0},
//				zoomMinValue: {type: "f", value: -1.0},
//				zoomMaxValue: {type: "f", value: 0.0},
				mapVSpeed: {type: "f", value: instanceConfig.connectionMeshVSpeed},
				mapUSize: {type: "f", value: 0.125},
				mapUStartLine: {type: "f", value: 0.25-0.125*0.5},
				mapUStartArrow: {type: "f", value: 0.75-0.125*0.5},
				uvOffset: {type: "f", value: 1000.0},
				isZoomingAlpha: {type: "f", value: 1.0}
			},
			vertexShader: "#define USE_SHADING\n"/*+"#define USE_ZOOMFADE\n"*/+"#define USE_MAP\n"+LigaMap.shader.getOutlineShaderVertex(),
			fragmentShader: /*"#define USE_SHADING\n"+*/"#define USE_FADEIN\n"/*+"#define USE_ZOOMFADE\n"*/+"#define USE_MAP\n"+LigaMap.shader.getOutlineShaderFragment(instanceConfig.fogStartFactor, instanceConfig.minZoom, instanceConfig.maxZoom, instanceConfig.objectFadeInDuration),
			depthWrite: false,
			depthTest: false,
			side: THREE.FrontSide,
			transparent: true,
			shading: THREE.FlatShading
		});			

		var objectsToAddInOrder = [[],[]];
		
		var addConnectionObject = function(addOrderIndex, currentX, currentY, lastX, lastY, material, userData) {
			var lineRadius = instanceConfig.connectionLineRadius;
			var pushStrength = instanceConfig.connectionLineRadiusDistancePushStrength;
			var dx = currentX-lastX;
			var dy = currentY-lastY;
			var length = Math.sqrt(dx*dx+dy*dy);
			var startRadius = 0;
			var lengthReduced = length - 2*startRadius;
			var angle = Math.atan2(dy, dx);
			var correctedVScale = instanceConfig.connectionMeshVScale/pushStrength;
			var u1 = 0.5 - 0.5*instanceConfig.connectionMeshUScale;
			var u2 = 0.5 + 0.5*instanceConfig.connectionMeshUScale;

			var geometry = new THREE.BufferGeometry();
			var positionArray = [], uvArray = [], normalArray = [], pushNormalArray = [];
			var fillArrays = function(maxIndex, currentIndex) {
				if (!currentIndex) {
					currentIndex = 1;
				}
				var l = lengthReduced / maxIndex;
				var o = (currentIndex-1) * l;
				var p1 = -0.5*lengthReduced + o;
				var p2 = p1 + l;
				positionArray = positionArray.concat([
					lineRadius, p2, 0,
					-lineRadius,  p2, 0,
					-lineRadius, p1, 0,
					-lineRadius, p1, 0,
					lineRadius, p1, 0,
					lineRadius, p2, 0
				]);
				var vp1 = o*correctedVScale;
				var vp2 = vp1 + l*correctedVScale;
				uvArray = uvArray.concat([
					u2, vp2,
					u1, vp2,
					u1, vp1,
					u1, vp1,
					u2, vp1,
					u2, vp2
				]);
				normalArray = normalArray.concat([
					0, 0, 1,
					0, 0, 1,
					0, 0, 1,
					0, 0, 1,
					0, 0, 1,
					0, 0, 1
				]);
				pushNormalArray = pushNormalArray.concat([
					pushStrength,	0,	0,
					-pushStrength,	0,	0,
					-pushStrength,	0,	0,
					-pushStrength,	0,	0,
					pushStrength,	0,	0,
					pushStrength,	0,	0
				]);
				if (currentIndex < maxIndex) {
					fillArrays(maxIndex, currentIndex+1);
			}
			};
			var subDivCount = Math.ceil(lengthReduced/instanceConfig.connectionMeshSubdivisionDistance);
			fillArrays(subDivCount);
//			console.log(lengthReduced, positionArray, uvArray);
			geometry.addAttribute( 'position', new THREE.BufferAttribute(new Float32Array(positionArray), 3, 0));
			geometry.addAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(uvArray), 2));
			geometry.addAttribute( 'normal', new THREE.BufferAttribute(new Float32Array(normalArray), 3, 1));
			geometry.addAttribute('pushNormal', new THREE.BufferAttribute(new Float32Array(pushNormalArray), 3, 1));
			var connectionObject = new THREE.Mesh(
				geometry,
				material
			);
			// connectionObject.geometry.computeVertexNormals();
			connectionObject.position.x = (lastX+currentX)*0.5;
			connectionObject.position.y = (lastY+currentY)*0.5;
			connectionObject.position.z = instanceConfig.connectionHeight;
			connectionObject.rotation.z = angle - Math.PI*0.5;
			connectionObject.matrixAutoUpdate = false;
			connectionObject.updateMatrix();
			connectionObject.updateMatrixWorld();
			// ... korrekte Transformation wichtig, für Bounding-Box, siehe unten
			if (userData) {
				connectionObject.userData = userData;
				connectionObject.userData['connectionSegment'] = new THREE.Line3(new THREE.Vector3(lastX, lastY, connectionObject.position.z), new THREE.Vector3(currentX, currentY, connectionObject.position.z));
			}
			if (!connectionObject.userData) {
				connectionObject.userData = {};
			}
			connectionObject.userData['rotationAngle'] = angle - Math.PI*0.5;
			objectsToAddInOrder[addOrderIndex].push(connectionObject);
			return {angle:angle, length:length, object:connectionObject};
		};
		
		var connection, lastX, lastY, lastUID, currentX, currentY, currentUID, connectionInfo, connectionLineMaterial;
		for (var ci in instanceConfig.showConnections) if (instanceConfig.showConnections[ci].positionsX && (instanceConfig.showConnections[ci].positionsX.length > 0)) {
			connection = instanceConfig.showConnections[ci];
			lastX = lastY = lastUID = null;
			connectionLineMaterial = protoMaterial.clone();
			instantiateMaps(protoMaterial, connectionLineMaterial);
			var directionShaderString = "";
			if (connection['direction'] > 0) {
				directionShaderString = "#define MAP_DIRECTION_"+connection['direction']+"\n";
			}
			connectionLineMaterial.vertexShader = directionShaderString+connectionLineMaterial.vertexShader;
			connectionLineMaterial.fragmentShader = directionShaderString+connectionLineMaterial.fragmentShader;
			if (connection.color != undefined) {
				connectionLineMaterial.uniforms.color.value.set(connection.color);
			}
			if (connection.alpha != undefined) {
				connectionLineMaterial.uniforms.layerMaxAlpha.value = connection.alpha;
			}
			connectionLineMaterial.userData = {visible:true};	// nicht im Quadtree, also manuell (wird auch nicht geklont)
			if (connection.selectUID != undefined) {
				// Ziel-UID im Material speichern, um es bei Selektion hervorzuheben
				connectionLineMaterial.userData['selectUID'] = connection.selectUID;
			}
			layers['connections'].usedMaterials.push(connectionLineMaterial);
			for (var pi in connection.positionsX) if (connection.positionsY[pi]) {
				currentX = connection.positionsX[pi];
				currentY = connection.positionsY[pi];
				if ((connection.labels != undefined) && (connection.labels[pi] != undefined) && (connection.labels[pi]['uid'] != undefined)) {
					currentUID = connection.labels[pi]['uid'];
				} else {
					currentUID = null;
				}
				connectionInfo = null;
				if (!connection.labelsonly) {
					if (lastX !== null) {	// connection
						connectionInfo = addConnectionObject(
							1,
							currentX, currentY, lastX, lastY,
							connectionLineMaterial,
							(connection.selectUID != undefined)? {selectUID:connection.selectUID, selectType:connection.selectType} : null
						);
						}
								}
				if ((connection.labels != undefined) && (connection.labels[pi] != undefined) && (connection.labels[pi]['caption'] != undefined) && (connection.labels[pi]['caption'].length > 0)) {
					var labelUserData = {};
					if (connection.labels[pi]['icon']) {
						labelUserData['iconType'] = connection.labels[pi]['icon'];
					}
					if (connectionInfo && connectionInfo.object && (connection.selectUID != undefined)) {
						labelUserData['connectionObject'] = connectionInfo.object;
					}
					var labelObject = addLabelObject(
						instanceConfig, layers, 'cnnctns',
						instanceConfig.sceneScale*currentX,
						instanceConfig.sceneScale*currentY,
						(connection.labels[pi]['uid'])? connection.labels[pi]['uid'] : -pi,
						connection.labels[pi]['caption'],
						LigaMap.layerFixedLabelColors['user-lb'],
						undefined,
						undefined,
						labelUserData,
						true
					);
					if (connectionInfo && connectionInfo.object) {
						connectionInfo.object.userData['labelObject'] = labelObject;
					}
				}
				lastX = currentX;
				lastY = currentY;
				lastUID = currentUID;
			}
		}
		// add objects in order
		var targetLayer, bbox;
		for (var oi in objectsToAddInOrder) {
				targetLayer = 'connections';
			for (var i in objectsToAddInOrder[oi]) {
				layers[targetLayer].scene.add(objectsToAddInOrder[oi][i]);
				objectsToAddInOrder[oi][i].geometry.computeBoundingBox();
				bbox = new THREE.Box3().setFromObject(objectsToAddInOrder[oi][i]);
				objectsToAddInOrder[oi][i].userData['worldBoundingBox'] = bbox;
				if (!layers[targetLayer].quadtree.addObject(
					objectsToAddInOrder[oi][i],
					bbox.min.x,
					bbox.max.x,
					bbox.min.y,
					bbox.max.y
				)) {
						LigaMap.console.log("Verbindung nicht im Quadtree:", bbox.min.x+".."+bbox.max.x, bbox.min.y+".."+bbox.max.y);
					}
				// Debug Min-/Max-Werte
				if (instanceConfig.showBoundingBoxes) {
					var object = new THREE.Mesh( new THREE.PlaneGeometry((bbox.max.x-bbox.min.x), (bbox.max.y-bbox.min.y)), new THREE.MeshBasicMaterial( {name:'boundingBox-fill', color: new THREE.Color(self.debugColors[i%self.debugColors.length]), wireframe: true } ) );
					object.position.x = (bbox.max.x+bbox.min.x)*0.5;
					object.position.y = (bbox.max.y+bbox.min.y)*0.5;
					object.matrixAutoUpdate = false;
					object.updateMatrix();
					object.updateMatrixWorld();
					layers[targetLayer].scene.add(object);
				}
			}
		}
	};


	self.mesh.loadLeagues = function(instanceConfig, layers, getLastRenderTimestamp, finishedCallback, updateLoadingCallback) {
		// LigaMap.console.log("loadLeagues()", instanceConfig.showLeagues);
		if (typeof(layers['league']) === 'undefined') return;
		var dataToAdd = null;
		var readyCounter = 0;
		var finishedReadyCount = 0;
		var checkReadyToAdd = function() {	// erst nachdem alte Daten gelöscht sind, neue hinzufügen
//			LigaMap.console.log("checkReadyToAdd()", readyCounter);
			readyCounter++;
			if (readyCounter >= finishedReadyCount) {
//				LigaMap.console.log("checkReadyToAdd()", "finish");
				LigaMap.mesh.addData(instanceConfig, layers, getLastRenderTimestamp, 'league', 0xff00ff, 1.0, dataToAdd, 0, 0, 0, 0, function() {
					if (finishedCallback != undefined) {
						finishedCallback(dataToAdd.desc);
					}
				}, updateLoadingCallback);
			}
		};
		// Alte löschen
		finishedReadyCount++;
		layers['league'].clearWithFade(checkReadyToAdd);
		
		// gibt es überhaupt etwas zum Anzeigen?
		if (!instanceConfig.showLeagues || !Array.isArray(instanceConfig.showLeagues) || (instanceConfig.showLeagues.length < 1)) {
			LigaMap.console.log("no (array) data in instanceConfig.showLeagues");
			return;
		}
		finishedReadyCount++;
		$.ajax({
			method: "GET",
			url: instanceConfig.ajaxUrl,
			dataType: 'json',
			timeout: 10*1000,
			data: {layer: 'league', ids: instanceConfig.showLeagues.join(',') }
		})
		.done(function( data ) {
//			LigaMap.console.log("loadLeagues() ajax.done: ", data);
			dataToAdd = data;
			checkReadyToAdd();
		});
	};


	self.mesh.getDotObject = function(instanceConfig, getLastRenderTimestamp, dotInfos, color, dotSize) {
		var material = new THREE.ShaderMaterial( {
			name: 'heatmapDots',
			uniforms: {
				layerAlpha: {type: "f", value: 0.0},
				fogFactor: {type: "f", value: 1.0},
				resolution: {type: "v2", value: new THREE.Vector2(1.0, 1.0) },
				color: {type: "c", value: new THREE.Color(color)},
				layerMaxAlpha: {type: "f", value: 1.0},
				dotSharpness: {type: "f", value: dotSize/750},	// Kantenschärfe	(3000/750=4)
				dotScale: {type: "f", value: 0.5},	// Punkt-Größe, wird in Renderschleife aktualisiert
				fadeInStartTime: {type: "f", value: getLastRenderTimestamp()},
				currentTime: {type: "f", value: getLastRenderTimestamp()},
				quadtreeFade: {type: "f", value: 1.0}
			},
			vertexShader: LigaMap.shader.getDotShaderVertex(),
			fragmentShader:	"#define USE_FADEIN\n"+LigaMap.shader.getDotShaderFragment(instanceConfig.fogStartFactor, instanceConfig.minZoom, instanceConfig.maxZoom, instanceConfig.objectFadeInDuration),
			depthWrite: false,
			depthTest: false,
			// side: THREE.FrontSide,
			side: THREE.DoubleSide,	// TODO: temporär
			shading: THREE.FlatShading,
			transparent: true
		} );
		// Plane Geometry
		var geometry = new THREE.InstancedBufferGeometry();
		// per mesh data
		var s = dotSize*0.5;
		var vertices = new THREE.BufferAttribute( new Float32Array( [
			-1*s,    s, 0,
			   s,    s, 0,
			-1*s, -1*s, 0,
			   s, -1*s, 0
		] ), 3 );
		geometry.addAttribute( 'position', vertices );
		var uvs = new THREE.BufferAttribute( new Float32Array( [
			0, 0,
			1, 0,
			0, 1,
			1, 1
		] ), 2 );
		geometry.addAttribute( 'uv', uvs );
		var indices = new Uint16Array( [
			0, 1, 2,
			2, 1, 3
		] );
		geometry.setIndex( new THREE.BufferAttribute( indices, 1 ) );
		// per instance data
		var instances = dotInfos.length;
		var offsets = new THREE.InstancedBufferAttribute( new Float32Array( instances * 3 ), 3, 1 );
		var vector = new THREE.Vector4();
		for ( var i = 0; i < instances; i++ ) {
			offsets.setXYZ(i, dotInfos[i][1], dotInfos[i][2], 0);
		}
		geometry.addAttribute( 'offset', offsets ); // per mesh translation
		var dotObject = new THREE.Mesh(geometry, material);
		dotObject.frustumCulled = false;
		dotObject.matrixAutoUpdate = false;
		dotObject.updateMatrix();
		dotObject.updateMatrixWorld();
		return dotObject;
	};


	var addFloorTileObjects = function(instanceConfig, layers, getLastRenderTimestamp, meshDataArray) {
		if (meshDataArray === null) {
			return;	// nichts zu tun
		}
		var n = 0;
		for (var i in meshDataArray) {
			n++;
			(function(i) {
				window.setTimeout(function() {
					addFloorTileObject(instanceConfig, layers, getLastRenderTimestamp, meshDataArray[i]);
				}, n*25);
			})(i);
		}
	};

	var addFloorTileObject = function(instanceConfig, layers, getLastRenderTimestamp, meshData) {
		if (meshData === null) {
			return;	// nichts zu tun
		}
		var loadedMinX = meshData.origMinX;
		var loadedMinY = meshData.origMinY; 
		// LigaMap.console.log("addFloorTileObject()", meshData);
		// LigaMap.console.time("addFloorTileObject-"+meshData.floorTileMinX+"-"+meshData.floorTileMaxX+"-"+meshData.floorTileMinY+"-"+meshData.floorTileMaxY);

		var addBuildings = function(meshName, transformData, centerX, centerY, boundingSphereRadius, origMinX, origMaxX, origMinY, origMaxY, loadedMinX, loadedMinY) {
			// LigaMap.console.log("addBuildings()", meshName, transformData.length, centerX, centerY, boundingSphereRadius, origMinX, origMaxX, origMinY, origMaxY);
			var object = getStadiumCopy(instanceConfig, layers, meshName, transformData.length, centerX, centerY, boundingSphereRadius, origMinX, origMaxX, origMinY, origMaxY);
			if (object === false) {
				return;
			}
			for (var i in transformData) {
				var bposx = ((transformData[i].x-1)*instanceConfig.floorTileSize-centerX+origMinX)/object.object.scale.x;
				var bposy = ((transformData[i].y-1)*instanceConfig.floorTileSize-centerY+origMinY)/object.object.scale.y;
				object.bufferOffsets.setXYZ(object.count, bposx, bposy, 0);
				var quat = new THREE.Quaternion();
				// transformData[i].r = 0|1|2|3
				quat.setFromEuler(new THREE.Euler(Math.PI*0.5, (1.0-transformData[i].r*0.25) * 2 * Math.PI, 0));
				quat.normalize();
				object.bufferOrientations.setXYZW(object.count, quat.x, quat.y, quat.z, quat.w );
				object.bufferUserColors.setXYZW(object.count, transformData[i].c[0], transformData[i].c[1], transformData[i].c[2], 1.0);
				object.count++;
			}
			if (object.count > 0) {
				// LigaMap.console.log("buildings", object.object.position, object.object.scale, object.bufferOffsets);
				// object.object.material.uniforms.fadeInStartTime.value = 0;
				// object.object.material.uniforms.currentTime.value = lastRenderTimestamp;
				object.object.material.uniforms.fadeInStartTime.value = getLastRenderTimestamp();
				object.object.material.uniforms.currentTime.value = getLastRenderTimestamp();
				var layerName = instanceConfig.stadiumObjects[object.stadiumConfigName].layer;
				if (instanceConfig.stadiumOnlyLoVersions && (layerName == 'stadium')) {
					layerName = 'stadium_lo';
				}
				if (layers['user'].quadtree.addObjectLoaded(object.object, loadedMinX, loadedMinY, layerName)) {	// damit es entladen werden kann
					layers[layerName].scene.add(object.object);
					layers[layerName].usedMaterials.push(object.object.material);
					if (!layers[layerName].quadtree.addObject(object.object, origMinX, origMaxX, origMinY, origMaxY)) {
						LigaMap.console.log("Gebäude nicht im Quadtree:", meshName, origMinX+".."+origMaxX, origMinY+".."+origMaxY);
					}
				}
			}
		};

		var material = new THREE.ShaderMaterial({
			name: 'floorTiles',
			uniforms: {
				layerAlpha: {type: "f", value: 0.0},
				fogFactor: {type: "f", value: 1.0},
				resolution: {type: "v2", value: new THREE.Vector2(1.0, 1.0) },
				color: {type: "c", value: new THREE.Color(0xffffff)},
				// color: {type: "c", value: new THREE.Color(Math.round(Math.random()*0xffffff))},	// Debug
				layerMaxAlpha: {type: "f", value: 1.0},
				map: {type: "t", value: floorTileTexture},
				fadeInStartTime: {type: "f", value: getLastRenderTimestamp()},
				currentTime: {type: "f", value: getLastRenderTimestamp()},
				colorFadeStrength: {type: "f", value: 0.0},
				quadtreeFade: {type: "f", value: 1.0}
			},
			vertexShader: LigaMap.shader.getFloorShaderVertex(),
			fragmentShader: "#define USE_FADEIN\n#define USE_MAP\n"+LigaMap.shader.getFloorShaderFragment(instanceConfig.fogStartFactor, instanceConfig.minZoom, instanceConfig.maxZoom, instanceConfig.objectFadeInDuration, 0.8),
			depthWrite: false,
			depthTest: false,
			// side: THREE.FrontSide,
			// side: THREE.DoubleSide,	// TODO: temporär
			shading: THREE.FlatShading,
			transparent: true
		});
		var geometry = new THREE.BufferGeometry();
		geometry.setIndex( new THREE.BufferAttribute( meshData.indices, 1 ) );
		geometry.addAttribute( 'position', new THREE.BufferAttribute( meshData.positions, 3 ) );
		geometry.addAttribute( 'uv', new THREE.BufferAttribute( meshData.uvs, 2 ) );
		// geometry.computeBoundingSphere();
		// Manuell ist schneller
		geometry.boundingSphere = new THREE.Sphere(new THREE.Vector3(
				(meshData.floorTileMaxX-meshData.floorTileMinX)*0.5,
				(meshData.floorTileMaxY-meshData.floorTileMinY)*0.5,
				0
			),
			0.5*Math.sqrt(Math.pow(meshData.floorTileMaxX-meshData.floorTileMinX, 2) + Math.pow(meshData.floorTileMaxY-meshData.floorTileMinY, 2))
		);
		var mesh = new THREE.Mesh( geometry, material );
		mesh.position.set(meshData.position[0], meshData.position[1], meshData.position[2]);
		mesh.matrixAutoUpdate = false;
		mesh.updateMatrix();
		mesh.updateMatrixWorld();
		mesh.name = 'stadium_floor-'+meshData.floorTileMinX+'-'+meshData.floorTileMinY;
		if (layers['user'].quadtree.addObjectLoaded(mesh, loadedMinX, loadedMinY, 'stadium_floor')) {	// damit es entladen werden kann
			layers['stadium_floor'].scene.add(mesh);
			layers['stadium_floor'].usedMaterials.push(mesh.material);
			if (!layers['stadium_floor'].quadtree.addObject(mesh, meshData.floorTileMinX, meshData.floorTileMaxX, meshData.floorTileMinY, meshData.floorTileMaxY)) {
				LigaMap.console.log("Bodenkachel nicht im Quadtree:", mesh);
			}
		}
		// LigaMap.console.log("kacheln", mesh.position, mesh.scale, meshData.positions);
		var n = 0;
		for (var bn in meshData.buildings) {
			n++;
			(function(n, bn) {
				window.setTimeout(function() {
					addBuildings(
						bn,
						meshData.buildings[bn],
						meshData.position[0] + geometry.boundingSphere.center.x,
						meshData.position[1] + geometry.boundingSphere.center.y,
						geometry.boundingSphere.radius,
						meshData.floorTileMinX,
						meshData.floorTileMaxX,
						meshData.floorTileMinY,
						meshData.floorTileMaxY,
						loadedMinX, loadedMinY
					);
				}, n*25);
			}) (n, bn);
		}
		// LigaMap.console.timeEnd("addFloorTileObject-"+meshData.floorTileMinX+"-"+meshData.floorTileMaxX+"-"+meshData.floorTileMinY+"-"+meshData.floorTileMaxY);
		// LigaMap.console.timeStamp();
	};


	var getStadiumCopy = function(instanceConfig, layers, stadiumName, instancesCount, centerX, centerY, boundingSphereRadius, origMinX, origMaxX, origMinY, origMaxY) {
//		LigaMap.console.log("getStadiumCopy()", stadiumName, instancesCount, centerX, centerY);
		if (!stadiumObjects[stadiumName]) {
//			LigaMap.console.warn(stadiumName,": not found");
			return false;
		}
		if (!stadiumObjects[stadiumName].userData || !stadiumObjects[stadiumName].userData.stadiumConfigName) {
			LigaMap.console.warn(stadiumName,": no stadiumConfigName");
			return false;
		}
		var stadiumConfigName = stadiumObjects[stadiumName].userData.stadiumConfigName;
		if (!stadiumObjects[stadiumName]) {
			LigaMap.console.warn(stadiumName,": not found");
			return false;
		}
		var geometry = (new THREE.InstancedBufferGeometry()).copy(stadiumObjects[stadiumName].geometry);
		// per instance data
		var offsets = new THREE.InstancedBufferAttribute( new Float32Array( instancesCount * 3 ), 3, 1 );
		var orientations = new THREE.InstancedBufferAttribute( new Float32Array( instancesCount * 4 ), 4, 1 );
		var userColors = new THREE.InstancedBufferAttribute( new Float32Array( instancesCount * 4 ), 4, 1 );
		geometry.addAttribute( 'offset', offsets ); // per mesh translation
		geometry.addAttribute( 'orientation', orientations ); // per mesh orientation
		geometry.addAttribute( 'userColors', userColors );
		var userColors2 = null;
		if (typeof instanceConfig.stadiumObjects[stadiumConfigName].mask2 !== "undefined") {
			userColors2 = new THREE.InstancedBufferAttribute( new Float32Array( instancesCount * 4 ), 4, 1 );
			geometry.addAttribute( 'userColors2', userColors2 );
		}

		var material = stadiumObjects[stadiumName].material.clone();
		instantiateMaps(stadiumObjects[stadiumName].material, material);
		// compareObject(stadiumObjects[stadiumName].material, material, "<"+stadiumName+">");

		var stadiumMesh = new THREE.Mesh(geometry, material);
		stadiumMesh.name = "stadium-"+stadiumName;
		stadiumMesh.customDepthMaterial = stadiumObjects[stadiumName].customDepthMaterial;
		if (instanceConfig.useRealtimeShadow) {
			stadiumMesh.castShadow = stadiumObjects[stadiumName].castShadow;
			stadiumMesh.receiveShadow = stadiumObjects[stadiumName].receiveShadow;
		}
		// stadiumMesh.frustumCulled = false;
		geometry.computeBoundingSphere();
		geometry.boundingSphere.radius += boundingSphereRadius / Math.max(0.0001, Math.min(instanceConfig.stadiumScale[0], Math.min(instanceConfig.stadiumScale[1], instanceConfig.stadiumScale[2])));
		stadiumMesh.position.set(centerX, centerY, 0);
		stadiumMesh.scale.set(instanceConfig.stadiumScale[0], instanceConfig.stadiumScale[1], instanceConfig.stadiumScale[2]);
		stadiumMesh.matrixAutoUpdate = false;
		// stadiumMesh.frustumCulled = false;	// Debug
		stadiumMesh.updateMatrix();
		stadiumMesh.updateMatrixWorld();

		return {object: stadiumMesh, count: 0, bufferOffsets: offsets, bufferOrientations: orientations, bufferUserColors: userColors, bufferUserColors2: userColors2, stadiumConfigName: stadiumConfigName};
	};
	
	
	var addLabelObject = function(instanceConfig, layers, layername, x, y, id, name, color, origMinX, origMinY, extraUserData, keepInView) {
//		return {position:{x:x, y:y, z:0}};
//		LigaMap.console.log("Mesh.addLabelObject()", "instanceConfig", "layers", layername, "pos:", [x, y], "id:", id, "name:", name, "color:", color, "origMin:", [origMinX, origMinY], "extraUserData:", extraUserData, "keepInView:", keepInView);
		if (typeof(layers[layername+'-lb']) === 'undefined') return;
		var labelobject = new THREE.Object3D();//getLabelObject(d.name, d['_color']);
		labelobject.position.x = x;
		labelobject.position.y = y;
		labelobject.position.z = 0;
		labelobject.scale.set(4, 4, 4);
		labelobject.matrixAutoUpdate = false;
		labelobject.updateMatrix();
		labelobject.updateMatrixWorld();
		labelobject.name = "label-"+layername+"-"+id+"-"+name;
		labelobject.userData = {
			id: id,
			// name: d.id+": "+d.name,
			name: name,
			color: ((LigaMap.layerFixedLabelColors[layername+'-lb']!=undefined)? LigaMap.layerFixedLabelColors[layername+'-lb'] : color),
			origColor: color,
			randVal: Math.random()
		};
		if (extraUserData !== undefined) {
			for (var i in extraUserData) {
				labelobject.userData[i] = extraUserData[i];
			}
		}
		var minX = labelobject.position.x;
		var maxX = minX;
		var minY = labelobject.position.y;
		var maxY = minY;
		if (keepInView) {
			labelobject.userData['keepInView'] = true;
			minX = instanceConfig.minPanX;
			maxX = instanceConfig.maxPanX;
			minY = instanceConfig.minPanY;
			maxY = instanceConfig.maxPanY;
		}
		if (origMinX !== undefined) {
			if (layers[layername].quadtree.addObjectLoaded(labelobject, origMinX, origMinY, layername+'-lb')) {	// damit es entladen werden kann
				layers[layername+'-lb'].scene.add(labelobject);
				if (!layers[layername+'-lb'].quadtree.addObject(labelobject, minX, maxX, minY, maxY)) {
					LigaMap.console.log("Labelobjekt nicht im Quadtree:", name, minX, maxX, minY, maxY);
				}
			}
		} else {
			layers[layername+'-lb'].scene.add(labelobject);
			if (!layers[layername+'-lb'].quadtree.addObject(labelobject, minX, maxX, minY, maxY)) {
				LigaMap.console.log("Labelobjekt nicht im Quadtree:", name, minX, maxX, minY, maxY);
			}
		}
		return labelobject;
	};



	LigaMap.mesh.addData = function(instanceConfig, layers, getLastRenderTimestamp, layername, borderColor, alpha, data, origMinX, origMaxX, origMinY, origMaxY, finishedCallback, updateLoadingCallback) {
//		 LigaMap.console.log("addData(...", layername, borderColor, alpha, data, origMinX, origMaxX, origMinY, origMaxY, "...)");
		// LigaMap.console.time("addData("+layername+"*"+data['desc'].length+") "+origMinX+", "+origMinY);
		var doAddLabels = (typeof(layers[layername+'-lb']) !== 'undefined');
		var centerX = (origMinX+origMaxX)*0.5;
		var centerY = (origMinY+origMaxY)*0.5;
		var radius = Math.sqrt(Math.pow(origMaxX-origMinX, 2)+Math.pow(origMaxY-origMinY, 2)) * 0.5;
		var stadiums = [];
		var currentID = layername+'-'+origMinX+'-'+origMaxX+'-'+origMinY+'-'+origMaxY;
		// var floorTileData = null;
		var isUserLayer = (layername == 'user')/* && (origMinX==20000) && (origMinY==25000)*/;


		var lastPreparedMeshIndex = null;
		var preparationInstances = null;
		var prepareMeshes = function(finishedCallback) {
			var didFinish = true;
			if (isUserLayer) {
				if (lastPreparedMeshIndex === null) {	// erster Aufruf
					if (workerFloorTiles) {
						workerFloorTiles.postMessage({action: "setCommonParams", params: {
							config: instanceConfig,
							floorTileTextureWidth: floorTileTexture.image.width,
							floorTileTextureHeight: floorTileTexture.image.height,
							floorTileAtlasTilesSizeLookup: floorTileAtlasTilesSizeLookup
						}});
					}
					if (instanceConfig.generateFloortiles) {
						if (workerFloorTiles) {
							workerFloorTiles.postMessage({action: "buildFloorTileData", params: {
								id: currentID,
								origMinX: origMinX,
								origMaxX: origMaxX,
								origMinY: origMinY,
								origMaxY: origMaxY
							}});
						} else {
							LigaMap.floorTiles.buildFloorTileData(instanceConfig, currentID, floorTileTexture.image.width, floorTileTexture.image.height, origMinX, origMaxX, origMinY, origMaxY);
						}
					}
					// Stadien vorbereiten
					preparationInstances = [];
//					var possibleStadiums = [];	// TODO: nicht Zufalls-Stadion, sondern aus DB (s.a.u)
//					for (var n in instanceConfig.stadiumObjects) {
//						if (instanceConfig.stadiumObjects[n].loVersion != undefined) {
//							possibleStadiums.push(n);
//						}
//					}
//					if (possibleStadiums.length < 1) {
//						LigaMap.console.warn("No stadiums available???")
//					}
					if (data['desc']) {
						var subTileCount = Math.pow(2, instanceConfig.performance.stadiumsSubdivideForQuadtree);
						var subTileWidth = (origMaxX-origMinX)/subTileCount;
						var subTileHeight = (origMaxY-origMinY)/subTileCount;
						preparationInstances = Array(subTileCount*subTileCount);
						var subTileIndex, stadiumName, x, y;
						for (var di=0; di<data['desc'].length; di++) {
							// stadiumName = possibleStadiums[Math.round(Math.random()*(possibleStadiums.length-1))];
//							data['desc'][di].stadiumName = possibleStadiums[di % possibleStadiums.length];	// TODO: siehe oben
							// subTileIndex = 1;
							x = instanceConfig.sceneScale*parseFloat(data['desc'][di].labelX);
							y = instanceConfig.sceneScale*parseFloat(data['desc'][di].labelY);
							subTileIndex = Math.floor((y-origMinY)/subTileHeight) * subTileCount + Math.floor((x-origMinX)/subTileWidth);
							data['desc'][di].subTileIndex = subTileIndex;
							if (preparationInstances[subTileIndex] == undefined) {
								preparationInstances[subTileIndex] = [];
							}
							stadiumName = data['desc'][di].stadiumName;
							if (!stadiumObjects[stadiumName] && stadiumObjects["sks_0"]) {
								LigaMap.console.warn("Stadium not loaded, fallback to \"sks_0\":", stadiumName);
								stadiumName = data['desc'][di].stadiumName = "sks_0";
							}
							if (instanceConfig.stadiumObjects[stadiumName] != undefined && instanceConfig.stadiumObjects[stadiumName].loVersion != undefined) {
								if (!instanceConfig.stadiumOnlyLoVersions) {
									if (preparationInstances[subTileIndex][stadiumName] == undefined) {
										preparationInstances[subTileIndex][stadiumName] = 1;
									} else {
										preparationInstances[subTileIndex][stadiumName]++;
									}
								}
								stadiumName = instanceConfig.stadiumObjects[stadiumName].loVersion;
								if (preparationInstances[subTileIndex][stadiumName] == undefined) {
									preparationInstances[subTileIndex][stadiumName] = 1;
								} else {
									preparationInstances[subTileIndex][stadiumName]++;
								}
								// für Schatten wirds auch benötigt
								stadiumName = 'stadium_floor';
								if (preparationInstances[subTileIndex][stadiumName] == undefined) {
									preparationInstances[subTileIndex][stadiumName] = 1;
								} else {
									preparationInstances[subTileIndex][stadiumName]++;
								}
							} else {
								LigaMap.console.warn("Stadium or Lo-Stadium not found: ", stadiumName);
							}
						}
						stadiums =  Array(subTileCount*subTileCount);
					}
				}

				var preparedMeshCountInCall = 0;
				var lastPreparedMeshFound = (lastPreparedMeshIndex === null);
				var si;
				var subTileRadius = radius / (1+instanceConfig.performance.stadiumsSubdivideForQuadtree);
				var subTileCount = Math.pow(2, instanceConfig.performance.stadiumsSubdivideForQuadtree);
				var subTileWidth = (origMaxX-origMinX)/subTileCount;
				var subTileHeight = (origMaxY-origMinY)/subTileCount;
				var stx, sty, stCenterX, stCenterY;
				for (var sti = 0; sti<preparationInstances.length; sti++) if (preparationInstances[sti] != undefined) {
					sty = Math.floor(sti/subTileCount);
					stx = sti-(sty*subTileCount);
					stCenterX = origMinX + (stx+0.5)*subTileWidth;
					stCenterY = origMinY + (sty+0.5)*subTileHeight;
					for (si in preparationInstances[sti]) {
						if (!lastPreparedMeshFound && (lastPreparedMeshIndex !== null)) {	// wiederholter Durchgang: Start suchen
							if (lastPreparedMeshIndex == sti+'/'+si) {	// also beim nächsten weiter
								lastPreparedMeshFound = true;
							}
						} else {
							if (preparedMeshCountInCall < instanceConfig.performance.maxPrepareMeshesPerCall) {	// Maximal x Meshes in einem Schritt vorbereiten
								var stadium = getStadiumCopy(instanceConfig, layers, si, preparationInstances[sti][si], stCenterX, stCenterY, subTileRadius);
								if (stadium !== false) {
									if (stadiums[sti] == undefined) {
										stadiums[sti] = {};
									}
									stadiums[sti][si] = stadium;
								}
								lastPreparedMeshIndex = sti+'/'+si;
								preparedMeshCountInCall++;
							} else {
								didFinish = false;
								break;
							}
						}
					}
					if (!didFinish) {
						break;
					}
				}
			}
			if (didFinish) {
				preparationInstances = null;
				finishedCallback();
			} else {
				window.setTimeout(function() {
					// LigaMap.console.log("delay remaining");
					prepareMeshes(finishedCallback);
				}, instanceConfig.performance.deferredPrepareMeshesDelay);
			}
		};


		var addedMeshCount = 0;
		var addMeshes = function(finishedCallback) {
			//loadingStatusContainer.text("Adding Data (bld, bld-lb)");
//			LigaMap.console.log("addMeshes()", layername, "datacount: ", data['desc'].length, "step: ", addedMeshCount);
			var addedMeshCountInCall = 0;
			if (data['desc']) while ((data['desc'][addedMeshCount] != undefined) && (addedMeshCountInCall<instanceConfig.performance.maxAddMeshesPerCall)) {	// Maximal x Meshes in einem Schritt hinzufügen
				var d = data['desc'][addedMeshCount];
				// LigaMap.console.log(d);
				var minX = instanceConfig.sceneScale*parseFloat(d.minX);
				var maxX = instanceConfig.sceneScale*parseFloat(d.maxX);
				var minY = instanceConfig.sceneScale*parseFloat(d.minY);
				var maxY = instanceConfig.sceneScale*parseFloat(d.maxY);
				if (d['_triangles'] && (d['_triangles'].length > 0)) {
					var zpos = 0;//5*parseFloat(d['zorder']);
					var triangleCount = Math.floor(d['_triangles'].length/2);
					if (d['_triangles'].length%2 == 1) {
						LigaMap.console.warn('Triangle count is not multiple of 2');
					}
//					LigaMap.console.log("addMeshes()", "mesh, triangles: ", triangleCount);
					var positions = new Float32Array( triangleCount * 3 ); // three components per vertex
					var geometry = new THREE.BufferGeometry();
					for (var j=0; j<triangleCount; j++) {
						var xpos = instanceConfig.sceneScale*parseFloat(d['_triangles'][j*2+0]);
						var ypos = instanceConfig.sceneScale*parseFloat(d['_triangles'][j*2+1]);
						positions[j*3+0] = xpos;
						positions[j*3+1] = ypos;
						positions[j*3+2] = zpos;
					}
					geometry.addAttribute( 'position', new THREE.BufferAttribute( positions, 3 ) );
					geometry.computeBoundingBox();
					geometry.computeBoundingSphere();

					var shader = new THREE.ShaderMaterial({
						name: 'fill',
						uniforms: {
							layerAlpha: {type: "f", value: 0.0},
							fogFactor: {type: "f", value: 1.0},
							resolution: {type: "v2", value: new THREE.Vector2(1.0, 1.0) },
							color: {type: "c", value: new THREE.Color(d['_color'])},
							layerMaxAlpha: {type: "f", value: alpha},
							// color: {type: "c", value: new THREE.Color(self.debugColors[(d.id+addedMeshCount)%self.debugColors.length])}
							//color: {type: "c", value: new THREE.Color(self.debugColors[(d.zorder)%self.debugColors.length])}
							detailTex: {type: "t", value: null},
							fadeInStartTime: {type: "f", value: getLastRenderTimestamp()},
							currentTime: {type: "f", value: getLastRenderTimestamp()},
							quadtreeFade: {type: "f", value: 1.0}
						},
						vertexShader: LigaMap.shader.getFillShaderVertex(),
						fragmentShader: "#define USE_FADEIN\n"+LigaMap.shader.getFillShaderFragment(instanceConfig.fogStartFactor, instanceConfig.minZoom, instanceConfig.maxZoom, instanceConfig.objectFadeInDuration),
						depthWrite: false,
						depthTest: false,
						// side: THREE.FrontSide,
						side: THREE.DoubleSide,	// TODO: temporär
						shading: THREE.FlatShading,
						transparent: true
					});
					if (instanceConfig.useFloorDetailTexture && (layername == 'krs')) {
						shader.uniforms.detailTex.value = detailTexture;
						shader.fragmentShader = "#define USE_DETAILMAP\n"+shader.fragmentShader;
					}
					if (d['lod'] !== undefined) {
						shader.userData = {lod:d['lod']};
						// shader.wireframe = true;	// Debug
					}

					//if (d.zorder == 0) {	// Debug
					var object = new THREE.Mesh(geometry, shader );
					object.matrixAutoUpdate = false;
					object.name = d['name'];
					if (layers[layername].quadtree.addObject(object, minX, maxX, minY, maxY, origMinX, origMinY)) {
						layers[layername].scene.add(object);
						layers[layername].usedMaterials.push(shader);
					} else {
						LigaMap.console.log("Objekt nicht im Quadtree:", d['id'], d['name'], minX+".."+maxX+"x"+minY+".."+maxY);
//						layers[layername].quadtree.print();
						// shader.uniforms.color.value =  new THREE.Color("#ff0000");
						// shader.wireframe = true;
					}
					//}	// Debug
					// Debug Min-/Max-Werte
					if (instanceConfig.showBoundingBoxes) {
						object = new THREE.Mesh( new THREE.PlaneGeometry((maxX-minX), (maxY-minY)), new THREE.MeshBasicMaterial( {name:'boundingBox-fill', color: new THREE.Color(self.debugColors[(d.id+addedMeshCount)%self.debugColors.length]), wireframe: true } ) );
						object.position.x = (maxX+minX)*0.5;
						object.position.y = (maxY+minY)*0.5;
						object.matrixAutoUpdate = false;
						object.updateMatrix();
						object.updateMatrixWorld();
						if (layers[layername+'-gr']) {
							layers[layername+'-gr'].scene.add(object);
						} else {
							layers[layername].scene.add(object);
						}
					}

					// LigaMap.console.log("Füge Objekt hinzu: ", d['id'], d['name'], d['_triangles'].length);
//				} else {
//					 LigaMap.console.log("Objekt ohne Geometrie: ", layername, d['id'], d['name']);
				}

				// Labels und Stadien
				if (doAddLabels) {
					// if (isUserLayer) {
					// 	d.name = d.name+' ('+d.stadiumName+')';
					// }
					// Label-Position und Infos
					var labelobject = addLabelObject(
							instanceConfig, layers, layername,
							instanceConfig.sceneScale*parseFloat(d.labelX),
							instanceConfig.sceneScale*parseFloat(d.labelY),
							d['id'],
							d['name'],
							d['_color'],
							origMinX, origMinY
						);
					// Stadien-Buffer
					if (isUserLayer) {
						// LoPoly
						// LigaMap.console.log("use stadium: ", d.stadiumName, possibleStadiums);
						if (instanceConfig.stadiumObjects[d.stadiumName] != undefined && instanceConfig.stadiumObjects[d.stadiumName].loVersion != undefined) {
							var currentStadium = stadiums[d.subTileIndex][d.stadiumName];
							var quat = new THREE.Quaternion();
							var stadiumRotation = Math.floor(4.0*Math.random())*0.25;	// 0.0, 0.25, 0.5, 0.75
							quat.setFromEuler(new THREE.Euler(Math.PI*0.5, stadiumRotation * 2 * Math.PI, 0));
							quat.normalize();
							var c = new THREE.Color(d['_color']);
							var c2;
							var a = d['deleted']? instanceConfig.stadiumObjects["stadium_buildings"]["opacity"] : 1.0;	// Weniger Alpha -> "Geister"-Stadion
							var stadiumPosition;
							if (currentStadium) {
								stadiumPosition = [(labelobject.position.x-currentStadium.object.position.x)/currentStadium.object.scale.x, (labelobject.position.y-currentStadium.object.position.y)/currentStadium.object.scale.y, 0];
								currentStadium.bufferOffsets.setXYZ(currentStadium.count, stadiumPosition[0]+instanceConfig.stadiumPositionOffset[0], stadiumPosition[1]+instanceConfig.stadiumPositionOffset[1], stadiumPosition[2]+instanceConfig.stadiumPositionOffset[2]);
								// quat.setFromEuler(new THREE.Euler(Math.PI*0.5, (Math.random() * 2 - 1) * Math.PI, 0));
								currentStadium.bufferOrientations.setXYZW(currentStadium.count, quat.x, quat.y, quat.z, quat.w );
								currentStadium.bufferUserColors.setXYZW(currentStadium.count, c.r, c.g, c.b, a);
								if (currentStadium.bufferUserColors2 !== null) {
									c2 = new THREE.Color(d['_color2']);
									currentStadium.bufferUserColors2.setXYZW(currentStadium.count, c2.r, c2.g, c2.b, a);
								}
								currentStadium.count++;
							} else {
//								LigaMap.console.log("stadium not found (addmeshes)", d.subTileIndex, d.stadiumName);
							}
							// LoLoPoly
							var stadiumLoName = instanceConfig.stadiumObjects[d.stadiumName].loVersion;
							currentStadium = stadiums[d.subTileIndex][stadiumLoName];
							if (currentStadium) {
								stadiumPosition = [(labelobject.position.x-currentStadium.object.position.x)/currentStadium.object.scale.x, (labelobject.position.y-currentStadium.object.position.y)/currentStadium.object.scale.y, 0];
								currentStadium.bufferOffsets.setXYZ(currentStadium.count, stadiumPosition[0]+instanceConfig.stadiumPositionOffset[0], stadiumPosition[1]+instanceConfig.stadiumPositionOffset[1], stadiumPosition[2]+instanceConfig.stadiumPositionOffset[2]);
								currentStadium.bufferOrientations.setXYZW(currentStadium.count, quat.x, quat.y, quat.z, quat.w );
								currentStadium.bufferUserColors.setXYZW(currentStadium.count, c.r, c.g, c.b, a);
								if (currentStadium.bufferUserColors2 !== null) {
									if (!c2) c2 = new THREE.Color(d['_color2']);
									currentStadium.bufferUserColors2.setXYZW(currentStadium.count, c2.r, c2.g, c2.b, a);
								}
								currentStadium.count++;
							} else {
//								LigaMap.console.log("stadium not found (addmeshes)", d.subTileIndex, stadiumLoName);
							}
							// Floor (Schatten)
							currentStadium = stadiums[d.subTileIndex]['stadium_floor'];
							if (currentStadium) {
								stadiumPosition = [(labelobject.position.x-currentStadium.object.position.x)/currentStadium.object.scale.x, (labelobject.position.y-currentStadium.object.position.y)/currentStadium.object.scale.y, 0];
								currentStadium.bufferOffsets.setXYZ(currentStadium.count, stadiumPosition[0], stadiumPosition[1], stadiumPosition[2]);
								currentStadium.bufferOrientations.setXYZW(currentStadium.count, quat.x, quat.y, quat.z, quat.w );
								var uoffset = instanceConfig.stadiumObjects[d.stadiumName].shadowTileU + (stadiumRotation*4.0);
								var voffset = instanceConfig.stadiumObjects[d.stadiumName].shadowTileV;
								var uvscale = 1.0/instanceConfig.stadiumShadowTextureTileCount;
								currentStadium.bufferUserColors.setXYZW(currentStadium.count, uoffset, voffset, uvscale, 1.0);	// Buffer für Orientierung misbrauchen
								currentStadium.count++;
							}
							// Boden-Kacheln
							if (instanceConfig.generateFloortiles) {
								var tileIndex = instanceConfig.stadiumObjects[d.stadiumName].floorIndex;
								var rot = Math.round(Math.random())*2;	// 0° oder 180° (also 0 oder 2)
								rot = rot + (stadiumRotation*4)%2;	// Stadienrotation hinzurechnen (90°)
								if (workerFloorTiles) {
									workerFloorTiles.postMessage({action: "addRandomFloorTileWithId", params: {
										id: currentID,
										positionX: labelobject.position.x,
										positionY: labelobject.position.y,
										rotation: rot,
										tileIndex: tileIndex,
										density: 1.0
									}});
								} else {
									LigaMap.floorTiles.addRandomFloorTileWithId(instanceConfig, currentID, floorTileAtlasTilesSizeLookup, labelobject.position.x, labelobject.position.y, rot, tileIndex/*, 1.0*/);	// no density needed
								}
							}
						}
					}
				}

				addedMeshCount++;
				addedMeshCountInCall++;
			}
			if (data['desc'] && data['desc'][addedMeshCount] != undefined) {
				// Meshes in Etappen hinzufügen
				/*if (window.requestIdleCallback) {
					window.requestIdleCallback(function(idleDeadline) {
						// LigaMap.console.log("requestIdleCallback, didTimeout?", idleDeadline.didTimeout, ", timeRemaining: ", idleDeadline.timeRemaining().toFixed(2), "ms");
						addMeshes(finishedCallback);
					}, {timeout:1000});
				} else {*/
				// LigaMap.console.log("add with timeout: %.2f", instanceConfig.performance.deferredAddMeshesDelay);
					setTimeout(function() {
						addMeshes(finishedCallback);
					}, instanceConfig.performance.deferredAddMeshesDelay);
				//}
			} else {
				// Stadien-Objekte hinzufügen
				if (isUserLayer) {
					// Platzhalter entfernen (dots)
					var posString = origMinX+'_'+origMaxX+'_'+origMinY+'_'+origMaxY;
					if ((typeof(LigaMap.mesh.loadedStadiumDots['loaded_'+posString]) !== 'undefined') &&
							(LigaMap.mesh.loadedStadiumDots['loaded_'+posString] !== null)) {
						// LigaMap.console.log("clear dots", origMinX, origMaxX, origMinY, origMaxY);
						// Zeitverzögert, bis Stadien eingeblendet sind
						(function(dotobject) {
							window.setTimeout(function() {
								layers['stadium_dots'].scene.remove(dotobject);
								layers['stadium_dots'].quadtree.removeObject(dotobject);
							}, 1000);
						}) (LigaMap.mesh.loadedStadiumDots['loaded_'+posString]);
						LigaMap.mesh.loadedStadiumDots['notLoaded_'+posString] = LigaMap.mesh.loadedStadiumDots['loaded_'+posString];	// merken, falls Stadien entladen werden
						LigaMap.mesh.loadedStadiumDots['loaded_'+posString] = null;
					} else {
						// LigaMap.console.log("clear dots (nothing)", origMinX, origMaxX, origMinY, origMaxY, typeof(LigaMap.mesh.loadedStadiumDots['loaded_'+posString]), LigaMap.mesh.loadedStadiumDots['loaded_'+posString]);
					}

					// LigaMap.console.log("stadiums ", origMinX, origMaxX, origMinY, origMaxY, stadiums);
					var subTileCount = Math.pow(2, instanceConfig.performance.stadiumsSubdivideForQuadtree);	// Wie bei prepare()
					var subTileWidth = (origMaxX-origMinX)/subTileCount;
					var subTileHeight = (origMaxY-origMinY)/subTileCount;
					var stx, sty, stMinX, stMaxX, stMinY, stMaxY;
					for (var sti=0; sti<stadiums.length; sti++) {
						sty = Math.floor(sti/subTileCount);
						stx = sti-(sty*subTileCount);
						stMinX = origMinX + stx*subTileWidth;
						stMaxX = origMinX + (stx+1)*subTileWidth;
						stMinY = origMinY + sty*subTileHeight;
						stMaxY = origMinY + (sty+1)*subTileHeight;
						for (var si in stadiums[sti]) if (stadiums[sti][si].count > 0) {
							// LigaMap.console.log(stadiums[sti][si]);
							// LigaMap.console.log("stadium", sti, si, stadiums[sti][si].object.position, stadiums[sti][si].object.scale, stadiums[sti][si].bufferOffsets);
							var stadiumConfigName = stadiums[sti][si].stadiumConfigName;
							if (layers[layername].quadtree.addObjectLoaded(stadiums[sti][si].object, origMinX, origMinY, instanceConfig.stadiumObjects[stadiumConfigName].layer)) {	// damit es entladen werden kann)
								layers[instanceConfig.stadiumObjects[stadiumConfigName].layer].scene.add(stadiums[sti][si].object);
								stadiums[sti][si].object.material.uniforms.fadeInStartTime.value = getLastRenderTimestamp();
								stadiums[sti][si].object.material.uniforms.currentTime.value = getLastRenderTimestamp();
								layers[instanceConfig.stadiumObjects[stadiumConfigName].layer].usedMaterials.push(stadiums[sti][si].object.material);
								// subTileIndex = Math.floor((y-origMinY)/subTileHeight) * subTileCount + Math.floor((x-origMinX)/subTileWidth);
								if (!layers[instanceConfig.stadiumObjects[stadiumConfigName].layer].quadtree.addObject(stadiums[sti][si].object, stMinX, stMaxX, stMinY, stMaxY)) {
									LigaMap.console.log("Stadion nicht im Quadtree:", sti, si, stMinX+".."+stMaxX, stMinY+".."+stMaxY);
								}
								if (instanceConfig.showBoundingBoxes) {
									var color = new THREE.Color(self.debugColors[addedMeshCount%self.debugColors.length]);
									var object = new THREE.Mesh(
										new THREE.PlaneGeometry((stMaxX-stMinX), (stMaxY-stMinY)),
										new THREE.MeshBasicMaterial( {name:'boundingBox-stadium', color: color, wireframe: true } )
									);
									object.name = "boundingBox";
									object.position.x = (stMinX+stMaxX)*0.5;
									object.position.y = (stMinY+stMaxY)*0.5;
									object.matrixAutoUpdate = false;
									object.updateMatrix();
									object.updateMatrixWorld();
									layers[instanceConfig.stadiumObjects[stadiumConfigName].layer].scene.add(object);
									if (!layers[instanceConfig.stadiumObjects[stadiumConfigName].layer].quadtree.addObject(object, stMinX, stMaxX, stMinY, stMaxY)) {
										LigaMap.console.log("Stadion-BoundingBox nicht im Quadtree:", stMinX+".."+stMaxX, stMinY+".."+stMaxY);
									}
								}
							}
						} else {
							LigaMap.console.warn("unnötiges Stadion?!", sti, si);
						}
					}
					if (instanceConfig.generateFloortiles) {
						if (workerFloorTiles) {
							workerFloorTiles.postMessage({action: "getFloorTileObject", params: {
								id: currentID,
								doExpensiveCalculations: !instanceConfig.generateFloortilesOnlyStadium
							}});
						} else {
							addFloorTileObjects(instanceConfig, layers, getLastRenderTimestamp, LigaMap.floorTiles.getFloorTileObject(instanceConfig, currentID, floorTileAtlasTilesSizeLookup, false));
						}
					}
				}
				finishedCallback();
			}
		}


		var addLines = function(finishedCallback) {
			//loadingStatusContainer.text("Adding Data (bld-gr)");
			if (data && data["brdr"] && data["brdr"]["v"] && (data["brdr"]["v"].length > 0)) {
//				LigaMap.console.log("addLines()", layername, "datacount: ", data['desc'].length, "verticecount: ", (data && data["brdr"] && data["brdr"]["v"])? data["brdr"]["v"].length : '?');
				var positions = new Float32Array( data["brdr"]["v"].length ); // three components per vertex
				var normals = new Float32Array( positions.length );
				var indices = new Uint32Array( data["brdr"]["t"].length );
				var geometry = new THREE.BufferGeometry();
				var zpos = 0;
				var j = 0;
				var minX = 999999;
				var maxX = -999999;
				var minY = 999999;
				var maxY = -999999;
				for (var i = 0; i<(data["brdr"]["v"].length/3); i++) {
					var xpos = instanceConfig.sceneScale*parseFloat(data["brdr"]["v"][i*3+0]);
					var ypos = instanceConfig.sceneScale*parseFloat(data["brdr"]["v"][i*3+1]);
					var refid = parseInt(data["brdr"]["v"][i*3+2]);
					var refxpos = instanceConfig.sceneScale*parseFloat(data["brdr"]["vn"][refid*2+0]);
					var refypos = instanceConfig.sceneScale*parseFloat(data["brdr"]["vn"][refid*2+1]);
					positions[i*3+0] = xpos;
					positions[i*3+1] = ypos;
					positions[i*3+2] = zpos;
					normals[i*3+0] = (xpos - refxpos) / instanceConfig.borderNormalFactor;
					normals[i*3+1] = (ypos - refypos) / instanceConfig.borderNormalFactor;
					normals[i*3+2] = 0;
					minX = Math.min(minX, xpos);
					maxX = Math.max(maxX, xpos);
					minY = Math.min(minY, ypos);
					maxY = Math.max(maxY, ypos);
				}
				for (var i = 0; i<data["brdr"]["t"].length; i++) {
					indices[i] = data["brdr"]["t"][i];
				}
				//LigaMap.console.log(positions);
				//geometry.computeFaceNormals();
				geometry.setIndex( new THREE.BufferAttribute( indices, 1 ) );
				geometry.addAttribute( 'position', new THREE.BufferAttribute( positions, 3 ) );
				geometry.addAttribute( 'normal', new THREE.BufferAttribute( normals, 3 ) );
				geometry.computeBoundingBox();
				geometry.computeBoundingSphere();
				//var m = new THREE.MeshBasicMaterial( { color: "#ffffff", side: THREE.DoubleSide });
				//LigaMap.console.log(m);
				var grShader = new THREE.ShaderMaterial({
					name: 'outline',
					uniforms: {
						offset: {type: "f", value: 0.0},
						layerAlpha: {type: "f", value: 0.0},
						layerMaxAlpha: {type: "f", value: 1.0},
						fogFactor: {type: "f", value: 1.0},
						resolution: {type: "v2", value: new THREE.Vector2(1.0, 1.0) },
						color: {type: "c", value: new THREE.Color(borderColor)},
						fadeInStartTime: {type: "f", value: getLastRenderTimestamp()},
						currentTime: {type: "f", value: getLastRenderTimestamp()},
						quadtreeFade: {type: "f", value: 1.0}
					},
					vertexShader: LigaMap.shader.getOutlineShaderVertex(),
					fragmentShader: "#define USE_FADEIN\n"+LigaMap.shader.getOutlineShaderFragment(instanceConfig.fogStartFactor, instanceConfig.minZoom, instanceConfig.maxZoom, instanceConfig.objectFadeInDuration),
					depthWrite: false,
					// side: THREE.BackSide,
					side: THREE.DoubleSide,	// TODO: temporär
					shading: THREE.FlatShading,
					transparent: true
				});
				var object = new THREE.Mesh(geometry, grShader );
				object.matrixAutoUpdate = false;
				object.name = 'outline-'+layername+'-'+minX+'-'+minY;
				if (layers[layername+'-gr']) {
					if (layers[layername].quadtree.addObjectLoaded(object, origMinX, origMinY, layername+'-gr')) {	// damit es entladen werden kann
						layers[layername+'-gr'].scene.add(object);
						layers[layername+'-gr'].usedMaterials.push(grShader);
	//					var bB = geometry.boundingBox;
						if (!layers[layername+'-gr'].quadtree.addObject(object, minX, maxX, minY, maxY)) {
							LigaMap.console.log("Linien-Objekt nicht im Quadtree:", minX, maxX, minY, maxY);
							//shader.uniforms.color.value =  new THREE.Color("#ff0000");
//							grShader.wireframe = true;	// ### ???
						}
					}
					//LigaMap.console.log("Linien hinzugefügt");
				}
				//loadingStatusContainer.empty();
			}
			finishedCallback();
		}


		self.mesh.addingCount++;
		updateLoadingCallback();
		prepareMeshes(function() {
			addMeshes(function() {
				addLines(function() {
					// LigaMap.console.timeEnd("addData("+layername+"*"+data['desc'].length+") "+origMinX+", "+origMinY);
					self.mesh.addingCount--;
					updateLoadingCallback();
					finishedCallback();
				});
			});
		});
	};

	self.mesh.getFloorTileAtlasTilesSizeLookup = function() {
		return floorTileAtlasTilesSizeLookup;
	};


	return self;
}(LigaMap || {}));
