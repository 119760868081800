/**
 * Copyright Christian Kubitza
 * christian@ck3d.de
 * 2015-2018
 */

var LigaMap = (function(self) {
	"use strict";

	var Layer = function(
		mapInstance,
		layerName,
		minPanX,
		maxPanX,
		minPanY,
		maxPanY,
		quadTreeDepth,
		quadtreeLoadingLevel,
		loadingQuadtreeOffset,
		quadtreeMaxDistanceFactor,
		onlyReadyWhenAllLeavesLoaded,
		readyStateDependsOnLayer,
		neverRender,
		neverUnload,
		maxLoadedLeavesCount,
		setRenderingIsDirty
	) {
		this.neverRender = (neverRender==true);
		this.neverUnload = (neverUnload==true);
		this.scene = null;
		this.loadingQuadtreeOffset = loadingQuadtreeOffset;
		this.onlyReadyWhenAllLeavesLoaded = (onlyReadyWhenAllLeavesLoaded==true);
		this.readyStateDependsOnLayer = readyStateDependsOnLayer;
		this.ready = false;
		this.minZoom = 0;
		this.maxZoom = 0;
		this.visible = true;
		this.autoVisible = true;
		this.alpha = 0.0;
		this.alphaAnimationSpeed = 2;
		this.alphaZoomLink = false;	// ?(0)...MinZoom(0)...MinZoom+Range(1)...MaxZoom-Range(1)...MaxZoom(0)...?(0)
		this.alphaZoomLinkMinFade = 0.33;
		this.alphaZoomLinkMaxFade = 0.33;
		this.alphaForcedAnimation = {
			active: false,
			startValue: 0.0,
			endValue: 1.0,
			startTime: 0,
			duration: 0.0,
			easingFunction: 'easeInOutQuad'
		};
		this.labelFont = null;	// Font String or null
		this.labelImportance = 0;
		this.clearFadeActive = false;
		this.clearFadeValue = 0.0;
		this.clearFadeCallback = null;
		this.usedMaterials = Array();
		var self = this;

		if (!this.neverRender) {
			this.scene = new THREE.Scene();
			this.scene.autoUpdate = false;
		}

		this.clearWithFade = function(clearedCallback) {	// delayed clear with fadeout
			this.clearFadeValue = 0.0;
			this.clearFadeCallback = clearedCallback;
			this.clearFadeActive = true;
		};
				
		this.clear = function(notFromQuadtree) {
			this.usedMaterials.length = 0;
			if (!notFromQuadtree) {
				// console.time("Layer.clear (quadtree) "+layerName);
				this.quadtree.clear();
				// console.timeEnd("Layer.clear (quadtree) "+layerName);
			}
			if (this.scene && this.scene.children) {
				// console.time("Layer.clear (layer) "+layerName);
				var obj;
				for( var i = this.scene.children.length - 1; i >= 0; i--) {
					obj = this.scene.children[i];
					if (!obj.isLight) {	// Lichter nicht entfernen
						this.scene.remove(obj);
						if (obj.geometry) {
							obj.geometry.dispose();
						}
						if (obj.material) {
							obj.material.dispose();
						}
					}
				}
				// console.timeEnd("Layer.clear (layer) "+layerName);
			}
		};
		
		this.removeObjects = function(objects, notFromQuadtree) {
			// var tstring = '';
			// for (var oi=0; oi<objects.length; oi++) {
			// 	tstring = tstring+(oi==0? '' : ', ')+(objects[oi].name? objects[oi].name : '[unnamed object]');
			// }
			if (objects.length >= self.scene.children.length) {	// everything
				// LigaMap.console.log("layer.removeObjects()->clear()", layerName, tstring);
				self.clear(notFromQuadtree);
			} else {
				// LigaMap.console.log("layer.removeObjects()", layerName, tstring);
				var i, obj, index, materialsToRemove = [];
				for (i=0; i<objects.length; i++) {
					obj = objects[i];
					if (obj.material) {
						materialsToRemove.push(obj.material);
					}
					self.scene.remove(obj);
					if (obj.geometry) {
						obj.geometry.dispose();
					}
				}
				// materialien rausfiltern, die noch benötigt werden
				for (i=0; i<self.scene.children.length; i++) {
					obj = self.scene.children[i];
					if (obj.material) {
						index = materialsToRemove.indexOf(obj.material);
						if (index >= 0) {
							materialsToRemove.splice(index, 1);
						}
					}
				}
				// übrige materialien entfernen
				for (i=0; i<materialsToRemove.length; i++) {
					index = self.usedMaterials.indexOf(materialsToRemove[i]);
					if (index >= 0) {
						self.usedMaterials.splice(index, 1);
					}
					materialsToRemove[i].dispose();
				}
			}
			return true;
		};

		this.updateVisibilityValues = function(zoomValue, timestamp, deltaSeconds) {	// Alpha und evtl. Visibility setzen abhängig von bestimmten Einstellungen und Werten
			if (this.onlyReadyWhenAllLeavesLoaded) {
				this.ready = this.quadtree.isAllLoaded();
				if (this.ready) {
					// is a one-time check
					this.onlyReadyWhenAllLeavesLoaded = false;
				}
			} else {
				this.ready = true;
			}
			if (this.readyStateDependsOnLayer != null) {
				var otherLayer = mapInstance.getLayer(this.readyStateDependsOnLayer);
				if (otherLayer != null) {
					this.ready = (this.ready && otherLayer.ready);
				} else {
					LigaMap.console.warn("depending layer not found");
				}
			}
			if (this.alphaForcedAnimation.active) {
				var d = Math.max(0, (timestamp - this.alphaForcedAnimation.startTime)) / (1000.0*this.alphaForcedAnimation.duration);
				if (d >= 1.0) {
					this.alphaForcedAnimation.active = false;
					d = 1.0;
				}
				this.alpha = EasingFunctions[this.alphaForcedAnimation.easingFunction](d);
				if (this.autoVisible) {
					this.visible = (this.alpha > 0.0);
				}
			} else {
				if (this.autoVisible) {
					if (this.alphaZoomLink) {
						var talpha = 1.0;
						// var minRange = (this.maxZoom-this.minZoom)*0.5*this.alphaZoomLinkMinFade;
						// var maxRange = (this.maxZoom-this.minZoom)*0.5*this.alphaZoomLinkMaxFade;
						// var minRange = Math.abs(Math.min((1.0*this.minZoom+1.0*this.maxZoom)*0.5, 1.0*this.minZoom + this.minZoom*this.alphaZoomLinkMinFade)) - Math.abs(this.minZoom);
						var minRange = this.minZoom - Math.abs(Math.max(0, this.minZoom - this.minZoom*this.alphaZoomLinkMinFade));
						var maxRange = this.maxZoom - Math.abs(Math.max(this.minZoom, this.maxZoom - this.maxZoom*this.alphaZoomLinkMaxFade));
						// LigaMap.console.log(layernames[i], zoomValue.toFixed(2), "..."+Math.floor(this.minZoom-minRange)+"_"+this.minZoom+"_"+Math.floor(this.maxZoom-maxRange)+"_"+this.maxZoom+"...");
						// LigaMap.console.log(zoomValue.toFixed(1), (this.minZoom-minRange).toFixed(1), (this.maxZoom-maxRange).toFixed(1));
						if (this.minZoom > zoomValue) {
							talpha = 1.0 - ((this.minZoom-zoomValue) / Math.max(1, minRange));
							// LigaMap.console.log(layernames[i], "alphaZoomMinRange:", zoomValue, talpha);
						} else
						if ((this.maxZoom-maxRange) < zoomValue) {
							talpha = (this.maxZoom-zoomValue) / Math.max(1, maxRange);
							// LigaMap.console.log(layernames[i], "alphaZoomMaxRange:", zoomValue, talpha);
						}
						this.alpha = Math.max(0, Math.min(1, talpha));
					} else {
						var d = deltaSeconds*this.alphaAnimationSpeed;
						this.alpha = ((zoomValue >= this.minZoom) && (zoomValue <= this.maxZoom))? this.alpha + d : this.alpha - d;
						this.alpha = Math.max(0, Math.min(1, this.alpha));
					}
					this.visible = (this.alpha > 0.0);
				} else {
					this.alpha = this.visible? 1 : 0;
				}
			}
			if (this.clearFadeActive) {
				this.clearFadeValue += deltaSeconds*this.alphaAnimationSpeed;
				if (this.clearFadeValue > 1.0) {	// fadeout finished
					this.clear();
					this.clearFadeActive = false;
					if (this.clearFadeCallback != null) {
						this.clearFadeCallback();
						this.clearFadeCallback = null;
					}
				} else {
					this.alpha *= 1.0 - EasingFunctions['easeInOutQuad'](this.clearFadeValue);
//					LigaMap.console.log("fade", layerName, this.alpha);
				}
			}
			// this.alpha = ((layernames[i] == 'user') || (layernames[i] == 'user-lb') || (layernames[i] == 'bld') || (layernames[i] == 'bld-gr'))? 1 : 0;	// Debug FC
			// this.alpha = (layernames[i] == 'bld')? this.alpha : 0;	// Debug BLD (no layer switch)
			// this.alpha = (layernames[i] == 'krs')? 0.5 : 0;	// Debug KRS (no layer switch)
			// if (layernames[i] == 'krs-gr') this.alpha = 1;	// Debug KRS-Label always on (no layer switch)
			// if (layernames[i] == 'bg') this.alpha = 1;	// Debug
			// if (layernames[i].indexOf('league') === 0) this.alpha = 0;	// Debug Disable Leagues
			// this.alpha = (layernames[i] == 'bg')? 1 : 0;	// Debug BLD (no layer switch)
			//LigaMap.console.log(layernames[i], this.alpha);

		};
		
		this.quadtree = new LigaMap.Quadtree(mapInstance, minPanX, maxPanX, minPanY, maxPanY, quadTreeDepth, quadtreeLoadingLevel, layerName, quadtreeMaxDistanceFactor, (neverUnload==true), maxLoadedLeavesCount, setRenderingIsDirty);
	};


	var labelStyles = [
			"700 17px 'Exo 2',sans-serif",	// 17px	// 23px
			"400 15px 'Exo 2',sans-serif",	// 15px	// 20px
			"300 14px 'Exo 2',sans-serif",	// 14px	// 19px
			"300 21px 'Exo 2',sans-serif",	// 21px	// 28px
			"400 17px 'Exo 2',sans-serif"
	];

	var layernames = Array(	'preload',	'bg',	'foreign',	'foreign-gr',	'bld',	'user',	'userpos',	'krs',	'gem',	'gem-gr',	'krs-gr',	'bld-gr',	'league',	'heatmap',	'stadium_dots',	'stadium_floor',	'stadium_shadow',	'stadium_lo',	'stadium',	'bld-lb',		'krs-lb',		'gem-lb',		'user-lb',		'connections',	'cnnctns-lb',	'debug'	);   			
	var layerMinZooms;
	var layerMaxZooms;
	var layerLabels;
	var layerLabelImportance;
	var layerAlphaZoomLink;
	var layerAlphaZLMinFade;
	var layerAlphaZLMaxFade;
	var layerLoadingLevel;
	var layerLoadingQTOffset;
	var layerMaxLevel;
	var layerMaxDistanceFactor;
	// Hinweis zum LoadingLevel: Wenn Dreiecke größer als eine Kachel sind, werden sie u.U. nicht in der richtigen Reihenfolge gezeichnet, weil die Kacheln nacheinander gezeichnet werden
	self.layerFixedLabelColors = {"user-lb": "#404040"};
	var onlyReadyWhenAllLeavesLoadedLayers = Array("bld");
	var neverRenderLayers = Array("userpos", "user", "gem");
	var neverUnloadLayers = Array("foreign", "bld", "userpos");	// Vorraussetzung: loadingLevel >= 0
	var readyStateDependsOnLayer = {
		"bld-gr": "bld",
		"bld-lb": "bld",
		"foreign": "bld",
		"foreign-gr": "bld",
		"connections": "bld",
		"cnnctns-lb": "bld",
		"userpos": "bld",
		"heatmap": "bld",
		"league": "bld"
	};

//	var whiteListLayers = Array("bld", "bld-lb", "bld-gr");
	
	self.createLayers = function(mapInstance, minPanX, maxPanX, minPanY, maxPanY, quadTreeDepth, setRenderingIsDirty) {
		var instanceConfig = mapInstance.getConfig();
		var s
		var layers = [];
		var maxDepth;

		var config = mapInstance.getConfig();
		layerMinZooms = config.layerMinZooms;   		
		layerMaxZooms = config.layerMaxZooms;   		
		layerLabels = Array(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, labelStyles[0], labelStyles[1], labelStyles[2], labelStyles[4], null, labelStyles[4], null);					
		layerLabelImportance = config.layerLabelImportance;  	
		layerAlphaZoomLink = config.layerAlphaZoomLink;   	
		layerAlphaZLMinFade = config.layerAlphaZLMinFade;   	
		layerAlphaZLMaxFade = config.layerAlphaZLMaxFade;   	
		layerLoadingLevel = config.layerLoadingLevel;   	
		layerLoadingQTOffset = config.layerLoadingQTOffset;   	
		layerMaxLevel = config.layerMaxLevel;  			
		layerMaxDistanceFactor = config.layerMaxDistanceFactor; 

		for (var i in layernames) {
			if (!instanceConfig.useDebugLayer && (layernames[i] == 'debug')) {
				continue;
			}
			if (instanceConfig.stadiumOnlyLoVersions && ((layernames[i] == 'stadium') || (layernames[i] == 'stadium_shadow'))) {
				continue;
			}
			if (!instanceConfig.generateFloortiles && (layernames[i] == 'stadium_floor')) {
				continue;
			}
			if (!instanceConfig.preloadMaterials && !instanceConfig.preloadTextures && (layernames[i] == 'preload')) {
				continue;
			}
			if (instanceConfig.noForeignLayer && ((layernames[i] == 'foreign') || (layernames[i] == 'foreign-gr'))) {
				continue;
			}
//			if (layernames[i].slice(-3) == '-lb') {
//				continue;
//			}
//			if (whiteListLayers.indexOf(layernames[i]) < 0) {
//				continue;
//			}
			maxDepth = quadTreeDepth;
			if (layerMaxLevel[i] >= 0) {
				maxDepth = Math.min(maxDepth, layerMaxLevel[i]);
			}
			if (layerLoadingLevel[i] >= 0) {
				maxDepth = Math.max(maxDepth, layerLoadingLevel[i]);
			}
			layers[layernames[i]] = new Layer(
				mapInstance,
				layernames[i],
				minPanX,
				maxPanX,
				minPanY,
				maxPanY,
				maxDepth,
				layerLoadingLevel[i],
				layerLoadingQTOffset[i],
				layerMaxDistanceFactor[i],
				(onlyReadyWhenAllLeavesLoadedLayers.indexOf(layernames[i]) >= 0),
				readyStateDependsOnLayer[layernames[i]],
				(neverRenderLayers.indexOf(layernames[i]) >= 0),
				(neverUnloadLayers.indexOf(layernames[i]) >= 0),
				instanceConfig.maxLoadedLeavesCount,
				setRenderingIsDirty
			);
			layers[layernames[i]].minZoom = layerMinZooms[i] *LigaMap.globalNavigationScale * instanceConfig.layerZoomFactor;	// Debug
			layers[layernames[i]].maxZoom = layerMaxZooms[i] *LigaMap.globalNavigationScale * instanceConfig.layerZoomFactor;	// Debug
			layers[layernames[i]].labelFont = layerLabels[i];
			layers[layernames[i]].labelImportance = layerLabelImportance[i];
			layers[layernames[i]].alphaZoomLink = layerAlphaZoomLink[i];
			layers[layernames[i]].alphaZoomLinkMinFade = layerAlphaZLMinFade[i];
			layers[layernames[i]].alphaZoomLinkMaxFade = layerAlphaZLMaxFade[i];
			if (instanceConfig.stadiumOnlyLoVersions && (layernames[i] == 'stadium_lo')) {
				layers[layernames[i]].minZoom = 0;
			}
		}
		return layers;
	};

	return self;
}(LigaMap || {}));
